html,
body,
#main {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

:root {
    -webkit-font-smoothing: antialiased
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: inherit
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
    margin: 0
}

body,
input,
textarea,
select,
button {
    font-size: 12px;
    font-family: sans-serif
}

body {
    --token-c93c3154-b582-4db9-bd10-52e824fd414c: #020718;
    --token-fed421e9-3f6f-4d58-bb1b-a95deadf172a: #FFFFFF;
    --token-0bc9d5e2-cabe-4eb2-b691-d577f23a764a: rgb(0, 82, 204);
    --token-f9b89b17-0991-4c0e-b401-ca53a64a9c7b: #02041D;
}

#__framer-badge-container {
    position: fixed;
    bottom: 0;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: right;
    pointer-events: none;
    z-index: 314159
}

.__framer-badge {
    pointer-events: auto
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-display: block;
    src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZJhiI2B.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-display: block;
    src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZthiI2B.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-display: block;
    src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZNhiI2B.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-display: block;
    src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZxhiI2B.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-display: block;
    src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZBhiI2B.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-display: block;
    src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZFhiI2B.woff2) format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-display: block;
    src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZ9hiA.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Inter Placeholder;
    ascent-override: 90%;
    descent-override: 22.43%;
    line-gap-override: 0%;
    size-adjust: 107.64%;
    src: local("Arial")
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 100;
    src: url(https://app.framerstatic.com/Inter-Thin.cyrillic-ext-BHDA65O4.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 100;
    src: url(https://app.framerstatic.com/Inter-Thin.cyrillic-Q5IXHU2O.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 100;
    src: url(https://app.framerstatic.com/Inter-Thin.greek-ext-VVOQA2NE.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 100;
    src: url(https://app.framerstatic.com/Inter-Thin.greek-E3WNEUH6.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 100;
    src: url(https://app.framerstatic.com/Inter-Thin.latin-ext-5RC4NRHN.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 100;
    src: url(https://app.framerstatic.com/Inter-Thin.latin-GJ7CRGHG.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 100;
    src: url(https://app.framerstatic.com/Inter-Thin.vietnamese-Z3Y7DPWO.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 200;
    src: url(https://app.framerstatic.com/Inter-ExtraLight.cyrillic-ext-7NWKXNAT.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 200;
    src: url(https://app.framerstatic.com/Inter-ExtraLight.cyrillic-IBWUD6RJ.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 200;
    src: url(https://app.framerstatic.com/Inter-ExtraLight.greek-ext-HUMDTRBU.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 200;
    src: url(https://app.framerstatic.com/Inter-ExtraLight.greek-ZLLHEEN3.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 200;
    src: url(https://app.framerstatic.com/Inter-ExtraLight.latin-ext-72JE5FGU.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 200;
    src: url(https://app.framerstatic.com/Inter-ExtraLight.latin-2JHDAFAQ.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 200;
    src: url(https://app.framerstatic.com/Inter-ExtraLight.vietnamese-VKRCA4VC.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    src: url(https://app.framerstatic.com/Inter-Light.cyrillic-ext-G3OTPKE4.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    src: url(https://app.framerstatic.com/Inter-Light.cyrillic-JO7ZJTP6.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    src: url(https://app.framerstatic.com/Inter-Light.greek-ext-N63XCCK3.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    src: url(https://app.framerstatic.com/Inter-Light.greek-5GVUXSXZ.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    src: url(https://app.framerstatic.com/Inter-Light.latin-ext-RREJIMQ3.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    src: url(https://app.framerstatic.com/Inter-Light.latin-EKF76FXR.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    src: url(https://app.framerstatic.com/Inter-Light.vietnamese-GVC2UOFS.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    src: url(https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    src: url(https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    src: url(https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    src: url(https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    src: url(https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    src: url(https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    src: url(https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    src: url(https://app.framerstatic.com/Inter-Medium.cyrillic-ext-M4WHNGTS.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    src: url(https://app.framerstatic.com/Inter-Medium.cyrillic-JVU2PANX.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    src: url(https://app.framerstatic.com/Inter-Medium.greek-ext-4KCQBEIZ.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    src: url(https://app.framerstatic.com/Inter-Medium.greek-DPOQGN7L.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    src: url(https://app.framerstatic.com/Inter-Medium.latin-ext-J4DBSW7F.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    src: url(https://app.framerstatic.com/Inter-Medium.latin-Y3IVPL46.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    src: url(https://app.framerstatic.com/Inter-Medium.vietnamese-PJV76O4P.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    src: url(https://app.framerstatic.com/Inter-SemiBold.cyrillic-ext-C7KWUKA7.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    src: url(https://app.framerstatic.com/Inter-SemiBold.cyrillic-JWV7SOZ6.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    src: url(https://app.framerstatic.com/Inter-SemiBold.greek-ext-FBKSFTSU.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    src: url(https://app.framerstatic.com/Inter-SemiBold.greek-EQ3PSENU.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    src: url(https://app.framerstatic.com/Inter-SemiBold.latin-ext-ULRSO3ZR.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    src: url(https://app.framerstatic.com/Inter-SemiBold.latin-RDYY2AG2.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    src: url(https://app.framerstatic.com/Inter-SemiBold.vietnamese-ESQNSEQ3.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    src: url(https://app.framerstatic.com/Inter-Bold.cyrillic-ext-XOTVL7ZR.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    src: url(https://app.framerstatic.com/Inter-Bold.cyrillic-6LOMBC2V.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    src: url(https://app.framerstatic.com/Inter-Bold.greek-ext-WXWSJXLB.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    src: url(https://app.framerstatic.com/Inter-Bold.greek-YRST7ODZ.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    src: url(https://app.framerstatic.com/Inter-Bold.latin-ext-BASA5UL3.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    src: url(https://app.framerstatic.com/Inter-Bold.latin-UCM45LQF.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    src: url(https://app.framerstatic.com/Inter-Bold.vietnamese-OEVJMXEP.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    src: url(https://app.framerstatic.com/Inter-ExtraBold.cyrillic-ext-7Q6SVIPE.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    src: url(https://app.framerstatic.com/Inter-ExtraBold.cyrillic-JSLPE6KW.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    src: url(https://app.framerstatic.com/Inter-ExtraBold.greek-ext-6OYGJJV7.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    src: url(https://app.framerstatic.com/Inter-ExtraBold.greek-SHW2FPC4.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    src: url(https://app.framerstatic.com/Inter-ExtraBold.latin-ext-A5DUFOP6.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    src: url(https://app.framerstatic.com/Inter-ExtraBold.latin-OW4UGSRU.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 800;
    src: url(https://app.framerstatic.com/Inter-ExtraBold.vietnamese-IBBC7NGV.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    src: url(https://app.framerstatic.com/Inter-Black.cyrillic-ext-TU4ITVTR.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    src: url(https://app.framerstatic.com/Inter-Black.cyrillic-JX7CGTYD.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    src: url(https://app.framerstatic.com/Inter-Black.greek-ext-LS3GCBFI.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    src: url(https://app.framerstatic.com/Inter-Black.greek-ZWCJHBP5.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    src: url(https://app.framerstatic.com/Inter-Black.latin-ext-BZLEUMX6.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    src: url(https://app.framerstatic.com/Inter-Black.latin-TETRYDF7.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 900;
    src: url(https://app.framerstatic.com/Inter-Black.vietnamese-RXQCC3EJ.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 100;
    src: url(https://app.framerstatic.com/Inter-ThinItalic.cyrillic-ext-2RGKWUBV.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 100;
    src: url(https://app.framerstatic.com/Inter-ThinItalic.cyrillic-TDYIP5HV.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 100;
    src: url(https://app.framerstatic.com/Inter-ThinItalic.greek-ext-WR4TIDYZ.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 100;
    src: url(https://app.framerstatic.com/Inter-ThinItalic.greek-V3WZMSP7.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 100;
    src: url(https://app.framerstatic.com/Inter-ThinItalic.latin-ext-TXFTJONQ.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 100;
    src: url(https://app.framerstatic.com/Inter-ThinItalic.latin-RVEBKP6O.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 100;
    src: url(https://app.framerstatic.com/Inter-ThinItalic.vietnamese-WCBPP4MD.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 200;
    src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.cyrillic-ext-OVCHMVPD.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 200;
    src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.cyrillic-BRDZE5UH.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 200;
    src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.greek-ext-YV64YFFH.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 200;
    src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.greek-EJVCLASM.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 200;
    src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.latin-ext-2MHTM56A.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 200;
    src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.latin-JB3CJMMM.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 200;
    src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.vietnamese-3EJ3IQYS.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 300;
    src: url(https://app.framerstatic.com/Inter-LightItalic.cyrillic-ext-C2S5XS3D.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 300;
    src: url(https://app.framerstatic.com/Inter-LightItalic.cyrillic-E7CYPW5D.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 300;
    src: url(https://app.framerstatic.com/Inter-LightItalic.greek-ext-ROSAFPGE.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 300;
    src: url(https://app.framerstatic.com/Inter-LightItalic.greek-PAWWH37Z.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 300;
    src: url(https://app.framerstatic.com/Inter-LightItalic.latin-ext-N2Z67Z45.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 300;
    src: url(https://app.framerstatic.com/Inter-LightItalic.latin-SLSTLWEU.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 300;
    src: url(https://app.framerstatic.com/Inter-LightItalic.vietnamese-RLGM2D3Y.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    src: url(https://app.framerstatic.com/Inter-Italic.cyrillic-ext-YDGMJOJO.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    src: url(https://app.framerstatic.com/Inter-Italic.cyrillic-BFOVMAQB.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    src: url(https://app.framerstatic.com/Inter-Italic.greek-ext-4KOU3AHC.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    src: url(https://app.framerstatic.com/Inter-Italic.greek-OJTBJNE6.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    src: url(https://app.framerstatic.com/Inter-Italic.latin-ext-H4B22QN6.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    src: url(https://app.framerstatic.com/Inter-Italic.latin-2DWX32EN.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    src: url(https://app.framerstatic.com/Inter-Italic.vietnamese-TYMT6CKW.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 500;
    src: url(https://app.framerstatic.com/Inter-MediumItalic.cyrillic-ext-QYBZQ2NF.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 500;
    src: url(https://app.framerstatic.com/Inter-MediumItalic.cyrillic-ZHAJHZCC.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 500;
    src: url(https://app.framerstatic.com/Inter-MediumItalic.greek-ext-W5ABYGZR.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 500;
    src: url(https://app.framerstatic.com/Inter-MediumItalic.greek-RGNSYVNV.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 500;
    src: url(https://app.framerstatic.com/Inter-MediumItalic.latin-ext-7DZEPSAS.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 500;
    src: url(https://app.framerstatic.com/Inter-MediumItalic.latin-SKPQAMBJ.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 500;
    src: url(https://app.framerstatic.com/Inter-MediumItalic.vietnamese-23WIFZV7.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 600;
    src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.cyrillic-ext-MEHHCDC3.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 600;
    src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.cyrillic-YACNRNDE.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 600;
    src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.greek-ext-GFL7KADI.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 600;
    src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.greek-5W77OPRT.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 600;
    src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.latin-ext-OYJJ2W6R.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 600;
    src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.latin-KBLJMBDH.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 600;
    src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.vietnamese-5ZFOV65G.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 700;
    src: url(https://app.framerstatic.com/Inter-BoldItalic.cyrillic-ext-PEYDHC3S.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 700;
    src: url(https://app.framerstatic.com/Inter-BoldItalic.cyrillic-7EIL6JWG.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 700;
    src: url(https://app.framerstatic.com/Inter-BoldItalic.greek-ext-3DJOYQMH.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 700;
    src: url(https://app.framerstatic.com/Inter-BoldItalic.greek-TJBTLTT7.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 700;
    src: url(https://app.framerstatic.com/Inter-BoldItalic.latin-ext-FVPCPRBJ.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 700;
    src: url(https://app.framerstatic.com/Inter-BoldItalic.latin-5ZFQS4XK.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: italic;
    font-weight: 700;
    src: url(https://app.framerstatic.com/Inter-BoldItalic.vietnamese-W2625PGF.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 800;
    src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.cyrillic-ext-ACWDZ3VD.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 800;
    src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.cyrillic-ZKBSDAI2.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 800;
    src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.greek-ext-3CY5DPTP.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 800;
    src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.greek-YL5CC63W.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 800;
    src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.latin-ext-7IZFJI4D.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 800;
    src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.latin-O5HH4IX3.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 800;
    src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.vietnamese-UW3XUJOD.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    src: url(https://app.framerstatic.com/Inter-BlackItalic.cyrillic-ext-TRM4ITYR.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    src: url(https://app.framerstatic.com/Inter-BlackItalic.cyrillic-FPHIQVZS.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    src: url(https://app.framerstatic.com/Inter-BlackItalic.greek-ext-JTGUUSP5.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    src: url(https://app.framerstatic.com/Inter-BlackItalic.greek-LUNA3RFO.woff2) format("woff2");
    unicode-range: U+0370-03FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    src: url(https://app.framerstatic.com/Inter-BlackItalic.latin-ext-KU7ICFYH.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    src: url(https://app.framerstatic.com/Inter-BlackItalic.latin-FRVXWQSB.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-display: block;
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    src: url(https://app.framerstatic.com/Inter-BlackItalic.vietnamese-2Q7MQKJX.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

[data-framer-component-type] {
    position: absolute
}

[data-framer-component-type=Text] {
    cursor: inherit
}

[data-framer-component-text-autosized] * {
    white-space: pre
}

[data-framer-component-type=Text]>* {
    text-align: var(--framer-text-alignment, start)
}

[data-framer-component-type=Text] span span,
[data-framer-component-type=Text] p span,
[data-framer-component-type=Text] h1 span,
[data-framer-component-type=Text] h2 span,
[data-framer-component-type=Text] h3 span,
[data-framer-component-type=Text] h4 span,
[data-framer-component-type=Text] h5 span,
[data-framer-component-type=Text] h6 span {
    display: block
}

[data-framer-component-type=Text] span span span,
[data-framer-component-type=Text] p span span,
[data-framer-component-type=Text] h1 span span,
[data-framer-component-type=Text] h2 span span,
[data-framer-component-type=Text] h3 span span,
[data-framer-component-type=Text] h4 span span,
[data-framer-component-type=Text] h5 span span,
[data-framer-component-type=Text] h6 span span {
    display: unset
}

[data-framer-component-type=Text] div div span,
[data-framer-component-type=Text] a div span,
[data-framer-component-type=Text] span span span,
[data-framer-component-type=Text] p span span,
[data-framer-component-type=Text] h1 span span,
[data-framer-component-type=Text] h2 span span,
[data-framer-component-type=Text] h3 span span,
[data-framer-component-type=Text] h4 span span,
[data-framer-component-type=Text] h5 span span,
[data-framer-component-type=Text] h6 span span,
[data-framer-component-type=Text] a {
    font-family: var(--font-family);
    font-style: var(--font-style);
    font-weight: min(calc(var(--framer-font-weight-increase, 0) + var(--font-weight, 400)), 900);
    color: var(--text-color);
    letter-spacing: var(--letter-spacing);
    font-size: var(--font-size);
    text-transform: var(--text-transform);
    text-decoration: var(--text-decoration);
    line-height: var(--line-height)
}

[data-framer-component-type=Text] div div span,
[data-framer-component-type=Text] a div span,
[data-framer-component-type=Text] span span span,
[data-framer-component-type=Text] p span span,
[data-framer-component-type=Text] h1 span span,
[data-framer-component-type=Text] h2 span span,
[data-framer-component-type=Text] h3 span span,
[data-framer-component-type=Text] h4 span span,
[data-framer-component-type=Text] h5 span span,
[data-framer-component-type=Text] h6 span span,
[data-framer-component-type=Text] a {
    --font-family: var(--framer-font-family);
    --font-style: var(--framer-font-style);
    --font-weight: var(--framer-font-weight);
    --text-color: var(--framer-text-color);
    --letter-spacing: var(--framer-letter-spacing);
    --font-size: var(--framer-font-size);
    --text-transform: var(--framer-text-transform);
    --text-decoration: var(--framer-text-decoration);
    --line-height: var(--framer-line-height)
}

[data-framer-component-type=Text] a,
[data-framer-component-type=Text] a div span,
[data-framer-component-type=Text] a span span span,
[data-framer-component-type=Text] a p span span,
[data-framer-component-type=Text] a h1 span span,
[data-framer-component-type=Text] a h2 span span,
[data-framer-component-type=Text] a h3 span span,
[data-framer-component-type=Text] a h4 span span,
[data-framer-component-type=Text] a h5 span span,
[data-framer-component-type=Text] a h6 span span {
    --font-family: var(--framer-link-font-family, var(--framer-font-family));
    --font-style: var(--framer-link-font-style, var(--framer-font-style));
    --font-weight: var(--framer-link-font-weight, var(--framer-font-weight));
    --text-color: var(--framer-link-text-color, var(--framer-text-color));
    --font-size: var(--framer-link-font-size, var(--framer-font-size));
    --text-transform: var(--framer-link-text-transform, var(--framer-text-transform));
    --text-decoration: var(--framer-link-text-decoration, var(--framer-text-decoration))
}

[data-framer-component-type=Text] a:hover,
[data-framer-component-type=Text] a div span:hover,
[data-framer-component-type=Text] a span span span:hover,
[data-framer-component-type=Text] a p span span:hover,
[data-framer-component-type=Text] a h1 span span:hover,
[data-framer-component-type=Text] a h2 span span:hover,
[data-framer-component-type=Text] a h3 span span:hover,
[data-framer-component-type=Text] a h4 span span:hover,
[data-framer-component-type=Text] a h5 span span:hover,
[data-framer-component-type=Text] a h6 span span:hover {
    --font-family: var(--framer-link-hover-font-family, var(--framer-link-font-family, var(--framer-font-family)));
    --font-style: var(--framer-link-hover-font-style, var(--framer-link-font-style, var(--framer-font-style)));
    --font-weight: var(--framer-link-hover-font-weight, var(--framer-link-font-weight, var(--framer-font-weight)));
    --text-color: var(--framer-link-hover-text-color, var(--framer-link-text-color, var(--framer-text-color)));
    --font-size: var(--framer-link-hover-font-size, var(--framer-link-font-size, var(--framer-font-size)));
    --text-transform: var(--framer-link-hover-text-transform, var(--framer-link-text-transform, var(--framer-text-transform)));
    --text-decoration: var(--framer-link-hover-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration)))
}

[data-framer-component-type=Text].isCurrent a,
[data-framer-component-type=Text].isCurrent a div span,
[data-framer-component-type=Text].isCurrent a span span span,
[data-framer-component-type=Text].isCurrent a p span span,
[data-framer-component-type=Text].isCurrent a h1 span span,
[data-framer-component-type=Text].isCurrent a h2 span span,
[data-framer-component-type=Text].isCurrent a h3 span span,
[data-framer-component-type=Text].isCurrent a h4 span span,
[data-framer-component-type=Text].isCurrent a h5 span span,
[data-framer-component-type=Text].isCurrent a h6 span span {
    --font-family: var(--framer-link-current-font-family, var(--framer-link-font-family, var(--framer-font-family)));
    --font-style: var(--framer-link-current-font-style, var(--framer-link-font-style, var(--framer-font-style)));
    --font-weight: var(--framer-link-current-font-weight, var(--framer-link-font-weight, var(--framer-font-weight)));
    --text-color: var(--framer-link-current-text-color, var(--framer-link-text-color, var(--framer-text-color)));
    --font-size: var(--framer-link-current-font-size, var(--framer-link-font-size, var(--framer-font-size)));
    --text-transform: var(--framer-link-current-text-transform, var(--framer-link-text-transform, var(--framer-text-transform)));
    --text-decoration: var(--framer-link-current-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration)))
}

p.framer-text,
div.framer-text,
h1.framer-text,
h2.framer-text,
h3.framer-text,
h4.framer-text,
h5.framer-text,
h6.framer-text,
ol.framer-text,
ul.framer-text {
    margin: 0;
    padding: 0
}

p.framer-text,
div.framer-text,
h1.framer-text,
h2.framer-text,
h3.framer-text,
h4.framer-text,
h5.framer-text,
h6.framer-text,
li.framer-text,
ol.framer-text,
ul.framer-text,
span.framer-text:not([data-text-fill]) {
    font-family: var(--framer-font-family, Inter, Inter Placeholder, sans-serif);
    font-style: var(--framer-font-style, normal);
    font-weight: var(--framer-font-weight, 400);
    color: var(--framer-text-color, #000);
    font-size: calc(var(--framer-font-size, 16px) * var(--framer-font-size-scale, 1));
    letter-spacing: var(--framer-letter-spacing, 0);
    text-transform: var(--framer-text-transform, none);
    text-decoration: var(--framer-text-decoration, none);
    line-height: var(--framer-line-height, 1.2em);
    text-align: var(--framer-text-alignment, start)
}

strong.framer-text {
    font-weight: bolder
}

em.framer-text {
    font-style: italic
}

p.framer-text:not(:first-child),
div.framer-text:not(:first-child),
h1.framer-text:not(:first-child),
h2.framer-text:not(:first-child),
h3.framer-text:not(:first-child),
h4.framer-text:not(:first-child),
h5.framer-text:not(:first-child),
h6.framer-text:not(:first-child),
ol.framer-text:not(:first-child),
ul.framer-text:not(:first-child),
.framer-image.framer-text:not(:first-child) {
    margin-top: var(--framer-paragraph-spacing, 0)
}

li.framer-text>ul.framer-text:nth-child(2),
li.framer-text>ol.framer-text:nth-child(2) {
    margin-top: 0
}

.framer-text[data-text-fill] {
    display: inline-block;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: max(0em, calc(calc(1.3em - var(--framer-line-height, 1.3em)) / 2));
    margin: min(0em, calc(calc(1.3em - var(--framer-line-height, 1.3em)) / -2))
}

code.framer-text,
code.framer-text span.framer-text:not([data-text-fill]) {
    font-family: var(--framer-code-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
    font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
    font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
    color: var(--framer-code-text-color, var(--framer-text-color, #000));
    font-size: calc(var(--framer-font-size, 16px) * var(--framer-font-size-scale, 1));
    letter-spacing: var(--framer-letter-spacing, 0);
    line-height: var(--framer-line-height, 1.2em)
}

a.framer-text,
a.framer-text span.framer-text:not([data-text-fill]) {
    font-family: var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
    font-style: var(--framer-link-font-style, var(--framer-font-style, normal));
    font-weight: var(--framer-link-font-weight, var(--framer-font-weight, 400));
    color: var(--framer-link-text-color, var(--framer-text-color, #000));
    font-size: calc(var(--framer-link-font-size, var(--framer-font-size, 16px)) * var(--framer-font-size-scale, 1));
    text-transform: var(--framer-link-text-transform, var(--framer-text-transform, none));
    text-decoration: var(--framer-link-text-decoration, var(--framer-text-decoration, none));
    cursor: var(--framer-custom-cursors, pointer)
}

code.framer-text a.framer-text,
code.framer-text a.framer-text span.framer-text:not([data-text-fill]) {
    font-family: var(--framer-code-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
    font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
    font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
    color: var(--framer-link-text-color, var(--framer-code-text-color, var(--framer-text-color, #000)));
    font-size: calc(var(--framer-link-font-size, var(--framer-font-size, 16px)) * var(--framer-font-size-scale, 1))
}

a.framer-text:hover,
a.framer-text:hover span.framer-text:not([data-text-fill]) {
    font-family: var(--framer-link-hover-font-family, var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif)));
    font-style: var(--framer-link-hover-font-style, var(--framer-link-font-style, var(--framer-font-style, normal)));
    font-weight: var(--framer-link-hover-font-weight, var(--framer-link-font-weight, var(--framer-font-weight, 400)));
    color: var(--framer-link-hover-text-color, var(--framer-link-text-color, var(--framer-text-color, #000)));
    font-size: calc(var(--framer-link-hover-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px))) * var(--framer-font-size-scale, 1));
    text-transform: var(--framer-link-hover-text-transform, var(--framer-link-text-transform, var(--framer-text-transform, none)));
    text-decoration: var(--framer-link-hover-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration, none)))
}

code.framer-text a.framer-text:hover,
code.framer-text a.framer-text:hover span.framer-text:not([data-text-fill]) {
    font-family: var(--framer-code-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
    font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
    font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
    color: var(--framer-link-hover-text-color, var(--framer-link-text-color, var(--framer-code-text-color, var(--framer-text-color, #000))));
    font-size: calc(var(--framer-link-hover-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px))) * var(--framer-font-size-scale, 1))
}

a.framer-text[data-framer-page-link-current],
a.framer-text[data-framer-page-link-current] span.framer-text:not([data-text-fill]) {
    font-family: var(--framer-link-current-font-family, var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif)));
    font-style: var(--framer-link-current-font-style, var(--framer-link-font-style, var(--framer-font-style, normal)));
    font-weight: var(--framer-link-current-font-weight, var(--framer-link-font-weight, var(--framer-font-weight, 400)));
    color: var(--framer-link-current-text-color, var(--framer-link-text-color, var(--framer-text-color, #000)));
    font-size: calc(var(--framer-link-current-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px))) * var(--framer-font-size-scale, 1));
    text-transform: var(--framer-link-current-text-transform, var(--framer-link-text-transform, var(--framer-text-transform, none)));
    text-decoration: var(--framer-link-current-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration, none)))
}

code.framer-text a.framer-text[data-framer-page-link-current],
code.framer-text a.framer-text[data-framer-page-link-current] span.framer-text:not([data-text-fill]) {
    font-family: var(--framer-code-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
    font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
    font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
    color: var(--framer-link-current-text-color, var(--framer-link-text-color, var(--framer-code-text-color, var(--framer-text-color, #000))));
    font-size: calc(var(--framer-link-current-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px))) * var(--framer-font-size-scale, 1))
}

a.framer-text[data-framer-page-link-current]:hover,
a.framer-text[data-framer-page-link-current]:hover span.framer-text:not([data-text-fill]) {
    font-family: var(--framer-link-hover-font-family, var(--framer-link-current-font-family, var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif))));
    font-style: var(--framer-link-hover-font-style, var(--framer-link-current-font-style, var(--framer-link-font-style, var(--framer-font-style, normal))));
    font-weight: var(--framer-link-hover-font-weight, var(--framer-link-current-font-weight, var(--framer-link-font-weight, var(--framer-font-weight, 400))));
    color: var(--framer-link-hover-text-color, var(--framer-link-current-text-color, var(--framer-link-text-color, var(--framer-text-color, #000))));
    font-size: calc(var(--framer-link-hover-font-size, var(--framer-link-current-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px)))) * var(--framer-font-size-scale, 1));
    text-transform: var(--framer-link-hover-text-transform, var(--framer-link-current-text-transform, var(--framer-link-text-transform, var(--framer-text-transform, none))));
    text-decoration: var(--framer-link-hover-text-decoration, var(--framer-link-current-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration, none))))
}

code.framer-text a.framer-text[data-framer-page-link-current]:hover,
code.framer-text a.framer-text[data-framer-page-link-current]:hover span.framer-text:not([data-text-fill]) {
    font-family: var(--framer-code-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
    font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
    font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
    color: var(--framer-link-hover-text-color, var(--framer-link-current-text-color, var(--framer-link-text-color, var(--framer-code-text-color, var(--framer-text-color, #000)))));
    font-size: calc(var(--framer-link-hover-font-size, var(--framer-link-current-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px)))) * var(--framer-font-size-scale, 1))
}

.framer-image.framer-text {
    display: block;
    max-width: 100%;
    height: auto
}

.text-styles-preset-reset.framer-text {
    --framer-font-family: Inter, Inter Placeholder, sans-serif;
    --framer-font-style: normal;
    --framer-font-weight: 500;
    --framer-text-color: #000;
    --framer-font-size: 16px;
    --framer-letter-spacing: 0;
    --framer-text-transform: none;
    --framer-text-decoration: none;
    --framer-line-height: 1.2em;
    --framer-text-alignment: start
}

ul.framer-text,
ol.framer-text {
    display: table;
    width: 100%
}

li.framer-text {
    display: table-row;
    counter-increment: list-item;
    list-style: none
}

ol.framer-text>li.framer-text:before {
    display: table-cell;
    width: 2.25ch;
    box-sizing: border-box;
    padding-inline-end: .75ch;
    content: counter(list-item) ".";
    white-space: nowrap
}

ul.framer-text>li.framer-text:before {
    display: table-cell;
    width: 2.25ch;
    box-sizing: border-box;
    padding-inline-end: .75ch;
    content: "\2022"
}

.framer-text-module[style*=aspect-ratio]>:first-child {
    width: 100%
}

@supports not (aspect-ratio: 1) {
    .framer-text-module[style*=aspect-ratio] {
        position: relative
    }
}

@supports not (aspect-ratio: 1) {
    .framer-text-module[style*=aspect-ratio]:before {
        content: "";
        display: block;
        padding-bottom: calc(100% / calc(var(--aspect-ratio)))
    }
}

@supports not (aspect-ratio: 1) {
    .framer-text-module[style*=aspect-ratio]>:first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%
    }
}

[data-framer-component-type=DeprecatedRichText] {
    cursor: inherit
}

[data-framer-component-type=DeprecatedRichText] .text-styles-preset-reset {
    --framer-font-family: Inter, Inter Placeholder, sans-serif;
    --framer-font-style: normal;
    --framer-font-weight: 500;
    --framer-text-color: #000;
    --framer-font-size: 16px;
    --framer-letter-spacing: 0;
    --framer-text-transform: none;
    --framer-text-decoration: none;
    --framer-line-height: 1.2em;
    --framer-text-alignment: start
}

[data-framer-component-type=DeprecatedRichText] p,
[data-framer-component-type=DeprecatedRichText] div,
[data-framer-component-type=DeprecatedRichText] h1,
[data-framer-component-type=DeprecatedRichText] h2,
[data-framer-component-type=DeprecatedRichText] h3,
[data-framer-component-type=DeprecatedRichText] h4,
[data-framer-component-type=DeprecatedRichText] h5,
[data-framer-component-type=DeprecatedRichText] h6 {
    margin: 0;
    padding: 0
}

[data-framer-component-type=DeprecatedRichText] p,
[data-framer-component-type=DeprecatedRichText] div,
[data-framer-component-type=DeprecatedRichText] h1,
[data-framer-component-type=DeprecatedRichText] h2,
[data-framer-component-type=DeprecatedRichText] h3,
[data-framer-component-type=DeprecatedRichText] h4,
[data-framer-component-type=DeprecatedRichText] h5,
[data-framer-component-type=DeprecatedRichText] h6,
[data-framer-component-type=DeprecatedRichText] li,
[data-framer-component-type=DeprecatedRichText] ol,
[data-framer-component-type=DeprecatedRichText] ul,
[data-framer-component-type=DeprecatedRichText] span:not([data-text-fill]) {
    font-family: var(--framer-font-family, Inter, Inter Placeholder, sans-serif);
    font-style: var(--framer-font-style, normal);
    font-weight: var(--framer-font-weight, 400);
    color: var(--framer-text-color, #000);
    font-size: var(--framer-font-size, 16px);
    letter-spacing: var(--framer-letter-spacing, 0);
    text-transform: var(--framer-text-transform, none);
    text-decoration: var(--framer-text-decoration, none);
    line-height: var(--framer-line-height, 1.2em);
    text-align: var(--framer-text-alignment, start)
}

[data-framer-component-type=DeprecatedRichText] p:not(:first-child),
[data-framer-component-type=DeprecatedRichText] div:not(:first-child),
[data-framer-component-type=DeprecatedRichText] h1:not(:first-child),
[data-framer-component-type=DeprecatedRichText] h2:not(:first-child),
[data-framer-component-type=DeprecatedRichText] h3:not(:first-child),
[data-framer-component-type=DeprecatedRichText] h4:not(:first-child),
[data-framer-component-type=DeprecatedRichText] h5:not(:first-child),
[data-framer-component-type=DeprecatedRichText] h6:not(:first-child),
[data-framer-component-type=DeprecatedRichText] ol:not(:first-child),
[data-framer-component-type=DeprecatedRichText] ul:not(:first-child),
[data-framer-component-type=DeprecatedRichText] .framer-image:not(:first-child) {
    margin-top: var(--framer-paragraph-spacing, 0)
}

[data-framer-component-type=DeprecatedRichText] span[data-text-fill] {
    display: inline-block;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

[data-framer-component-type=DeprecatedRichText] a,
[data-framer-component-type=DeprecatedRichText] a span:not([data-text-fill]) {
    font-family: var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
    font-style: var(--framer-link-font-style, var(--framer-font-style, normal));
    font-weight: var(--framer-link-font-weight, var(--framer-font-weight, 400));
    color: var(--framer-link-text-color, var(--framer-text-color, #000));
    font-size: var(--framer-link-font-size, var(--framer-font-size, 16px));
    text-transform: var(--framer-link-text-transform, var(--framer-text-transform, none));
    text-decoration: var(--framer-link-text-decoration, var(--framer-text-decoration, none))
}

[data-framer-component-type=DeprecatedRichText] a:hover,
[data-framer-component-type=DeprecatedRichText] a:hover span:not([data-text-fill]) {
    font-family: var(--framer-link-hover-font-family, var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif)));
    font-style: var(--framer-link-hover-font-style, var(--framer-link-font-style, var(--framer-font-style, normal)));
    font-weight: var(--framer-link-hover-font-weight, var(--framer-link-font-weight, var(--framer-font-weight, 400)));
    color: var(--framer-link-hover-text-color, var(--framer-link-text-color, var(--framer-text-color, #000)));
    font-size: var(--framer-link-hover-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px)));
    text-transform: var(--framer-link-hover-text-transform, var(--framer-link-text-transform, var(--framer-text-transform, none)));
    text-decoration: var(--framer-link-hover-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration, none)))
}

a[data-framer-page-link-current],
a[data-framer-page-link-current] span:not([data-text-fill]) {
    font-family: var(--framer-link-current-font-family, var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif)));
    font-style: var(--framer-link-current-font-style, var(--framer-link-font-style, var(--framer-font-style, normal)));
    font-weight: var(--framer-link-current-font-weight, var(--framer-link-font-weight, var(--framer-font-weight, 400)));
    color: var(--framer-link-current-text-color, var(--framer-link-text-color, var(--framer-text-color, #000)));
    font-size: var(--framer-link-current-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px)));
    text-transform: var(--framer-link-current-text-transform, var(--framer-link-text-transform, var(--framer-text-transform, none)));
    text-decoration: var(--framer-link-current-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration, none)))
}

a[data-framer-page-link-current]:hover,
a[data-framer-page-link-current]:hover span:not([data-text-fill]) {
    font-family: var(--framer-link-hover-font-family, var(--framer-link-current-font-family, var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif))));
    font-style: var(--framer-link-hover-font-style, var(--framer-link-current-font-style, var(--framer-link-font-style, var(--framer-font-style, normal))));
    font-weight: var(--framer-link-hover-font-weight, var(--framer-link-current-font-weight, var(--framer-link-font-weight, var(--framer-font-weight, 400))));
    color: var(--framer-link-hover-text-color, var(--framer-link-current-text-color, var(--framer-link-text-color, var(--framer-text-color, #000))));
    font-size: var(--framer-link-hover-font-size, var(--framer-link-current-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px))));
    text-transform: var(--framer-link-hover-text-transform, var(--framer-link-current-text-transform, var(--framer-link-text-transform, var(--framer-text-transform, none))));
    text-decoration: var(--framer-link-hover-text-decoration, var(--framer-link-current-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration, none))))
}

[data-framer-component-type=DeprecatedRichText] strong {
    font-weight: bolder
}

[data-framer-component-type=DeprecatedRichText] em {
    font-style: italic
}

[data-framer-component-type=DeprecatedRichText] .framer-image {
    display: block;
    max-width: 100%;
    height: auto
}

[data-framer-component-type=DeprecatedRichText] ul,
[data-framer-component-type=DeprecatedRichText] ol {
    display: table;
    width: 100%;
    padding-left: 0;
    margin: 0
}

[data-framer-component-type=DeprecatedRichText] li {
    display: table-row;
    counter-increment: list-item;
    list-style: none
}

[data-framer-component-type=DeprecatedRichText] ol>li:before {
    display: table-cell;
    width: 2.25ch;
    box-sizing: border-box;
    padding-right: .75ch;
    content: counter(list-item) ".";
    white-space: nowrap
}

[data-framer-component-type=DeprecatedRichText] ul>li:before {
    display: table-cell;
    width: 2.25ch;
    box-sizing: border-box;
    padding-right: .75ch;
    content: "\2022"
}

:not([data-framer-generated])>[data-framer-stack-content-wrapper]>*,
:not([data-framer-generated])>[data-framer-stack-content-wrapper]>[data-framer-component-type],
:not([data-framer-generated])>[data-framer-stack-content-wrapper]>[data-framer-legacy-stack-gap-enabled]>*,
:not([data-framer-generated])>[data-framer-stack-content-wrapper]>[data-framer-legacy-stack-gap-enabled]>[data-framer-component-type] {
    position: relative
}

.flexbox-gap-not-supported [data-framer-legacy-stack-gap-enabled=true]>*,
[data-framer-legacy-stack-gap-enabled=true][data-framer-stack-flexbox-gap=false] {
    margin-top: calc(var(--stack-gap-y) / 2);
    margin-bottom: calc(var(--stack-gap-y) / 2);
    margin-right: calc(var(--stack-gap-x) / 2);
    margin-left: calc(var(--stack-gap-x) / 2)
}

[data-framer-stack-content-wrapper][data-framer-stack-gap-enabled=true] {
    row-gap: var(--stack-native-row-gap);
    column-gap: var(--stack-native-column-gap)
}

.flexbox-gap-not-supported [data-framer-stack-content-wrapper][data-framer-stack-gap-enabled=true] {
    row-gap: unset;
    column-gap: unset
}

.flexbox-gap-not-supported [data-framer-stack-direction-reverse=false] [data-framer-legacy-stack-gap-enabled=true]>*:first-child,
[data-framer-stack-direction-reverse=false] [data-framer-legacy-stack-gap-enabled=true][data-framer-stack-flexbox-gap=false]>*:first-child,
.flexbox-gap-not-supported [data-framer-stack-direction-reverse=true] [data-framer-legacy-stack-gap-enabled=true]>*:last-child,
[data-framer-stack-direction-reverse=true] [data-framer-legacy-stack-gap-enabled=true][data-framer-stack-flexbox-gap=false]>*:last-child {
    margin-top: 0;
    margin-left: 0
}

.flexbox-gap-not-supported [data-framer-stack-direction-reverse=false] [data-framer-legacy-stack-gap-enabled=true]>*:last-child,
[data-framer-stack-direction-reverse=false] [data-framer-legacy-stack-gap-enabled=true][data-framer-stack-flexbox-gap=false]>*:last-child,
.flexbox-gap-not-supported [data-framer-stack-direction-reverse=true] [data-framer-legacy-stack-gap-enabled=true]>*:first-child,
[data-framer-stack-direction-reverse=true] [data-framer-legacy-stack-gap-enabled=true][data-framer-stack-flexbox-gap=false]>*:first-child {
    margin-right: 0;
    margin-bottom: 0
}

NavigationContainer [data-framer-component-type=NavigationContainer]>*,
[data-framer-component-type=NavigationContainer]>[data-framer-component-type] {
    position: relative
}

[data-framer-component-type=Scroll]::-webkit-scrollbar {
    display: none
}

[data-framer-component-type=ScrollContentWrapper]>* {
    position: relative
}

[data-framer-component-type=NativeScroll] {
    -webkit-overflow-scrolling: touch
}

[data-framer-component-type=NativeScroll]>* {
    position: relative
}

[data-framer-component-type=NativeScroll].direction-both {
    overflow-x: scroll;
    overflow-y: scroll
}

[data-framer-component-type=NativeScroll].direction-vertical {
    overflow-x: hidden;
    overflow-y: scroll
}

[data-framer-component-type=NativeScroll].direction-horizontal {
    overflow-x: scroll;
    overflow-y: hidden
}

[data-framer-component-type=NativeScroll].direction-vertical>* {
    width: 100% !important
}

[data-framer-component-type=NativeScroll].direction-horizontal>* {
    height: 100% !important
}

[data-framer-component-type=NativeScroll].scrollbar-hidden::-webkit-scrollbar {
    display: none
}

[data-framer-component-type=PageContentWrapper]>*,
[data-framer-component-type=PageContentWrapper]>[data-framer-component-type] {
    position: relative
}

[data-framer-component-type=DeviceComponent].no-device>* {
    width: 100% !important;
    height: 100% !important
}

[data-is-present=false],
[data-is-present=false] * {
    pointer-events: none !important
}

[data-framer-cursor=pointer] {
    cursor: pointer
}

[data-framer-cursor=grab] {
    cursor: grab
}

[data-framer-cursor=grab]:active {
    cursor: grabbing
}

[data-framer-component-type=Frame] *,
[data-framer-component-type=Stack] * {
    pointer-events: auto
}

[data-framer-generated] * {
    pointer-events: unset
}

.svgContainer svg {
    display: block
}

[data-reset=button] {
    border-width: 0;
    padding: 0;
    background: none
}

[data-hide-scrollbars=true]::-webkit-scrollbar {
    width: 0px;
    height: 0px
}

[data-hide-scrollbars=true]::-webkit-scrollbar-thumb {
    background: transparent
}

.framer-97rJI [data-border=true]:after {
    content: "";
    border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
    border-color: var(--border-color, none);
    border-style: var(--border-style, none);
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    border-radius: inherit;
    pointer-events: none
}

body {
    background-color: var(--token-f9b89b17-0991-4c0e-b401-ca53a64a9c7b, rgb(2, 4, 29))
}

.framer-97rJI .framer-lux5qc {
    display: block
}

.framer-97rJI .framer-72rtr7 {
    align-content: center;
    align-items: center;
    background-color: var(--token-f9b89b17-0991-4c0e-b401-ca53a64a9c7b, #02041d);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    // overflow: hidden;
    padding: 0;
    position: relative;
    width: 1440px
}

.framer-97rJI .navbar {
    --border-bottom-width: 1px;
    --border-color: rgba(255, 255, 255, .11);
    --border-left-width: 1px;
    --border-right-width: 1px;
    --border-style: solid;
    --border-top-width: 1px;
    -webkit-backdrop-filter: blur(45px);
    align-content: center;
    align-items: center;
    backdrop-filter: blur(45px);
    border-radius: 20px;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: center;
    left: 50%;
    overflow: hidden;
    padding: 20px;
    position: fixed;
    top: 20px;
    transform: perspective(1200px) translate(-50%);
    width: min-content;
    will-change: transform;
    z-index: 10
}

.framer-97rJI .framer-931it5-container,
.framer-97rJI .framer-1ca8cvs-container,
.framer-97rJI .framer-73f844-container,
.framer-97rJI .framer-25d02p-container,
.framer-97rJI .framer-v39bd3-container,
.framer-97rJI .framer-1ki0x6a-container,
.framer-97rJI .framer-l6sovz-container,
.framer-97rJI .framer-1gpy6tb-container,
.framer-97rJI .framer-3bj60h-container,
.framer-97rJI .framer-17tzy02-container,
.framer-97rJI .framer-h4w8mj-container,
.framer-97rJI .framer-j1709x-container {
    flex: none;
    height: auto;
    position: relative;
    width: auto
}

.framer-97rJI .framer-1l9mm7q,
.framer-97rJI .framer-13ew9r8,
.framer-97rJI .framer-kzpjwj,
.framer-97rJI .framer-s6hy2b {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-1pvz644 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 1528px;
    justify-content: center;
    left: -316px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: -316px;
    top: -210px;
    transform: perspective(1200px);
    z-index: 0
}

.framer-97rJI .framer-y6bzdz-container {
    flex: none;
    inset: 403px 1225px 829px 220px;
    position: absolute;
    transform: rotate(390deg);
    z-index: -1
}

.framer-97rJI .framer-5j3a53 {
    flex: none;
    height: 1360px;
    position: relative;
    width: 95%;
    z-index: 0
}

.framer-97rJI .framer-1wrrtkp {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    max-width: 1350px;
    overflow: hidden;
    padding: 240px 24px 80px;
    position: relative;
    width: 100%;
    z-index: 0
}

.framer-97rJI .framer-ofssek {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 80px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-e61qcl {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 25px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    transform: perspective(1200px);
    width: min-content
}

.framer-97rJI .framer-hgg6ej {
    --border-bottom-width: 1px;
    --border-color: rgba(255, 255, 255, .08);
    --border-left-width: 1px;
    --border-right-width: 1px;
    --border-style: solid;
    --border-top-width: 1px;
    align-content: center;
    align-items: center;
    background-color: #ffffff08;
    border-radius: 12px;
    box-shadow: inset 0 0 11px #5c80fa66;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 5px 20px;
    position: relative;
    transform: perspective(1200px);
    width: min-content
}

.framer-97rJI .framer-1ps7qll,
.framer-97rJI .framer-1yunemf {
    flex: none;
    height: 24px;
    position: relative;
    width: 25px
}

.framer-97rJI .framer-wla99d,
.framer-97rJI .framer-1f73tpq,
.framer-97rJI .framer-1jnfu0l,
.framer-97rJI .framer-1g56jfn,
.framer-97rJI .framer-1da989o,
.framer-97rJI .framer-lti0vz,
.framer-97rJI .framer-151rzvw,
.framer-97rJI .framer-bpufrc,
.framer-97rJI .framer-1du20j6,
.framer-97rJI .framer-1n3q0o,
.framer-97rJI .framer-gsr5x1,
.framer-97rJI .framer-1vhcsox,
.framer-97rJI .framer-8ag0cn,
.framer-97rJI .framer-cn6gtm,
.framer-97rJI .framer-1o31f8i,
.framer-97rJI .framer-18z5ztj,
.framer-97rJI .framer-bte1da,
.framer-97rJI .framer-14vj6ad,
.framer-97rJI .framer-1cse84g,
.framer-97rJI .framer-a3fea7,
.framer-97rJI .framer-mbx9bf,
.framer-97rJI .framer-2taaz7,
.framer-97rJI .framer-1yz1r9y,
.framer-97rJI .framer-d174w6,
.framer-97rJI .framer-1edgbly,
.framer-97rJI .framer-1jbusz5,
.framer-97rJI .framer-sw86xi,
.framer-97rJI .framer-1vjs3vr,
.framer-97rJI .framer-6al0l6,
.framer-97rJI .framer-ty5sop,
.framer-97rJI .framer-1jwr6z2,
.framer-97rJI .framer-hupx2d,
.framer-97rJI .framer-xje24l,
.framer-97rJI .framer-wwst7a,
.framer-97rJI .framer-1nfbl4r,
.framer-97rJI .framer-2qnq25,
.framer-97rJI .framer-o088sl,
.framer-97rJI .framer-9qxzwt,
.framer-97rJI .framer-19l65ef,
.framer-97rJI .framer-qsfsgs,
.framer-97rJI .framer-10b6sc0,
.framer-97rJI .framer-5791k4,
.framer-97rJI .framer-d95bhi,
.framer-97rJI .framer-3us2p9,
.framer-97rJI .framer-vgk8ic,
.framer-97rJI .framer-1nmucc8,
.framer-97rJI .framer-175qbsr,
.framer-97rJI .framer-l1c7aj,
.framer-97rJI .framer-1i82zy9 {
    --framer-paragraph-spacing: 0px;
    flex: none;
    height: auto;
    position: relative;
    white-space: pre;
    width: auto
}

.framer-97rJI .framer-1chwo98 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    transform: perspective(1200px);
    width: min-content
}

.framer-97rJI .framer-gj8r3p,
.framer-97rJI .framer-u1xhss,
.framer-97rJI .framer-2hs7n1,
.framer-97rJI .framer-1sfr8we,
.framer-97rJI .framer-1n8goct,
.framer-97rJI .framer-1udceag,
.framer-97rJI .framer-8okrui {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-1gjs8ze {
    --framer-paragraph-spacing: 0px;
    flex: none;
    height: auto;
    position: relative;
    transform: perspective(1200px);
    white-space: pre-wrap;
    width: 607px;
    word-break: break-word;
    word-wrap: break-word
}

.framer-97rJI .framer-wpl817 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 946px;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: relative;
    transform: perspective(1200px);
    width: 1167px
}

.framer-97rJI .framer-1yfo5ku-container {
    cursor: pointer;
    flex: none;
    height: auto;
    left: 50%;
    position: absolute;
    top: 342px;
    transform: translate(-50%);
    width: auto;
    z-index: 10
}

.framer-97rJI .framer-14k2fe4 {
    aspect-ratio: 1.233615221987315 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 946px);
    overflow: visible;
    position: relative;
    width: 1167px
}

.framer-97rJI .framer-1nwyw2w-container {
    bottom: 0;
    flex: none;
    height: 200px;
    left: calc(50.00000000000002% - 100% / 2);
    position: absolute;
    width: 100%;
    z-index: 0
}

.framer-97rJI .framer-1b5foaf {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 50px;
    height: min-content;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-15rf8eu {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 32px;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 221px
}

.framer-97rJI .framer-h6hqo4 {
    aspect-ratio: 6.90625 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 32px);
    position: relative;
    width: 221px
}

.framer-97rJI .framer-1yspppi {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 27px;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 143px
}

.framer-97rJI .framer-x9fjzq {
    aspect-ratio: 5.296296296296297 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 27px);
    position: relative;
    width: 143px
}

.framer-97rJI .framer-euc7iw {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 30px;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 156px
}

.framer-97rJI .framer-bgc34j {
    aspect-ratio: 5.2 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 30px);
    position: relative;
    width: 156px
}

.framer-97rJI .framer-1wcn9io {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 35px;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 189px
}

.framer-97rJI .framer-1qxxihe {
    aspect-ratio: 5.4 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 35px);
    position: relative;
    width: 189px
}

.framer-97rJI .framer-1un2ayx {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    max-width: 1350px;
    overflow: visible;
    padding: 30px 24px 80px;
    position: relative;
    width: 100%;
    z-index: 2
}

.framer-97rJI .framer-11nc1m0 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 120px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0 10px;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-3ssmbw,
.framer-97rJI .framer-9ocitq {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 16px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    transform: perspective(1200px);
    width: min-content
}

.framer-97rJI .framer-3mzl39,
.framer-97rJI .framer-1pkz81l,
.framer-97rJI .framer-7t6h8s,
.framer-97rJI .framer-w1kx9j,
.framer-97rJI .framer-1ho5ydl,
.framer-97rJI .framer-1huo939,
.framer-97rJI .framer-1mfv3cu,
.framer-97rJI .framer-7cfgdx,
.framer-97rJI .framer-qjpmzt {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-7rez0,
.framer-97rJI .framer-1kc87cu,
.framer-97rJI .framer-1syp31b,
.framer-97rJI .framer-1tsrlxx,
.framer-97rJI .framer-r7pqdu,
.framer-97rJI .framer-1cqgb3d,
.framer-97rJI .framer-1sgd7z5,
.framer-97rJI .framer-ebbv65,
.framer-97rJI .framer-kqtwxw,
.framer-97rJI .framer-1hincbq,
.framer-97rJI .framer-tk0wzh,
.framer-97rJI .framer-13jjph3,
.framer-97rJI .framer-1r14b7r,
.framer-97rJI .framer-12eryat,
.framer-97rJI .framer-i9uze0,
.framer-97rJI .framer-1q7ayv4,
.framer-97rJI .framer-1dzylcx,
.framer-97rJI .framer-13362bf {
    flex: none;
    height: 5px;
    position: relative;
    width: 451px
}

.framer-97rJI .framer-1b3swhe {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
    height: min-content;
    justify-content: center;
    overflow: visible;
    padding: 0;
    position: relative;
}

.framer-97rJI .framer-1m4ycra,
.framer-97rJI .framer-y562of,
.framer-97rJI .framer-bmcqfn,
.framer-97rJI .framer-1lx9t9v,
.framer-97rJI .framer-eic4vs {
    --framer-paragraph-spacing: 0px;
    flex: none;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 607px;
    word-break: break-word;
    word-wrap: break-word
}

.framer-97rJI .framer-1aae7r3 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 30px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    transform: perspective(1200px);
    width: min-content
}

.framer-97rJI .framer-qsls2j {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 30px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-je95qz,
.framer-97rJI .framer-smc5ba,
.framer-97rJI .framer-1lfr6ou,
.framer-97rJI .framer-1rfv8ym {
    --border-bottom-width: 1px;
    --border-color: rgba(255, 255, 255, .2);
    --border-left-width: 1px;
    --border-right-width: 1px;
    --border-style: solid;
    --border-top-width: 1px;
    align-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, .03) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 22px;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    height: 497px;
    justify-content: flex-end;
    overflow: hidden;
    padding: 30px 0;
    position: relative;
    width: 410px;
    will-change: transform
}

.framer-97rJI .framer-49is85 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 270px;
    justify-content: center;
    overflow: visible;
    padding: 0;
    position: absolute;
    right: -12px;
    top: 23px;
    width: 374px;
    z-index: 1
}

.framer-97rJI .framer-1s3sbvi {
    aspect-ratio: 1.2210031347962382 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 327px);
    overflow: visible;
    position: relative;
    width: 400px
}

.framer-97rJI .framer-vpdmo1,
.framer-97rJI .framer-vw6whp,
.framer-97rJI .framer-1n5y460,
.framer-97rJI .framer-1j0lv20,
.framer-97rJI .framer-ddvqi8,
.framer-97rJI .framer-9cl8q0,
.framer-97rJI .framer-rgjd9e {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 12px;
    height: 156px;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-14bkxj8,
.framer-97rJI .framer-1712gxo,
.framer-97rJI .framer-bjbc8i,
.framer-97rJI .framer-15ch4kt,
.framer-97rJI .framer-12jripk,
.framer-97rJI .framer-1wdyiwz,
.framer-97rJI .framer-lr9764 {
    --framer-paragraph-spacing: 0px;
    flex: none;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 330px;
    word-break: break-word;
    word-wrap: break-word
}

.framer-97rJI .framer-1v1z9j {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 1086px;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: -109px;
    top: -279px;
    width: 648px;
    z-index: 1
}

.framer-97rJI .framer-nckmto {
    aspect-ratio: .8249496981891348 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 533px);
    overflow: visible;
    position: relative;
    width: 440px
}

.framer-97rJI .framer-12n1cz {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 639px;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: -22px;
    top: -42px;
    width: 525px;
    z-index: 1
}

.framer-97rJI .framer-ad2f06 {
    aspect-ratio: .8249496981891348 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 594px);
    overflow: visible;
    position: relative;
    width: 490px
}

.framer-97rJI .framer-o74vfa {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 30px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-6wkfww {
    --border-bottom-width: 1px;
    --border-color: rgba(255, 255, 255, .2);
    --border-left-width: 1px;
    --border-right-width: 1px;
    --border-style: solid;
    --border-top-width: 1px;
    align-content: flex-start;
    align-items: flex-start;
    border-radius: 22px;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    height: 497px;
    justify-content: flex-end;
    overflow: hidden;
    padding: 30px 50px 40px;
    position: relative;
    width: 848px;
    will-change: transform
}

.framer-97rJI .framer-866dgb {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 1086px;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: -211px;
    top: -295px;
    width: 1394px;
    z-index: 0
}

.framer-97rJI .framer-vjylu8 {
    aspect-ratio: 1.3268876611418048 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 1086px);
    overflow: visible;
    position: relative;
    width: 1441px
}

.framer-97rJI .framer-1lrlkmo {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 23px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 682px
}

.framer-97rJI .framer-r5tvq6 {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 15px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 443px
}

.framer-97rJI .framer-jhmtls {
    --border-bottom-width: 1px;
    --border-color: rgba(255, 255, 255, .08);
    --border-left-width: 1px;
    --border-right-width: 1px;
    --border-style: solid;
    --border-top-width: 1px;
    align-content: center;
    align-items: center;
    background-color: #ffffff08;
    border-radius: 12px;
    box-shadow: inset 0 0 12px #c6c2ff4d;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 5px 20px;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-16w7l19 {
    --framer-paragraph-spacing: 0px;
    flex: none;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 443px;
    word-break: break-word;
    word-wrap: break-word
}

.framer-97rJI .framer-ws2afn {
    aspect-ratio: 1.0896057347670252 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 274px);
    overflow: visible;
    position: relative;
    width: 298px
}

.framer-97rJI .framer-11udwvy {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    max-width: 1350px;
    overflow: visible;
    padding: 80px 24px 350px;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-1a2dpgh {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 120px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0 10px;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-1xwcvgu,
.framer-97rJI .framer-dzdjvj {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 50px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-1wfkc1z,
.framer-97rJI .framer-1rp823r,
.framer-97rJI .framer-19ah3bk,
.framer-97rJI .framer-1peizua,
.framer-97rJI .framer-juqyll,
.framer-97rJI .framer-mrehk0,
.framer-97rJI .framer-1tehynk,
.framer-97rJI .framer-ulnt9v,
.framer-97rJI .framer-r1c62u,
.framer-97rJI .framer-1ve8ypk {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-19w5gd7,
.framer-97rJI .framer-13rh0fy,
.framer-97rJI .framer-1l6d1of,
.framer-97rJI .framer-6md674 {
    flex: none;
    height: 5px;
    position: relative;
    width: 300px
}

.framer-97rJI .framer-1n6ov80,
.framer-97rJI .framer-1a1pvl9,
.framer-97rJI .framer-evcvxq,
.framer-97rJI .framer-1ax2e7y,
.framer-97rJI .framer-12a0s4y,
.framer-97rJI .framer-1v0oifu,
.framer-97rJI .framer-1b8wti4,
.framer-97rJI .framer-r6z4pg {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
}

.framer-97rJI .framer-fy4ath,
.framer-97rJI .framer-10opef8 {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
}

.framer-97rJI .framer-o8tt5c,
.framer-97rJI .framer-qnjpuc,
.framer-97rJI .framer-zri7uj,
.framer-97rJI .framer-fqwwjg,
.framer-97rJI .framer-1vjfh25,
.framer-97rJI .framer-1la0vsm,
.framer-97rJI .framer-1vhm7oi,
.framer-97rJI .framer-vkyocj {
    --framer-paragraph-spacing: 0px;
    flex: none;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 507px;
    word-break: break-word;
    word-wrap: break-word
}

.framer-97rJI .framer-zoyn32,
.framer-97rJI .framer-c5bs8e,
.framer-97rJI .framer-1int50y,
.framer-97rJI .framer-1uj7sjp,
.framer-97rJI .framer-konhgg,
.framer-97rJI .framer-1i05iuo {
    flex: none;
    height: 22px;
    position: relative;
    width: 21px
}

.framer-97rJI .framer-zf6ut8,
.framer-97rJI .framer-o9uaah,
.framer-97rJI .framer-xmzru8 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 13px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-rgy95z {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: center;
    overflow: visible;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: min-content;
    z-index: 1
}

.framer-97rJI .framer-h56obv {
    -webkit-filter: blur(100px);
    background: linear-gradient(180deg, #5ee7d2 0%, #97f774 100%);
    filter: blur(100px);
    flex: none;
    height: 626px;
    left: calc(50.072780203784596% - 541px / 2);
    opacity: .05;
    overflow: visible;
    position: absolute;
    top: -47px;
    width: 541px;
    z-index: 1
}

.framer-97rJI .framer-elb6p6 {
    aspect-ratio: .6962421711899791 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 987px);
    overflow: visible;
    position: relative;
    width: 687px
}

.framer-97rJI .framer-1oyfwvc,
.framer-97rJI .framer-1964g6t {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0 0 80px;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-1wu4mmx {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: center;
    left: 48%;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: 60px;
    transform: perspective(1200px) translate(-50%);
    width: min-content;
    z-index: 0
}

.framer-97rJI .framer-8pw6ho {
    aspect-ratio: .8685446009389671 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 852px);
    position: relative;
    width: 740px
}

.framer-97rJI .framer-1iy7ri3 {
    -webkit-filter: blur(100px);
    aspect-ratio: .8743386243386243 / 1;
    background: linear-gradient(180deg, #f29487 0%, #e8749e 100%);
    border-radius: 380px;
    filter: blur(100px);
    flex: none;
    height: var(--framer-aspect-ratio-supported, 700px);
    left: 50%;
    opacity: .1;
    overflow: visible;
    position: absolute;
    top: -47px;
    transform: translate(-50%);
    width: 612px;
    z-index: 1
}

.framer-97rJI .framer-cylkc8 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    max-width: 1350px;
    overflow: visible;
    padding: 650px 24px 0;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-hqsyw0 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 120px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 110px 10px;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-g9rlpx {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 19px;
    height: 240px;
    justify-content: center;
    overflow: visible;
    padding: 0;
    position: relative;
    transform: perspective(1200px);
    width: 1058px
}

.framer-97rJI .framer-1l5i75g,
.framer-97rJI .framer-dnjzfc {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
    height: min-content;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-yvjwy2 {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 50px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    transform: perspective(1200px);
    width: min-content
}

.framer-97rJI .framer-1sfg8nx,
.framer-97rJI .framer-1ou3qdj,
.framer-97rJI .framer-17amp86 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 50px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-zt7lmv {
    flex: none;
    height: 269px;
    position: relative;
    width: 224px
}

.framer-97rJI .framer-1bhx7l4 {
    flex: none;
    height: 269px;
    position: relative;
    width: 232px
}

.framer-97rJI .framer-1xm5n0 {
    flex: none;
    height: 269px;
    position: relative;
    width: 238px
}

.framer-97rJI .framer-xwt5a8 {
    -webkit-filter: blur(100px);
    background: linear-gradient(180deg, #f29487 0%, #e8749e 100%);
    border-radius: 380px;
    filter: blur(100px);
    flex: none;
    height: 370px;
    left: calc(50.072780203784596% - 1190px / 2);
    opacity: .06;
    overflow: visible;
    position: absolute;
    top: -47px;
    width: 1190px;
    z-index: 1
}

.framer-97rJI .framer-1r97wfd,
.framer-97rJI .framer-dx4x6g {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-12no29k,
.framer-97rJI .framer-1l182px,
.framer-97rJI .framer-v3u1up {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    max-width: 1350px;
    overflow: visible;
    padding: 80px 24px;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-1urlfsk {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-end;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-jzz0h2 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 699px;
    justify-content: center;
    left: -90px;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: calc(49.85590778097985% - 699px / 2);
    width: 738px;
    z-index: 1
}

.framer-97rJI .framer-1tybo1s {
    -webkit-filter: blur(100px);
    aspect-ratio: .8743386243386243 / 1;
    background: linear-gradient(180deg, var(--token-0bc9d5e2-cabe-4eb2-b691-d577f23a764a, #0052cc) 0%, #A06BFA 100%);
    border-radius: 380px;
    filter: blur(100px);
    flex: none;
    height: var(--framer-aspect-ratio-supported, 746px);
    left: 66px;
    opacity: .05;
    overflow: visible;
    position: absolute;
    top: -40px;
    width: 652px;
    z-index: 0
}

.framer-97rJI .framer-12yh7j2 {
    aspect-ratio: 1.055793991416309 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 699px);
    overflow: visible;
    position: relative;
    width: 738px
}

.framer-97rJI .framer-1qxe9o5,
.framer-97rJI .framer-wepogq {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 120px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-ai426w {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 19px;
    height: min-content;
    justify-content: center;
    overflow: visible;
    padding: 0;
    position: relative;
    transform: perspective(1200px);
    width: min-content
}

.framer-97rJI .framer-106ooov-container {
    flex: none;
    height: 400px;
    position: relative;
    transform: perspective(1200px);
    width: 100%
}

.framer-97rJI .framer-1wwocei,
.framer-97rJI .framer-ojg17a,
.framer-97rJI .framer-2qwdqp,
.framer-97rJI .framer-1opk852,
.framer-97rJI .framer-ekp3g7,
.framer-97rJI .framer-ma71n8 {
    --border-bottom-width: 1px;
    --border-color: rgba(255, 255, 255, .2);
    --border-left-width: 1px;
    --border-right-width: 1px;
    --border-style: solid;
    --border-top-width: 1px;
    align-content: flex-start;
    align-items: flex-start;
    background: linear-gradient(180deg, rgba(255, 255, 255, .02) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-start;
    overflow: hidden;
    padding: 40px 30px;
    position: relative;
    width: min-content;
    will-change: transform
}

.framer-97rJI .framer-14ey6aw,
.framer-97rJI .framer-16lndoa,
.framer-97rJI .framer-19fybtn,
.framer-97rJI .framer-b7zc80,
.framer-97rJI .framer-4awfvo,
.framer-97rJI .framer-474jxp {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 23px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-1eziktk,
.framer-97rJI .framer-ikv213,
.framer-97rJI .framer-zi5uus,
.framer-97rJI .framer-kdjj0f,
.framer-97rJI .framer-qc30gh,
.framer-97rJI .framer-1x4s9us {
    flex: none;
    height: 29px;
    position: relative;
    width: 142px
}

.framer-97rJI .framer-45qgtz,
.framer-97rJI .framer-zstebz,
.framer-97rJI .framer-1yv3j1l,
.framer-97rJI .framer-txsgm9,
.framer-97rJI .framer-11bseo5,
.framer-97rJI .framer-1hj9k54 {
    --framer-paragraph-spacing: 0px;
    flex: none;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 503px;
    word-break: break-word;
    word-wrap: break-word
}

.framer-97rJI .framer-1cghnmp,
.framer-97rJI .framer-4z4z3,
.framer-97rJI .framer-16i0ts8,
.framer-97rJI .framer-nss9lt,
.framer-97rJI .framer-116mv18,
.framer-97rJI .framer-90tm6k {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 19px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-1tuvnc7-container {
    flex: none;
    height: 440px;
    position: relative;
    transform: perspective(1200px);
    width: 100%
}

.framer-97rJI .framer-ohe6nr {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 230px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-1rs5s5g {
    align-content: center;
    align-items: center;
    background: linear-gradient(180deg, #02031c 0%, #02031c 100%);
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: center;
    left: 50%;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 10px;
    transform: perspective(1200px) translate(-50%);
    width: min-content;
    z-index: 0
}

.framer-97rJI .framer-pyz7fu {
    aspect-ratio: .6549479166666666 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 768px);
    position: relative;
    width: 503px
}

.framer-97rJI .framer-hmcbx0,
.framer-97rJI .framer-zya26f {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: center;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-skpi98 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 90px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 400px 0 0;
    position: relative;
    transform: perspective(1200px);
    width: min-content
}

.framer-97rJI .framer-o210t,
.framer-97rJI .framer-vldtt2 {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 50px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-l1o88t,
.framer-97rJI .framer-an9xu2,
.framer-97rJI .framer-ynh4nb,
.framer-97rJI .framer-4tb9db,
.framer-97rJI .framer-1vfw6bt {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 27px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-gxev2m,
.framer-97rJI .framer-1i7x0hx,
.framer-97rJI .framer-1ostbui,
.framer-97rJI .framer-10gffta {
    aspect-ratio: 1 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 76px);
    position: relative;
    width: 76px
}

.framer-97rJI .framer-fag6of,
.framer-97rJI .framer-7tvetr,
.framer-97rJI .framer-1xr59kg,
.framer-97rJI .framer-1u79zrq,
.framer-97rJI .framer-j8p8bn {
    --framer-paragraph-spacing: 0px;
    flex: none;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 344px;
    word-break: break-word;
    word-wrap: break-word
}

.framer-97rJI .framer-9q2q,
.framer-97rJI .framer-5pg4k2,
.framer-97rJI .framer-1iivd7a {
    flex: none;
    height: 196px;
    position: relative;
    width: 4px
}

.framer-97rJI .framer-1jipn5y {
    aspect-ratio: .9736842105263158 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 76px);
    position: relative;
    width: 74px
}

.framer-97rJI .framer-e2ke3n {
    -webkit-filter: blur(100px);
    background-color: var(--token-0bc9d5e2-cabe-4eb2-b691-d577f23a764a, #0052cc);
    filter: blur(100px);
    flex: none;
    height: 433px;
    left: 1120px;
    opacity: .33;
    overflow: hidden;
    position: absolute;
    top: 14px;
    transform: rotate(35deg);
    width: 157px;
    z-index: 1
}

.framer-97rJI .framer-933oxh {
    -webkit-filter: blur(100px);
    background-color: var(--token-0bc9d5e2-cabe-4eb2-b691-d577f23a764a, #0052cc);
    bottom: 35px;
    filter: blur(100px);
    flex: none;
    height: 433px;
    left: 339px;
    opacity: .33;
    overflow: hidden;
    position: absolute;
    transform: rotate(35deg);
    width: 125px;
    z-index: 1
}

.framer-97rJI .framer-1jdjxoj {
    -webkit-filter: blur(100px);
    background-color: var(--token-0bc9d5e2-cabe-4eb2-b691-d577f23a764a, #0052cc);
    bottom: -3px;
    filter: blur(100px);
    flex: none;
    height: 433px;
    opacity: .33;
    overflow: hidden;
    position: absolute;
    right: -49px;
    transform: rotate(35deg);
    width: 125px;
    z-index: 1
}

.framer-97rJI .framer-1qoiz2j {
    -webkit-filter: blur(100px);
    background-color: var(--token-0bc9d5e2-cabe-4eb2-b691-d577f23a764a, #0052cc);
    filter: blur(100px);
    flex: none;
    height: 433px;
    left: -18px;
    opacity: .33;
    overflow: hidden;
    position: absolute;
    top: -88px;
    transform: rotate(35deg);
    width: 125px;
    z-index: 1
}

.framer-97rJI .framer-1nj42j2 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 1321px;
    justify-content: center;
    left: -785px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 716px;
    top: -229px;
    transform: perspective(1200px);
    z-index: 1
}

.framer-97rJI .framer-w6p6oe,
.framer-97rJI .framer-u1zxb8 {
    aspect-ratio: 1.1431767337807606 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 1068px);
    overflow: visible;
    position: relative;
    width: 1220px
}

.framer-97rJI .framer-fom51z {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 1321px;
    justify-content: center;
    left: 685px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: -744px;
    top: -260px;
    transform: perspective(1200px);
    z-index: 1
}

.framer-97rJI .framer-8yddhs {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    max-width: 1350px;
    overflow: visible;
    padding: 200px 24px;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-984wht {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 44px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    transform: perspective(1200px);
    width: min-content;
    z-index: 9
}

.framer-97rJI .framer-5ely8e {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-1h4njvz {
    flex: none;
    height: 70px;
    position: relative;
    width: 64px
}

.framer-97rJI .framer-hq1d08 {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 15px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-1y9xts9 {
    --border-bottom-width: 1px;
    --border-color: rgba(255, 255, 255, .18);
    --border-left-width: 1px;
    --border-right-width: 1px;
    --border-style: solid;
    --border-top-width: 1px;
    align-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, .19) 0%, rgba(255, 255, 255, .04) 100%);
    border-radius: 10px;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 8px 16px;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-193ukeq {
    flex: none;
    height: 25px;
    position: relative;
    width: 25px
}

.framer-97rJI .framer-11f4a4n {
    --border-bottom-width: 1px;
    --border-color: rgba(255, 255, 255, .18);
    --border-left-width: 1px;
    --border-right-width: 1px;
    --border-style: solid;
    --border-top-width: 1px;
    align-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, .19) 0%, rgba(255, 255, 255, .04) 100%);
    border-radius: 10px;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 8px 16px;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-z2m1sq {
    flex: none;
    height: 31px;
    position: relative;
    width: 31px
}

.framer-97rJI .framer-pa3pbi {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 12px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-97rJI .framer-1rq4ay6-container {
    flex: none;
    height: auto;
    position: relative;
    width: auto;
    z-index: 9
}

.framer-97rJI .framer-9azefj {
    --framer-paragraph-spacing: 0px;
    flex: none;
    height: auto;
    opacity: .6;
    position: relative;
    white-space: pre-wrap;
    width: 607px;
    word-break: break-word;
    word-wrap: break-word
}

.framer-97rJI .framer-nprimq-container {
    flex: none;
    height: auto;
    position: relative;
    width: 100%
}

.framer-97rJI .framer-1eu4ola-container {
    flex: none;
    height: auto;
    position: relative;
    width: 1440px
}

@supports (background: -webkit-named-image(i)) and (not (scale:1)) {

    .framer-97rJI .framer-72rtr7,
    .framer-97rJI .navbar,
    .framer-97rJI .framer-1l9mm7q,
    .framer-97rJI .framer-1pvz644,
    .framer-97rJI .framer-1wrrtkp,
    .framer-97rJI .framer-ofssek,
    .framer-97rJI .framer-e61qcl,
    .framer-97rJI .framer-hgg6ej,
    .framer-97rJI .framer-1chwo98,
    .framer-97rJI .framer-gj8r3p,
    .framer-97rJI .framer-wpl817,
    .framer-97rJI .framer-1b5foaf,
    .framer-97rJI .framer-15rf8eu,
    .framer-97rJI .framer-1yspppi,
    .framer-97rJI .framer-euc7iw,
    .framer-97rJI .framer-1wcn9io,
    .framer-97rJI .framer-13ew9r8,
    .framer-97rJI .framer-1un2ayx,
    .framer-97rJI .framer-11nc1m0,
    .framer-97rJI .framer-3ssmbw,
    .framer-97rJI .framer-3mzl39,
    .framer-97rJI .framer-1b3swhe,
    .framer-97rJI .framer-1aae7r3,
    .framer-97rJI .framer-qsls2j,
    .framer-97rJI .framer-je95qz,
    .framer-97rJI .framer-49is85,
    .framer-97rJI .framer-vpdmo1,
    .framer-97rJI .framer-smc5ba,
    .framer-97rJI .framer-vw6whp,
    .framer-97rJI .framer-1v1z9j,
    .framer-97rJI .framer-1lfr6ou,
    .framer-97rJI .framer-1n5y460,
    .framer-97rJI .framer-12n1cz,
    .framer-97rJI .framer-o74vfa,
    .framer-97rJI .framer-6wkfww,
    .framer-97rJI .framer-866dgb,
    .framer-97rJI .framer-1lrlkmo,
    .framer-97rJI .framer-r5tvq6,
    .framer-97rJI .framer-jhmtls,
    .framer-97rJI .framer-1rfv8ym,
    .framer-97rJI .framer-1j0lv20,
    .framer-97rJI .framer-kzpjwj,
    .framer-97rJI .framer-11udwvy,
    .framer-97rJI .framer-1a2dpgh,
    .framer-97rJI .framer-1xwcvgu,
    .framer-97rJI .framer-1wfkc1z,
    .framer-97rJI .framer-1pkz81l,
    .framer-97rJI .framer-7t6h8s,
    .framer-97rJI .framer-1n6ov80,
    .framer-97rJI .framer-fy4ath,
    .framer-97rJI .framer-1rp823r,
    .framer-97rJI .framer-19ah3bk,
    .framer-97rJI .framer-u1xhss,
    .framer-97rJI .framer-1peizua,
    .framer-97rJI .framer-2hs7n1,
    .framer-97rJI .framer-juqyll,
    .framer-97rJI .framer-1sfr8we,
    .framer-97rJI .framer-zf6ut8,
    .framer-97rJI .framer-rgy95z,
    .framer-97rJI .framer-1oyfwvc,
    .framer-97rJI .framer-1wu4mmx,
    .framer-97rJI .framer-cylkc8,
    .framer-97rJI .framer-hqsyw0,
    .framer-97rJI .framer-g9rlpx,
    .framer-97rJI .framer-w1kx9j,
    .framer-97rJI .framer-1l5i75g,
    .framer-97rJI .framer-yvjwy2,
    .framer-97rJI .framer-1sfg8nx,
    .framer-97rJI .framer-ddvqi8,
    .framer-97rJI .framer-1ou3qdj,
    .framer-97rJI .framer-9cl8q0,
    .framer-97rJI .framer-17amp86,
    .framer-97rJI .framer-rgjd9e,
    .framer-97rJI .framer-1r97wfd,
    .framer-97rJI .framer-12no29k,
    .framer-97rJI .framer-1urlfsk,
    .framer-97rJI .framer-jzz0h2,
    .framer-97rJI .framer-dzdjvj,
    .framer-97rJI .framer-mrehk0,
    .framer-97rJI .framer-1ho5ydl,
    .framer-97rJI .framer-1a1pvl9,
    .framer-97rJI .framer-10opef8,
    .framer-97rJI .framer-1huo939,
    .framer-97rJI .framer-1tehynk,
    .framer-97rJI .framer-ulnt9v,
    .framer-97rJI .framer-1n8goct,
    .framer-97rJI .framer-r1c62u,
    .framer-97rJI .framer-1udceag,
    .framer-97rJI .framer-1ve8ypk,
    .framer-97rJI .framer-8okrui,
    .framer-97rJI .framer-o9uaah,
    .framer-97rJI .framer-1964g6t,
    .framer-97rJI .framer-1l182px,
    .framer-97rJI .framer-1qxe9o5,
    .framer-97rJI .framer-ai426w,
    .framer-97rJI .framer-1mfv3cu,
    .framer-97rJI .framer-dnjzfc,
    .framer-97rJI .framer-1wwocei,
    .framer-97rJI .framer-14ey6aw,
    .framer-97rJI .framer-1cghnmp,
    .framer-97rJI .framer-evcvxq,
    .framer-97rJI .framer-ojg17a,
    .framer-97rJI .framer-16lndoa,
    .framer-97rJI .framer-4z4z3,
    .framer-97rJI .framer-1ax2e7y,
    .framer-97rJI .framer-2qwdqp,
    .framer-97rJI .framer-19fybtn,
    .framer-97rJI .framer-16i0ts8,
    .framer-97rJI .framer-12a0s4y,
    .framer-97rJI .framer-1opk852,
    .framer-97rJI .framer-b7zc80,
    .framer-97rJI .framer-nss9lt,
    .framer-97rJI .framer-1v0oifu,
    .framer-97rJI .framer-ekp3g7,
    .framer-97rJI .framer-4awfvo,
    .framer-97rJI .framer-116mv18,
    .framer-97rJI .framer-1b8wti4,
    .framer-97rJI .framer-ma71n8,
    .framer-97rJI .framer-474jxp,
    .framer-97rJI .framer-90tm6k,
    .framer-97rJI .framer-r6z4pg,
    .framer-97rJI .framer-s6hy2b,
    .framer-97rJI .framer-v3u1up,
    .framer-97rJI .framer-ohe6nr,
    .framer-97rJI .framer-1rs5s5g,
    .framer-97rJI .framer-9ocitq,
    .framer-97rJI .framer-7cfgdx,
    .framer-97rJI .framer-hmcbx0,
    .framer-97rJI .framer-skpi98,
    .framer-97rJI .framer-o210t,
    .framer-97rJI .framer-l1o88t,
    .framer-97rJI .framer-an9xu2,
    .framer-97rJI .framer-ynh4nb,
    .framer-97rJI .framer-vldtt2,
    .framer-97rJI .framer-4tb9db,
    .framer-97rJI .framer-1vfw6bt,
    .framer-97rJI .framer-dx4x6g,
    .framer-97rJI .framer-1nj42j2,
    .framer-97rJI .framer-fom51z,
    .framer-97rJI .framer-8yddhs,
    .framer-97rJI .framer-wepogq,
    .framer-97rJI .framer-984wht,
    .framer-97rJI .framer-5ely8e,
    .framer-97rJI .framer-qjpmzt,
    .framer-97rJI .framer-zya26f,
    .framer-97rJI .framer-hq1d08,
    .framer-97rJI .framer-1y9xts9,
    .framer-97rJI .framer-11f4a4n,
    .framer-97rJI .framer-pa3pbi,
    .framer-97rJI .framer-xmzru8 {
        gap: 0px
    }

    .framer-97rJI .framer-72rtr7>*,
    .framer-97rJI .framer-1l9mm7q>*,
    .framer-97rJI .framer-1wrrtkp>*,
    .framer-97rJI .framer-1chwo98>*,
    .framer-97rJI .framer-13ew9r8>*,
    .framer-97rJI .framer-1un2ayx>*,
    .framer-97rJI .framer-kzpjwj>*,
    .framer-97rJI .framer-11udwvy>*,
    .framer-97rJI .framer-1n6ov80>*,
    .framer-97rJI .framer-cylkc8>*,
    .framer-97rJI .framer-12no29k>*,
    .framer-97rJI .framer-1a1pvl9>*,
    .framer-97rJI .framer-1l182px>*,
    .framer-97rJI .framer-evcvxq>*,
    .framer-97rJI .framer-1ax2e7y>*,
    .framer-97rJI .framer-12a0s4y>*,
    .framer-97rJI .framer-1v0oifu>*,
    .framer-97rJI .framer-1b8wti4>*,
    .framer-97rJI .framer-r6z4pg>*,
    .framer-97rJI .framer-s6hy2b>*,
    .framer-97rJI .framer-v3u1up>*,
    .framer-97rJI .framer-8yddhs>* {
        margin: 0
    }

    .framer-97rJI .framer-72rtr7>:first-child,
    .framer-97rJI .framer-1l9mm7q>:first-child,
    .framer-97rJI .framer-1wrrtkp>:first-child,
    .framer-97rJI .framer-ofssek>:first-child,
    .framer-97rJI .framer-e61qcl>:first-child,
    .framer-97rJI .framer-1chwo98>:first-child,
    .framer-97rJI .framer-13ew9r8>:first-child,
    .framer-97rJI .framer-1un2ayx>:first-child,
    .framer-97rJI .framer-11nc1m0>:first-child,
    .framer-97rJI .framer-3ssmbw>:first-child,
    .framer-97rJI .framer-1aae7r3>:first-child,
    .framer-97rJI .framer-je95qz>:first-child,
    .framer-97rJI .framer-vpdmo1>:first-child,
    .framer-97rJI .framer-smc5ba>:first-child,
    .framer-97rJI .framer-vw6whp>:first-child,
    .framer-97rJI .framer-1lfr6ou>:first-child,
    .framer-97rJI .framer-1n5y460>:first-child,
    .framer-97rJI .framer-6wkfww>:first-child,
    .framer-97rJI .framer-1lrlkmo>:first-child,
    .framer-97rJI .framer-r5tvq6>:first-child,
    .framer-97rJI .framer-1rfv8ym>:first-child,
    .framer-97rJI .framer-1j0lv20>:first-child,
    .framer-97rJI .framer-kzpjwj>:first-child,
    .framer-97rJI .framer-11udwvy>:first-child,
    .framer-97rJI .framer-1xwcvgu>:first-child,
    .framer-97rJI .framer-1wfkc1z>:first-child,
    .framer-97rJI .framer-1n6ov80>:first-child,
    .framer-97rJI .framer-1rp823r>:first-child,
    .framer-97rJI .framer-19ah3bk>:first-child,
    .framer-97rJI .framer-1peizua>:first-child,
    .framer-97rJI .framer-juqyll>:first-child,
    .framer-97rJI .framer-1oyfwvc>:first-child,
    .framer-97rJI .framer-cylkc8>:first-child,
    .framer-97rJI .framer-hqsyw0>:first-child,
    .framer-97rJI .framer-g9rlpx>:first-child,
    .framer-97rJI .framer-1sfg8nx>:first-child,
    .framer-97rJI .framer-ddvqi8>:first-child,
    .framer-97rJI .framer-1ou3qdj>:first-child,
    .framer-97rJI .framer-9cl8q0>:first-child,
    .framer-97rJI .framer-17amp86>:first-child,
    .framer-97rJI .framer-rgjd9e>:first-child,
    .framer-97rJI .framer-1r97wfd>:first-child,
    .framer-97rJI .framer-12no29k>:first-child,
    .framer-97rJI .framer-dzdjvj>:first-child,
    .framer-97rJI .framer-mrehk0>:first-child,
    .framer-97rJI .framer-1a1pvl9>:first-child,
    .framer-97rJI .framer-1tehynk>:first-child,
    .framer-97rJI .framer-ulnt9v>:first-child,
    .framer-97rJI .framer-r1c62u>:first-child,
    .framer-97rJI .framer-1ve8ypk>:first-child,
    .framer-97rJI .framer-1964g6t>:first-child,
    .framer-97rJI .framer-1l182px>:first-child,
    .framer-97rJI .framer-1qxe9o5>:first-child,
    .framer-97rJI .framer-ai426w>:first-child,
    .framer-97rJI .framer-14ey6aw>:first-child,
    .framer-97rJI .framer-evcvxq>:first-child,
    .framer-97rJI .framer-16lndoa>:first-child,
    .framer-97rJI .framer-1ax2e7y>:first-child,
    .framer-97rJI .framer-19fybtn>:first-child,
    .framer-97rJI .framer-12a0s4y>:first-child,
    .framer-97rJI .framer-b7zc80>:first-child,
    .framer-97rJI .framer-1v0oifu>:first-child,
    .framer-97rJI .framer-4awfvo>:first-child,
    .framer-97rJI .framer-1b8wti4>:first-child,
    .framer-97rJI .framer-474jxp>:first-child,
    .framer-97rJI .framer-r6z4pg>:first-child,
    .framer-97rJI .framer-s6hy2b>:first-child,
    .framer-97rJI .framer-v3u1up>:first-child,
    .framer-97rJI .framer-ohe6nr>:first-child,
    .framer-97rJI .framer-9ocitq>:first-child,
    .framer-97rJI .framer-skpi98>:first-child,
    .framer-97rJI .framer-l1o88t>:first-child,
    .framer-97rJI .framer-an9xu2>:first-child,
    .framer-97rJI .framer-ynh4nb>:first-child,
    .framer-97rJI .framer-4tb9db>:first-child,
    .framer-97rJI .framer-1vfw6bt>:first-child,
    .framer-97rJI .framer-dx4x6g>:first-child,
    .framer-97rJI .framer-8yddhs>:first-child,
    .framer-97rJI .framer-wepogq>:first-child,
    .framer-97rJI .framer-984wht>:first-child,
    .framer-97rJI .framer-5ely8e>:first-child,
    .framer-97rJI .framer-pa3pbi>:first-child {
        margin-top: 0
    }

    .framer-97rJI .framer-72rtr7>:last-child,
    .framer-97rJI .framer-1l9mm7q>:last-child,
    .framer-97rJI .framer-1wrrtkp>:last-child,
    .framer-97rJI .framer-ofssek>:last-child,
    .framer-97rJI .framer-e61qcl>:last-child,
    .framer-97rJI .framer-1chwo98>:last-child,
    .framer-97rJI .framer-13ew9r8>:last-child,
    .framer-97rJI .framer-1un2ayx>:last-child,
    .framer-97rJI .framer-11nc1m0>:last-child,
    .framer-97rJI .framer-3ssmbw>:last-child,
    .framer-97rJI .framer-1aae7r3>:last-child,
    .framer-97rJI .framer-je95qz>:last-child,
    .framer-97rJI .framer-vpdmo1>:last-child,
    .framer-97rJI .framer-smc5ba>:last-child,
    .framer-97rJI .framer-vw6whp>:last-child,
    .framer-97rJI .framer-1lfr6ou>:last-child,
    .framer-97rJI .framer-1n5y460>:last-child,
    .framer-97rJI .framer-6wkfww>:last-child,
    .framer-97rJI .framer-1lrlkmo>:last-child,
    .framer-97rJI .framer-r5tvq6>:last-child,
    .framer-97rJI .framer-1rfv8ym>:last-child,
    .framer-97rJI .framer-1j0lv20>:last-child,
    .framer-97rJI .framer-kzpjwj>:last-child,
    .framer-97rJI .framer-11udwvy>:last-child,
    .framer-97rJI .framer-1xwcvgu>:last-child,
    .framer-97rJI .framer-1wfkc1z>:last-child,
    .framer-97rJI .framer-1n6ov80>:last-child,
    .framer-97rJI .framer-1rp823r>:last-child,
    .framer-97rJI .framer-19ah3bk>:last-child,
    .framer-97rJI .framer-1peizua>:last-child,
    .framer-97rJI .framer-juqyll>:last-child,
    .framer-97rJI .framer-1oyfwvc>:last-child,
    .framer-97rJI .framer-cylkc8>:last-child,
    .framer-97rJI .framer-hqsyw0>:last-child,
    .framer-97rJI .framer-g9rlpx>:last-child,
    .framer-97rJI .framer-1sfg8nx>:last-child,
    .framer-97rJI .framer-ddvqi8>:last-child,
    .framer-97rJI .framer-1ou3qdj>:last-child,
    .framer-97rJI .framer-9cl8q0>:last-child,
    .framer-97rJI .framer-17amp86>:last-child,
    .framer-97rJI .framer-rgjd9e>:last-child,
    .framer-97rJI .framer-1r97wfd>:last-child,
    .framer-97rJI .framer-12no29k>:last-child,
    .framer-97rJI .framer-dzdjvj>:last-child,
    .framer-97rJI .framer-mrehk0>:last-child,
    .framer-97rJI .framer-1a1pvl9>:last-child,
    .framer-97rJI .framer-1tehynk>:last-child,
    .framer-97rJI .framer-ulnt9v>:last-child,
    .framer-97rJI .framer-r1c62u>:last-child,
    .framer-97rJI .framer-1ve8ypk>:last-child,
    .framer-97rJI .framer-1964g6t>:last-child,
    .framer-97rJI .framer-1l182px>:last-child,
    .framer-97rJI .framer-1qxe9o5>:last-child,
    .framer-97rJI .framer-ai426w>:last-child,
    .framer-97rJI .framer-14ey6aw>:last-child,
    .framer-97rJI .framer-evcvxq>:last-child,
    .framer-97rJI .framer-16lndoa>:last-child,
    .framer-97rJI .framer-1ax2e7y>:last-child,
    .framer-97rJI .framer-19fybtn>:last-child,
    .framer-97rJI .framer-12a0s4y>:last-child,
    .framer-97rJI .framer-b7zc80>:last-child,
    .framer-97rJI .framer-1v0oifu>:last-child,
    .framer-97rJI .framer-4awfvo>:last-child,
    .framer-97rJI .framer-1b8wti4>:last-child,
    .framer-97rJI .framer-474jxp>:last-child,
    .framer-97rJI .framer-r6z4pg>:last-child,
    .framer-97rJI .framer-s6hy2b>:last-child,
    .framer-97rJI .framer-v3u1up>:last-child,
    .framer-97rJI .framer-ohe6nr>:last-child,
    .framer-97rJI .framer-9ocitq>:last-child,
    .framer-97rJI .framer-skpi98>:last-child,
    .framer-97rJI .framer-l1o88t>:last-child,
    .framer-97rJI .framer-an9xu2>:last-child,
    .framer-97rJI .framer-ynh4nb>:last-child,
    .framer-97rJI .framer-4tb9db>:last-child,
    .framer-97rJI .framer-1vfw6bt>:last-child,
    .framer-97rJI .framer-dx4x6g>:last-child,
    .framer-97rJI .framer-8yddhs>:last-child,
    .framer-97rJI .framer-wepogq>:last-child,
    .framer-97rJI .framer-984wht>:last-child,
    .framer-97rJI .framer-5ely8e>:last-child,
    .framer-97rJI .framer-pa3pbi>:last-child {
        margin-bottom: 0
    }

    .framer-97rJI .navbar>*,
    .framer-97rJI .framer-1pvz644>*,
    .framer-97rJI .framer-hgg6ej>*,
    .framer-97rJI .framer-gj8r3p>*,
    .framer-97rJI .framer-wpl817>*,
    .framer-97rJI .framer-15rf8eu>*,
    .framer-97rJI .framer-1yspppi>*,
    .framer-97rJI .framer-euc7iw>*,
    .framer-97rJI .framer-1wcn9io>*,
    .framer-97rJI .framer-49is85>*,
    .framer-97rJI .framer-1v1z9j>*,
    .framer-97rJI .framer-12n1cz>*,
    .framer-97rJI .framer-866dgb>*,
    .framer-97rJI .framer-jhmtls>*,
    .framer-97rJI .framer-fy4ath>*,
    .framer-97rJI .framer-u1xhss>*,
    .framer-97rJI .framer-2hs7n1>*,
    .framer-97rJI .framer-1sfr8we>*,
    .framer-97rJI .framer-rgy95z>*,
    .framer-97rJI .framer-1wu4mmx>*,
    .framer-97rJI .framer-jzz0h2>*,
    .framer-97rJI .framer-10opef8>*,
    .framer-97rJI .framer-1n8goct>*,
    .framer-97rJI .framer-1udceag>*,
    .framer-97rJI .framer-8okrui>*,
    .framer-97rJI .framer-1wwocei>*,
    .framer-97rJI .framer-ojg17a>*,
    .framer-97rJI .framer-2qwdqp>*,
    .framer-97rJI .framer-1opk852>*,
    .framer-97rJI .framer-ekp3g7>*,
    .framer-97rJI .framer-ma71n8>*,
    .framer-97rJI .framer-1rs5s5g>*,
    .framer-97rJI .framer-hmcbx0>*,
    .framer-97rJI .framer-1nj42j2>*,
    .framer-97rJI .framer-fom51z>*,
    .framer-97rJI .framer-zya26f>*,
    .framer-97rJI .framer-1y9xts9>* {
        margin: 0 5px
    }

    .framer-97rJI .navbar>:first-child,
    .framer-97rJI .framer-1pvz644>:first-child,
    .framer-97rJI .framer-hgg6ej>:first-child,
    .framer-97rJI .framer-gj8r3p>:first-child,
    .framer-97rJI .framer-wpl817>:first-child,
    .framer-97rJI .framer-1b5foaf>:first-child,
    .framer-97rJI .framer-15rf8eu>:first-child,
    .framer-97rJI .framer-1yspppi>:first-child,
    .framer-97rJI .framer-euc7iw>:first-child,
    .framer-97rJI .framer-1wcn9io>:first-child,
    .framer-97rJI .framer-3mzl39>:first-child,
    .framer-97rJI .framer-1b3swhe>:first-child,
    .framer-97rJI .framer-qsls2j>:first-child,
    .framer-97rJI .framer-49is85>:first-child,
    .framer-97rJI .framer-1v1z9j>:first-child,
    .framer-97rJI .framer-12n1cz>:first-child,
    .framer-97rJI .framer-o74vfa>:first-child,
    .framer-97rJI .framer-866dgb>:first-child,
    .framer-97rJI .framer-jhmtls>:first-child,
    .framer-97rJI .framer-1a2dpgh>:first-child,
    .framer-97rJI .framer-1pkz81l>:first-child,
    .framer-97rJI .framer-7t6h8s>:first-child,
    .framer-97rJI .framer-fy4ath>:first-child,
    .framer-97rJI .framer-u1xhss>:first-child,
    .framer-97rJI .framer-2hs7n1>:first-child,
    .framer-97rJI .framer-1sfr8we>:first-child,
    .framer-97rJI .framer-zf6ut8>:first-child,
    .framer-97rJI .framer-rgy95z>:first-child,
    .framer-97rJI .framer-1wu4mmx>:first-child,
    .framer-97rJI .framer-w1kx9j>:first-child,
    .framer-97rJI .framer-1l5i75g>:first-child,
    .framer-97rJI .framer-yvjwy2>:first-child,
    .framer-97rJI .framer-1urlfsk>:first-child,
    .framer-97rJI .framer-jzz0h2>:first-child,
    .framer-97rJI .framer-1ho5ydl>:first-child,
    .framer-97rJI .framer-10opef8>:first-child,
    .framer-97rJI .framer-1huo939>:first-child,
    .framer-97rJI .framer-1n8goct>:first-child,
    .framer-97rJI .framer-1udceag>:first-child,
    .framer-97rJI .framer-8okrui>:first-child,
    .framer-97rJI .framer-o9uaah>:first-child,
    .framer-97rJI .framer-1mfv3cu>:first-child,
    .framer-97rJI .framer-dnjzfc>:first-child,
    .framer-97rJI .framer-1wwocei>:first-child,
    .framer-97rJI .framer-1cghnmp>:first-child,
    .framer-97rJI .framer-ojg17a>:first-child,
    .framer-97rJI .framer-4z4z3>:first-child,
    .framer-97rJI .framer-2qwdqp>:first-child,
    .framer-97rJI .framer-16i0ts8>:first-child,
    .framer-97rJI .framer-1opk852>:first-child,
    .framer-97rJI .framer-nss9lt>:first-child,
    .framer-97rJI .framer-ekp3g7>:first-child,
    .framer-97rJI .framer-116mv18>:first-child,
    .framer-97rJI .framer-ma71n8>:first-child,
    .framer-97rJI .framer-90tm6k>:first-child,
    .framer-97rJI .framer-1rs5s5g>:first-child,
    .framer-97rJI .framer-7cfgdx>:first-child,
    .framer-97rJI .framer-hmcbx0>:first-child,
    .framer-97rJI .framer-o210t>:first-child,
    .framer-97rJI .framer-vldtt2>:first-child,
    .framer-97rJI .framer-1nj42j2>:first-child,
    .framer-97rJI .framer-fom51z>:first-child,
    .framer-97rJI .framer-qjpmzt>:first-child,
    .framer-97rJI .framer-zya26f>:first-child,
    .framer-97rJI .framer-hq1d08>:first-child,
    .framer-97rJI .framer-1y9xts9>:first-child,
    .framer-97rJI .framer-11f4a4n>:first-child,
    .framer-97rJI .framer-xmzru8>:first-child {
        margin-left: 0
    }

    .framer-97rJI .navbar>:last-child,
    .framer-97rJI .framer-1pvz644>:last-child,
    .framer-97rJI .framer-hgg6ej>:last-child,
    .framer-97rJI .framer-gj8r3p>:last-child,
    .framer-97rJI .framer-wpl817>:last-child,
    .framer-97rJI .framer-1b5foaf>:last-child,
    .framer-97rJI .framer-15rf8eu>:last-child,
    .framer-97rJI .framer-1yspppi>:last-child,
    .framer-97rJI .framer-euc7iw>:last-child,
    .framer-97rJI .framer-1wcn9io>:last-child,
    .framer-97rJI .framer-3mzl39>:last-child,
    .framer-97rJI .framer-1b3swhe>:last-child,
    .framer-97rJI .framer-qsls2j>:last-child,
    .framer-97rJI .framer-49is85>:last-child,
    .framer-97rJI .framer-1v1z9j>:last-child,
    .framer-97rJI .framer-12n1cz>:last-child,
    .framer-97rJI .framer-o74vfa>:last-child,
    .framer-97rJI .framer-866dgb>:last-child,
    .framer-97rJI .framer-jhmtls>:last-child,
    .framer-97rJI .framer-1a2dpgh>:last-child,
    .framer-97rJI .framer-1pkz81l>:last-child,
    .framer-97rJI .framer-7t6h8s>:last-child,
    .framer-97rJI .framer-fy4ath>:last-child,
    .framer-97rJI .framer-u1xhss>:last-child,
    .framer-97rJI .framer-2hs7n1>:last-child,
    .framer-97rJI .framer-1sfr8we>:last-child,
    .framer-97rJI .framer-zf6ut8>:last-child,
    .framer-97rJI .framer-rgy95z>:last-child,
    .framer-97rJI .framer-1wu4mmx>:last-child,
    .framer-97rJI .framer-w1kx9j>:last-child,
    .framer-97rJI .framer-1l5i75g>:last-child,
    .framer-97rJI .framer-yvjwy2>:last-child,
    .framer-97rJI .framer-1urlfsk>:last-child,
    .framer-97rJI .framer-jzz0h2>:last-child,
    .framer-97rJI .framer-1ho5ydl>:last-child,
    .framer-97rJI .framer-10opef8>:last-child,
    .framer-97rJI .framer-1huo939>:last-child,
    .framer-97rJI .framer-1n8goct>:last-child,
    .framer-97rJI .framer-1udceag>:last-child,
    .framer-97rJI .framer-8okrui>:last-child,
    .framer-97rJI .framer-o9uaah>:last-child,
    .framer-97rJI .framer-1mfv3cu>:last-child,
    .framer-97rJI .framer-dnjzfc>:last-child,
    .framer-97rJI .framer-1wwocei>:last-child,
    .framer-97rJI .framer-1cghnmp>:last-child,
    .framer-97rJI .framer-ojg17a>:last-child,
    .framer-97rJI .framer-4z4z3>:last-child,
    .framer-97rJI .framer-2qwdqp>:last-child,
    .framer-97rJI .framer-16i0ts8>:last-child,
    .framer-97rJI .framer-1opk852>:last-child,
    .framer-97rJI .framer-nss9lt>:last-child,
    .framer-97rJI .framer-ekp3g7>:last-child,
    .framer-97rJI .framer-116mv18>:last-child,
    .framer-97rJI .framer-ma71n8>:last-child,
    .framer-97rJI .framer-90tm6k>:last-child,
    .framer-97rJI .framer-1rs5s5g>:last-child,
    .framer-97rJI .framer-7cfgdx>:last-child,
    .framer-97rJI .framer-hmcbx0>:last-child,
    .framer-97rJI .framer-o210t>:last-child,
    .framer-97rJI .framer-vldtt2>:last-child,
    .framer-97rJI .framer-1nj42j2>:last-child,
    .framer-97rJI .framer-fom51z>:last-child,
    .framer-97rJI .framer-qjpmzt>:last-child,
    .framer-97rJI .framer-zya26f>:last-child,
    .framer-97rJI .framer-hq1d08>:last-child,
    .framer-97rJI .framer-1y9xts9>:last-child,
    .framer-97rJI .framer-11f4a4n>:last-child,
    .framer-97rJI .framer-xmzru8>:last-child {
        margin-right: 0
    }

    .framer-97rJI .framer-ofssek>* {
        margin: 40px 0
    }

    .framer-97rJI .framer-e61qcl>* {
        margin: 12.5px 0
    }

    .framer-97rJI .framer-1b5foaf>*,
    .framer-97rJI .framer-yvjwy2>*,
    .framer-97rJI .framer-o210t>*,
    .framer-97rJI .framer-vldtt2>* {
        margin: 0 25px
    }

    .framer-97rJI .framer-11nc1m0>*,
    .framer-97rJI .framer-hqsyw0>*,
    .framer-97rJI .framer-1qxe9o5>*,
    .framer-97rJI .framer-wepogq>* {
        margin: 60px 0
    }

    .framer-97rJI .framer-3ssmbw>*,
    .framer-97rJI .framer-9ocitq>* {
        margin: 8px 0
    }

    .framer-97rJI .framer-3mzl39>*,
    .framer-97rJI .framer-1pkz81l>*,
    .framer-97rJI .framer-7t6h8s>*,
    .framer-97rJI .framer-w1kx9j>*,
    .framer-97rJI .framer-1ho5ydl>*,
    .framer-97rJI .framer-1huo939>*,
    .framer-97rJI .framer-1mfv3cu>*,
    .framer-97rJI .framer-7cfgdx>*,
    .framer-97rJI .framer-qjpmzt>* {
        margin: 0 10px
    }

    .framer-97rJI .framer-1b3swhe>*,
    .framer-97rJI .framer-1l5i75g>*,
    .framer-97rJI .framer-dnjzfc>* {
        margin: 0 6px
    }

    .framer-97rJI .framer-1aae7r3>* {
        margin: 15px 0
    }

    .framer-97rJI .framer-qsls2j>*,
    .framer-97rJI .framer-o74vfa>* {
        margin: 0 15px
    }

    .framer-97rJI .framer-je95qz>*,
    .framer-97rJI .framer-smc5ba>*,
    .framer-97rJI .framer-1lfr6ou>*,
    .framer-97rJI .framer-6wkfww>*,
    .framer-97rJI .framer-1rfv8ym>*,
    .framer-97rJI .framer-1oyfwvc>*,
    .framer-97rJI .framer-1r97wfd>*,
    .framer-97rJI .framer-1964g6t>*,
    .framer-97rJI .framer-dx4x6g>* {
        margin: 5px 0
    }

    .framer-97rJI .framer-vpdmo1>*,
    .framer-97rJI .framer-vw6whp>*,
    .framer-97rJI .framer-1n5y460>*,
    .framer-97rJI .framer-1j0lv20>*,
    .framer-97rJI .framer-ddvqi8>*,
    .framer-97rJI .framer-9cl8q0>*,
    .framer-97rJI .framer-rgjd9e>*,
    .framer-97rJI .framer-pa3pbi>* {
        margin: 6px 0
    }

    .framer-97rJI .framer-1lrlkmo>*,
    .framer-97rJI .framer-14ey6aw>*,
    .framer-97rJI .framer-16lndoa>*,
    .framer-97rJI .framer-19fybtn>*,
    .framer-97rJI .framer-b7zc80>*,
    .framer-97rJI .framer-4awfvo>*,
    .framer-97rJI .framer-474jxp>* {
        margin: 11.5px 0
    }

    .framer-97rJI .framer-r5tvq6>* {
        margin: 7.5px 0
    }

    .framer-97rJI .framer-1a2dpgh>* {
        margin: 0 60px
    }

    .framer-97rJI .framer-1xwcvgu>*,
    .framer-97rJI .framer-1sfg8nx>*,
    .framer-97rJI .framer-1ou3qdj>*,
    .framer-97rJI .framer-17amp86>*,
    .framer-97rJI .framer-dzdjvj>* {
        margin: 25px 0
    }

    .framer-97rJI .framer-1wfkc1z>*,
    .framer-97rJI .framer-1rp823r>*,
    .framer-97rJI .framer-19ah3bk>*,
    .framer-97rJI .framer-1peizua>*,
    .framer-97rJI .framer-juqyll>*,
    .framer-97rJI .framer-mrehk0>*,
    .framer-97rJI .framer-1tehynk>*,
    .framer-97rJI .framer-ulnt9v>*,
    .framer-97rJI .framer-r1c62u>*,
    .framer-97rJI .framer-1ve8ypk>*,
    .framer-97rJI .framer-5ely8e>* {
        margin: 10px 0
    }

    .framer-97rJI .framer-zf6ut8>*,
    .framer-97rJI .framer-o9uaah>*,
    .framer-97rJI .framer-xmzru8>* {
        margin: 0;
        margin-left: calc(12.64598560333252px / 2);
        margin-right: calc(12.64598560333252px / 2)
    }

    .framer-97rJI .framer-g9rlpx>*,
    .framer-97rJI .framer-ai426w>* {
        margin: 9.5px 0
    }

    .framer-97rJI .framer-1urlfsk>* {
        margin: 0
    }

    .framer-97rJI .framer-1cghnmp>*,
    .framer-97rJI .framer-4z4z3>*,
    .framer-97rJI .framer-16i0ts8>*,
    .framer-97rJI .framer-nss9lt>*,
    .framer-97rJI .framer-116mv18>*,
    .framer-97rJI .framer-90tm6k>* {
        margin: 0 9.5px
    }

    .framer-97rJI .framer-ohe6nr>* {
        margin: 115px 0
    }

    .framer-97rJI .framer-skpi98>* {
        margin: 45px 0
    }

    .framer-97rJI .framer-l1o88t>*,
    .framer-97rJI .framer-an9xu2>*,
    .framer-97rJI .framer-ynh4nb>*,
    .framer-97rJI .framer-4tb9db>*,
    .framer-97rJI .framer-1vfw6bt>* {
        margin: 13.5px 0
    }

    .framer-97rJI .framer-984wht>* {
        margin: 22px 0
    }

    .framer-97rJI .framer-hq1d08>* {
        margin: 0 7.5px
    }

    .framer-97rJI .framer-11f4a4n>* {
        margin: 0 4px
    }
}

@media (min-width: 1439px) {
    .framer-97rJI .hidden-72rtr7 {
        display: none !important
    }
}

@media (min-width: 810px) and (max-width: 1439px) {
    .framer-97rJI .hidden-18ccrvo {
        display: none !important
    }

    body {
        background: var(--token-f9b89b17-0991-4c0e-b401-ca53a64a9c7b, rgb(2, 4, 29))
    }

    .framer-97rJI .framer-72rtr7 {
        width: 100%
    }

    .framer-97rJI .navbar {
        width: 90%
    }

    .framer-97rJI .framer-931it5-container,
    .framer-97rJI .framer-1r14b7r,
    .framer-97rJI .framer-12eryat,
    .framer-97rJI .framer-i9uze0,
    .framer-97rJI .framer-1q7ayv4,
    .framer-97rJI .framer-1dzylcx,
    .framer-97rJI .framer-13362bf {
        flex: 1 0 0px;
        width: 1px
    }

    .framer-97rJI .framer-1pvz644 {
        height: 97%;
        left: 0;
        right: 0;
        top: -250px
    }

    .framer-97rJI .framer-y6bzdz-container {
        bottom: unset;
        height: 279px;
        left: -246px;
        right: unset;
        top: 331px;
        width: 770px
    }

    .framer-97rJI .framer-5j3a53 {
        aspect-ratio: 1.4625 / 1;
        height: var(--framer-aspect-ratio-supported, 800px);
        width: 144%
    }

    .framer-97rJI .framer-1wrrtkp {
        padding: 200px 40px 80px
    }

    .framer-97rJI .framer-e61qcl,
    .framer-97rJI .framer-16w7l19,
    .framer-97rJI .framer-ai426w,
    .framer-97rJI .framer-1mfv3cu,
    .framer-97rJI .framer-9ocitq,
    .framer-97rJI .framer-7cfgdx,
    .framer-97rJI .framer-1eu4ola-container {
        width: 100%
    }

    .framer-97rJI .framer-gj8r3p,
    .framer-97rJI .framer-u1xhss,
    .framer-97rJI .framer-2hs7n1,
    .framer-97rJI .framer-1sfr8we {
        justify-content: center
    }

    .framer-97rJI .framer-1gjs8ze,
    .framer-97rJI .framer-1m4ycra {
        width: 600px
    }

    .framer-97rJI .framer-wpl817 {
        aspect-ratio: 1.1716171617161717 / 1;
        height: var(--framer-aspect-ratio-supported, 623px);
        width: 100%
    }

    .framer-97rJI .framer-1yfo5ku-container {
        left: 50%;
        top: 46%;
        transform: translate(-50%, -50%)
    }

    .framer-97rJI .framer-14k2fe4 {
        flex: 1 0 0px;
        height: var(--framer-aspect-ratio-supported, 591px);
        width: 1px
    }

    .framer-97rJI .framer-1nwyw2w-container {
        height: 130px
    }

    .framer-97rJI .framer-1un2ayx {
        padding: 60px 40px 80px
    }

    .framer-97rJI .framer-7rez0,
    .framer-97rJI .framer-1kc87cu {
        width: 300px
    }

    .framer-97rJI .framer-1aae7r3 {
        gap: 50px;
        width: 100%
    }

    .framer-97rJI .framer-qsls2j {
        align-content: center;
        align-items: center;
        flex-direction: column;
        gap: 50px;
        width: 100%
    }

    .framer-97rJI .framer-o74vfa {
        flex-direction: column;
        gap: 50px
    }

    .framer-97rJI .framer-6wkfww {
        height: 537px;
        width: 410px
    }

    .framer-97rJI .framer-866dgb {
        right: -478px;
        top: -352px
    }

    .framer-97rJI .framer-1lrlkmo {
        align-content: center;
        align-items: center;
        gap: 0px;
        justify-content: flex-end;
        width: 100%
    }

    .framer-97rJI .framer-r5tvq6 {
        align-content: center;
        align-items: center;
        justify-content: center;
        width: 100%
    }

    .framer-97rJI .framer-jhmtls {
        -webkit-backdrop-filter: blur(45px);
        backdrop-filter: blur(45px)
    }

    .framer-97rJI .framer-ws2afn {
        height: var(--framer-aspect-ratio-supported, 273px)
    }

    .framer-97rJI .framer-11udwvy,
    .framer-97rJI .framer-1l182px {
        padding: 80px 40px
    }

    .framer-97rJI .framer-1a2dpgh {
        align-content: center;
        align-items: center;
        flex-direction: column;
        gap: 100px;
        padding: 0
    }

    .framer-97rJI .framer-1xwcvgu {
        align-content: center;
        align-items: center;
        justify-content: center;
        order: 1
    }

    .framer-97rJI .framer-1wfkc1z,
    .framer-97rJI .framer-1rp823r,
    .framer-97rJI .framer-19ah3bk,
    .framer-97rJI .framer-1peizua,
    .framer-97rJI .framer-juqyll,
    .framer-97rJI .framer-dzdjvj,
    .framer-97rJI .framer-ulnt9v,
    .framer-97rJI .framer-r1c62u,
    .framer-97rJI .framer-1ve8ypk {
        align-content: center;
        align-items: center;
        justify-content: center
    }

    .framer-97rJI .framer-1n6ov80,
    .framer-97rJI .framer-mrehk0,
    .framer-97rJI .framer-1tehynk {
        align-content: center;
        align-items: center
    }

    .framer-97rJI .framer-qnjpuc,
    .framer-97rJI .framer-zri7uj,
    .framer-97rJI .framer-fqwwjg,
    .framer-97rJI .framer-1la0vsm,
    .framer-97rJI .framer-1vhm7oi,
    .framer-97rJI .framer-vkyocj {
        white-space: pre;
        width: auto
    }

    .framer-97rJI .framer-1tsrlxx,
    .framer-97rJI .framer-r7pqdu,
    .framer-97rJI .framer-1cqgb3d,
    .framer-97rJI .framer-1hincbq,
    .framer-97rJI .framer-tk0wzh,
    .framer-97rJI .framer-13jjph3 {
        width: 241px
    }

    .framer-97rJI .framer-rgy95z {
        height: 980px;
        order: 0;
        position: relative;
        right: unset;
        top: unset;
        width: 100%
    }

    .framer-97rJI .framer-h56obv {
        aspect-ratio: .9144021739130435 / 1;
        height: var(--framer-aspect-ratio-supported, 648px);
        left: 54%;
        top: -58px;
        transform: translate(-50%);
        width: 593px
    }

    .framer-97rJI .framer-elb6p6 {
        height: var(--framer-aspect-ratio-supported, 929px);
        width: 647px
    }

    .framer-97rJI .framer-1wu4mmx {
        left: 49%;
        top: 100px
    }

    .framer-97rJI .framer-1iy7ri3 {
        aspect-ratio: .9144021739130435 / 1;
        height: var(--framer-aspect-ratio-supported, 648px);
        left: 51%;
        top: -8px;
        width: 593px
    }

    .framer-97rJI .framer-cylkc8 {
        padding: 650px 40px 0
    }

    .framer-97rJI .framer-hqsyw0 {
        padding: 110px 10px 90px
    }

    .framer-97rJI .framer-yvjwy2 {
        align-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%
    }

    .framer-97rJI .framer-xwt5a8 {
        height: 1250px;
        left: calc(50.94339622641512% - 593px / 2);
        opacity: .04;
        top: -8px;
        width: 593px
    }

    .framer-97rJI .framer-12no29k {
        padding: 50px 40px 80px 24px
    }

    .framer-97rJI .framer-1urlfsk {
        flex-direction: column
    }

    .framer-97rJI .framer-jzz0h2 {
        left: unset;
        position: relative;
        top: unset
    }

    .framer-97rJI .framer-1tybo1s {
        aspect-ratio: .9144021739130435 / 1;
        height: var(--framer-aspect-ratio-supported, 649px);
        top: -8px;
        width: 593px
    }

    .framer-97rJI .framer-1a1pvl9 {
        align-content: center;
        align-items: center;
        order: 2
    }

    .framer-97rJI .framer-1vjfh25 {
        order: 3
    }

    .framer-97rJI .framer-1huo939 {
        order: 0
    }

    .framer-97rJI .framer-106ooov-container {
        height: 410px
    }

    .framer-97rJI .framer-1tuvnc7-container {
        height: 420px
    }

    .framer-97rJI .framer-v3u1up {
        padding: 80px 30px
    }

    .framer-97rJI .framer-skpi98 {
        flex-wrap: wrap;
        padding: 350px 0 0;
        width: 100%
    }

    .framer-97rJI .framer-o210t {
        flex-wrap: wrap;
        justify-content: center;
        width: 100%
    }

    .framer-97rJI .framer-1nj42j2 {
        height: min-content;
        left: -285px;
        right: unset;
        top: 391px;
        width: min-content
    }

    .framer-97rJI .framer-w6p6oe,
    .framer-97rJI .framer-u1zxb8 {
        height: var(--framer-aspect-ratio-supported, 569px);
        width: 650px
    }

    .framer-97rJI .framer-fom51z {
        height: min-content;
        left: 98%;
        right: unset;
        top: 22%;
        transform: perspective(1200px) translate(-50%, -50%);
        width: 340px
    }

    .framer-97rJI .framer-8yddhs {
        padding: 180px 24px 200px
    }

    .framer-97rJI .framer-qjpmzt {
        align-self: stretch;
        width: auto
    }

    @supports (background: -webkit-named-image(i)) and (not (scale:1)) {

        .framer-97rJI .framer-1aae7r3,
        .framer-97rJI .framer-qsls2j,
        .framer-97rJI .framer-o74vfa,
        .framer-97rJI .framer-1lrlkmo,
        .framer-97rJI .framer-1a2dpgh,
        .framer-97rJI .framer-yvjwy2,
        .framer-97rJI .framer-1urlfsk {
            gap: 0px
        }

        .framer-97rJI .framer-1aae7r3>*,
        .framer-97rJI .framer-qsls2j>*,
        .framer-97rJI .framer-o74vfa>*,
        .framer-97rJI .framer-yvjwy2>* {
            margin: 25px 0
        }

        .framer-97rJI .framer-1aae7r3>:first-child,
        .framer-97rJI .framer-qsls2j>:first-child,
        .framer-97rJI .framer-o74vfa>:first-child,
        .framer-97rJI .framer-1lrlkmo>:first-child,
        .framer-97rJI .framer-1a2dpgh>:first-child,
        .framer-97rJI .framer-yvjwy2>:first-child,
        .framer-97rJI .framer-1urlfsk>:first-child {
            margin-top: 0
        }

        .framer-97rJI .framer-1aae7r3>:last-child,
        .framer-97rJI .framer-qsls2j>:last-child,
        .framer-97rJI .framer-o74vfa>:last-child,
        .framer-97rJI .framer-1lrlkmo>:last-child,
        .framer-97rJI .framer-1a2dpgh>:last-child,
        .framer-97rJI .framer-yvjwy2>:last-child,
        .framer-97rJI .framer-1urlfsk>:last-child {
            margin-bottom: 0
        }

        .framer-97rJI .framer-1lrlkmo>*,
        .framer-97rJI .framer-1urlfsk>* {
            margin: 0
        }

        .framer-97rJI .framer-1a2dpgh>* {
            margin: 50px 0
        }
    }
}

@media (max-width: 809px) {
    .framer-97rJI .hidden-p5w47l {
        display: none !important
    }

    body {
        background: var(--token-f9b89b17-0991-4c0e-b401-ca53a64a9c7b, rgb(2, 4, 29))
    }

    .framer-97rJI .framer-72rtr7 {
        width: 100%
    }

    .framer-97rJI .navbar {
        --border-left-width: 0px;
        --border-right-width: 0px;
        --border-top-width: 0px;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        padding: 13px 20px;
        top: 0;
        width: 100%
    }

    .framer-97rJI .framer-931it5-container {
        flex: 1 0 0px;
        width: 1px
    }

    .framer-97rJI .framer-1pvz644 {
        height: 97%;
        left: 0;
        right: 0;
        top: -160px
    }

    .framer-97rJI .framer-y6bzdz-container {
        bottom: 327px;
        left: -13px;
        right: unset;
        top: 105px;
        transform: rotate(-40deg);
        width: 250px
    }

    .framer-97rJI .framer-5j3a53 {
        aspect-ratio: .8085714285714286 / 1;
        height: var(--framer-aspect-ratio-supported, 700px);
        width: 146%
    }

    .framer-97rJI .framer-1wrrtkp {
        max-width: 450px;
        padding: 140px 20px 60px
    }

    .framer-97rJI .framer-ofssek {
        gap: 10px
    }

    .framer-97rJI .framer-e61qcl {
        gap: 30px;
        max-width: 500px;
        width: 100%
    }

    .framer-97rJI .framer-hgg6ej,
    .framer-97rJI .framer-jhmtls {
        padding: 3px 17px
    }

    .framer-97rJI .framer-1ps7qll {
        aspect-ratio: 1.0416666666666667 / 1;
        height: var(--framer-aspect-ratio-supported, 21px);
        width: 21px
    }

    .framer-97rJI .framer-1chwo98,
    .framer-97rJI .framer-1gjs8ze,
    .framer-97rJI .framer-1m4ycra,
    .framer-97rJI .framer-1aae7r3,
    .framer-97rJI .framer-14bkxj8,
    .framer-97rJI .framer-1712gxo,
    .framer-97rJI .framer-bjbc8i,
    .framer-97rJI .framer-1lrlkmo,
    .framer-97rJI .framer-16w7l19,
    .framer-97rJI .framer-15ch4kt,
    .framer-97rJI .framer-o8tt5c,
    .framer-97rJI .framer-qnjpuc,
    .framer-97rJI .framer-1peizua,
    .framer-97rJI .framer-zri7uj,
    .framer-97rJI .framer-juqyll,
    .framer-97rJI .framer-fqwwjg,
    .framer-97rJI .framer-y562of,
    .framer-97rJI .framer-1sfg8nx,
    .framer-97rJI .framer-12jripk,
    .framer-97rJI .framer-1ou3qdj,
    .framer-97rJI .framer-1wdyiwz,
    .framer-97rJI .framer-17amp86,
    .framer-97rJI .framer-lr9764,
    .framer-97rJI .framer-1tehynk,
    .framer-97rJI .framer-ulnt9v,
    .framer-97rJI .framer-1la0vsm,
    .framer-97rJI .framer-r1c62u,
    .framer-97rJI .framer-1vhm7oi,
    .framer-97rJI .framer-1ve8ypk,
    .framer-97rJI .framer-vkyocj,
    .framer-97rJI .framer-ai426w,
    .framer-97rJI .framer-bmcqfn,
    .framer-97rJI .framer-h4w8mj-container,
    .framer-97rJI .framer-1lx9t9v,
    .framer-97rJI .framer-l1o88t,
    .framer-97rJI .framer-fag6of,
    .framer-97rJI .framer-an9xu2,
    .framer-97rJI .framer-7tvetr,
    .framer-97rJI .framer-ynh4nb,
    .framer-97rJI .framer-1xr59kg,
    .framer-97rJI .framer-4tb9db,
    .framer-97rJI .framer-1u79zrq,
    .framer-97rJI .framer-1vfw6bt,
    .framer-97rJI .framer-j8p8bn,
    .framer-97rJI .framer-984wht,
    .framer-97rJI .framer-5ely8e,
    .framer-97rJI .framer-eic4vs,
    .framer-97rJI .framer-1eu4ola-container {
        width: 100%
    }

    .framer-97rJI .framer-gj8r3p,
    .framer-97rJI .framer-1sfr8we,
    .framer-97rJI .framer-hmcbx0 {
        flex-direction: column;
        gap: 0px;
        width: 100%
    }

    .framer-97rJI .framer-wpl817 {
        aspect-ratio: .8838383838383839 / 1;
        height: var(--framer-aspect-ratio-supported, 396px);
        width: 100%
    }

    .framer-97rJI .framer-1yfo5ku-container {
        aspect-ratio: 1 / 1;
        height: var(--framer-aspect-ratio-supported, 100px);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100px
    }

    .framer-97rJI .framer-14k2fe4 {
        flex: 1 0 0px;
        height: var(--framer-aspect-ratio-supported, 284px);
        width: 1px
    }

    .framer-97rJI .framer-1nwyw2w-container {
        height: 61px
    }

    .framer-97rJI .framer-1un2ayx,
    .framer-97rJI .framer-1l182px {
        max-width: 450px;
        padding: 60px 20px
    }

    .framer-97rJI .framer-11nc1m0 {
        gap: 80px;
        padding: 0
    }

    .framer-97rJI .framer-3ssmbw {
        gap: 25px;
        max-width: 500px;
        width: 100%
    }

    .framer-97rJI .framer-3mzl39,
    .framer-97rJI .framer-1pkz81l,
    .framer-97rJI .framer-w1kx9j,
    .framer-97rJI .framer-1mfv3cu,
    .framer-97rJI .framer-ohe6nr,
    .framer-97rJI .framer-qjpmzt {
        justify-content: center
    }

    .framer-97rJI .framer-7rez0,
    .framer-97rJI .framer-1kc87cu,
    .framer-97rJI .framer-19w5gd7,
    .framer-97rJI .framer-13rh0fy,
    .framer-97rJI .framer-1sgd7z5,
    .framer-97rJI .framer-ebbv65,
    .framer-97rJI .framer-1l6d1of,
    .framer-97rJI .framer-6md674,
    .framer-97rJI .framer-1r14b7r,
    .framer-97rJI .framer-12eryat,
    .framer-97rJI .framer-i9uze0,
    .framer-97rJI .framer-1q7ayv4,
    .framer-97rJI .framer-1dzylcx,
    .framer-97rJI .framer-13362bf {
        width: 100px
    }

    .framer-97rJI .framer-1b3swhe,
    .framer-97rJI .framer-1l5i75g,
    .framer-97rJI .framer-dnjzfc {
        flex-direction: column;
        gap: 0px
    }

    .framer-97rJI .framer-qsls2j {
        align-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 500px;
        width: 100%
    }

    .framer-97rJI .framer-je95qz {
        gap: 9px;
        height: 480px;
        padding: 30px 20px;
        width: 100%
    }

    .framer-97rJI .framer-49is85 {
        left: -8px;
        right: -36px;
        width: unset
    }

    .framer-97rJI .framer-1s3sbvi {
        height: var(--framer-aspect-ratio-supported, 328px)
    }

    .framer-97rJI .framer-vpdmo1,
    .framer-97rJI .framer-vw6whp,
    .framer-97rJI .framer-1n5y460,
    .framer-97rJI .framer-1j0lv20,
    .framer-97rJI .framer-g9rlpx,
    .framer-97rJI .framer-ddvqi8,
    .framer-97rJI .framer-9cl8q0,
    .framer-97rJI .framer-rgjd9e {
        height: min-content;
        width: 100%
    }

    .framer-97rJI .framer-smc5ba,
    .framer-97rJI .framer-1lfr6ou {
        height: 480px;
        padding: 30px 20px;
        width: 100%
    }

    .framer-97rJI .framer-1v1z9j {
        right: -129px;
        top: -286px
    }

    .framer-97rJI .framer-o74vfa {
        flex-direction: column;
        max-width: 500px;
        width: 100%
    }

    .framer-97rJI .framer-6wkfww {
        gap: 0px;
        height: 560px;
        padding: 30px 20px 40px;
        width: 100%
    }

    .framer-97rJI .framer-866dgb {
        right: -461px;
        top: -305px
    }

    .framer-97rJI .framer-r5tvq6,
    .framer-97rJI .framer-1n6ov80,
    .framer-97rJI .framer-19ah3bk,
    .framer-97rJI .framer-mrehk0 {
        align-content: center;
        align-items: center;
        justify-content: center;
        width: 100%
    }

    .framer-97rJI .framer-1yunemf {
        aspect-ratio: 1.0416666666666667 / 1;
        height: var(--framer-aspect-ratio-supported, 20px);
        width: 21px
    }

    .framer-97rJI .framer-1rfv8ym {
        height: 494px;
        padding: 30px 20px;
        width: 100%
    }

    .framer-97rJI .framer-ws2afn {
        height: var(--framer-aspect-ratio-supported, 273px)
    }

    .framer-97rJI .framer-gsr5x1,
    .framer-97rJI .framer-qsfsgs {
        white-space: pre-wrap;
        width: 100%;
        word-break: break-word;
        word-wrap: break-word
    }

    .framer-97rJI .framer-11udwvy {
        max-width: 450px;
        padding: 40px 20px 60px
    }

    .framer-97rJI .framer-1a2dpgh {
        flex-direction: column;
        gap: 70px;
        padding: 0
    }

    .framer-97rJI .framer-1xwcvgu {
        align-content: center;
        align-items: center;
        max-width: 450px;
        order: 1;
        width: 100%
    }

    .framer-97rJI .framer-1wfkc1z,
    .framer-97rJI .framer-dzdjvj {
        align-content: center;
        align-items: center;
        width: 100%
    }

    .framer-97rJI .framer-fy4ath {
        align-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0px
    }

    .framer-97rJI .framer-1rp823r {
        align-content: center;
        align-items: center;
        gap: 25px;
        justify-content: center;
        width: 100%
    }

    .framer-97rJI .framer-u1xhss,
    .framer-97rJI .framer-2hs7n1 {
        flex-direction: column;
        gap: 0px;
        justify-content: center;
        width: 100%
    }

    .framer-97rJI .framer-rgy95z {
        gap: 0px;
        order: 0;
        position: relative;
        right: unset;
        top: unset;
        width: 100%
    }

    .framer-97rJI .framer-h56obv {
        aspect-ratio: .8409090909090909 / 1;
        height: var(--framer-aspect-ratio-supported, 380px);
        left: 50%;
        transform: translate(-50%);
        width: 320px
    }

    .framer-97rJI .framer-elb6p6 {
        flex: 1 0 0px;
        height: var(--framer-aspect-ratio-supported, 502px);
        width: 1px
    }

    .framer-97rJI .framer-1oyfwvc,
    .framer-97rJI .framer-dx4x6g {
        gap: 0px
    }

    .framer-97rJI .framer-1wu4mmx {
        left: -15px;
        right: 15px;
        top: 92px;
        transform: perspective(1200px);
        width: unset
    }

    .framer-97rJI .framer-1iy7ri3 {
        aspect-ratio: .8409090909090909 / 1;
        height: var(--framer-aspect-ratio-supported, 417px);
        left: 30px;
        opacity: .16;
        right: 10px;
        top: -87px;
        transform: unset;
        width: unset
    }

    .framer-97rJI .framer-cylkc8 {
        max-width: 450px;
        padding: 670px 20px 0
    }

    .framer-97rJI .framer-hqsyw0 {
        gap: 0px;
        padding: 110px 0 80px
    }

    .framer-97rJI .framer-yvjwy2 {
        flex-direction: column;
        gap: 80px;
        max-width: 450px;
        padding: 0 20px;
        width: 100%
    }

    .framer-97rJI .framer-xwt5a8 {
        height: 1300px;
        left: calc(50.072780203784596% - 320px / 2);
        opacity: .09;
        width: 320px
    }

    .framer-97rJI .framer-12no29k {
        max-width: 450px;
        padding: 30px 20px 60px
    }

    .framer-97rJI .framer-1urlfsk {
        flex-direction: column;
        gap: 60px
    }

    .framer-97rJI .framer-jzz0h2 {
        height: min-content;
        left: unset;
        position: relative;
        top: unset;
        width: 100%
    }

    .framer-97rJI .framer-1tybo1s {
        aspect-ratio: .8409090909090909 / 1;
        height: var(--framer-aspect-ratio-supported, 324px);
        left: 45px;
        opacity: .15;
        width: 272px
    }

    .framer-97rJI .framer-12yh7j2 {
        aspect-ratio: 1.1287128712871286 / 1;
        flex: 1 0 0px;
        height: var(--framer-aspect-ratio-supported, 310px);
        width: 1px
    }

    .framer-97rJI .framer-1a1pvl9 {
        align-content: center;
        align-items: center;
        order: 2
    }

    .framer-97rJI .framer-1vjfh25 {
        order: 3;
        width: 100%
    }

    .framer-97rJI .framer-1huo939 {
        justify-content: center;
        order: 0;
        width: 100%
    }

    .framer-97rJI .framer-1n8goct,
    .framer-97rJI .framer-1udceag,
    .framer-97rJI .framer-8okrui {
        flex-direction: column;
        justify-content: center;
        width: 100%
    }

    .framer-97rJI .framer-1964g6t {
        padding: 0 0 60px
    }

    .framer-97rJI .framer-v3u1up {
        justify-content: center;
        max-width: 450px;
        padding: 60px 20px
    }

    .framer-97rJI .framer-1rs5s5g {
        top: 73px
    }

    .framer-97rJI .framer-pyz7fu {
        height: var(--framer-aspect-ratio-supported, 692px);
        width: 453px
    }

    .framer-97rJI .framer-9ocitq,
    .framer-97rJI .framer-7cfgdx {
        justify-content: center;
        width: 100%
    }

    .framer-97rJI .framer-skpi98 {
        gap: 70px;
        padding: 240px 0 0;
        width: 100%
    }

    .framer-97rJI .framer-o210t,
    .framer-97rJI .framer-vldtt2 {
        align-content: center;
        align-items: center;
        flex-direction: column;
        gap: 70px;
        padding: 0 20px;
        width: 100%
    }

    .framer-97rJI .framer-1nj42j2 {
        height: min-content;
        left: -230px;
        right: unset;
        top: -150px;
        width: min-content
    }

    .framer-97rJI .framer-w6p6oe,
    .framer-97rJI .framer-u1zxb8 {
        height: var(--framer-aspect-ratio-supported, 411px);
        width: 470px
    }

    .framer-97rJI .framer-fom51z {
        height: min-content;
        left: 94%;
        right: unset;
        top: 90%;
        transform: perspective(1200px) translate(-50%, -50%);
        width: min-content
    }

    .framer-97rJI .framer-8yddhs {
        max-width: 450px;
        padding: 220px 20px 310px
    }

    .framer-97rJI .framer-zya26f {
        align-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0px;
        width: 100%
    }

    .framer-97rJI .framer-hq1d08 {
        align-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        justify-content: center
    }

    @supports (background: -webkit-named-image(i)) and (not (scale:1)) {

        .framer-97rJI .framer-ofssek,
        .framer-97rJI .framer-e61qcl,
        .framer-97rJI .framer-gj8r3p,
        .framer-97rJI .framer-11nc1m0,
        .framer-97rJI .framer-3ssmbw,
        .framer-97rJI .framer-1b3swhe,
        .framer-97rJI .framer-qsls2j,
        .framer-97rJI .framer-je95qz,
        .framer-97rJI .framer-o74vfa,
        .framer-97rJI .framer-6wkfww,
        .framer-97rJI .framer-1a2dpgh,
        .framer-97rJI .framer-fy4ath,
        .framer-97rJI .framer-1rp823r,
        .framer-97rJI .framer-u1xhss,
        .framer-97rJI .framer-2hs7n1,
        .framer-97rJI .framer-1sfr8we,
        .framer-97rJI .framer-rgy95z,
        .framer-97rJI .framer-1oyfwvc,
        .framer-97rJI .framer-hqsyw0,
        .framer-97rJI .framer-1l5i75g,
        .framer-97rJI .framer-yvjwy2,
        .framer-97rJI .framer-1urlfsk,
        .framer-97rJI .framer-1n8goct,
        .framer-97rJI .framer-1udceag,
        .framer-97rJI .framer-8okrui,
        .framer-97rJI .framer-dnjzfc,
        .framer-97rJI .framer-hmcbx0,
        .framer-97rJI .framer-skpi98,
        .framer-97rJI .framer-o210t,
        .framer-97rJI .framer-vldtt2,
        .framer-97rJI .framer-dx4x6g,
        .framer-97rJI .framer-zya26f,
        .framer-97rJI .framer-hq1d08 {
            gap: 0px
        }

        .framer-97rJI .framer-ofssek>*,
        .framer-97rJI .framer-1n8goct>*,
        .framer-97rJI .framer-1udceag>*,
        .framer-97rJI .framer-8okrui>* {
            margin: 5px 0
        }

        .framer-97rJI .framer-ofssek>:first-child,
        .framer-97rJI .framer-e61qcl>:first-child,
        .framer-97rJI .framer-gj8r3p>:first-child,
        .framer-97rJI .framer-11nc1m0>:first-child,
        .framer-97rJI .framer-3ssmbw>:first-child,
        .framer-97rJI .framer-1b3swhe>:first-child,
        .framer-97rJI .framer-qsls2j>:first-child,
        .framer-97rJI .framer-je95qz>:first-child,
        .framer-97rJI .framer-o74vfa>:first-child,
        .framer-97rJI .framer-6wkfww>:first-child,
        .framer-97rJI .framer-1a2dpgh>:first-child,
        .framer-97rJI .framer-fy4ath>:first-child,
        .framer-97rJI .framer-1rp823r>:first-child,
        .framer-97rJI .framer-u1xhss>:first-child,
        .framer-97rJI .framer-2hs7n1>:first-child,
        .framer-97rJI .framer-1sfr8we>:first-child,
        .framer-97rJI .framer-1oyfwvc>:first-child,
        .framer-97rJI .framer-hqsyw0>:first-child,
        .framer-97rJI .framer-1l5i75g>:first-child,
        .framer-97rJI .framer-yvjwy2>:first-child,
        .framer-97rJI .framer-1urlfsk>:first-child,
        .framer-97rJI .framer-1n8goct>:first-child,
        .framer-97rJI .framer-1udceag>:first-child,
        .framer-97rJI .framer-8okrui>:first-child,
        .framer-97rJI .framer-dnjzfc>:first-child,
        .framer-97rJI .framer-hmcbx0>:first-child,
        .framer-97rJI .framer-skpi98>:first-child,
        .framer-97rJI .framer-o210t>:first-child,
        .framer-97rJI .framer-vldtt2>:first-child,
        .framer-97rJI .framer-dx4x6g>:first-child,
        .framer-97rJI .framer-zya26f>:first-child,
        .framer-97rJI .framer-hq1d08>:first-child {
            margin-top: 0
        }

        .framer-97rJI .framer-ofssek>:last-child,
        .framer-97rJI .framer-e61qcl>:last-child,
        .framer-97rJI .framer-gj8r3p>:last-child,
        .framer-97rJI .framer-11nc1m0>:last-child,
        .framer-97rJI .framer-3ssmbw>:last-child,
        .framer-97rJI .framer-1b3swhe>:last-child,
        .framer-97rJI .framer-qsls2j>:last-child,
        .framer-97rJI .framer-je95qz>:last-child,
        .framer-97rJI .framer-o74vfa>:last-child,
        .framer-97rJI .framer-6wkfww>:last-child,
        .framer-97rJI .framer-1a2dpgh>:last-child,
        .framer-97rJI .framer-fy4ath>:last-child,
        .framer-97rJI .framer-1rp823r>:last-child,
        .framer-97rJI .framer-u1xhss>:last-child,
        .framer-97rJI .framer-2hs7n1>:last-child,
        .framer-97rJI .framer-1sfr8we>:last-child,
        .framer-97rJI .framer-1oyfwvc>:last-child,
        .framer-97rJI .framer-hqsyw0>:last-child,
        .framer-97rJI .framer-1l5i75g>:last-child,
        .framer-97rJI .framer-yvjwy2>:last-child,
        .framer-97rJI .framer-1urlfsk>:last-child,
        .framer-97rJI .framer-1n8goct>:last-child,
        .framer-97rJI .framer-1udceag>:last-child,
        .framer-97rJI .framer-8okrui>:last-child,
        .framer-97rJI .framer-dnjzfc>:last-child,
        .framer-97rJI .framer-hmcbx0>:last-child,
        .framer-97rJI .framer-skpi98>:last-child,
        .framer-97rJI .framer-o210t>:last-child,
        .framer-97rJI .framer-vldtt2>:last-child,
        .framer-97rJI .framer-dx4x6g>:last-child,
        .framer-97rJI .framer-zya26f>:last-child,
        .framer-97rJI .framer-hq1d08>:last-child {
            margin-bottom: 0
        }

        .framer-97rJI .framer-e61qcl>*,
        .framer-97rJI .framer-qsls2j>*,
        .framer-97rJI .framer-o74vfa>* {
            margin: 15px 0
        }

        .framer-97rJI .framer-gj8r3p>*,
        .framer-97rJI .framer-1b3swhe>*,
        .framer-97rJI .framer-6wkfww>*,
        .framer-97rJI .framer-fy4ath>*,
        .framer-97rJI .framer-u1xhss>*,
        .framer-97rJI .framer-2hs7n1>*,
        .framer-97rJI .framer-1sfr8we>*,
        .framer-97rJI .framer-1oyfwvc>*,
        .framer-97rJI .framer-hqsyw0>*,
        .framer-97rJI .framer-1l5i75g>*,
        .framer-97rJI .framer-dnjzfc>*,
        .framer-97rJI .framer-hmcbx0>*,
        .framer-97rJI .framer-dx4x6g>*,
        .framer-97rJI .framer-zya26f>* {
            margin: 0
        }

        .framer-97rJI .framer-11nc1m0>*,
        .framer-97rJI .framer-yvjwy2>* {
            margin: 40px 0
        }

        .framer-97rJI .framer-3ssmbw>*,
        .framer-97rJI .framer-1rp823r>* {
            margin: 12.5px 0
        }

        .framer-97rJI .framer-je95qz>* {
            margin: 4.5px 0
        }

        .framer-97rJI .framer-1a2dpgh>*,
        .framer-97rJI .framer-skpi98>*,
        .framer-97rJI .framer-o210t>*,
        .framer-97rJI .framer-vldtt2>* {
            margin: 35px 0
        }

        .framer-97rJI .framer-rgy95z>* {
            margin: 0
        }

        .framer-97rJI .framer-rgy95z>:first-child {
            margin-left: 0
        }

        .framer-97rJI .framer-rgy95z>:last-child {
            margin-right: 0
        }

        .framer-97rJI .framer-1urlfsk>* {
            margin: 30px 0
        }

        .framer-97rJI .framer-hq1d08>* {
            margin: 10px 0
        }
    }
}

.framer-Pyo6Q .framer-styles-preset-13kmdo8:not(.rich-text-wrapper),
.framer-Pyo6Q .framer-styles-preset-13kmdo8.rich-text-wrapper h1 {
    --framer-font-family: "Inter-SemiBold", "Inter", sans-serif;
    --framer-font-size: 80px;
    --framer-font-style: normal;
    --framer-font-weight: 600;
    --framer-letter-spacing: -4px;
    --framer-line-height: 90px;
    --framer-paragraph-spacing: 40px;
    --framer-text-alignment: center;
    --framer-text-color: var(--token-fed421e9-3f6f-4d58-bb1b-a95deadf172a, #ffffff);
    --framer-text-decoration: none;
    --framer-text-transform: none
}

@media (max-width: 1439px) and (min-width: 810px) {

    .framer-Pyo6Q .framer-styles-preset-13kmdo8:not(.rich-text-wrapper),
    .framer-Pyo6Q .framer-styles-preset-13kmdo8.rich-text-wrapper h1 {
        --framer-font-family: "Inter-SemiBold", "Inter", sans-serif;
        --framer-font-size: 64px;
        --framer-font-style: normal;
        --framer-font-weight: 600;
        --framer-letter-spacing: -4px;
        --framer-line-height: 90px;
        --framer-paragraph-spacing: 40px;
        --framer-text-alignment: center;
        --framer-text-color: var(--token-fed421e9-3f6f-4d58-bb1b-a95deadf172a, #ffffff);
        --framer-text-decoration: none;
        --framer-text-transform: none
    }
}

@media (max-width: 809px) and (min-width: 0px) {

    .framer-Pyo6Q .framer-styles-preset-13kmdo8:not(.rich-text-wrapper),
    .framer-Pyo6Q .framer-styles-preset-13kmdo8.rich-text-wrapper h1 {
        --framer-font-family: "Inter-SemiBold", "Inter", sans-serif;
        --framer-font-size: 42px;
        --framer-font-style: normal;
        --framer-font-weight: 600;
        --framer-letter-spacing: -2px;
        --framer-line-height: 50px;
        --framer-paragraph-spacing: 40px;
        --framer-text-alignment: center;
        --framer-text-color: var(--token-fed421e9-3f6f-4d58-bb1b-a95deadf172a, #ffffff);
        --framer-text-decoration: none;
        --framer-text-transform: none
    }
}

.framer-8dioY .framer-styles-preset-12s9psp:not(.rich-text-wrapper),
.framer-8dioY .framer-styles-preset-12s9psp.rich-text-wrapper h3 {
    --framer-font-family: "Inter-Medium", "Inter", sans-serif;
    --framer-font-size: 33px;
    --framer-font-style: normal;
    --framer-font-weight: 500;
    --framer-letter-spacing: -1.65px;
    --framer-line-height: 1.2em;
    --framer-paragraph-spacing: 40px;
    --framer-text-alignment: center;
    --framer-text-color: #000000;
    --framer-text-decoration: none;
    --framer-text-transform: none
}

@media (max-width: 1439px) and (min-width: 810px) {

    .framer-8dioY .framer-styles-preset-12s9psp:not(.rich-text-wrapper),
    .framer-8dioY .framer-styles-preset-12s9psp.rich-text-wrapper h3 {
        --framer-font-family: "Inter-Medium", "Inter", sans-serif;
        --framer-font-size: 33px;
        --framer-font-style: normal;
        --framer-font-weight: 500;
        --framer-letter-spacing: -1.65px;
        --framer-line-height: 1.2em;
        --framer-paragraph-spacing: 40px;
        --framer-text-alignment: center;
        --framer-text-color: #000000;
        --framer-text-decoration: none;
        --framer-text-transform: none
    }
}

@media (max-width: 809px) and (min-width: 0px) {

    .framer-8dioY .framer-styles-preset-12s9psp:not(.rich-text-wrapper),
    .framer-8dioY .framer-styles-preset-12s9psp.rich-text-wrapper h3 {
        --framer-font-family: "Inter-Medium", "Inter", sans-serif;
        --framer-font-size: 28px;
        --framer-font-style: normal;
        --framer-font-weight: 500;
        --framer-letter-spacing: -1.65px;
        --framer-line-height: 1.2em;
        --framer-paragraph-spacing: 40px;
        --framer-text-alignment: center;
        --framer-text-color: #000000;
        --framer-text-decoration: none;
        --framer-text-transform: none
    }
}

.framer-AxzDx [data-border=true]:after {
    content: "";
    border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
    border-color: var(--border-color, none);
    border-style: var(--border-style, none);
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    border-radius: inherit;
    pointer-events: none
}

.framer-AxzDx .framer-9wlam3 {
    display: block
}

.framer-AxzDx .framer-lo4vtz {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-AxzDx .framer-87ldv4 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: 51px;
    justify-content: center;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 1197px
}

.framer-AxzDx .framer-28q7tn {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 63px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 994px
}

.framer-AxzDx .framer-1sxzzqr {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    text-decoration: none;
    width: min-content
}

.framer-AxzDx .framer-18eia0k {
    flex: none;
    height: 36px;
    position: relative;
    width: 33px
}

.framer-AxzDx .framer-1sj1zk,
.framer-AxzDx .framer-byc63r,
.framer-AxzDx .framer-d5cdbu,
.framer-AxzDx .framer-1d6g3ku,
.framer-AxzDx .framer-zazjwk,
.framer-AxzDx .framer-x06fog,
.framer-AxzDx .framer-1bqhzgq,
.framer-AxzDx .framer-g8kqwl,
.framer-AxzDx .framer-1ai7jy2,
.framer-AxzDx .framer-859j1z,
.framer-AxzDx .framer-1ui2da5 {
    flex: none;
    height: auto;
    position: relative;
    white-space: pre;
    width: auto
}

.framer-AxzDx .framer-1bpgfuw {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 19px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-AxzDx .framer-ls9ti8 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 13px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-AxzDx .framer-fpsl16-container {
    flex: none;
    height: auto;
    position: relative;
    width: auto
}

.framer-AxzDx .framer-1xjaemu {
    align-content: center;
    align-items: center;
    display: flex;
    flex: 1 0 0px;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-start;
    overflow: hidden;
    padding: 35px 20px;
    position: relative;
    width: 1px
}

.framer-AxzDx .framer-fbp3j2 {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 15px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

    .framer-AxzDx .framer-lo4vtz,
    .framer-AxzDx .framer-87ldv4,
    .framer-AxzDx .framer-28q7tn,
    .framer-AxzDx .framer-1sxzzqr,
    .framer-AxzDx .framer-1bpgfuw,
    .framer-AxzDx .framer-ls9ti8,
    .framer-AxzDx .framer-1xjaemu,
    .framer-AxzDx .framer-fbp3j2 {
        gap: 0px
    }

    .framer-AxzDx .framer-lo4vtz>* {
        margin: 0
    }

    .framer-AxzDx .framer-lo4vtz>:first-child,
    .framer-AxzDx .framer-87ldv4>:first-child,
    .framer-AxzDx .framer-28q7tn>:first-child,
    .framer-AxzDx .framer-1sxzzqr>:first-child,
    .framer-AxzDx .framer-1bpgfuw>:first-child,
    .framer-AxzDx .framer-ls9ti8>:first-child,
    .framer-AxzDx .framer-1xjaemu>:first-child {
        margin-left: 0
    }

    .framer-AxzDx .framer-lo4vtz>:last-child,
    .framer-AxzDx .framer-87ldv4>:last-child,
    .framer-AxzDx .framer-28q7tn>:last-child,
    .framer-AxzDx .framer-1sxzzqr>:last-child,
    .framer-AxzDx .framer-1bpgfuw>:last-child,
    .framer-AxzDx .framer-ls9ti8>:last-child,
    .framer-AxzDx .framer-1xjaemu>:last-child {
        margin-right: 0
    }

    .framer-AxzDx .framer-87ldv4>*,
    .framer-AxzDx .framer-1sxzzqr>*,
    .framer-AxzDx .framer-1xjaemu>* {
        margin: 0 5px
    }

    .framer-AxzDx .framer-28q7tn>* {
        margin: 0 31.5px
    }

    .framer-AxzDx .framer-1bpgfuw>* {
        margin: 0 9.5px
    }

    .framer-AxzDx .framer-ls9ti8>* {
        margin: 0;
        margin-left: calc(12.64598560333252px / 2);
        margin-right: calc(12.64598560333252px / 2)
    }

    .framer-AxzDx .framer-fbp3j2>* {
        margin: 7.5px 0
    }

    .framer-AxzDx .framer-fbp3j2>:first-child {
        margin-top: 0
    }

    .framer-AxzDx .framer-fbp3j2>:last-child {
        margin-bottom: 0
    }
}

.framer-AxzDx.framer-v-11x0qzs .framer-lo4vtz,
.framer-AxzDx.framer-v-s1w00j .framer-lo4vtz {
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 700px
}

.framer-AxzDx.framer-v-11x0qzs .framer-87ldv4,
.framer-AxzDx.framer-v-s1w00j .framer-87ldv4 {
    height: min-content;
    width: 100%
}

.framer-AxzDx.framer-v-11x0qzs .framer-28q7tn,
.framer-AxzDx.framer-v-s1w00j .framer-28q7tn {
    flex: 1 0 0px;
    width: 1px
}

@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {
    .framer-AxzDx.framer-v-11x0qzs .framer-lo4vtz {
        gap: 0px
    }

    .framer-AxzDx.framer-v-11x0qzs .framer-lo4vtz>* {
        margin: 0
    }

    .framer-AxzDx.framer-v-11x0qzs .framer-lo4vtz>:first-child {
        margin-top: 0
    }

    .framer-AxzDx.framer-v-11x0qzs .framer-lo4vtz>:last-child {
        margin-bottom: 0
    }
}

.framer-AxzDx.framer-v-s1w00j .framer-1xjaemu {
    flex: none;
    width: 100%
}

@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {
    .framer-AxzDx.framer-v-s1w00j .framer-lo4vtz {
        gap: 0px
    }

    .framer-AxzDx.framer-v-s1w00j .framer-lo4vtz>* {
        margin: 0
    }

    .framer-AxzDx.framer-v-s1w00j .framer-lo4vtz>:first-child {
        margin-top: 0
    }

    .framer-AxzDx.framer-v-s1w00j .framer-lo4vtz>:last-child {
        margin-bottom: 0
    }
}

.framer-M5MRE [data-border=true]:after {
    content: "";
    border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
    border-color: var(--border-color, none);
    border-style: var(--border-style, none);
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    border-radius: inherit;
    pointer-events: none
}

.framer-M5MRE .framer-hapvl9 {
    display: block
}

.framer-M5MRE .framer-86m2yc {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: min-content;
    will-change: transform
}

.framer-M5MRE .framer-dbgpm1 {
    aspect-ratio: 1 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 110px);
    position: relative;
    width: 110px;
    z-index: 0
}

@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {
    .framer-M5MRE .framer-86m2yc {
        gap: 0px
    }

    .framer-M5MRE .framer-86m2yc>* {
        margin: 0 5px
    }

    .framer-M5MRE .framer-86m2yc>:first-child {
        margin-left: 0
    }

    .framer-M5MRE .framer-86m2yc>:last-child {
        margin-right: 0
    }
}

.framer-LJUxW [data-border=true]:after {
    content: "";
    border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
    border-color: var(--border-color, none);
    border-style: var(--border-style, none);
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    border-radius: inherit;
    pointer-events: none
}

.framer-LJUxW .framer-12g3yo2 {
    display: block
}

.framer-LJUxW .framer-1aayn3s {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 1440px
}

.framer-LJUxW .framer-2am1f1 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    max-width: 1350px;
    overflow: visible;
    padding: 80px 24px;
    position: relative;
    width: 100%
}

.framer-LJUxW .framer-fbtuha {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 110px;
    height: min-content;
    justify-content: center;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-LJUxW .framer-sbtnlc {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 220px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-LJUxW .framer-izixkc {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-LJUxW .framer-r3i0ys {
    align-content: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: auto
}

.framer-LJUxW .framer-p0n910,
.framer-LJUxW .framer-171v13l {
    flex: 1 0 0px;
    height: 5px;
    position: relative;
    width: 1px
}

.framer-LJUxW .framer-11faj47,
.framer-LJUxW .framer-u5fogz,
.framer-LJUxW .framer-axfwds,
.framer-LJUxW .framer-rni9ns,
.framer-LJUxW .framer-1m021lb {
    flex: none;
    height: auto;
    position: relative;
    white-space: pre;
    width: auto
}

.framer-LJUxW .framer-19waysq {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-LJUxW .framer-1db3of8 {
    flex: none;
    height: 5px;
    position: relative;
    width: 451px
}

.framer-LJUxW .framer-1rkmp1x {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-LJUxW .framer-2jsrsv {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-LJUxW .framer-1umr50u {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 35px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-LJUxW .framer-1wsiscg {
    flex: none;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 507px;
    word-break: break-word;
    word-wrap: break-word
}

.framer-LJUxW .framer-8yx99d-container,
.framer-LJUxW .framer-1tve9vk-container,
.framer-LJUxW .framer-hyafd1-container,
.framer-LJUxW .framer-kfh7up-container,
.framer-LJUxW .framer-pyx49v-container,
.framer-LJUxW .framer-1q1zv07-container {
    flex: none;
    height: auto;
    position: relative;
    width: auto
}

.framer-LJUxW .framer-9nz7c6 {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 35px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 10px;
    position: relative;
    width: min-content
}

.framer-LJUxW .framer-bu5nxc,
.framer-LJUxW .framer-1q6w3m2,
.framer-LJUxW .framer-1rxczvh,
.framer-LJUxW .framer-19a87go {
    align-self: stretch;
    flex: none;
    height: 2px;
    overflow: hidden;
    position: relative;
    width: auto
}

@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

    .framer-LJUxW .framer-1aayn3s,
    .framer-LJUxW .framer-2am1f1,
    .framer-LJUxW .framer-fbtuha,
    .framer-LJUxW .framer-sbtnlc,
    .framer-LJUxW .framer-izixkc,
    .framer-LJUxW .framer-r3i0ys,
    .framer-LJUxW .framer-19waysq,
    .framer-LJUxW .framer-1rkmp1x,
    .framer-LJUxW .framer-2jsrsv,
    .framer-LJUxW .framer-1umr50u,
    .framer-LJUxW .framer-9nz7c6 {
        gap: 0px
    }

    .framer-LJUxW .framer-1aayn3s>*,
    .framer-LJUxW .framer-2am1f1>*,
    .framer-LJUxW .framer-1rkmp1x>* {
        margin: 0
    }

    .framer-LJUxW .framer-1aayn3s>:first-child,
    .framer-LJUxW .framer-2am1f1>:first-child,
    .framer-LJUxW .framer-sbtnlc>:first-child,
    .framer-LJUxW .framer-izixkc>:first-child,
    .framer-LJUxW .framer-1rkmp1x>:first-child,
    .framer-LJUxW .framer-1umr50u>:first-child,
    .framer-LJUxW .framer-9nz7c6>:first-child {
        margin-top: 0
    }

    .framer-LJUxW .framer-1aayn3s>:last-child,
    .framer-LJUxW .framer-2am1f1>:last-child,
    .framer-LJUxW .framer-sbtnlc>:last-child,
    .framer-LJUxW .framer-izixkc>:last-child,
    .framer-LJUxW .framer-1rkmp1x>:last-child,
    .framer-LJUxW .framer-1umr50u>:last-child,
    .framer-LJUxW .framer-9nz7c6>:last-child {
        margin-bottom: 0
    }

    .framer-LJUxW .framer-fbtuha>* {
        margin: 0 55px
    }

    .framer-LJUxW .framer-fbtuha>:first-child,
    .framer-LJUxW .framer-r3i0ys>:first-child,
    .framer-LJUxW .framer-19waysq>:first-child,
    .framer-LJUxW .framer-2jsrsv>:first-child {
        margin-left: 0
    }

    .framer-LJUxW .framer-fbtuha>:last-child,
    .framer-LJUxW .framer-r3i0ys>:last-child,
    .framer-LJUxW .framer-19waysq>:last-child,
    .framer-LJUxW .framer-2jsrsv>:last-child {
        margin-right: 0
    }

    .framer-LJUxW .framer-sbtnlc>* {
        margin: 110px 0
    }

    .framer-LJUxW .framer-izixkc>* {
        margin: 10px 0
    }

    .framer-LJUxW .framer-r3i0ys>*,
    .framer-LJUxW .framer-19waysq>* {
        margin: 0 10px
    }

    .framer-LJUxW .framer-2jsrsv>* {
        margin: 0 5px
    }

    .framer-LJUxW .framer-1umr50u>*,
    .framer-LJUxW .framer-9nz7c6>* {
        margin: 17.5px 0
    }
}

.framer-LJUxW.framer-v-gww2m3 .framer-fbtuha,
.framer-LJUxW.framer-v-1swwsya .framer-fbtuha {
    flex-direction: column
}

.framer-LJUxW.framer-v-gww2m3 .framer-sbtnlc {
    align-content: center;
    align-items: center;
    gap: 70px;
    justify-content: center
}

.framer-LJUxW.framer-v-gww2m3 .framer-izixkc,
.framer-LJUxW.framer-v-1swwsya .framer-izixkc {
    align-content: center;
    align-items: center;
    justify-content: center
}

.framer-LJUxW.framer-v-gww2m3 .framer-r3i0ys,
.framer-LJUxW.framer-v-1swwsya .framer-r3i0ys {
    order: 0
}

.framer-LJUxW.framer-v-gww2m3 .framer-1rkmp1x {
    align-content: center;
    align-items: center;
    align-self: stretch;
    order: 2;
    width: auto
}

.framer-LJUxW.framer-v-gww2m3 .framer-1umr50u,
.framer-LJUxW.framer-v-1swwsya .framer-2jsrsv {
    align-content: center;
    align-items: center
}

@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

    .framer-LJUxW.framer-v-gww2m3 .framer-fbtuha,
    .framer-LJUxW.framer-v-gww2m3 .framer-sbtnlc {
        gap: 0px
    }

    .framer-LJUxW.framer-v-gww2m3 .framer-fbtuha>* {
        margin: 55px 0
    }

    .framer-LJUxW.framer-v-gww2m3 .framer-fbtuha>:first-child,
    .framer-LJUxW.framer-v-gww2m3 .framer-sbtnlc>:first-child {
        margin-top: 0
    }

    .framer-LJUxW.framer-v-gww2m3 .framer-fbtuha>:last-child,
    .framer-LJUxW.framer-v-gww2m3 .framer-sbtnlc>:last-child {
        margin-bottom: 0
    }

    .framer-LJUxW.framer-v-gww2m3 .framer-sbtnlc>* {
        margin: 35px 0
    }
}

.framer-LJUxW.framer-v-1swwsya .framer-1aayn3s {
    width: 390px
}

.framer-LJUxW.framer-v-1swwsya .framer-sbtnlc {
    align-content: center;
    align-items: center;
    gap: 70px;
    justify-content: center;
    width: 100%
}

.framer-LJUxW.framer-v-1swwsya .framer-1rkmp1x {
    align-content: center;
    align-items: center;
    order: 2
}

.framer-LJUxW.framer-v-1swwsya .framer-1umr50u {
    align-content: center;
    align-items: center;
    width: 100%
}

.framer-LJUxW.framer-v-1swwsya .framer-1wsiscg,
.framer-LJUxW.framer-v-1swwsya .framer-9nz7c6,
.framer-LJUxW.framer-v-1swwsya .framer-1tve9vk-container,
.framer-LJUxW.framer-v-1swwsya .framer-hyafd1-container,
.framer-LJUxW.framer-v-1swwsya .framer-kfh7up-container,
.framer-LJUxW.framer-v-1swwsya .framer-pyx49v-container,
.framer-LJUxW.framer-v-1swwsya .framer-1q1zv07-container {
    width: 100%
}

.framer-LJUxW.framer-v-1swwsya .framer-bu5nxc,
.framer-LJUxW.framer-v-1swwsya .framer-1q6w3m2,
.framer-LJUxW.framer-v-1swwsya .framer-1rxczvh,
.framer-LJUxW.framer-v-1swwsya .framer-19a87go {
    align-self: unset;
    width: 100%
}

@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

    .framer-LJUxW.framer-v-1swwsya .framer-fbtuha,
    .framer-LJUxW.framer-v-1swwsya .framer-sbtnlc {
        gap: 0px
    }

    .framer-LJUxW.framer-v-1swwsya .framer-fbtuha>* {
        margin: 55px 0
    }

    .framer-LJUxW.framer-v-1swwsya .framer-fbtuha>:first-child,
    .framer-LJUxW.framer-v-1swwsya .framer-sbtnlc>:first-child {
        margin-top: 0
    }

    .framer-LJUxW.framer-v-1swwsya .framer-fbtuha>:last-child,
    .framer-LJUxW.framer-v-1swwsya .framer-sbtnlc>:last-child {
        margin-bottom: 0
    }

    .framer-LJUxW.framer-v-1swwsya .framer-sbtnlc>* {
        margin: 35px 0
    }
}

.framer-jUJDS .framer-styles-preset-186siln:not(.rich-text-wrapper),
.framer-jUJDS .framer-styles-preset-186siln.rich-text-wrapper h2 {
    --framer-font-family: "Inter-SemiBold", "Inter", sans-serif;
    --framer-font-size: 62px;
    --framer-font-style: normal;
    --framer-font-weight: 600;
    --framer-letter-spacing: -3.1px;
    --framer-line-height: 1.2em;
    --framer-paragraph-spacing: 40px;
    --framer-text-alignment: center;
    --framer-text-color: #000000;
    --framer-text-decoration: none;
    --framer-text-transform: none
}

@media (max-width: 1439px) and (min-width: 810px) {

    .framer-jUJDS .framer-styles-preset-186siln:not(.rich-text-wrapper),
    .framer-jUJDS .framer-styles-preset-186siln.rich-text-wrapper h2 {
        --framer-font-family: "Inter-SemiBold", "Inter", sans-serif;
        --framer-font-size: 55px;
        --framer-font-style: normal;
        --framer-font-weight: 600;
        --framer-letter-spacing: -3.1px;
        --framer-line-height: 1.2em;
        --framer-paragraph-spacing: 40px;
        --framer-text-alignment: center;
        --framer-text-color: #000000;
        --framer-text-decoration: none;
        --framer-text-transform: none
    }
}

@media (max-width: 809px) and (min-width: 0px) {

    .framer-jUJDS .framer-styles-preset-186siln:not(.rich-text-wrapper),
    .framer-jUJDS .framer-styles-preset-186siln.rich-text-wrapper h2 {
        --framer-font-family: "Inter-SemiBold", "Inter", sans-serif;
        --framer-font-size: 38px;
        --framer-font-style: normal;
        --framer-font-weight: 600;
        --framer-letter-spacing: -1.8px;
        --framer-line-height: 45px;
        --framer-paragraph-spacing: 40px;
        --framer-text-alignment: center;
        --framer-text-color: #000000;
        --framer-text-decoration: none;
        --framer-text-transform: none
    }
}

.framer-oyI4k [data-border=true]:after {
    content: "";
    border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
    border-color: var(--border-color, none);
    border-style: var(--border-style, none);
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    border-radius: inherit;
    pointer-events: none
}

.framer-oyI4k .framer-mesh6j {
    display: block
}

.framer-oyI4k .framer-693ydj {
    align-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 17px 25px;
    position: relative;
    text-decoration: none;
    width: min-content
}

.framer-oyI4k .framer-crfnzq {
    flex: none;
    height: auto;
    position: relative;
    white-space: pre;
    width: auto
}

.framer-oyI4k .framer-v-693ydj .framer-693ydj,
.framer-oyI4k .framer-v-1qf1y5n .framer-693ydj {
    cursor: pointer
}

@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {
    .framer-oyI4k .framer-693ydj {
        gap: 0px
    }

    .framer-oyI4k .framer-693ydj>* {
        margin: 0;
        margin-left: calc(8.430656433105469px / 2);
        margin-right: calc(8.430656433105469px / 2)
    }

    .framer-oyI4k .framer-693ydj>:first-child {
        margin-left: 0
    }

    .framer-oyI4k .framer-693ydj>:last-child {
        margin-right: 0
    }
}

.framer-KrDQC [data-border=true]:after {
    content: "";
    border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
    border-color: var(--border-color, none);
    border-style: var(--border-style, none);
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    border-radius: inherit;
    pointer-events: none
}

.framer-KrDQC .framer-1mzrcov {
    display: block
}

.framer-KrDQC .framer-1vbgyyi {
    align-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: center;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 500px
}

.framer-KrDQC .framer-1t196q0,
.framer-KrDQC .framer-18qc69a {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-KrDQC .framer-97smsc {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 8px;
    position: relative;
    width: min-content
}

.framer-KrDQC .framer-o5tq9a {
    aspect-ratio: 1 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 13px);
    position: relative;
    width: 13px
}

.framer-KrDQC .framer-1it09ja,
.framer-KrDQC .framer-slca9b {
    align-content: center;
    align-items: center;
    display: flex;
    flex: 1 0 0px;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-end;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 1px
}

.framer-KrDQC .framer-f2wsgf,
.framer-KrDQC .framer-18f3nw9 {
    flex: 1 0 0px;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 1px;
    word-break: break-word;
    word-wrap: break-word
}

@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

    .framer-KrDQC .framer-1vbgyyi,
    .framer-KrDQC .framer-1t196q0,
    .framer-KrDQC .framer-97smsc,
    .framer-KrDQC .framer-1it09ja,
    .framer-KrDQC .framer-18qc69a,
    .framer-KrDQC .framer-slca9b {
        gap: 0px
    }

    .framer-KrDQC .framer-1vbgyyi>*,
    .framer-KrDQC .framer-97smsc>* {
        margin: 5px 0
    }

    .framer-KrDQC .framer-1vbgyyi>:first-child,
    .framer-KrDQC .framer-97smsc>:first-child {
        margin-top: 0
    }

    .framer-KrDQC .framer-1vbgyyi>:last-child,
    .framer-KrDQC .framer-97smsc>:last-child {
        margin-bottom: 0
    }

    .framer-KrDQC .framer-1t196q0>*,
    .framer-KrDQC .framer-18qc69a>* {
        margin: 0 10px
    }

    .framer-KrDQC .framer-1t196q0>:first-child,
    .framer-KrDQC .framer-1it09ja>:first-child,
    .framer-KrDQC .framer-18qc69a>:first-child,
    .framer-KrDQC .framer-slca9b>:first-child {
        margin-left: 0
    }

    .framer-KrDQC .framer-1t196q0>:last-child,
    .framer-KrDQC .framer-1it09ja>:last-child,
    .framer-KrDQC .framer-18qc69a>:last-child,
    .framer-KrDQC .framer-slca9b>:last-child {
        margin-right: 0
    }

    .framer-KrDQC .framer-1it09ja>*,
    .framer-KrDQC .framer-slca9b>* {
        margin: 0 5px
    }
}

.ssr-variant {
    display: contents
}

.framer-QisTT [data-border=true]:after {
    content: "";
    border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
    border-color: var(--border-color, none);
    border-style: var(--border-style, none);
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    border-radius: inherit;
    pointer-events: none
}

@supports (aspect-ratio: 1) {
    body {
        --framer-aspect-ratio-supported: auto
    }
}

.framer-QisTT .framer-q9lbgg {
    display: block
}

.framer-QisTT .framer-18ei3y1 {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 1440px
}

.framer-QisTT .framer-99vg7j {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    max-width: 1350px;
    overflow: visible;
    padding: 50px 40px;
    position: relative;
    width: 100%
}

.framer-QisTT .framer-1dc1oib {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 80px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-QisTT .framer-gj4g34 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 78px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-QisTT .framer-1clxsno {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-QisTT .framer-1jg9z9u {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: 1 0 0px;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 12px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 1px
}

.framer-QisTT .framer-1fbiftf,
.framer-QisTT .framer-2996yt,
.framer-QisTT .framer-w5bnc6 {
    flex: none;
    height: auto;
    position: relative;
    white-space: pre;
    width: auto
}

.framer-QisTT .framer-1kpmir6 {
    flex: none;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 406px;
    word-break: break-word;
    word-wrap: break-word
}

.framer-QisTT .framer-tqrv5f {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 22px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-QisTT .framer-1d5s6c9 {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 30px;
    height: min-content;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-QisTT .framer-18kl1nc {
    aspect-ratio: 3.4285714285714284 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 77px);
    position: relative;
    text-decoration: none;
    width: 264px
}

.framer-QisTT .framer-1bh845a {
    aspect-ratio: 3.4155844155844157 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 77px);
    position: relative;
    text-decoration: none;
    width: 263px
}

.framer-QisTT .framer-17sxb2k,
.framer-QisTT .framer-1q5qog7 {
    flex: none;
    height: 4px;
    position: relative;
    width: 100%
}

.framer-QisTT .framer-ys4oie {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-QisTT .framer-1l3t5an {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: 1 0 0px;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 1px
}

.framer-QisTT .framer-17uvgnt {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: center;
    overflow: visible;
    padding: 0;
    position: relative;
    text-decoration: none;
    width: min-content
}

.framer-QisTT .framer-11ew9a1 {
    flex: none;
    height: 36px;
    position: relative;
    width: 33px
}

.framer-QisTT .framer-icvppe {
    flex: none;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 322px;
    word-break: break-word;
    word-wrap: break-word
}

.framer-QisTT .framer-je2t0f {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 9px;
    height: min-content;
    justify-content: center;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-QisTT .framer-6lu5oz,
.framer-QisTT .framer-qmmn06 {
    aspect-ratio: 1.0810810810810811 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 37px);
    position: relative;
    text-decoration: none;
    width: 40px
}

.framer-QisTT .framer-2ub89w,
.framer-QisTT .framer-1q9apjh {
    aspect-ratio: .975609756097561 / 1;
    flex: none;
    height: var(--framer-aspect-ratio-supported, 41px);
    position: relative;
    text-decoration: none;
    width: 40px
}

.framer-QisTT .framer-14vrh6b,
.framer-QisTT .framer-wjg7bn {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 46px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-QisTT .framer-cqqoa6,
.framer-QisTT .framer-kmkk5l {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 32px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 180px
}

.framer-QisTT .framer-5v7vgb,
.framer-QisTT .framer-d2d03o,
.framer-QisTT .framer-p4ok57,
.framer-QisTT .framer-1qoelkn,
.framer-QisTT .framer-mor0ai,
.framer-QisTT .framer-71n7r1,
.framer-QisTT .framer-ggz6qz,
.framer-QisTT .framer-1bcffoz,
.framer-QisTT .framer-17x09fs {
    flex: none;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 180px;
    word-break: break-word;
    word-wrap: break-word
}

.framer-QisTT .framer-12qnpf5,
.framer-QisTT .framer-hvhcsq {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 16px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-QisTT .framer-yd4uzl {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 16px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-QisTT .framer-1pza12w {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    height: min-content;
    justify-content: space-between;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%
}

.framer-QisTT .framer-pponoo {
    flex: none;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 282px;
    word-break: break-word;
    word-wrap: break-word
}

.framer-QisTT .framer-1c0b7qi {
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0px;
    height: min-content;
    justify-content: center;
    overflow: visible;
    padding: 0;
    position: relative;
    width: min-content
}

.framer-QisTT .framer-1ob507i,
.framer-QisTT .framer-186zccb {
    flex: none;
    height: auto;
    position: relative;
    white-space: pre-wrap;
    width: 150px;
    word-break: break-word;
    word-wrap: break-word
}

@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

    .framer-QisTT .framer-18ei3y1,
    .framer-QisTT .framer-99vg7j,
    .framer-QisTT .framer-1dc1oib,
    .framer-QisTT .framer-gj4g34,
    .framer-QisTT .framer-1clxsno,
    .framer-QisTT .framer-1jg9z9u,
    .framer-QisTT .framer-tqrv5f,
    .framer-QisTT .framer-1d5s6c9,
    .framer-QisTT .framer-ys4oie,
    .framer-QisTT .framer-1l3t5an,
    .framer-QisTT .framer-17uvgnt,
    .framer-QisTT .framer-je2t0f,
    .framer-QisTT .framer-14vrh6b,
    .framer-QisTT .framer-cqqoa6,
    .framer-QisTT .framer-12qnpf5,
    .framer-QisTT .framer-wjg7bn,
    .framer-QisTT .framer-kmkk5l,
    .framer-QisTT .framer-hvhcsq,
    .framer-QisTT .framer-yd4uzl,
    .framer-QisTT .framer-1c0b7qi {
        gap: 0px
    }

    .framer-QisTT .framer-18ei3y1>* {
        margin: 5px 0
    }

    .framer-QisTT .framer-18ei3y1>:first-child,
    .framer-QisTT .framer-99vg7j>:first-child,
    .framer-QisTT .framer-1dc1oib>:first-child,
    .framer-QisTT .framer-gj4g34>:first-child,
    .framer-QisTT .framer-1jg9z9u>:first-child,
    .framer-QisTT .framer-tqrv5f>:first-child,
    .framer-QisTT .framer-1l3t5an>:first-child,
    .framer-QisTT .framer-cqqoa6>:first-child,
    .framer-QisTT .framer-12qnpf5>:first-child,
    .framer-QisTT .framer-kmkk5l>:first-child,
    .framer-QisTT .framer-hvhcsq>:first-child,
    .framer-QisTT .framer-yd4uzl>:first-child {
        margin-top: 0
    }

    .framer-QisTT .framer-18ei3y1>:last-child,
    .framer-QisTT .framer-99vg7j>:last-child,
    .framer-QisTT .framer-1dc1oib>:last-child,
    .framer-QisTT .framer-gj4g34>:last-child,
    .framer-QisTT .framer-1jg9z9u>:last-child,
    .framer-QisTT .framer-tqrv5f>:last-child,
    .framer-QisTT .framer-1l3t5an>:last-child,
    .framer-QisTT .framer-cqqoa6>:last-child,
    .framer-QisTT .framer-12qnpf5>:last-child,
    .framer-QisTT .framer-kmkk5l>:last-child,
    .framer-QisTT .framer-hvhcsq>:last-child,
    .framer-QisTT .framer-yd4uzl>:last-child {
        margin-bottom: 0
    }

    .framer-QisTT .framer-99vg7j>* {
        margin: 0
    }

    .framer-QisTT .framer-1dc1oib>* {
        margin: 40px 0
    }

    .framer-QisTT .framer-gj4g34>* {
        margin: 39px 0
    }

    .framer-QisTT .framer-1clxsno>*,
    .framer-QisTT .framer-ys4oie>*,
    .framer-QisTT .framer-1c0b7qi>* {
        margin: 0
    }

    .framer-QisTT .framer-1clxsno>:first-child,
    .framer-QisTT .framer-1d5s6c9>:first-child,
    .framer-QisTT .framer-ys4oie>:first-child,
    .framer-QisTT .framer-17uvgnt>:first-child,
    .framer-QisTT .framer-je2t0f>:first-child,
    .framer-QisTT .framer-14vrh6b>:first-child,
    .framer-QisTT .framer-wjg7bn>:first-child,
    .framer-QisTT .framer-1c0b7qi>:first-child {
        margin-left: 0
    }

    .framer-QisTT .framer-1clxsno>:last-child,
    .framer-QisTT .framer-1d5s6c9>:last-child,
    .framer-QisTT .framer-ys4oie>:last-child,
    .framer-QisTT .framer-17uvgnt>:last-child,
    .framer-QisTT .framer-je2t0f>:last-child,
    .framer-QisTT .framer-14vrh6b>:last-child,
    .framer-QisTT .framer-wjg7bn>:last-child,
    .framer-QisTT .framer-1c0b7qi>:last-child {
        margin-right: 0
    }

    .framer-QisTT .framer-1jg9z9u>* {
        margin: 6px 0
    }

    .framer-QisTT .framer-tqrv5f>* {
        margin: 11px 0
    }

    .framer-QisTT .framer-1d5s6c9>* {
        margin: 0 15px
    }

    .framer-QisTT .framer-1l3t5an>* {
        margin: 10px 0
    }

    .framer-QisTT .framer-17uvgnt>* {
        margin: 0 5px
    }

    .framer-QisTT .framer-je2t0f>* {
        margin: 0 4.5px
    }

    .framer-QisTT .framer-14vrh6b>*,
    .framer-QisTT .framer-wjg7bn>* {
        margin: 0 23px
    }

    .framer-QisTT .framer-cqqoa6>*,
    .framer-QisTT .framer-kmkk5l>* {
        margin: 16px 0
    }

    .framer-QisTT .framer-12qnpf5>*,
    .framer-QisTT .framer-hvhcsq>*,
    .framer-QisTT .framer-yd4uzl>* {
        margin: 8px 0
    }
}

.framer-QisTT.framer-v-100waq7 .framer-18ei3y1 {
    width: 780px
}

.framer-QisTT.framer-v-100waq7 .framer-1dc1oib,
.framer-QisTT.framer-v-15ysd0k .framer-1dc1oib {
    gap: 50px
}

.framer-QisTT.framer-v-100waq7 .framer-1clxsno,
.framer-QisTT.framer-v-15ysd0k .framer-1clxsno {
    flex-direction: column;
    gap: 50px;
    justify-content: center
}

.framer-QisTT.framer-v-100waq7 .framer-1jg9z9u,
.framer-QisTT.framer-v-100waq7 .framer-1l3t5an,
.framer-QisTT.framer-v-15ysd0k .framer-1jg9z9u,
.framer-QisTT.framer-v-15ysd0k .framer-1l3t5an {
    align-content: center;
    align-items: center;
    flex: none;
    justify-content: center;
    width: 100%
}

.framer-QisTT.framer-v-100waq7 .framer-1kpmir6 {
    width: 426px
}

.framer-QisTT.framer-v-100waq7 .framer-tqrv5f,
.framer-QisTT.framer-v-100waq7 .framer-12qnpf5,
.framer-QisTT.framer-v-15ysd0k .framer-tqrv5f {
    align-content: center;
    align-items: center
}

.framer-QisTT.framer-v-100waq7 .framer-ys4oie,
.framer-QisTT.framer-v-15ysd0k .framer-ys4oie {
    align-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    justify-content: center
}

.framer-QisTT.framer-v-100waq7 .framer-14vrh6b,
.framer-QisTT.framer-v-15ysd0k .framer-14vrh6b {
    justify-content: center
}

.framer-QisTT.framer-v-100waq7 .framer-cqqoa6,
.framer-QisTT.framer-v-100waq7 .framer-kmkk5l,
.framer-QisTT.framer-v-100waq7 .framer-hvhcsq {
    align-content: center;
    align-items: center;
    justify-content: center
}

.framer-QisTT.framer-v-100waq7 .framer-5v7vgb,
.framer-QisTT.framer-v-100waq7 .framer-d2d03o,
.framer-QisTT.framer-v-100waq7 .framer-p4ok57,
.framer-QisTT.framer-v-100waq7 .framer-1qoelkn,
.framer-QisTT.framer-v-100waq7 .framer-mor0ai,
.framer-QisTT.framer-v-100waq7 .framer-71n7r1,
.framer-QisTT.framer-v-100waq7 .framer-ggz6qz,
.framer-QisTT.framer-v-100waq7 .framer-1bcffoz,
.framer-QisTT.framer-v-100waq7 .framer-17x09fs,
.framer-QisTT.framer-v-15ysd0k .framer-5v7vgb,
.framer-QisTT.framer-v-15ysd0k .framer-d2d03o,
.framer-QisTT.framer-v-15ysd0k .framer-p4ok57,
.framer-QisTT.framer-v-15ysd0k .framer-1qoelkn,
.framer-QisTT.framer-v-15ysd0k .framer-mor0ai,
.framer-QisTT.framer-v-15ysd0k .framer-71n7r1,
.framer-QisTT.framer-v-15ysd0k .framer-ggz6qz,
.framer-QisTT.framer-v-15ysd0k .framer-1bcffoz,
.framer-QisTT.framer-v-15ysd0k .framer-17x09fs,
.framer-QisTT.framer-v-15ysd0k .framer-1ob507i,
.framer-QisTT.framer-v-15ysd0k .framer-186zccb {
    white-space: pre;
    width: auto
}

@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

    .framer-QisTT.framer-v-100waq7 .framer-1dc1oib,
    .framer-QisTT.framer-v-100waq7 .framer-1clxsno,
    .framer-QisTT.framer-v-100waq7 .framer-ys4oie {
        gap: 0px
    }

    .framer-QisTT.framer-v-100waq7 .framer-1dc1oib>*,
    .framer-QisTT.framer-v-100waq7 .framer-1clxsno>*,
    .framer-QisTT.framer-v-100waq7 .framer-ys4oie>* {
        margin: 25px 0
    }

    .framer-QisTT.framer-v-100waq7 .framer-1dc1oib>:first-child,
    .framer-QisTT.framer-v-100waq7 .framer-1clxsno>:first-child,
    .framer-QisTT.framer-v-100waq7 .framer-ys4oie>:first-child {
        margin-top: 0
    }

    .framer-QisTT.framer-v-100waq7 .framer-1dc1oib>:last-child,
    .framer-QisTT.framer-v-100waq7 .framer-1clxsno>:last-child,
    .framer-QisTT.framer-v-100waq7 .framer-ys4oie>:last-child {
        margin-bottom: 0
    }
}

.framer-QisTT.framer-v-15ysd0k .framer-18ei3y1 {
    width: 390px
}

.framer-QisTT.framer-v-15ysd0k .framer-99vg7j {
    max-width: 450px;
    padding: 50px 20px
}

.framer-QisTT.framer-v-15ysd0k .framer-1fbiftf {
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    word-wrap: break-word
}

.framer-QisTT.framer-v-15ysd0k .framer-1kpmir6,
.framer-QisTT.framer-v-15ysd0k .framer-icvppe {
    width: 100%
}

.framer-QisTT.framer-v-15ysd0k .framer-1d5s6c9 {
    flex-direction: column;
    gap: 20px
}

.framer-QisTT.framer-v-15ysd0k .framer-cqqoa6,
.framer-QisTT.framer-v-15ysd0k .framer-kmkk5l,
.framer-QisTT.framer-v-15ysd0k .framer-hvhcsq {
    align-content: center;
    align-items: center;
    justify-content: center;
    width: min-content
}

.framer-QisTT.framer-v-15ysd0k .framer-12qnpf5 {
    align-content: center;
    align-items: center;
    width: 84px
}

.framer-QisTT.framer-v-15ysd0k .framer-1pza12w {
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    padding: 10px
}

.framer-QisTT.framer-v-15ysd0k .framer-1c0b7qi {
    gap: 20px;
    width: 100%
}

@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

    .framer-QisTT.framer-v-15ysd0k .framer-1dc1oib,
    .framer-QisTT.framer-v-15ysd0k .framer-1clxsno,
    .framer-QisTT.framer-v-15ysd0k .framer-1d5s6c9,
    .framer-QisTT.framer-v-15ysd0k .framer-ys4oie,
    .framer-QisTT.framer-v-15ysd0k .framer-1pza12w,
    .framer-QisTT.framer-v-15ysd0k .framer-1c0b7qi {
        gap: 0px
    }

    .framer-QisTT.framer-v-15ysd0k .framer-1dc1oib>*,
    .framer-QisTT.framer-v-15ysd0k .framer-1clxsno>*,
    .framer-QisTT.framer-v-15ysd0k .framer-ys4oie>* {
        margin: 25px 0
    }

    .framer-QisTT.framer-v-15ysd0k .framer-1dc1oib>:first-child,
    .framer-QisTT.framer-v-15ysd0k .framer-1clxsno>:first-child,
    .framer-QisTT.framer-v-15ysd0k .framer-1d5s6c9>:first-child,
    .framer-QisTT.framer-v-15ysd0k .framer-ys4oie>:first-child,
    .framer-QisTT.framer-v-15ysd0k .framer-1pza12w>:first-child {
        margin-top: 0
    }

    .framer-QisTT.framer-v-15ysd0k .framer-1dc1oib>:last-child,
    .framer-QisTT.framer-v-15ysd0k .framer-1clxsno>:last-child,
    .framer-QisTT.framer-v-15ysd0k .framer-1d5s6c9>:last-child,
    .framer-QisTT.framer-v-15ysd0k .framer-ys4oie>:last-child,
    .framer-QisTT.framer-v-15ysd0k .framer-1pza12w>:last-child {
        margin-bottom: 0
    }

    .framer-QisTT.framer-v-15ysd0k .framer-1d5s6c9>*,
    .framer-QisTT.framer-v-15ysd0k .framer-1pza12w>* {
        margin: 10px 0
    }

    .framer-QisTT.framer-v-15ysd0k .framer-1c0b7qi>* {
        margin: 0 10px
    }

    .framer-QisTT.framer-v-15ysd0k .framer-1c0b7qi>:first-child {
        margin-left: 0
    }

    .framer-QisTT.framer-v-15ysd0k .framer-1c0b7qi>:last-child {
        margin-right: 0
    }
}

.framer-EjWs1 .framer-styles-preset-1wzfti9:not(.rich-text-wrapper),
.framer-EjWs1 .framer-styles-preset-1wzfti9.rich-text-wrapper p {
    --framer-font-family: "Inter-Light", "Inter", sans-serif;
    --framer-font-size: 20px;
    --framer-font-style: normal;
    --framer-font-weight: 300;
    --framer-letter-spacing: -.4px;
    --framer-line-height: 35px;
    --framer-paragraph-spacing: 20px;
    --framer-text-alignment: center;
    --framer-text-color: #f1f5f9;
    --framer-text-decoration: none;
    --framer-text-transform: none
}

.framer-Adeeo .framer-styles-preset-138ee5y:not(.rich-text-wrapper),
.framer-Adeeo .framer-styles-preset-138ee5y.rich-text-wrapper p {
    --framer-font-family: "Inter-Light", "Inter", sans-serif;
    --framer-font-size: 19px;
    --framer-font-style: normal;
    --framer-font-weight: 300;
    --framer-letter-spacing: -.38px;
    --framer-line-height: 30px;
    --framer-paragraph-spacing: 20px;
    --framer-text-alignment: center;
    --framer-text-color: #f1f5f9;
    --framer-text-decoration: none;
    --framer-text-transform: none
}

.framer-Agnx9 .framer-styles-preset-1j1173s:not(.rich-text-wrapper),
.framer-Agnx9 .framer-styles-preset-1j1173s.rich-text-wrapper a {
    --framer-link-current-text-color: #ffffff;
    --framer-link-current-text-decoration: none;
    --framer-link-hover-text-color: var(--token-fed421e9-3f6f-4d58-bb1b-a95deadf172a, #ffffff);
    --framer-link-hover-text-decoration: none;
    --framer-link-text-color: rgba(255, 255, 255, .5);
    --framer-link-text-decoration: none
}

.framer-yRxrJ .framer-styles-preset-137xg8f:not(.rich-text-wrapper),
.framer-yRxrJ .framer-styles-preset-137xg8f.rich-text-wrapper a {
    --framer-link-current-text-decoration: none;
    --framer-link-hover-text-decoration: none;
    --framer-link-text-color: var(--token-fed421e9-3f6f-4d58-bb1b-a95deadf172a, #ffffff);
    --framer-link-text-decoration: none
}

.framer-72rtr7 {
    min-height: 100%;
    width: auto !important;
}