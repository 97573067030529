.svg-templates {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 0;
    height: 0;

}

.svg1776208339_28972_mask1_22_4879 {
    mask-type: alpha
}

.svg1776208339_28972_mask0_22_4879 {
    mask-type: alpha
}

#container_svg432874550_2020 {
    display: none;
}

// #svg-templates {
//     display: none;
// }