.framer-61j3w9 {

    .framer-1q9737y {

        .framer-1kaoxzn {
            #globeViz {
                width: 700px;
                height: 600px;
                position: relative;

                canvas {
                    position: absolute;
                    left: -350px;
                    top: -100px;
                    width: 1400px !important;
                    height: 800px !important;
                }
            }


            @media only screen and (max-width: 1439px) {
                gap: 0;

                #globeViz {
                    width: 60vw !important;
                    height: 40vw !important;
                    overflow: hidden;

                    canvas {
                        width: 80vw !important;
                        height: 45vw !important;
                        bottom: 0px;
                        left: -10vw;
                        top: -2vw;
                    }
                }
            }

            @media only screen and (max-width: 809px) {
                #globeViz {
                    width: 56vw !important;
                    height: 46vw !important;


                    canvas {
                        width: 90vw !important;
                        height: 50vw !important;
                        bottom: 0;
                        left: -17vw;
                        top: -2vw;
                    }
                }

            }





            .svgContainer {
                width: 100%;
                height: 100%;
                aspect-ratio: inherit;

                svg {
                    width: 100%;
                    height: 100%
                }
            }

            .framer-nro0f5 {
                // border: 2px solid green;

                .framer-s2ewvg {

                    .ramer-cwpdvo,
                    .hidden-4fkeid,
                    .hidden-1t38e76 {
                        .framer-851z3w {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text {
                                --font-selector: SW50ZXItTGlnaHQ=;
                                --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                --framer-font-size: 15px;
                                --framer-font-weight: 300;
                                --framer-letter-spacing: 0.75px;
                                --framer-line-height: 35px;
                                --framer-text-alignment: center;
                                --framer-text-color: rgb(241, 245, 249);
                                --framer-text-transform: uppercase
                            }
                        }

                        .framer-1xwyue6 {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1)
                        }
                    }

                    .framer-11n9tyi {
                        .framer-12uktrn {
                            .framer-fzbx7m-container {

                                .ssr-variant,
                                .hidden-1t38e76 {
                                    h1 {
                                        background-image: url(https://framerusercontent.com/images/DN7ut8BMdxCiadtYJWSG1Vt7zA.svg);
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        background-position: 0% 0%;
                                        background-clip: text;
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                        text-fill-color: transparent;
                                        color: rgb(136, 136, 136);
                                        font-size: 63px;
                                        --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                        font-style: normal;
                                        font-weight: 600;
                                        text-align: center;
                                        letter-spacing: -3.1px;
                                        white-space: normal;
                                        line-height: 1.1;
                                        margin: 0;
                                        padding: 3px
                                    }
                                }

                                .ssr-variant,
                                .hidden-4fkeid,
                                .hidden-1fdb8e9 {
                                    h1 {
                                        background-image: url(https://framerusercontent.com/images/DN7ut8BMdxCiadtYJWSG1Vt7zA.svg);
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        background-position: 0% 0%;
                                        background-clip: text;
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                        text-fill-color: transparent;
                                        color: rgb(136, 136, 136);
                                        font-size: 63px;


                                        @media only screen and (max-width: 1439px) {

                                            font-size: 55px;

                                        }

                                        @media only screen and (max-width: 809px) {

                                            font-size: 38px;

                                        }

                                        --framer-font-family: "Inter-Light",
                                        "Inter",
                                        sans-serif;
                                        font-style: normal;
                                        font-weight: 600;
                                        text-align: center;
                                        letter-spacing: -3.1px;
                                        white-space: normal;
                                        line-height: 1.1;
                                        margin: 0;
                                        padding: 3px
                                    }
                                }
                            }

                            .framer-1ntinmm {
                                outline: none;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                flex-shrink: 0;
                                transform: none;

                                .framer-text,
                                .framer-styles-preset-186siln {
                                    .framer-text {
                                        background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                    }
                                }
                            }
                        }

                        .framer-kn9idq {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-186siln {

                                .framer-text {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                }
                            }

                        }
                    }

                    .ssr-variant,
                    .hidden-1t38e76,
                    .hidden-4fkeid {
                        .framer-1wqvwy {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-1wzfti9 {
                                --framer-text-alignment: left
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-4fkeid,
                    .hidden-1fdb8e9 {
                        .framer-1wqvwy {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-1wzfti9 {
                                --framer-text-alignment: center
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-1t38e76,
                    .hidden-1fdb8e9 {
                        .framer-1wqvwy {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-1wzfti9 {
                                --framer-text-alignment: center
                            }
                        }
                    }

                    .framer-17o45yf,
                    .hidden-1fdb8e9 {
                        .framer-1rsw90i {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1)
                        }

                        .framer-4kcmtl {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text {
                                --font-selector: SW50ZXItTGlnaHQ=;
                                --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                --framer-font-size: 15px;
                                --framer-font-weight: 300;
                                --framer-letter-spacing: 0.75px;
                                --framer-line-height: 35px;
                                --framer-text-alignment: center;
                                --framer-text-color: rgb(241, 245, 249);
                                --framer-text-transform: uppercase
                            }
                        }

                        .framer-1cvknzf {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1)
                        }
                    }
                }

                .framer-171rso8 {
                    .framer-1l2tehj {

                        .framer-1s6thx0 {
                            .framer-5o6sw6 {
                                .framer-jeyvr2 {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text {
                                        --font-selector: SW50ZXItTWVkaXVt;
                                        --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                        --framer-font-size: 33px;
                                        --framer-font-weight: 500;
                                        --framer-letter-spacing: -1.65px;

                                        .framer-text {
                                            background-image: linear-gradient(179.081deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                        }
                                    }
                                }

                                .framer-fgxpt6 {
                                    .framer-16vopup {
                                        .framer-9hhfv2 {
                                            .framer-fzw30y {
                                                image-rendering: pixelated;
                                                flex-shrink: 0;
                                                fill: rgba(0, 0, 0, 1);
                                                color: rgba(0, 0, 0, 1)
                                            }
                                        }

                                        .framer-jv8ywr {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            transform: none;

                                            .framer-text {
                                                --framer-font-size: 19px;
                                                --framer-line-height: 30px;

                                                .framer-text {
                                                    --font-selector: SW50ZXItTGlnaHQ=;
                                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                    --framer-font-size: 19px;
                                                    --framer-font-weight: 300;
                                                    --framer-letter-spacing: -0.38px;
                                                    --framer-text-color: rgba(241, 245, 249, 0.6000000238418579)
                                                }
                                            }
                                        }
                                    }

                                    .framer-o6mvud {
                                        .framer-11r78z8 {
                                            .framer-6g7k00 {
                                                image-rendering: pixelated;
                                                flex-shrink: 0;
                                                fill: rgba(0, 0, 0, 1);
                                                color: rgba(0, 0, 0, 1)
                                            }
                                        }

                                        .framer-12kfj7m {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            transform: none;

                                            .framer-text {
                                                --framer-font-size: 19px;
                                                --framer-line-height: 30px;

                                                .framer-text {
                                                    --font-selector: SW50ZXItTGlnaHQ=;
                                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                    --framer-font-size: 19px;
                                                    --framer-font-weight: 300;
                                                    --framer-letter-spacing: -0.38px;
                                                    --framer-text-color: rgba(241, 245, 249, 0.6000000238418579)
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .framer-1y7uznq {
                            .framer-yfrdu1 {
                                .framer-15okq9t {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text {
                                        --font-selector: SW50ZXItTWVkaXVt;
                                        --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                        --framer-font-size: 33px;
                                        --framer-font-weight: 500;
                                        --framer-letter-spacing: -1.65px;

                                        .framer-text {
                                            background-image: linear-gradient(179.081deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                        }
                                    }
                                }

                                .framer-bx7k81 {
                                    .framer-ppismu {
                                        .framer-863ell {
                                            .framer-1d5gj3c {
                                                image-rendering: pixelated;
                                                flex-shrink: 0;
                                                fill: rgba(0, 0, 0, 1);
                                                color: rgba(0, 0, 0, 1)
                                            }
                                        }

                                        .framer-1n5ke9g {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            transform: none;

                                            .framer-text {
                                                --framer-font-size: 19px;
                                                --framer-line-height: 30px;

                                                .framer-text {
                                                    --font-selector: SW50ZXItTGlnaHQ=;
                                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                    --framer-font-size: 19px;
                                                    --framer-font-weight: 300;
                                                    --framer-letter-spacing: -0.38px;
                                                    --framer-text-color: rgba(241, 245, 249, 0.6000000238418579);
                                                }
                                            }
                                        }
                                    }

                                    .framer-1n5slct {
                                        .framer-1c4lsc1 {
                                            .framer-1azn9x {
                                                image-rendering: pixelated;
                                                flex-shrink: 0;
                                                fill: rgba(0, 0, 0, 1);
                                                color: rgba(0, 0, 0, 1);
                                            }
                                        }

                                        .framer-7i6xc8 {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            transform: none;

                                            .framer-text {
                                                --framer-font-size: 19px;
                                                --framer-line-height: 30px;

                                                .framer-text {
                                                    --font-selector: SW50ZXItTGlnaHQ=;
                                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                    --framer-font-size: 19px;
                                                    --framer-font-weight: 300;
                                                    --framer-letter-spacing: -0.38px;
                                                    --framer-text-color: rgba(241, 245, 249, 0.6000000238418579)
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .framer-oarzfo {
                        .framer-1ac0iy6 {
                            .framer-1vsu43a {
                                .framer-1q8qm9t {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text {
                                        --font-selector: SW50ZXItTWVkaXVt;
                                        --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                        --framer-font-size: 33px;
                                        --framer-font-weight: 500;
                                        --framer-letter-spacing: -1.65px;

                                        .framer-text {
                                            background-image: linear-gradient(179.081deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                        }
                                    }
                                }

                                .framer-g24dxc {
                                    .framer-1o4dgbd {
                                        .framer-1do99ys {
                                            .framer-ibjzxr {
                                                image-rendering: pixelated;
                                                flex-shrink: 0;
                                                fill: rgba(0, 0, 0, 1);
                                                color: rgba(0, 0, 0, 1);
                                            }
                                        }

                                        .framer-x8vk0c {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            transform: none;

                                            .framer-text {
                                                --framer-font-size: 19px;
                                                --framer-line-height: 30px;

                                                .framer-text {
                                                    --font-selector: SW50ZXItTGlnaHQ=;
                                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                    --framer-font-size: 19px;
                                                    --framer-font-weight: 300;
                                                    --framer-letter-spacing: -0.38px;
                                                    --framer-text-color: rgba(241, 245, 249, 0.6000000238418579);
                                                }
                                            }
                                        }
                                    }

                                    .framer-6kh8ho {
                                        .framer-15lyoiy {
                                            .framer-10qkvrl {
                                                image-rendering: pixelated;
                                                flex-shrink: 0;
                                                fill: rgba(0, 0, 0, 1);
                                                color: rgba(0, 0, 0, 1)
                                            }
                                        }

                                        .framer-1bo80ir {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            transform: none;

                                            .framer-text {
                                                --framer-font-size: 19px;
                                                --framer-line-height: 30px;

                                                .framer-text {
                                                    --font-selector: SW50ZXItTGlnaHQ=;
                                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                    --framer-font-size: 19px;
                                                    --framer-font-weight: 300;
                                                    --framer-letter-spacing: -0.38px;
                                                    --framer-text-color: rgba(241, 245, 249, 0.6000000238418579)
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .framer-8lwzvj {
                    .framer-1f950g6-container {

                        .ramer-oyI4k,
                        .framer-v-693ydj {
                            display: contents;

                            .framer-693ydj,
                            .framer-mesh6j {
                                --border-bottom-width: 1.6861314px;
                                --border-color: rgba(255, 255, 255, 0.3);
                                --border-left-width: 1.6861314px;
                                --border-right-width: 1.6861314px;
                                --border-style: solid;
                                --border-top-width: 1.6861314px;
                                background: linear-gradient(180deg, rgba(117, 172, 255, 0) 0%, rgba(255, 255, 255, 0.12) 100%);
                                background-color: rgba(0, 0, 0, 0);
                                border-bottom-left-radius: 10px;
                                border-bottom-right-radius: 10px;
                                border-top-left-radius: 10px;
                                border-top-right-radius: 10px;

                                .framer-crfnzq {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    --extracted-r6o4lv: rgb(255, 255, 255);
                                    --framer-paragraph-spacing: 0px;
                                    transform: none;

                                    .framer-text {
                                        --font-selector: SW50ZXItU2VtaUJvbGQ=;
                                        --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                        --framer-font-size: 16.86px;
                                        --framer-font-weight: 600;
                                        --framer-letter-spacing: -0.42px;
                                        --framer-line-height: 100%;
                                        --framer-text-alignment: center;
                                        --framer-text-color: var(--extracted-r6o4lv)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}