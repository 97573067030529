.framer-s6hy2b {
    .framer-v3u1up {
        .framer-ohe6nr {
            .framer-1rs5s5g {
                opacity: 1;
                transform: perspective(1200px) translateX(-50%) translateX(0px) translateY(-40px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0);
                width: 100%;
                .framer-pyz7fu {
                    image-rendering: pixelated;
                    flex-shrink: 0;
                    fill: black;
                    color: black;
                    width: 100%;
                    display: contents;
                    .svgContainer {
                        width: 100%;
                        height: 100%;
                        aspect-ratio: inherit;
                        max-width: 530px;

                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .framer-9ocitq {
                .framer-7cfgdx {
                    opacity: 1;
                    transform: perspective(1200px);

                    .framer-i9uze0 {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: rgba(0, 0, 0, 1);
                        color: rgba(0, 0, 0, 1);

                        .svgContainer {
                            width: 100%;
                            height: 100%;
                            aspect-ratio: inherit;

                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .framer-19l65ef {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        .framer-text {
                            --framer-font-size: 15px;
                            --framer-line-height: 35px;
                            --framer-text-alignment: center;

                            .framer-text {
                                --font-selector: SW50ZXItTGlnaHQ=;
                                font-family: "Inter-Light", "Inter", sans-serif;
                                --framer-font-size: 15px;
                                --framer-font-weight: 300;
                                --framer-letter-spacing: 0.75px;
                                --framer-text-color: rgba(241, 245, 249, 1);
                                --framer-text-transform: uppercase
                            }
                        }
                    }

                    .framer-1q7ayv4 {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: rgba(0, 0, 0, 1);
                        color: rgba(0, 0, 0, 1);

                        .svgContainer {
                            width: 100%;
                            height: 100%;
                            aspect-ratio: inherit;

                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                }

                .framer-hmcbx0 {
                    .framer-h4w8mj-container {

                        .ssr-variant,
                        .hidden-p5w47l,
                        .hidden-18ccrvo {
                            h1 {
                                background-image: url(https://framerusercontent.com/images/bTF3xblyn2uyR2rZCo89tUf9mVE.svg);
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: 0% 0%;
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                text-fill-color: transparent;
                                color: rgb(136, 136, 136);
                                font-size: 63px;
                                font-family: "Inter-Light", "Inter", sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                text-align: center;
                                letter-spacing: -3.1px;
                                white-space: normal;
                                line-height: 1.1;
                                margin: 0;
                                padding: 3px
                            }
                        }

                        .ssr-variant,
                        .hidden-18ccrvo,
                        .hidden-72rtr7 {
                            h1 {
                                background-image: url(https://framerusercontent.com/images/bTF3xblyn2uyR2rZCo89tUf9mVE.svg);
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: 0% 0%;
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                text-fill-color: transparent;
                                color: rgb(136, 136, 136);
                                font-size: 38px;
                                font-family: "Inter-Light", "Inter", sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                text-align: center;
                                letter-spacing: -3.1px;
                                white-space: normal;
                                line-height: 1.1;
                                margin: 0;
                                padding: 3px
                            }
                        }

                        .ssr-variant,
                        .hidden-p5w47l,
                        .hidden-72rtr7 {
                            h1 {
                                background-image: url(https://framerusercontent.com/images/bTF3xblyn2uyR2rZCo89tUf9mVE.svg);
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: 0% 0%;
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                text-fill-color: transparent;
                                color: rgb(136, 136, 136);

                                @media only screen and (max-width: 1439px) {

                                    font-size: 55px;

                                }

                                @media only screen and (max-width: 809px) {

                                    font-size: 38px;

                                }

                                font-size: 63px;
                                font-family: "Inter-Light",
                                "Inter",
                                sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                text-align: center;
                                letter-spacing: -3.1px;
                                white-space: normal;
                                line-height: 1.1;
                                margin: 0;
                                padding: 3px
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-18ccrvo {
                        .framer-qsfsgs {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-186siln {
                                .framer-text {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                }
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-p5w47l,
                    .hidden-72rtr7 {
                        .framer-qsfsgs {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-186siln {
                                .framer-text {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                                }
                            }
                        }
                    }
                }

                .framer-1lx9t9v {
                    outline: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex-shrink: 0;
                    transform: none;
                }

            }

            .framer-skpi98 {
                opacity: 1;
                transform: perspective(1200px);

                .framer-o210t {
                    .framer-l1o88t {
                        .framer-gxev2m {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: black;
                            color: black;

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }

                        .framer-10b6sc0 {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-12s9psp {
                                .framer-text {
                                    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgb(255, 255, 255) 100%)
                                }
                            }
                        }

                        .framer-fag6of {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none
                        }
                    }

                    .framer-9q2q,
                    .hidden-18ccrvo,
                    .hidden-p5w47l {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: rgba(0, 0, 0, 1);
                        color: rgba(0, 0, 0, 1);

                        .svgContainer {
                            width: 100%;
                            height: 100%;
                            aspect-ratio: inherit;

                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .framer-an9xu2 {
                        .framer-1i7x0hx {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: black;
                            color: black;

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }

                        .framer-5791k4 {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-12s9psp {
                                .framer-text {
                                    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgb(255, 255, 255) 100%)
                                }
                            }
                        }

                        .framer-7tvetr {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;
                        }
                    }

                    .ramer-5pg4k2,
                    .hidden-18ccrvo,
                    .hidden-p5w47l {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: rgba(0, 0, 0, 1);
                        color: rgba(0, 0, 0, 1);

                        .svgContainer {
                            width: 100%;
                            height: 100%;
                            aspect-ratio: inherit;

                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .framer-ynh4nb {
                        .framer-1ostbui {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: black;
                            color: black;

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }

                    .framer-d95bhi {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        .framer-text,
                        .framer-styles-preset-12s9psp {
                            .framer-text {
                                background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgb(255, 255, 255) 100%)
                            }
                        }
                    }

                    .framer-1xr59kg {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    }
                }

                .framer-vldtt2 {
                    .framer-4tb9db {
                        .framer-1jipn5y {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: black;
                            color: black;

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }

                        .framer-3us2p9 {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-12s9psp {
                                .framer-text {
                                    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgb(255, 255, 255) 100%)
                                }
                            }
                        }

                        .framer-1u79zrq {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none
                        }

                    }

                    .framer-1iivd7a,
                    .hidden-18ccrvo,
                    .hidden-p5w47l {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: rgba(0, 0, 0, 1);
                        color: rgba(0, 0, 0, 1);

                        .svgContainer {
                            width: 100%;
                            height: 100%;
                            aspect-ratio: inherit;

                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .framer-1vfw6bt {
                        .framer-10gffta {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: black;
                            color: black;

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }

                        .framer-vgk8ic {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-12s9psp {
                                .framer-text {
                                    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgb(255, 255, 255) 100%)
                                }
                            }
                        }

                        .framer-j8p8bn {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none
                        }
                    }
                }

            }
        }
    }
}