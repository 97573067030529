.framer-1r8s7jc {
    .framer-1und9d4 {
        .framer-dasrki {
            .framer-1d7q4in {
                opacity: 1;
                transform: perspective(1200px);

                .framer-10ecp3a {

                    .framer-gzvsc5 {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: rgba(0, 0, 0, 1);
                        color: rgba(0, 0, 0, 1);
                    }

                    .framer-161qdwk {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        .framer-text {
                            --font-selector: SW50ZXItTGlnaHQ=;
                            --framer-font-family: "Inter-Light", "Inter", sans-serif;
                            --framer-font-size: 15px;
                            --framer-font-weight: 300;
                            --framer-letter-spacing: 0.75px;
                            --framer-line-height: 35px;
                            --framer-text-alignment: center;
                            --framer-text-color: rgb(241, 245, 249);
                            --framer-text-transform: uppercase
                        }
                    }

                    .framer-sg55ij {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: rgba(0, 0, 0, 1);
                        color: rgba(0, 0, 0, 1);
                    }
                }

                .framer-w6947w {
                    .framer-9e8a7h-container {

                        .ssr-variant,
                        .hidden-1t38e76,
                        .hidden-4fkeid {
                            h1 {
                                background-image: url(https://framerusercontent.com/images/bTF3xblyn2uyR2rZCo89tUf9mVE.svg);
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: 0% 0%;
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                text-fill-color: transparent;
                                color: rgb(136, 136, 136);
                                font-size: 63px;
                                --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                text-align: center;
                                letter-spacing: -3.1px;
                                white-space: normal;
                                line-height: 1.1;
                                margin: 0;
                                padding: 3px
                            }
                        }

                        .ssr-variant,
                        .hidden-4fkeid,
                        .hidden-1fdb8e9 {
                            h1 {
                                background-image: url(https://framerusercontent.com/images/bTF3xblyn2uyR2rZCo89tUf9mVE.svg);
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: 0% 0%;
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                text-fill-color: transparent;
                                color: rgb(136, 136, 136);

                                @media only screen and (max-width: 809px) {

                                    font-size: 38px !important;
                                }

                                --framer-font-family: "Inter-Light",
                                "Inter",
                                sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                text-align: center;
                                letter-spacing: -3.1px;
                                white-space: normal;
                                line-height: 1.1;
                                margin: 0;
                                padding: 3px
                            }
                        }

                        .ssr-variant,
                        .hidden-1t38e76,
                        .hidden-1fdb8e9 {
                            h1 {
                                background-image: url(https://framerusercontent.com/images/bTF3xblyn2uyR2rZCo89tUf9mVE.svg);
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: 0% 0%;
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                text-fill-color: transparent;
                                color: rgb(136, 136, 136);

                                @media only screen and (max-width: 1439px) {

                                    font-size: 55px;
                                }

                                --framer-font-family: "Inter-Light",
                                "Inter",
                                sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                text-align: center;
                                letter-spacing: -3.1px;
                                white-space: normal;
                                line-height: 1.1;
                                margin: 0;
                                padding: 3px
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-4fkeid {
                        .framer-1q2copr {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-186siln {
                                .framer-text {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                }
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-1t38e76,
                    .hidden-1fdb8e9 {
                        .framer-1q2copr {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-186siln {
                                .framer-text {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                }
                            }
                        }
                    }
                }

                .framer-i88od4 {
                    outline: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex-shrink: 0;
                    transform: none
                }
            }

            .framer-r1gfd9 {
                opacity: 1;
                transform: perspective(1200px);

                .framer-cxzg9j {
                    .framer-1dweb51 {
                        div {
                            position: absolute;
                            border-radius: inherit;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                border-radius: inherit;
                                object-position: center;
                                object-fit: cover;
                                image-rendering: auto
                            }
                        }
                    }

                    .framer-1dw3xah {
                        .framer-15x1yip {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-12s9psp {
                                .framer-text {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                }
                            }
                        }

                        .framer-1au4ihw {
                            .framer-xkuahq {
                                .framer-o8ykqo {
                                    div {
                                        image-rendering: pixelated;
                                        flex-shrink: 0;
                                        fill: rgba(0, 0, 0, 1);
                                        color: rgba(0, 0, 0, 1)
                                    }
                                }

                                .framer-ouqkqx {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text {
                                        --font-selector: SW50ZXItTGlnaHQ=;
                                        --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                        --framer-font-size: 19px;
                                        --framer-font-weight: 300;
                                        --framer-letter-spacing: -0.38px;
                                        --framer-line-height: 30px;
                                        --framer-text-color: rgba(241, 245, 249, 0.6)
                                    }
                                }
                            }

                            .framer-16phgcw {
                                .framer-jsdva6 {
                                    .framer-cgpehi {
                                        image-rendering: pixelated;
                                        flex-shrink: 0;
                                        fill: rgba(0, 0, 0, 1);
                                        color: rgba(0, 0, 0, 1);
                                    }
                                }

                                .framer-1yq0igo {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text {
                                        --font-selector: SW50ZXItTGlnaHQ=;
                                        --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                        --framer-font-size: 19px;
                                        --framer-font-weight: 300;
                                        --framer-letter-spacing: -0.38px;
                                        --framer-line-height: 30px;
                                        --framer-text-color: rgba(241, 245, 249, 0.6)
                                    }
                                }
                            }
                        }
                    }
                }

                .framer-5in8s8 {
                    .framer-ban390 {
                        div {
                            position: absolute;
                            border-radius: inherit;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                border-radius: inherit;
                                object-position: center;
                                object-fit: cover;
                                image-rendering: auto;
                            }
                        }
                    }

                    .framer-bidwiq {
                        .framer-1udeick {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-12s9psp {
                                .framer-text {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                                }
                            }
                        }

                        .framer-1yir2yf {
                            .framer-11hf3be {
                                .framer-oyixu6 {
                                    div {
                                        image-rendering: pixelated;
                                        flex-shrink: 0;
                                        fill: rgba(0, 0, 0, 1);
                                        color: rgba(0, 0, 0, 1)
                                    }
                                }

                                .framer-1amohsv {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text {
                                        --font-selector: SW50ZXItTGlnaHQ=;
                                        --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                        --framer-font-size: 19px;
                                        --framer-font-weight: 300;
                                        --framer-letter-spacing: -0.38px;
                                        --framer-line-height: 30px;
                                        --framer-text-color: rgba(241, 245, 249, 0.6)
                                    }
                                }
                            }

                            .framer-1wxj3xv {
                                .framer-mi8nsc {
                                    div {
                                        image-rendering: pixelated;
                                        flex-shrink: 0;
                                        fill: rgba(0, 0, 0, 1);
                                        color: rgba(0, 0, 0, 1);
                                    }
                                }

                                .framer-27pmin {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text {
                                        --framer-font-size: 19px;
                                        --framer-line-height: 30px;

                                        .framer-text {
                                            --font-selector: SW50ZXItTGlnaHQ=;
                                            --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                            --framer-font-size: 19px;
                                            --framer-font-weight: 300;
                                            --framer-letter-spacing: -0.38px;
                                            --framer-text-color: rgba(241, 245, 249, 0.6000000238418579)
                                        }
                                    }

                                }
                            }
                        }
                    }
                }

                .framer-11x7boo {
                    .framer-sbluc2 {
                        div {
                            position: absolute;
                            border-radius: inherit;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                border-radius: inherit;
                                object-position: center;
                                object-fit: cover;
                                image-rendering: auto
                            }
                        }
                    }

                    .framer-sjs455 {
                        .framer-7ktxx7 {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-12s9psp {
                                .framer-text {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                }
                            }
                        }

                        .framer-oryefi {
                            .framer-141yalg {
                                .framer-2hee0i {
                                    .framer-1v55kv2 {
                                        image-rendering: pixelated;
                                        flex-shrink: 0;
                                        fill: rgba(0, 0, 0, 1);
                                        color: rgba(0, 0, 0, 1);
                                    }
                                }

                                .framer-1kizmbs {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text {
                                        --font-selector: SW50ZXItTGlnaHQ=;
                                        --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                        --framer-font-size: 19px;
                                        --framer-font-weight: 300;
                                        --framer-letter-spacing: -0.38px;
                                        --framer-line-height: 30px;
                                        --framer-text-color: rgba(241, 245, 249, 0.6)
                                    }
                                }
                            }

                            .framer-e1pstx {
                                .framer-11a4px1 {
                                    .framer-1uya3g7 {
                                        image-rendering: pixelated;
                                        flex-shrink: 0;
                                        fill: rgba(0, 0, 0, 1);
                                        color: rgba(0, 0, 0, 1)
                                    }
                                }

                                .framer-jvkc4r {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text {
                                        --framer-font-size: 19px;
                                        --framer-line-height: 30px;

                                        .framer-text {
                                            --font-selector: SW50ZXItTGlnaHQ=;
                                            --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                            --framer-font-size: 19px;
                                            --framer-font-weight: 300;
                                            --framer-letter-spacing: -0.38px;
                                            --framer-text-color: rgba(241, 245, 249, 0.6000000238418579)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}