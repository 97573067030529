.framer-1pxana1 {
    .ssr-variant {
        display: contents
    }

    .svgContainer {
        width: 100%;
        height: 100%;
        aspect-ratio: inherit;



        svg {
            width: 100%;
            height: 100%;
        }
    }

    .framer-oq9jai {
        opacity: 1;
        transform: perspective(100vw);

        .ssr-variant,
        .hidden-1t38e76 {

            .framer-ry9op0-container {
                div {

                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    background-color: rgba(255, 255, 255, 0);
                    transform: translateZ(0);
                    border-radius: 700px;
                    position: relative;

                    #One {
                        canvas {
                            width: 100%;
                            height: 100%;
                            position: absolute
                        }
                    }
                }
            }

        }

        .ssr-variant,
        .hidden-4fkeid,
        .hidden-1fdb8e9 {
            .framer-ry9op0-container {
                transform: rotate(-40deg);

                div {

                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    background-color: rgba(255, 255, 255, 0);
                    transform: translateZ(0);
                    border-radius: 700px;
                    position: relative;

                    #One {
                        canvas {
                            width: 100%;
                            height: 100%;
                            position: absolute
                        }
                    }
                }
            }
        }

        .framer-w5yuep {
            image-rendering: pixelated;
            flex-shrink: 0;
            fill: black;
            color: black;

        }
    }

    .framer-a7brsr {
        .framer-1wlua9y {
            .framer-dhmoms {
                opacity: 1;
                transform: perspective(100vw) translateX(0px) translateY(8px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0);

                .framer-1tuo9lc {
                    opacity: 1;
                    transform: perspective(100vw);

                    .framer-mkl5is {
                        .framer-o6rf7a-container {

                            .ssr-variant,
                            .hidden-1t38e76,
                            .hidden-4fkeid {
                                h1 {
                                    background-image: url(https://framerusercontent.com/images/U6UbhIrFD2OnmiS03kViwM0Qb8.svg);
                                    background-clip: text;
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    background-position: 0% 0%;
                                    background-clip: text;
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    text-fill-color: transparent;
                                    color: rgb(136, 136, 136);
                                    font-size: 80px;
                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                    font-style: normal;
                                    font-weight: 600;
                                    text-align: center;
                                    letter-spacing: -4px;
                                    white-space: normal;
                                    line-height: 1;
                                    margin: 0;
                                    padding: 3px
                                }
                            }

                            .ssr-variant,
                            .hidden-4fkeid,
                            .hidden-1fdb8e9 {
                                h1 {

                                    background-image: url(https://framerusercontent.com/images/U6UbhIrFD2OnmiS03kViwM0Qb8.svg);
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    background-position: 0% 0%;
                                    background-clip: text;
                                    font-size: 42px;
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    text-fill-color: transparent;
                                    color: rgb(136, 136, 136);

                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                    font-style: normal;
                                    font-weight: 600;
                                    text-align: center;
                                    letter-spacing: -2px;
                                    white-space: normal;
                                    line-height: 1;
                                    margin: 0;
                                    padding: 3px;


                                }
                            }

                            .ssr-variant,
                            .idden-1t38e76,
                            .hidden-1fdb8e9 {
                                h1 {


                                    background-image: url(https://framerusercontent.com/images/U6UbhIrFD2OnmiS03kViwM0Qb8.svg);
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    background-position: 0% 0%;
                                    background-clip: text;
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    text-fill-color: transparent;
                                    color: rgb(136, 136, 136);
                                    font-size: 64px;

                                    @media only screen and (max-width: 1439px) {

                                        font-size: 64px;

                                    }

                                    @media only screen and (max-width: 809px) {

                                        font-size: 42px;

                                    }

                                    font-size: 80px;
                                    --framer-font-family: "Inter-Light",
                                    "Inter",
                                    sans-serif;
                                    font-style: normal;
                                    font-weight: 600;
                                    text-align: center;
                                    letter-spacing: -4px;
                                    white-space: normal;
                                    line-height: 1;
                                    margin: 0;
                                    padding: 3px
                                }
                            }
                        }

                        .framer-1q1ljq3 {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-13kmdo8 {
                                .framer-text {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.4) 100%)
                                }
                            }
                        }
                    }

                    .framer-j4pyps {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        .framer-text,
                        .framer-styles-preset-13kmdo8 {
                            .framer-text {
                                background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.4) 100%);
                                background-clip: text;
                            }
                        }
                    }
                }

                .ssr-variant,
                .hidden-1t38e76 {
                    .framer-vxbjup {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        opacity: 1;
                        transform: perspective(100vw)
                    }
                }

                .ssr-variant,
                .hidden-4fkeid,
                .hidden-1fdb8e9 {
                    .framer-vxbjup {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        opacity: 1;
                        transform: perspective(100vw)
                    }
                }
            }

            .ssr-variant,
            .hidden-1t38e76 {


                .framer-1bxm4u7 {
                    opacity: 1;
                    transform: perspective(100vw) translateX(-50%) translateX(0px) translateY(8px) scale(1) !important;
                    left: 50%;




                    .framer-znopii {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: black;
                        color: black;

                    }
                }
            }


            .ssr-variant,
            .hidden-4fkeid,
            .hidden-1fdb8e9 {

                .framer-1bxm4u7 {
                    opacity: 1;
                    transform: perspective(100vw) translateX(0%) translateY(8px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0);



                    .framer-znopii {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: black;
                        color: black;

                    }
                }
            }
        }
    }

    .framer-19bu65k {

        .framer-1i5chw {
            .framer-1ewqsxg {

                .ssr-variant,
                .hidden-1t38e76 {
                    .framer-1gzvda5 {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        .framer-tex {
                            --font-selector: SW50ZXItTGlnaHQ=;
                            --framer-font-family: "Inter-Light", "Inter", sans-serif;
                            --framer-font-size: 83.27px;
                            --framer-font-weight: 300;
                            --framer-letter-spacing: -4.16px;

                            .framer-tex {
                                background-image: linear-gradient(179.081deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                            }
                        }
                    }
                }

                .ssr-variant,
                .hidden-4fkeid,
                .hidden-1fdb8e9 {
                    .framer-1gzvda5 {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        .framer-text {
                            --font-selector: SW50ZXItTGlnaHQ=;
                            --framer-font-family: "Inter-Light", "Inter", sans-serif;
                            --framer-font-size: 60.27px;
                            --framer-font-weight: 300;
                            --framer-letter-spacing: -4.16px;

                            .framer-text {
                                background-image: linear-gradient(179.081deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                            }
                        }
                    }
                }

                .framer-1q1lw1r {
                    outline: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex-shrink: 0;
                    transform: none;
                }
            }
        }

        .ssr-variant,
        .hidden-4fkeid {

            .framer-4qqe42,
            .hidden-1t38e76 {
                image-rendering: pixelated;
                flex-shrink: 0;
                fill: rgba(0, 0, 0, 1);
                color: rgba(0, 0, 0, 1)
            }
        }

        .ssr-variant,
        .hidden-1t38e76,
        .hidden-1fdb8e9 {

            .ramer-4qqe42,
            .hidden-1t38e76 {
                border: 1px solid rgb(77, 76, 76);
                outline: hidden;
                image-rendering: pixelated;
                flex-shrink: 0;
                fill: rgba(0, 0, 0, 1);
                color: rgba(0, 0, 0, 1)
            }
        }

        .framer-l6tnfh {
            .framer-1p0sh1i {

                .ssr-variant,
                .hidden-1t38e76 {
                    .framer-1u3lfz2 {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        .ramer-text {
                            --font-selector: SW50ZXItTGlnaHQ=;
                            --framer-font-family: "Inter-Light", "Inter", sans-serif;
                            --framer-font-size: 83.27px;
                            --framer-font-weight: 300;
                            --framer-letter-spacing: -4.16px;

                            .ramer-text {
                                background-image: linear-gradient(179.081deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                            }
                        }
                    }
                }

                .ssr-variant,
                .hidden-4fkeid,
                .hidden-1fdb8e9 {
                    .framer-1u3lfz2 {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        .framer-text {
                            --font-selector: SW50ZXItTGlnaHQ=;
                            --framer-font-family: "Inter-Light", "Inter", sans-serif;
                            --framer-font-size: 60.27px;
                            --framer-font-weight: 300;
                            --framer-letter-spacing: -4.16px;

                            .framer-text {
                                background-image: linear-gradient(179.081deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                            }
                        }
                    }
                }

                .framer-rbiuz4 {
                    outline: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex-shrink: 0;
                    transform: none;
                }
            }
        }

        .framer-1uepckd {
            .framer-1m9zwry {
                image-rendering: pixelated;
                flex-shrink: 0;
                fill: rgba(0, 0, 0, 1);
                color: rgba(0, 0, 0, 1);
            }
        }
    }
}