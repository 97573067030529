body{
width: 100vw;
overflow-x: hidden;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.modal-content{
    background-color: rgba(5, 7, 29, 1);
    color: white;
    font-family: "Inter-Light", "Inter", sans-serif;
}

.modal-header{
    border-bottom: none;
}

.modal-footer{
    border-top: none;
}
.modal-body{
    margin: auto;
    text-align: center;
}

.modal-header  span{
    margin-left: 20px;
}