.framer-1oyfwvc {


    .ssr-variant,
    .hidden-p5w47l {

        .framer-1wu4mmx {
            transform: perspective(100vw) translateX(-50%) !important;
            opacity: 1;

            .framer-8pw6ho {
                image-rendering: pixelated;
                flex-shrink: 0;
                fill: black;
                color: black;


                .svgContainer {
                    width: 100%;
                    height: 100%;
                    aspect-ratio: inherit;

                    svg {
                        width: 100%;
                        height: 100%;
                    }

                }
            }

            .framer-1iy7ri3 {
                transform: translateX(-50%) !important;
            }
        }
    }

    .ssr-variant,
    .hidden-18ccrvo,
    .hidden-72rtr7 {
        .framer-1wu4mmx {
            @media only screen and (max-width: 809px) {

                opacity: 1;
                transform: perspective(100vw);
                transform: translateX(0%) !important;

                .framer-8pw6ho {
                    image-rendering: pixelated;
                    flex-shrink: 0;
                    fill: black;
                    color: black;

                    .svgContainer {
                        width: 100%;
                        height: 100%;
                        aspect-ratio: inherit;

                        svg {
                            @media only screen and (max-width: 600px) {
                                width: 80vw;
                            }
                        }
                    }
                }



                .framer-1iy7ri3 {
                    transform: translateX(0%) !important;
                }
            }

            @media only screen and (max-width: 600px) {
                width: 80vw;
                margin-left: 10vw;


                .framer-8pw6ho {
                    width: 80vw;

                    .svgContainer {
                        width: 80vw;
                        height: auto !important;

                        img {
                            width: 100%;
                        }
                    }
                }



                .framer-1iy7ri3 {
                    transform: translateX(0%) !important;
                }
            }



        }
    }

    .framer-cylkc8 {
        @media only screen and (max-width: 600px) {
            padding-top: 110vw !important;
        }

        .framer-hqsyw0 {
            @media only screen and (max-width: 600px) {
                padding-top: 0px !important;
            }

            .svgContainer {
                width: 100%;
                height: 100%;
                aspect-ratio: inherit;

                .left {
                    transform: rotate(180deg);
                }

                svg {
                    width: 100%;
                    height: 100%;

                }

                .right {
                    width: 100% !important;
                }

                @media only screen and (max-width: 809px) {

                    .right {
                        height: 10px;
                    }

                    .left {
                        height: 10px;
                    }
                }
            }

            .framer-g9rlpx {
                opacity: 1;
                transform: perspective(100vw) translateX(0px) translateY(8px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0);

                .framer-w1kx9j {
                    width: 100%;

                    .framer-1sgd7z5 {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: rgba(0, 0, 0, 1);
                        color: rgba(0, 0, 0, 1);

                        .svgContainer {
                            width: 100%;
                            height: 100%;
                            aspect-ratio: inherit;

                            .left {
                                transform: rotate(180deg);
                            }

                            svg {
                                width: 100%;
                                height: 100%;

                            }

                            .right {
                                width: 100% !important;
                            }

                            @media only screen and (max-width: 809px) {

                                .right {
                                    width: 100% !important;
                                    height: 10px;
                                    display: none;
                                }

                                .left {
                                    width: 100% !important;
                                    height: 10px;
                                }
                            }
                        }
                    }

                    .framer-18z5ztj {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        .framer-text {
                            --framer-font-size: 15px;
                            --framer-line-height: 35px;
                            --framer-text-alignment: center;

                            .framer-text {
                                --font-selector: SW50ZXItTGlnaHQ=;
                                --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                --framer-font-size: 15px;
                                --framer-font-weight: 300;
                                --framer-letter-spacing: 0.75px;
                                --framer-text-color: rgba(241, 245, 249, 1);
                                --framer-text-transform: uppercase;
                            }
                        }
                    }

                    .framer-ebbv65 {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: rgba(0, 0, 0, 1);
                        color: rgba(0, 0, 0, 1);

                        .svgContainer {
                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;



                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }

                .framer-1l5i75g {
                    .framer-l6sovz-container {

                        .ssr-variant,
                        .hidden-p5w47l,
                        .hidden-18ccrvo {
                            h1 {
                                background-image: url(https://framerusercontent.com/images/U6UbhIrFD2OnmiS03kViwM0Qb8.svg);
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: 0% 0%;
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                text-fill-color: transparent;
                                color: rgb(136, 136, 136);
                                font-size: 63px;
                                --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                text-align: center;
                                letter-spacing: -3.1px;
                                white-space: normal;
                                line-height: 1.1;
                                margin: 0;
                                padding: 3px
                            }
                        }

                        .ssr-variant,
                        .hidden-18ccrvo,
                        .hidden-72rtr7 {
                            h1 {
                                background-image: url(https://framerusercontent.com/images/U6UbhIrFD2OnmiS03kViwM0Qb8.svg);
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: 0% 0%;
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                text-fill-color: transparent;
                                color: rgb(136, 136, 136);

                                @media only screen and (max-width: 809px) {

                                    font-size: 38px;

                                }

                                font-size: 38px;
                                --framer-font-family: "Inter-Light",
                                "Inter",
                                sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                text-align: center;
                                letter-spacing: -3.1px;
                                white-space: normal;
                                line-height: 0.9;
                                margin: 0;
                                padding: 3px
                            }
                        }

                        .ssr-variant,
                        .hidden-p5w47l,
                        .hidden-72rtr7 {
                            h1 {
                                background-image: url(https://framerusercontent.com/images/U6UbhIrFD2OnmiS03kViwM0Qb8.svg);
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: 0% 0%;
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                text-fill-color: transparent;
                                color: rgb(136, 136, 136);

                                @media only screen and (max-width: 1439px) {

                                    font-size: 55px;

                                }

                                @media only screen and (max-width: 809px) {

                                    font-size: 38px;

                                }

                                font-size: 63px;
                                font-size: 55px;
                                --framer-font-family: "Inter-Light",
                                "Inter",
                                sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                text-align: center;
                                letter-spacing: -3.1px;
                                white-space: normal;
                                line-height: 1.1;
                                margin: 0;
                                padding: 3px
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-18ccrvo {
                        .framer-bte1da {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-186siln {
                                background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                                background-clip: text;
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-p5w47l,
                    .hidden-72rtr7 {
                        .framer-bte1da {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-186siln {
                                background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                                background-clip: text;
                            }
                        }
                    }

                    .framer-y562of {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none
                    }
                }
            }
        }

    }

    .framer-yvjwy2 {
        opacity: 1;
        transform: perspective(100vw) translateX(0px) translateY(8px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0);

        .framer-1sfg8nx {
            .framer-zt7lmv {
                image-rendering: pixelated;
                flex-shrink: 0;
                fill: rgba(0, 0, 0, 1);
                color: rgba(0, 0, 0, 1);

                .svgContainer {
                    width: 100%;
                    height: 100%;
                    aspect-ratio: inherit;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .framer-ddvqi8 {
                .framer-14vj6ad {
                    outline: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex-shrink: 0;
                    transform: none;

                    .framer-text,
                    .framer-styles-preset-12s9psp {
                        span {
                            background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);

                        }
                    }
                }

                .framer-12jripk {
                    outline: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex-shrink: 0;
                    transform: none
                }
            }
        }

        .framer-1ou3qdj {
            .framer-1bhx7l4 {
                image-rendering: pixelated;
                flex-shrink: 0;
                fill: rgba(0, 0, 0, 1);
                color: rgba(0, 0, 0, 1);

                .svgContainer {
                    width: 100%;
                    height: 100%;
                    aspect-ratio: inherit;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .framer-9cl8q0 {
                .framer-1cse84g {
                    outline: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex-shrink: 0;
                    transform: none;

                    .framer-text,
                    .framer-styles-preset-12s9psp {
                        background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                        background-clip: text;
                    }
                }

                .framer-1wdyiwz {
                    outline: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex-shrink: 0;
                    transform: none
                }
            }
        }

        .framer-17amp86 {
            .framer-1xm5n0 {
                image-rendering: pixelated;
                flex-shrink: 0;
                fill: rgba(0, 0, 0, 1);
                color: rgba(0, 0, 0, 1);

                .svgContainer {
                    width: 100%;
                    height: 100%;
                    aspect-ratio: inherit;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .framer-rgjd9e {
                .framer-a3fea7 {

                    outline: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex-shrink: 0;
                    transform: none;

                    .framer-text,
                    .framer-styles-preset-12s9psp {
                        background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                        background-clip: text;
                    }
                }

                .framer-lr9764 {
                    outline: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex-shrink: 0;
                    transform: none
                }
            }
        }
    }
}