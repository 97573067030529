.framer-1rxdd4g {
    .framer-13rx7v7 {
        .svgContainer {
            width: 100%;
            height: 100%;
            aspect-ratio: inherit;

            svg {
                width: 100%;
                height: 100%
            }
        }

        .framer-pf7ty7 {
            .framer-1wd6miv {
                opacity: 1;
                transform: perspective(100vw);

                .framer-1fkqlne {
                    .framer-18va552 {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: rgba(0, 0, 0, 1);
                        color: rgba(0, 0, 0, 1)
                    }

                    .framer-144i9i1 {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        .framer-text {
                            --font-selector: SW50ZXItTGlnaHQ=;
                            --framer-font-family: "Inter-Light", "Inter", sans-serif;
                            --framer-font-size: 15px;
                            --framer-font-weight: 300;
                            --framer-letter-spacing: 0.75px;
                            --framer-line-height: 35px;
                            --framer-text-alignment: center;
                            --framer-text-color: rgb(241, 245, 249);
                            --framer-text-transform: uppercase
                        }
                    }

                    .framer-d5wszl {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: rgba(0, 0, 0, 1);
                        color: rgba(0, 0, 0, 1)
                    }
                }


                .framer-b3qr2b {
                    .framer-81d4qq-container {

                        .ssr-variant,
                        .hidden-1t38e76,
                        .hidden-4fkeid {

                            h1 {
                                background-image: url(https://framerusercontent.com/images/bTF3xblyn2uyR2rZCo89tUf9mVE.svg);
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: 0% 0%;
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                text-fill-color: transparent;
                                color: rgb(136, 136, 136);
                                font-size: 63px;
                                --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                text-align: center;
                                letter-spacing: -3.1px;
                                white-space: normal;
                                line-height: 1.1;
                                margin: 0;
                                padding: 3px
                            }
                        }

                        .ssr-variant,
                        .hidden-4fkeid,
                        .hidden-1fdb8e9 {
                            h1 {
                                @media only screen and (max-width: 809px) {
                                    background-image: url(https://framerusercontent.com/images/bTF3xblyn2uyR2rZCo89tUf9mVE.svg);
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    background-position: 0% 0%;
                                    background-clip: text;
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    text-fill-color: transparent;
                                    color: rgb(136, 136, 136);
                                    font-size: 38px !important;
                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                    font-style: normal;
                                    font-weight: 600;
                                    text-align: center;
                                    letter-spacing: -3.1px;
                                    white-space: normal;
                                    line-height: 1.1;
                                    margin: 0;
                                    padding: 3px
                                }
                            }
                        }

                        .ssr-variant,
                        .hidden-1t38e76,
                        .hidden-1fdb8e9 {
                            h1 {
                                @media only screen and (max-width: 1439px) {

                                    background-image: url(https://framerusercontent.com/images/bTF3xblyn2uyR2rZCo89tUf9mVE.svg);
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    background-position: 0% 0%;
                                    background-clip: text;
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    text-fill-color: transparent;
                                    color: rgb(136, 136, 136);
                                    font-size: 55px;
                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                    font-style: normal;
                                    font-weight: 600;
                                    text-align: center;
                                    letter-spacing: -3.1px;
                                    white-space: normal;
                                    line-height: 1.1;
                                    margin: 0;
                                    padding: 3px
                                }
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-4fkeid {
                        .framer-24a6rx {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-186siln {

                                .framer-text {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                }
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-1t38e76,
                    .hidden-1fdb8e9 {
                        .framer-24a6rx {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-186siln {

                                .framer-text {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                }
                            }
                        }
                    }
                }

                .framer-tqod9x {
                    outline: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex-shrink: 0;
                    transform: none
                }

            }

            .framer-ypdnqf {
                opacity: 1;
                transform: perspective(100vw);


                .framer-9s1iyu {
                    .framer-innttb {
                        .framer-1d01eib {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: black;
                            color: black;
                        }



                        .framer-1hpkppv {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-12s9psp {
                                .framer-text {
                                    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgb(255, 255, 255) 100%)
                                }
                            }
                        }

                        .framer-18hzsxf {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none
                        }

                    }

                    .framer-8vd0n0,
                    .hidden-4fkeid,
                    .hidden-1t38e76 {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: rgba(0, 0, 0, 1);
                        color: rgba(0, 0, 0, 1)
                    }

                    .framer-dju9og {
                        .framer-1awvzgu {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: black;
                            color: black;
                        }

                        .framer-3fp08x {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-12s9psp {
                                .framer-text {
                                    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgb(255, 255, 255) 100%)
                                }
                            }
                        }

                        .framer-klceqd {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none
                        }
                    }

                    .framer-e7q26m,
                    .hidden-4fkeid,
                    .hidden-1t38e76 {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: rgba(0, 0, 0, 1);
                        color: rgba(0, 0, 0, 1)
                    }

                    .framer-5jto6g {
                        .framer-1pk2klm {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: black;
                            color: black;
                        }

                        .framer-jfusm0 {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-12s9psp {
                                .framer-text {
                                    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, rgb(255, 255, 255) 100%)
                                }
                            }
                        }

                        .framer-3n8fwm {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none
                        }
                    }
                }
            }
        }
    }
}