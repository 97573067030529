.framer-1r97wfd {
    .framer-12no29k {
        .framer-1urlfsk {
            .framer-jzz0h2 {

                z-index: 1;

                .framer-12yh7j2 {
                    div {
                        position: absolute;
                        border-radius: inherit;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-position: center;
                            object-fit: cover;
                            image-rendering: auto;
                        }
                    }
                }
            }

            .framer-dzdjvj {
                .framer-mrehk0 {

                    .framer-1ho5ydl,
                    .hidden-18ccrvo,
                    .hidden-p5w47l {
                        .framer-mbx9bf {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text {
                                --framer-font-size: 15px;
                                --framer-line-height: 35px;
                                --framer-text-alignment: center;

                                .framer-text {
                                    --font-selector: SW50ZXItTGlnaHQ=;
                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                    --framer-font-size: 15px;
                                    --framer-font-weight: 300;
                                    --framer-letter-spacing: 0.75px;
                                    --framer-text-color: rgba(241, 245, 249, 1);
                                    --framer-text-transform: uppercase
                                }
                            }
                        }

                        .framer-kqtwxw {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1);


                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }

                    }

                    .framer-1a1pvl9 {
                        .framer-10opef8 {
                            .framer-1gpy6tb-container {

                                .framer-text,
                                .framer-styles-preset-186siln {
                                    .framer-text {
                                        background-image: url(https://framerusercontent.com/images/bTF3xblyn2uyR2rZCo89tUf9mVE.svg);
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        background-position: 0% 0%;
                                        background-clip: text;
                                    }
                                }
                            }

                            .framer-2taaz7 {
                                outline: none;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                flex-shrink: 0;
                                transform: none;

                                .framer-text,
                                .framer-styles-preset-186siln {
                                    .framer-text {
                                        background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                    }
                                }
                            }
                        }

                        .framer-1yz1r9y {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-186siln {
                                .framer-text {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                }
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-p5w47l,
                    .hidden-18ccrvo {
                        .framer-1vjfh25 {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-1wzfti9 {
                                --framer-text-alignment: left
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-18ccrvo,
                    .hidden-72rtr7 {
                        .framer-1vjfh25 {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-1wzfti9 {
                                --framer-text-alignment: center
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-p5w47l,
                    .hidden-72rtr7 {
                        .framer-1vjfh25 {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-1wzfti9 {
                                --framer-text-alignment: center
                            }
                        }
                    }

                    .framer-1huo939,
                    .hidden-72rtr7 {
                        .framer-1l6d1of {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1);

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }

                        .framer-d174w6 {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text {
                                --font-selector: SW50ZXItTGlnaHQ=;
                                --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                --framer-font-size: 15px;
                                --framer-font-weight: 300;
                                --framer-letter-spacing: 0.75px;
                                --framer-line-height: 35px;
                                --framer-text-alignment: center;
                                --framer-text-color: rgb(241, 245, 249);
                                --framer-text-transform: uppercase
                            }
                        }

                        .framer-6md674 {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1);

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }

                .framer-1tehynk {
                    .framer-ulnt9v {
                        .framer-1n8goct {
                            .framer-1uj7sjp {
                                image-rendering: pixelated;
                                flex-shrink: 0;
                                fill: rgba(0, 0, 0, 1);
                                color: rgba(0, 0, 0, 1);

                                .svgContainer {
                                    width: 100%;
                                    height: 100%;
                                    aspect-ratio: inherit;

                                    svg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }

                            .ssr-variant,
                            .hidden-p5w47l {
                                outline: none;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                flex-shrink: 0;
                                transform: none;

                                .framer-text,
                                .framer-styles-preset-1wzfti9 {
                                    --framer-text-alignment: left
                                }
                            }

                            .ssr-variant,
                            .hidden-18ccrvo,
                            .hidden-72rtr7 {
                                outline: none;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                flex-shrink: 0;
                                transform: none;

                                @media only screen and (max-width: 1439px) {

                                    .framer-text,
                                    .framer-styles-preset-1wzfti9 {
                                        --framer-text-alignment: center
                                    }

                                }

                            }
                        }

                        .framer-1hincbq,
                        .hidden-p5w47l {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1);

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }

                    .framer-r1c62u {
                        .framer-1udceag {
                            .framer-konhgg {
                                image-rendering: pixelated;
                                flex-shrink: 0;
                                fill: rgba(0, 0, 0, 1);
                                color: rgba(0, 0, 0, 1);

                                .svgContainer {
                                    width: 100%;
                                    height: 100%;
                                    aspect-ratio: inherit;

                                    svg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }

                            .ssr-variant,
                            .hidden-p5w47l {
                                .framer-1vhm7oi {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text,
                                    .framer-styles-preset-1wzfti9 {
                                        --framer-text-alignment: left
                                    }
                                }
                            }

                            .ssr-variant,
                            .hidden-18ccrvo,
                            .hidden-72rtr7 {
                                .framer-1vhm7oi {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    @media only screen and (max-width: 1439px) {

                                        .framer-text,
                                        .framer-styles-preset-1wzfti9 {
                                            --framer-text-alignment: center
                                        }

                                    }

                                }
                            }
                        }

                        .framer-tk0wzh,
                        .hidden-p5w47l {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1);

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }

                    .framer-1ve8ypk {
                        .framer-8okrui {
                            .framer-1i05iuo {
                                image-rendering: pixelated;
                                flex-shrink: 0;
                                fill: rgba(0, 0, 0, 1);
                                color: rgba(0, 0, 0, 1);

                                .svgContainer {
                                    width: 100%;
                                    height: 100%;
                                    aspect-ratio: inherit;

                                    svg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }

                            .ssr-variant,
                            .hidden-p5w47l {
                                .framer-vkyocj {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text,
                                    .framer-styles-preset-1wzfti9 {
                                        --framer-text-alignment: left
                                    }
                                }
                            }

                            .ssr-variant,
                            .hidden-18ccrvo,
                            .hidden-72rtr7 {
                                .framer-vkyocj {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    @media only screen and (max-width: 1439px) {

                                        .framer-text,
                                        .framer-styles-preset-1wzfti9 {
                                            --framer-text-alignment: center
                                        }

                                    }
                                }
                            }
                        }

                        .framer-13jjph3,
                        .hidden-p5w47l {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1);

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }


                }

                .framer-o9uaah {
                    .framer-3bj60h-container {

                        .framer-oyI4k,
                        .framer-v-693ydj {
                            display: contents;

                            .framer-693ydj,
                            .framer-mesh6j {
                                --border-bottom-width: 1.6861314px;
                                --border-color: rgba(255, 255, 255, 0.3);
                                --border-left-width: 1.6861314px;
                                --border-right-width: 1.6861314px;
                                --border-style: solid;
                                --border-top-width: 1.6861314px;
                                background: linear-gradient(180deg, rgba(117, 172, 255, 0) 0%, rgba(255, 255, 255, 0.12) 100%);
                                background-color: rgba(0, 0, 0, 0);
                                border-bottom-left-radius: 10px;
                                border-bottom-right-radius: 10px;
                                border-top-left-radius: 10px;
                                border-top-right-radius: 10px;

                                .framer-crfnzq {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    --extracted-r6o4lv: rgb(255, 255, 255);
                                    --framer-paragraph-spacing: 0px;
                                    transform: none;

                                    .framer-text {
                                        --font-selector: SW50ZXItU2VtaUJvbGQ=;
                                        --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                        --framer-font-size: 16.86px;
                                        --framer-font-weight: 600;
                                        --framer-letter-spacing: -0.42px;
                                        --framer-line-height: 100%;
                                        --framer-text-alignment: center;
                                        --framer-text-color: var(--extracted-r6o4lv)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}