.framer-11nc1m0 {
    .framer-3ssmbw {
        opacity: 1;
        transform: perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0);

        .framer-3mzl39 {
            .framer-7rez0 {
                image-rendering: pixelated;
                flex-shrink: 0;
                fill: rgba(0, 0, 0, 1);
                color: rgba(0, 0, 0, 1);

                .svgContainer {
                    width: 100%;
                    height: 100%;
                    aspect-ratio: inherit;

                    svg {
                        width: 100%;
                        height: 100%
                    }
                }
            }

            .framer-1g56jfn {
                outline: none;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex-shrink: 0;
                transform: none;

                .framer-text {
                    --framer-font-size: 15px;
                    --framer-line-height: 35px;
                    --framer-text-alignment: center;

                    .framer-text {
                        --font-selector: SW50ZXItTGlnaHQ=;
                        font-family: "Inter-Bold", "Inter", "Inter Placeholder", sans-serif;
                        --framer-font-size: 15px;
                        --framer-font-weight: 300;
                        --framer-letter-spacing: 0.75px;
                        --framer-text-color: rgba(241, 245, 249, 1);
                        --framer-text-transform: uppercase
                    }
                }
            }

            .framer-1kc87cu {
                image-rendering: pixelated;
                flex-shrink: 0;
                fill: rgba(0, 0, 0, 1);
                color: rgba(0, 0, 0, 1);

                .svgContainer {
                    width: 100%;
                    height: 100%;
                    aspect-ratio: inherit;

                    svg {
                        width: 100%;
                        height: 100%
                    }
                }

            }
        }

        .framer-1b3swhe {
            .framer-73f844-container {

                .ssr-variant,
                .hidden-p5w47l,
                .hidden-18ccrvo {
                    h1 {
                        background-image: url(https://framerusercontent.com/images/U6UbhIrFD2OnmiS03kViwM0Qb8.svg);
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: 0% 0%;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        text-fill-color: transparent;
                        color: rgb(136, 136, 136);
                        font-size: 63px;
                        font-family: "Inter-Bold", "Inter", "Inter Placeholder", sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        text-align: center;
                        letter-spacing: -3.1px;
                        white-space: normal;
                        line-height: 1.1;
                        margin: 0;
                        padding: 3px
                    }
                }

                .ssr-variant,
                .hidden-18ccrvo,
                .hidden-72rtr7 {
                    h1 {
                        background-image: url(https://framerusercontent.com/images/U6UbhIrFD2OnmiS03kViwM0Qb8.svg);
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: 0% 0%;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        text-fill-color: transparent;
                        color: rgb(136, 136, 136);
                        font-size: 38px;
                        font-family: "Inter-Bold", "Inter", "Inter Placeholder", sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        text-align: center;
                        letter-spacing: -1.8px;
                        white-space: normal;
                        line-height: 1.1;
                        margin: 0;
                        padding: 3px
                    }
                }

                .ssr-variant,
                .hidden-p5w47l,
                .hidden-72rtr7 {
                    h1 {
                        background-image: url(https://framerusercontent.com/images/U6UbhIrFD2OnmiS03kViwM0Qb8.svg);
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: 0% 0%;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        text-fill-color: transparent;
                        color: rgb(136, 136, 136);

                        @media only screen and (max-width: 1439px) {

                            font-size: 55px;

                        }

                        @media only screen and (max-width: 809px) {

                            font-size: 38px;

                        }

                        font-size: 63px;
                        font-family: "Inter-Bold",
                        "Inter",
                        "Inter Placeholder",
                        sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        text-align: center;
                        letter-spacing: -3.1px;
                        white-space: normal;
                        line-height: 1.1;
                        margin: 0;
                        padding: 3px
                    }
                }
            }

            .framer-1da989o {
                outline: none;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex-shrink: 0;
                transform: none;

                .framer-text,
                .framer-styles-preset-186siln {
                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                    background-clip: text;
                }
            }
        }

        .ssr-variant,
        .hidden-p5w47l {
            .framer-1m4ycra {
                outline: none;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex-shrink: 0;
                transform: none
            }
        }

        .ssr-variant,
        .hidden-18ccrvo,
        .hidden-72rtr7 {
            .ramer-1m4ycra {
                outline: none;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex-shrink: 0;
                transform: none
            }
        }
    }

    .framer-1aae7r3 {
        opacity: 1;
        transform: perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0);

        .framer-qsls2j {
            .framer-je95qz {
                .framer-49is85 {
                    .framer-1s3sbvi {
                        div {
                            position: absolute;
                            border-radius: inherit;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                border-radius: inherit;
                                object-position: center;
                                object-fit: cover;
                                image-rendering: auto
                            }
                        }
                    }
                }

                .framer-vpdmo1 {
                    .framer-lti0vz {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        .framer-text,
                        .framer-styles-preset-12s9psp {
                            background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                            background-clip: text;
                        }
                    }

                    .framer-14bkxj8 {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none
                    }
                }
            }

            .framer-smc5ba {
                .framer-vw6whp {
                    .framer-151rzvw {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        .framer-text,
                        .framer-styles-preset-12s9psp {
                            background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                            background-clip: text;
                        }
                    }

                    .framer-1712gxo {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none
                    }
                }

                .framer-1v1z9j {
                    .framer-nckmto {
                        div {
                            position: absolute;
                            border-radius: inherit;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                border-radius: inherit;
                                object-position: center;
                                object-fit: cover;
                                image-rendering: auto
                            }
                        }
                    }
                }
            }

            .framer-1lfr6ou {
                .framer-1n5y460 {
                    .framer-bpufrc {

                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: nones;

                        .ramer-text,
                        .framer-styles-preset-12s9psp {
                            span {
                                background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                                background-clip: text;
                            }
                        }
                    }

                    .framer-bjbc8i {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none
                    }
                }

                .framer-12n1cz {
                    .framer-ad2f06 {
                        div {
                            position: absolute;
                            border-radius: inherit;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                border-radius: inherit;
                                object-position: center;
                                object-fit: cover;
                                image-rendering: auto
                            }
                        }
                    }
                }
            }
        }

        .framer-o74vfa {
            .framer-6wkfww {
                .framer-866dgb {
                    .framer-vjylu8 {
                        div {
                            position: absolute;
                            border-radius: inherit;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                border-radius: inherit;
                                object-position: center;
                                object-fit: cover;
                                image-rendering: auto
                            }
                        }
                    }
                }

                .framer-1lrlkmo {
                    .framer-r5tvq6 {
                        .framer-jhmtls {
                            .framer-1yunemf {
                                image-rendering: pixelated;
                                flex-shrink: 0;
                                fill: rgba(0, 0, 0, 1);
                                color: rgba(0, 0, 0, 1);

                                .svgContainer {
                                    width: 100%;
                                    height: 100%;
                                    aspect-ratio: inherit;

                                    svg {
                                        width: 100%;
                                        height: 100%
                                    }
                                }
                            }

                            .ssr-variant,
                            .hidden-p5w47l {
                                .framer-1du20j6 {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text {
                                        --framer-font-size: 17px;
                                        --framer-letter-spacing: -0.34px;
                                        --framer-line-height: 35px;
                                        --framer-text-alignment: center;
                                        --framer-text-color: rgb(241, 245, 249)
                                    }
                                }
                            }

                            .ssr-variant,
                            .hidden-18ccrvo,
                            .hidden-72rtr7 {
                                .framer-1du20j6 {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text {
                                        --framer-font-size: 14px;
                                        --framer-letter-spacing: -0.34px;
                                        --framer-line-height: 35px;
                                        --framer-text-alignment: center;
                                        --framer-text-color: rgb(241, 245, 249)
                                    }
                                }
                            }
                        }

                        .ssr-variant,
                        .hidden-p5w47l,
                        .hidden-18ccrvo {
                            .framer-1n3q0o {
                                outline: none;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                flex-shrink: 0;
                                transform: none;

                                .ramer-text {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                                    background-clip: text;
                                }
                            }
                        }

                        .ssr-variant,
                        .hidden-18ccrvo,
                        .hidden-72rtr7 {
                            .framer-1n3q0o {
                                outline: none;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                flex-shrink: 0;
                                transform: none;

                                .framer-text,
                                .framer-styles-preset-12s9psp {
                                    --framer-text-alignment: center;

                                    .framer-text {
                                        background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                                        background-clip: text;
                                    }
                                }
                            }
                        }




                        .ssr-variant,
                        .hidden-p5w47l,
                        .hidden-72rtr7 {
                            .framer-1n3q0o {
                                outline: none;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                flex-shrink: 0;
                                transform: none;

                                .framer-text,
                                .framer-styles-preset-12s9psp {
                                    --framer-text-alignment: center;

                                    .framer-text {
                                        background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                                        background-clip: text;
                                    }
                                }
                            }
                        }

                        .ssr-variant,
                        .hidden-p5w47l,
                        .hidden-18ccrvo {
                            .framer-16w7l19 {
                                outline: none;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                flex-shrink: 0;
                                transform: none;

                                .framer-text,
                                .framer-styles-preset-138ee5y {
                                    --framer-text-alignment: left
                                }
                            }
                        }

                        .ssr-variant,
                        .hidden-18ccrvo,
                        .hidden-72rtr7 {
                            .framer-16w7l19 {
                                outline: none;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                flex-shrink: 0;
                                transform: none;

                                .framer-text,
                                .framer-styles-preset-138ee5y {
                                    --framer-text-alignment: center;
                                }
                            }
                        }

                        .ssr-variant,
                        .hidden-p5w47l,
                        .hidden-72rtr7 {
                            .framer-16w7l19 {
                                outline: none;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                flex-shrink: 0;
                                transform: none;

                                .framer-text,
                                .framer-styles-preset-138ee5y {
                                    --framer-text-alignment: center;
                                }
                            }
                        }

                        .framer-25d02p-container {
                            display: contents;

                            .framer-693ydj,
                            .framer-mesh6j {
                                --border-bottom-width: 1.6861314px;
                                --border-color: rgba(255, 255, 255, 0.3);
                                --border-left-width: 1.6861314px;
                                --border-right-width: 1.6861314px;
                                --border-style: solid;
                                --border-top-width: 1.6861314px;
                                background: linear-gradient(180deg, rgba(117, 172, 255, 0) 0%, rgba(255, 255, 255, 0.12) 100%);
                                background-color: rgba(0, 0, 0, 0);
                                border-bottom-left-radius: 10px;
                                border-bottom-right-radius: 10px;
                                border-top-left-radius: 10px;
                                border-top-right-radius: 10px;

                                .framer-crfnzq {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    --extracted-r6o4lv: rgb(255, 255, 255);
                                    --framer-paragraph-spacing: 0px;
                                    transform: none;

                                    .framer-text {
                                        --font-selector: SW50ZXItU2VtaUJvbGQ=;
                                        font-family: "Inter-Bold", "Inter", "Inter Placeholder", sans-serif;
                                        --framer-font-size: 16.86px;
                                        --framer-font-weight: 600;
                                        --framer-letter-spacing: -0.42px;
                                        --framer-line-height: 100%;
                                        --framer-text-alignment: center;
                                        --framer-text-color: var(--extracted-r6o4lv)
                                    }
                                }
                            }
                        }
                    }

                    .framer-1rfv8ym {}
                }
            }

            .framer-1rfv8ym {
                .framer-ws2afn {
                    div {
                        position: absolute;
                        border-radius: inherit;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-position: center;
                            object-fit: cover;
                            image-rendering: auto
                        }
                    }
                }

                .framer-1j0lv20 {
                    .framer-gsr5x1 {

                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        .framer-text,
                        .framer-styles-preset-12s9psp {
                            background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                            background-clip: text;
                        }
                    }

                    .framer-15ch4kt {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;
                    }
                }
            }

        }
    }
}