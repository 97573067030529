        html,
        body,
        #main {
            margin: 0;
            padding: 0;
            box-sizing: border-box
        }

        :root {
            -webkit-font-smoothing: antialiased
        }

        * {
            box-sizing: border-box;
            -webkit-font-smoothing: inherit
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        figure {
            margin: 0
        }

        body,
        input,
        textarea,
        select,
        button {
            font-size: 12px;
            font-family: sans-serif
        }

        body {
            --token-c93c3154-b582-4db9-bd10-52e824fd414c: #020718;
            --token-fed421e9-3f6f-4d58-bb1b-a95deadf172a: #FFFFFF;
            --token-0bc9d5e2-cabe-4eb2-b691-d577f23a764a: rgb(0, 82, 204);
            --token-f9b89b17-0991-4c0e-b401-ca53a64a9c7b: #02041D
        }

        #__framer-badge-container {
            position: fixed;
            bottom: 0;
            padding: 20px;
            width: 100%;
            display: flex;
            justify-content: right;
            pointer-events: none;
            z-index: 314159
        }

        .__framer-badge {
            pointer-events: auto
        }

        @font-face {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-display: block;
            src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZJhiI2B.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-display: block;
            src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZthiI2B.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-display: block;
            src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZNhiI2B.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-display: block;
            src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZxhiI2B.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-display: block;
            src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZBhiI2B.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-display: block;
            src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZFhiI2B.woff2) format("woff2");
            unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-display: block;
            src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZ9hiA.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-family: Inter Placeholder;
            ascent-override: 90%;
            descent-override: 22.43%;
            line-gap-override: 0%;
            size-adjust: 107.64%;
            src: local("Arial")
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 100;
            src: url(https://app.framerstatic.com/Inter-Thin.cyrillic-ext-BHDA65O4.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 100;
            src: url(https://app.framerstatic.com/Inter-Thin.cyrillic-Q5IXHU2O.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 100;
            src: url(https://app.framerstatic.com/Inter-Thin.greek-ext-VVOQA2NE.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 100;
            src: url(https://app.framerstatic.com/Inter-Thin.greek-E3WNEUH6.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 100;
            src: url(https://app.framerstatic.com/Inter-Thin.latin-ext-5RC4NRHN.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 100;
            src: url(https://app.framerstatic.com/Inter-Thin.latin-GJ7CRGHG.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 100;
            src: url(https://app.framerstatic.com/Inter-Thin.vietnamese-Z3Y7DPWO.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 200;
            src: url(https://app.framerstatic.com/Inter-ExtraLight.cyrillic-ext-7NWKXNAT.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 200;
            src: url(https://app.framerstatic.com/Inter-ExtraLight.cyrillic-IBWUD6RJ.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 200;
            src: url(https://app.framerstatic.com/Inter-ExtraLight.greek-ext-HUMDTRBU.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 200;
            src: url(https://app.framerstatic.com/Inter-ExtraLight.greek-ZLLHEEN3.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 200;
            src: url(https://app.framerstatic.com/Inter-ExtraLight.latin-ext-72JE5FGU.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 200;
            src: url(https://app.framerstatic.com/Inter-ExtraLight.latin-2JHDAFAQ.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 200;
            src: url(https://app.framerstatic.com/Inter-ExtraLight.vietnamese-VKRCA4VC.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 300;
            src: url(https://app.framerstatic.com/Inter-Light.cyrillic-ext-G3OTPKE4.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 300;
            src: url(https://app.framerstatic.com/Inter-Light.cyrillic-JO7ZJTP6.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 300;
            src: url(https://app.framerstatic.com/Inter-Light.greek-ext-N63XCCK3.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 300;
            src: url(https://app.framerstatic.com/Inter-Light.greek-5GVUXSXZ.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 300;
            src: url(https://app.framerstatic.com/Inter-Light.latin-ext-RREJIMQ3.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 300;
            src: url(https://app.framerstatic.com/Inter-Light.latin-EKF76FXR.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 300;
            src: url(https://app.framerstatic.com/Inter-Light.vietnamese-GVC2UOFS.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            src: url(https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            src: url(https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            src: url(https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            src: url(https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            src: url(https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            src: url(https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            src: url(https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            src: url(https://app.framerstatic.com/Inter-Medium.cyrillic-ext-M4WHNGTS.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            src: url(https://app.framerstatic.com/Inter-Medium.cyrillic-JVU2PANX.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            src: url(https://app.framerstatic.com/Inter-Medium.greek-ext-4KCQBEIZ.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            src: url(https://app.framerstatic.com/Inter-Medium.greek-DPOQGN7L.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            src: url(https://app.framerstatic.com/Inter-Medium.latin-ext-J4DBSW7F.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            src: url(https://app.framerstatic.com/Inter-Medium.latin-Y3IVPL46.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            src: url(https://app.framerstatic.com/Inter-Medium.vietnamese-PJV76O4P.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            src: url(https://app.framerstatic.com/Inter-SemiBold.cyrillic-ext-C7KWUKA7.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            src: url(https://app.framerstatic.com/Inter-SemiBold.cyrillic-JWV7SOZ6.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            src: url(https://app.framerstatic.com/Inter-SemiBold.greek-ext-FBKSFTSU.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            src: url(https://app.framerstatic.com/Inter-SemiBold.greek-EQ3PSENU.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            src: url(https://app.framerstatic.com/Inter-SemiBold.latin-ext-ULRSO3ZR.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            src: url(https://app.framerstatic.com/Inter-SemiBold.latin-RDYY2AG2.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            src: url(https://app.framerstatic.com/Inter-SemiBold.vietnamese-ESQNSEQ3.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: normal;
            font-weight: 700;
            src: url(https://app.framerstatic.com/Inter-Bold.cyrillic-ext-XOTVL7ZR.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: normal;
            font-weight: 700;
            src: url(https://app.framerstatic.com/Inter-Bold.cyrillic-6LOMBC2V.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: normal;
            font-weight: 700;
            src: url(https://app.framerstatic.com/Inter-Bold.greek-ext-WXWSJXLB.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: normal;
            font-weight: 700;
            src: url(https://app.framerstatic.com/Inter-Bold.greek-YRST7ODZ.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: normal;
            font-weight: 700;
            src: url(https://app.framerstatic.com/Inter-Bold.latin-ext-BASA5UL3.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: normal;
            font-weight: 700;
            src: url(https://app.framerstatic.com/Inter-Bold.latin-UCM45LQF.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: normal;
            font-weight: 700;
            src: url(https://app.framerstatic.com/Inter-Bold.vietnamese-OEVJMXEP.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 800;
            src: url(https://app.framerstatic.com/Inter-ExtraBold.cyrillic-ext-7Q6SVIPE.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 800;
            src: url(https://app.framerstatic.com/Inter-ExtraBold.cyrillic-JSLPE6KW.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 800;
            src: url(https://app.framerstatic.com/Inter-ExtraBold.greek-ext-6OYGJJV7.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 800;
            src: url(https://app.framerstatic.com/Inter-ExtraBold.greek-SHW2FPC4.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 800;
            src: url(https://app.framerstatic.com/Inter-ExtraBold.latin-ext-A5DUFOP6.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 800;
            src: url(https://app.framerstatic.com/Inter-ExtraBold.latin-OW4UGSRU.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 800;
            src: url(https://app.framerstatic.com/Inter-ExtraBold.vietnamese-IBBC7NGV.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 900;
            src: url(https://app.framerstatic.com/Inter-Black.cyrillic-ext-TU4ITVTR.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 900;
            src: url(https://app.framerstatic.com/Inter-Black.cyrillic-JX7CGTYD.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 900;
            src: url(https://app.framerstatic.com/Inter-Black.greek-ext-LS3GCBFI.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 900;
            src: url(https://app.framerstatic.com/Inter-Black.greek-ZWCJHBP5.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 900;
            src: url(https://app.framerstatic.com/Inter-Black.latin-ext-BZLEUMX6.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 900;
            src: url(https://app.framerstatic.com/Inter-Black.latin-TETRYDF7.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: normal;
            font-weight: 900;
            src: url(https://app.framerstatic.com/Inter-Black.vietnamese-RXQCC3EJ.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 100;
            src: url(https://app.framerstatic.com/Inter-ThinItalic.cyrillic-ext-2RGKWUBV.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 100;
            src: url(https://app.framerstatic.com/Inter-ThinItalic.cyrillic-TDYIP5HV.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 100;
            src: url(https://app.framerstatic.com/Inter-ThinItalic.greek-ext-WR4TIDYZ.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 100;
            src: url(https://app.framerstatic.com/Inter-ThinItalic.greek-V3WZMSP7.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 100;
            src: url(https://app.framerstatic.com/Inter-ThinItalic.latin-ext-TXFTJONQ.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 100;
            src: url(https://app.framerstatic.com/Inter-ThinItalic.latin-RVEBKP6O.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 100;
            src: url(https://app.framerstatic.com/Inter-ThinItalic.vietnamese-WCBPP4MD.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 200;
            src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.cyrillic-ext-OVCHMVPD.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 200;
            src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.cyrillic-BRDZE5UH.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 200;
            src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.greek-ext-YV64YFFH.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 200;
            src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.greek-EJVCLASM.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 200;
            src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.latin-ext-2MHTM56A.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 200;
            src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.latin-JB3CJMMM.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 200;
            src: url(https://app.framerstatic.com/Inter-ExtraLightItalic.vietnamese-3EJ3IQYS.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 300;
            src: url(https://app.framerstatic.com/Inter-LightItalic.cyrillic-ext-C2S5XS3D.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 300;
            src: url(https://app.framerstatic.com/Inter-LightItalic.cyrillic-E7CYPW5D.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 300;
            src: url(https://app.framerstatic.com/Inter-LightItalic.greek-ext-ROSAFPGE.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 300;
            src: url(https://app.framerstatic.com/Inter-LightItalic.greek-PAWWH37Z.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 300;
            src: url(https://app.framerstatic.com/Inter-LightItalic.latin-ext-N2Z67Z45.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 300;
            src: url(https://app.framerstatic.com/Inter-LightItalic.latin-SLSTLWEU.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 300;
            src: url(https://app.framerstatic.com/Inter-LightItalic.vietnamese-RLGM2D3Y.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: italic;
            font-weight: 400;
            src: url(https://app.framerstatic.com/Inter-Italic.cyrillic-ext-YDGMJOJO.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: italic;
            font-weight: 400;
            src: url(https://app.framerstatic.com/Inter-Italic.cyrillic-BFOVMAQB.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: italic;
            font-weight: 400;
            src: url(https://app.framerstatic.com/Inter-Italic.greek-ext-4KOU3AHC.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: italic;
            font-weight: 400;
            src: url(https://app.framerstatic.com/Inter-Italic.greek-OJTBJNE6.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: italic;
            font-weight: 400;
            src: url(https://app.framerstatic.com/Inter-Italic.latin-ext-H4B22QN6.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: italic;
            font-weight: 400;
            src: url(https://app.framerstatic.com/Inter-Italic.latin-2DWX32EN.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: italic;
            font-weight: 400;
            src: url(https://app.framerstatic.com/Inter-Italic.vietnamese-TYMT6CKW.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 500;
            src: url(https://app.framerstatic.com/Inter-MediumItalic.cyrillic-ext-QYBZQ2NF.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 500;
            src: url(https://app.framerstatic.com/Inter-MediumItalic.cyrillic-ZHAJHZCC.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 500;
            src: url(https://app.framerstatic.com/Inter-MediumItalic.greek-ext-W5ABYGZR.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 500;
            src: url(https://app.framerstatic.com/Inter-MediumItalic.greek-RGNSYVNV.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 500;
            src: url(https://app.framerstatic.com/Inter-MediumItalic.latin-ext-7DZEPSAS.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 500;
            src: url(https://app.framerstatic.com/Inter-MediumItalic.latin-SKPQAMBJ.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 500;
            src: url(https://app.framerstatic.com/Inter-MediumItalic.vietnamese-23WIFZV7.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 600;
            src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.cyrillic-ext-MEHHCDC3.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 600;
            src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.cyrillic-YACNRNDE.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 600;
            src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.greek-ext-GFL7KADI.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 600;
            src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.greek-5W77OPRT.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 600;
            src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.latin-ext-OYJJ2W6R.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 600;
            src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.latin-KBLJMBDH.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 600;
            src: url(https://app.framerstatic.com/Inter-SemiBoldItalic.vietnamese-5ZFOV65G.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: italic;
            font-weight: 700;
            src: url(https://app.framerstatic.com/Inter-BoldItalic.cyrillic-ext-PEYDHC3S.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: italic;
            font-weight: 700;
            src: url(https://app.framerstatic.com/Inter-BoldItalic.cyrillic-7EIL6JWG.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: italic;
            font-weight: 700;
            src: url(https://app.framerstatic.com/Inter-BoldItalic.greek-ext-3DJOYQMH.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: italic;
            font-weight: 700;
            src: url(https://app.framerstatic.com/Inter-BoldItalic.greek-TJBTLTT7.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: italic;
            font-weight: 700;
            src: url(https://app.framerstatic.com/Inter-BoldItalic.latin-ext-FVPCPRBJ.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: italic;
            font-weight: 700;
            src: url(https://app.framerstatic.com/Inter-BoldItalic.latin-5ZFQS4XK.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: swap;
            font-family: Inter;
            font-style: italic;
            font-weight: 700;
            src: url(https://app.framerstatic.com/Inter-BoldItalic.vietnamese-W2625PGF.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 800;
            src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.cyrillic-ext-ACWDZ3VD.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 800;
            src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.cyrillic-ZKBSDAI2.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 800;
            src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.greek-ext-3CY5DPTP.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 800;
            src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.greek-YL5CC63W.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 800;
            src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.latin-ext-7IZFJI4D.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 800;
            src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.latin-O5HH4IX3.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 800;
            src: url(https://app.framerstatic.com/Inter-ExtraBoldItalic.vietnamese-UW3XUJOD.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 900;
            src: url(https://app.framerstatic.com/Inter-BlackItalic.cyrillic-ext-TRM4ITYR.woff2) format("woff2");
            unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 900;
            src: url(https://app.framerstatic.com/Inter-BlackItalic.cyrillic-FPHIQVZS.woff2) format("woff2");
            unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 900;
            src: url(https://app.framerstatic.com/Inter-BlackItalic.greek-ext-JTGUUSP5.woff2) format("woff2");
            unicode-range: U+1F00-1FFF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 900;
            src: url(https://app.framerstatic.com/Inter-BlackItalic.greek-LUNA3RFO.woff2) format("woff2");
            unicode-range: U+0370-03FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 900;
            src: url(https://app.framerstatic.com/Inter-BlackItalic.latin-ext-KU7ICFYH.woff2) format("woff2");
            unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 900;
            src: url(https://app.framerstatic.com/Inter-BlackItalic.latin-FRVXWQSB.woff2) format("woff2");
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
        }

        @font-face {
            font-display: block;
            font-family: Inter;
            font-style: italic;
            font-weight: 900;
            src: url(https://app.framerstatic.com/Inter-BlackItalic.vietnamese-2Q7MQKJX.woff2) format("woff2");
            unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
        }

        [data-framer-component-type] {
            position: absolute
        }

        [data-framer-component-type=Text] {
            cursor: inherit
        }

        [data-framer-component-text-autosized] * {
            white-space: pre
        }

        [data-framer-component-type=Text]>* {
            text-align: var(--framer-text-alignment, start)
        }

        [data-framer-component-type=Text] span span,
        [data-framer-component-type=Text] p span,
        [data-framer-component-type=Text] h1 span,
        [data-framer-component-type=Text] h2 span,
        [data-framer-component-type=Text] h3 span,
        [data-framer-component-type=Text] h4 span,
        [data-framer-component-type=Text] h5 span,
        [data-framer-component-type=Text] h6 span {
            display: block
        }

        [data-framer-component-type=Text] span span span,
        [data-framer-component-type=Text] p span span,
        [data-framer-component-type=Text] h1 span span,
        [data-framer-component-type=Text] h2 span span,
        [data-framer-component-type=Text] h3 span span,
        [data-framer-component-type=Text] h4 span span,
        [data-framer-component-type=Text] h5 span span,
        [data-framer-component-type=Text] h6 span span {
            display: unset
        }

        [data-framer-component-type=Text] div div span,
        [data-framer-component-type=Text] a div span,
        [data-framer-component-type=Text] span span span,
        [data-framer-component-type=Text] p span span,
        [data-framer-component-type=Text] h1 span span,
        [data-framer-component-type=Text] h2 span span,
        [data-framer-component-type=Text] h3 span span,
        [data-framer-component-type=Text] h4 span span,
        [data-framer-component-type=Text] h5 span span,
        [data-framer-component-type=Text] h6 span span,
        [data-framer-component-type=Text] a {
            font-family: var(--font-family);
            font-style: var(--font-style);
            font-weight: min(calc(var(--framer-font-weight-increase, 0) + var(--font-weight, 400)), 900);
            color: var(--text-color);
            letter-spacing: var(--letter-spacing);
            font-size: var(--font-size);
            text-transform: var(--text-transform);
            text-decoration: var(--text-decoration);
            line-height: var(--line-height)
        }

        [data-framer-component-type=Text] div div span,
        [data-framer-component-type=Text] a div span,
        [data-framer-component-type=Text] span span span,
        [data-framer-component-type=Text] p span span,
        [data-framer-component-type=Text] h1 span span,
        [data-framer-component-type=Text] h2 span span,
        [data-framer-component-type=Text] h3 span span,
        [data-framer-component-type=Text] h4 span span,
        [data-framer-component-type=Text] h5 span span,
        [data-framer-component-type=Text] h6 span span,
        [data-framer-component-type=Text] a {
            --font-family: var(--framer-font-family);
            --font-style: var(--framer-font-style);
            --font-weight: var(--framer-font-weight);
            --text-color: var(--framer-text-color);
            --letter-spacing: var(--framer-letter-spacing);
            --font-size: var(--framer-font-size);
            --text-transform: var(--framer-text-transform);
            --text-decoration: var(--framer-text-decoration);
            --line-height: var(--framer-line-height)
        }

        [data-framer-component-type=Text] a,
        [data-framer-component-type=Text] a div span,
        [data-framer-component-type=Text] a span span span,
        [data-framer-component-type=Text] a p span span,
        [data-framer-component-type=Text] a h1 span span,
        [data-framer-component-type=Text] a h2 span span,
        [data-framer-component-type=Text] a h3 span span,
        [data-framer-component-type=Text] a h4 span span,
        [data-framer-component-type=Text] a h5 span span,
        [data-framer-component-type=Text] a h6 span span {
            --font-family: var(--framer-link-font-family, var(--framer-font-family));
            --font-style: var(--framer-link-font-style, var(--framer-font-style));
            --font-weight: var(--framer-link-font-weight, var(--framer-font-weight));
            --text-color: var(--framer-link-text-color, var(--framer-text-color));
            --font-size: var(--framer-link-font-size, var(--framer-font-size));
            --text-transform: var(--framer-link-text-transform, var(--framer-text-transform));
            --text-decoration: var(--framer-link-text-decoration, var(--framer-text-decoration))
        }

        [data-framer-component-type=Text] a:hover,
        [data-framer-component-type=Text] a div span:hover,
        [data-framer-component-type=Text] a span span span:hover,
        [data-framer-component-type=Text] a p span span:hover,
        [data-framer-component-type=Text] a h1 span span:hover,
        [data-framer-component-type=Text] a h2 span span:hover,
        [data-framer-component-type=Text] a h3 span span:hover,
        [data-framer-component-type=Text] a h4 span span:hover,
        [data-framer-component-type=Text] a h5 span span:hover,
        [data-framer-component-type=Text] a h6 span span:hover {
            --font-family: var(--framer-link-hover-font-family, var(--framer-link-font-family, var(--framer-font-family)));
            --font-style: var(--framer-link-hover-font-style, var(--framer-link-font-style, var(--framer-font-style)));
            --font-weight: var(--framer-link-hover-font-weight, var(--framer-link-font-weight, var(--framer-font-weight)));
            --text-color: var(--framer-link-hover-text-color, var(--framer-link-text-color, var(--framer-text-color)));
            --font-size: var(--framer-link-hover-font-size, var(--framer-link-font-size, var(--framer-font-size)));
            --text-transform: var(--framer-link-hover-text-transform, var(--framer-link-text-transform, var(--framer-text-transform)));
            --text-decoration: var(--framer-link-hover-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration)))
        }

        [data-framer-component-type=Text].isCurrent a,
        [data-framer-component-type=Text].isCurrent a div span,
        [data-framer-component-type=Text].isCurrent a span span span,
        [data-framer-component-type=Text].isCurrent a p span span,
        [data-framer-component-type=Text].isCurrent a h1 span span,
        [data-framer-component-type=Text].isCurrent a h2 span span,
        [data-framer-component-type=Text].isCurrent a h3 span span,
        [data-framer-component-type=Text].isCurrent a h4 span span,
        [data-framer-component-type=Text].isCurrent a h5 span span,
        [data-framer-component-type=Text].isCurrent a h6 span span {
            --font-family: var(--framer-link-current-font-family, var(--framer-link-font-family, var(--framer-font-family)));
            --font-style: var(--framer-link-current-font-style, var(--framer-link-font-style, var(--framer-font-style)));
            --font-weight: var(--framer-link-current-font-weight, var(--framer-link-font-weight, var(--framer-font-weight)));
            --text-color: var(--framer-link-current-text-color, var(--framer-link-text-color, var(--framer-text-color)));
            --font-size: var(--framer-link-current-font-size, var(--framer-link-font-size, var(--framer-font-size)));
            --text-transform: var(--framer-link-current-text-transform, var(--framer-link-text-transform, var(--framer-text-transform)));
            --text-decoration: var(--framer-link-current-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration)))
        }

        p.framer-text,
        div.framer-text,
        h1.framer-text,
        h2.framer-text,
        h3.framer-text,
        h4.framer-text,
        h5.framer-text,
        h6.framer-text,
        ol.framer-text,
        ul.framer-text {
            margin: 0;
            padding: 0
        }

        p.framer-text,
        div.framer-text,
        h1.framer-text,
        h2.framer-text,
        h3.framer-text,
        h4.framer-text,
        h5.framer-text,
        h6.framer-text,
        li.framer-text,
        ol.framer-text,
        ul.framer-text,
        span.framer-text:not([data-text-fill]) {
            font-family: var(--framer-font-family, Inter, Inter Placeholder, sans-serif);
            font-style: var(--framer-font-style, normal);
            font-weight: var(--framer-font-weight, 400);
            color: var(--framer-text-color, #000);
            font-size: calc(var(--framer-font-size, 16px) * var(--framer-font-size-scale, 1));
            letter-spacing: var(--framer-letter-spacing, 0);
            text-transform: var(--framer-text-transform, none);
            text-decoration: var(--framer-text-decoration, none);
            line-height: var(--framer-line-height, 1.2em);
            text-align: var(--framer-text-alignment, start)
        }

        strong.framer-text {
            font-weight: bolder
        }

        em.framer-text {
            font-style: italic
        }

        p.framer-text:not(:first-child),
        div.framer-text:not(:first-child),
        h1.framer-text:not(:first-child),
        h2.framer-text:not(:first-child),
        h3.framer-text:not(:first-child),
        h4.framer-text:not(:first-child),
        h5.framer-text:not(:first-child),
        h6.framer-text:not(:first-child),
        ol.framer-text:not(:first-child),
        ul.framer-text:not(:first-child),
        .framer-image.framer-text:not(:first-child) {
            margin-top: var(--framer-paragraph-spacing, 0)
        }

        li.framer-text>ul.framer-text:nth-child(2),
        li.framer-text>ol.framer-text:nth-child(2) {
            margin-top: 0
        }

        .framer-text[data-text-fill] {
            display: inline-block;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding: max(0em, calc(calc(1.3em - var(--framer-line-height, 1.3em)) / 2));
            margin: min(0em, calc(calc(1.3em - var(--framer-line-height, 1.3em)) / -2))
        }

        code.framer-text,
        code.framer-text span.framer-text:not([data-text-fill]) {
            font-family: var(--framer-code-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
            font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
            font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
            color: var(--framer-code-text-color, var(--framer-text-color, #000));
            font-size: calc(var(--framer-font-size, 16px) * var(--framer-font-size-scale, 1));
            letter-spacing: var(--framer-letter-spacing, 0);
            line-height: var(--framer-line-height, 1.2em)
        }

        a.framer-text,
        a.framer-text span.framer-text:not([data-text-fill]) {
            font-family: var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
            font-style: var(--framer-link-font-style, var(--framer-font-style, normal));
            font-weight: var(--framer-link-font-weight, var(--framer-font-weight, 400));
            color: var(--framer-link-text-color, var(--framer-text-color, #000));
            font-size: calc(var(--framer-link-font-size, var(--framer-font-size, 16px)) * var(--framer-font-size-scale, 1));
            text-transform: var(--framer-link-text-transform, var(--framer-text-transform, none));
            text-decoration: var(--framer-link-text-decoration, var(--framer-text-decoration, none));
            cursor: var(--framer-custom-cursors, pointer)
        }

        code.framer-text a.framer-text,
        code.framer-text a.framer-text span.framer-text:not([data-text-fill]) {
            font-family: var(--framer-code-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
            font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
            font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
            color: var(--framer-link-text-color, var(--framer-code-text-color, var(--framer-text-color, #000)));
            font-size: calc(var(--framer-link-font-size, var(--framer-font-size, 16px)) * var(--framer-font-size-scale, 1))
        }

        a.framer-text:hover,
        a.framer-text:hover span.framer-text:not([data-text-fill]) {
            font-family: var(--framer-link-hover-font-family, var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif)));
            font-style: var(--framer-link-hover-font-style, var(--framer-link-font-style, var(--framer-font-style, normal)));
            font-weight: var(--framer-link-hover-font-weight, var(--framer-link-font-weight, var(--framer-font-weight, 400)));
            color: var(--framer-link-hover-text-color, var(--framer-link-text-color, var(--framer-text-color, #000)));
            font-size: calc(var(--framer-link-hover-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px))) * var(--framer-font-size-scale, 1));
            text-transform: var(--framer-link-hover-text-transform, var(--framer-link-text-transform, var(--framer-text-transform, none)));
            text-decoration: var(--framer-link-hover-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration, none)))
        }

        code.framer-text a.framer-text:hover,
        code.framer-text a.framer-text:hover span.framer-text:not([data-text-fill]) {
            font-family: var(--framer-code-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
            font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
            font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
            color: var(--framer-link-hover-text-color, var(--framer-link-text-color, var(--framer-code-text-color, var(--framer-text-color, #000))));
            font-size: calc(var(--framer-link-hover-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px))) * var(--framer-font-size-scale, 1))
        }

        a.framer-text[data-framer-page-link-current],
        a.framer-text[data-framer-page-link-current] span.framer-text:not([data-text-fill]) {
            font-family: var(--framer-link-current-font-family, var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif)));
            font-style: var(--framer-link-current-font-style, var(--framer-link-font-style, var(--framer-font-style, normal)));
            font-weight: var(--framer-link-current-font-weight, var(--framer-link-font-weight, var(--framer-font-weight, 400)));
            color: var(--framer-link-current-text-color, var(--framer-link-text-color, var(--framer-text-color, #000)));
            font-size: calc(var(--framer-link-current-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px))) * var(--framer-font-size-scale, 1));
            text-transform: var(--framer-link-current-text-transform, var(--framer-link-text-transform, var(--framer-text-transform, none)));
            text-decoration: var(--framer-link-current-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration, none)))
        }

        code.framer-text a.framer-text[data-framer-page-link-current],
        code.framer-text a.framer-text[data-framer-page-link-current] span.framer-text:not([data-text-fill]) {
            font-family: var(--framer-code-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
            font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
            font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
            color: var(--framer-link-current-text-color, var(--framer-link-text-color, var(--framer-code-text-color, var(--framer-text-color, #000))));
            font-size: calc(var(--framer-link-current-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px))) * var(--framer-font-size-scale, 1))
        }

        a.framer-text[data-framer-page-link-current]:hover,
        a.framer-text[data-framer-page-link-current]:hover span.framer-text:not([data-text-fill]) {
            font-family: var(--framer-link-hover-font-family, var(--framer-link-current-font-family, var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif))));
            font-style: var(--framer-link-hover-font-style, var(--framer-link-current-font-style, var(--framer-link-font-style, var(--framer-font-style, normal))));
            font-weight: var(--framer-link-hover-font-weight, var(--framer-link-current-font-weight, var(--framer-link-font-weight, var(--framer-font-weight, 400))));
            color: var(--framer-link-hover-text-color, var(--framer-link-current-text-color, var(--framer-link-text-color, var(--framer-text-color, #000))));
            font-size: calc(var(--framer-link-hover-font-size, var(--framer-link-current-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px)))) * var(--framer-font-size-scale, 1));
            text-transform: var(--framer-link-hover-text-transform, var(--framer-link-current-text-transform, var(--framer-link-text-transform, var(--framer-text-transform, none))));
            text-decoration: var(--framer-link-hover-text-decoration, var(--framer-link-current-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration, none))))
        }

        code.framer-text a.framer-text[data-framer-page-link-current]:hover,
        code.framer-text a.framer-text[data-framer-page-link-current]:hover span.framer-text:not([data-text-fill]) {
            font-family: var(--framer-code-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
            font-style: var(--framer-code-font-style, var(--framer-font-style, normal));
            font-weight: var(--framer-code-font-weight, var(--framer-font-weight, 400));
            color: var(--framer-link-hover-text-color, var(--framer-link-current-text-color, var(--framer-link-text-color, var(--framer-code-text-color, var(--framer-text-color, #000)))));
            font-size: calc(var(--framer-link-hover-font-size, var(--framer-link-current-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px)))) * var(--framer-font-size-scale, 1))
        }

        .framer-image.framer-text {
            display: block;
            max-width: 100%;
            height: auto
        }

        .text-styles-preset-reset.framer-text {
            --framer-font-family: Inter, Inter Placeholder, sans-serif;
            --framer-font-style: normal;
            --framer-font-weight: 500;
            --framer-text-color: #000;
            --framer-font-size: 16px;
            --framer-letter-spacing: 0;
            --framer-text-transform: none;
            --framer-text-decoration: none;
            --framer-line-height: 1.2em;
            --framer-text-alignment: start
        }

        ul.framer-text,
        ol.framer-text {
            display: table;
            width: 100%
        }

        li.framer-text {
            display: table-row;
            counter-increment: list-item;
            list-style: none
        }

        ol.framer-text>li.framer-text:before {
            display: table-cell;
            width: 2.25ch;
            box-sizing: border-box;
            padding-inline-end: .75ch;
            content: counter(list-item) ".";
            white-space: nowrap
        }

        ul.framer-text>li.framer-text:before {
            display: table-cell;
            width: 2.25ch;
            box-sizing: border-box;
            padding-inline-end: .75ch;
            content: "\2022"
        }

        .framer-text-module[style*=aspect-ratio]>:first-child {
            width: 100%
        }

        @supports not (aspect-ratio: 1) {
            .framer-text-module[style*=aspect-ratio] {
                position: relative
            }
        }

        @supports not (aspect-ratio: 1) {
            .framer-text-module[style*=aspect-ratio]:before {
                content: "";
                display: block;
                padding-bottom: calc(100% / calc(var(--aspect-ratio)))
            }
        }

        @supports not (aspect-ratio: 1) {
            .framer-text-module[style*=aspect-ratio]>:first-child {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%
            }
        }

        [data-framer-component-type=DeprecatedRichText] {
            cursor: inherit
        }

        [data-framer-component-type=DeprecatedRichText] .text-styles-preset-reset {
            --framer-font-family: Inter, Inter Placeholder, sans-serif;
            --framer-font-style: normal;
            --framer-font-weight: 500;
            --framer-text-color: #000;
            --framer-font-size: 16px;
            --framer-letter-spacing: 0;
            --framer-text-transform: none;
            --framer-text-decoration: none;
            --framer-line-height: 1.2em;
            --framer-text-alignment: start
        }

        [data-framer-component-type=DeprecatedRichText] p,
        [data-framer-component-type=DeprecatedRichText] div,
        [data-framer-component-type=DeprecatedRichText] h1,
        [data-framer-component-type=DeprecatedRichText] h2,
        [data-framer-component-type=DeprecatedRichText] h3,
        [data-framer-component-type=DeprecatedRichText] h4,
        [data-framer-component-type=DeprecatedRichText] h5,
        [data-framer-component-type=DeprecatedRichText] h6 {
            margin: 0;
            padding: 0
        }

        [data-framer-component-type=DeprecatedRichText] p,
        [data-framer-component-type=DeprecatedRichText] div,
        [data-framer-component-type=DeprecatedRichText] h1,
        [data-framer-component-type=DeprecatedRichText] h2,
        [data-framer-component-type=DeprecatedRichText] h3,
        [data-framer-component-type=DeprecatedRichText] h4,
        [data-framer-component-type=DeprecatedRichText] h5,
        [data-framer-component-type=DeprecatedRichText] h6,
        [data-framer-component-type=DeprecatedRichText] li,
        [data-framer-component-type=DeprecatedRichText] ol,
        [data-framer-component-type=DeprecatedRichText] ul,
        [data-framer-component-type=DeprecatedRichText] span:not([data-text-fill]) {
            font-family: var(--framer-font-family, Inter, Inter Placeholder, sans-serif);
            font-style: var(--framer-font-style, normal);
            font-weight: var(--framer-font-weight, 400);
            color: var(--framer-text-color, #000);
            font-size: var(--framer-font-size, 16px);
            letter-spacing: var(--framer-letter-spacing, 0);
            text-transform: var(--framer-text-transform, none);
            text-decoration: var(--framer-text-decoration, none);
            line-height: var(--framer-line-height, 1.2em);
            text-align: var(--framer-text-alignment, start)
        }

        [data-framer-component-type=DeprecatedRichText] p:not(:first-child),
        [data-framer-component-type=DeprecatedRichText] div:not(:first-child),
        [data-framer-component-type=DeprecatedRichText] h1:not(:first-child),
        [data-framer-component-type=DeprecatedRichText] h2:not(:first-child),
        [data-framer-component-type=DeprecatedRichText] h3:not(:first-child),
        [data-framer-component-type=DeprecatedRichText] h4:not(:first-child),
        [data-framer-component-type=DeprecatedRichText] h5:not(:first-child),
        [data-framer-component-type=DeprecatedRichText] h6:not(:first-child),
        [data-framer-component-type=DeprecatedRichText] ol:not(:first-child),
        [data-framer-component-type=DeprecatedRichText] ul:not(:first-child),
        [data-framer-component-type=DeprecatedRichText] .framer-image:not(:first-child) {
            margin-top: var(--framer-paragraph-spacing, 0)
        }

        [data-framer-component-type=DeprecatedRichText] span[data-text-fill] {
            display: inline-block;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent
        }

        [data-framer-component-type=DeprecatedRichText] a,
        [data-framer-component-type=DeprecatedRichText] a span:not([data-text-fill]) {
            font-family: var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
            font-style: var(--framer-link-font-style, var(--framer-font-style, normal));
            font-weight: var(--framer-link-font-weight, var(--framer-font-weight, 400));
            color: var(--framer-link-text-color, var(--framer-text-color, #000));
            font-size: var(--framer-link-font-size, var(--framer-font-size, 16px));
            text-transform: var(--framer-link-text-transform, var(--framer-text-transform, none));
            text-decoration: var(--framer-link-text-decoration, var(--framer-text-decoration, none))
        }

        [data-framer-component-type=DeprecatedRichText] a:hover,
        [data-framer-component-type=DeprecatedRichText] a:hover span:not([data-text-fill]) {
            font-family: var(--framer-link-hover-font-family, var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif)));
            font-style: var(--framer-link-hover-font-style, var(--framer-link-font-style, var(--framer-font-style, normal)));
            font-weight: var(--framer-link-hover-font-weight, var(--framer-link-font-weight, var(--framer-font-weight, 400)));
            color: var(--framer-link-hover-text-color, var(--framer-link-text-color, var(--framer-text-color, #000)));
            font-size: var(--framer-link-hover-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px)));
            text-transform: var(--framer-link-hover-text-transform, var(--framer-link-text-transform, var(--framer-text-transform, none)));
            text-decoration: var(--framer-link-hover-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration, none)))
        }

        a[data-framer-page-link-current],
        a[data-framer-page-link-current] span:not([data-text-fill]) {
            font-family: var(--framer-link-current-font-family, var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif)));
            font-style: var(--framer-link-current-font-style, var(--framer-link-font-style, var(--framer-font-style, normal)));
            font-weight: var(--framer-link-current-font-weight, var(--framer-link-font-weight, var(--framer-font-weight, 400)));
            color: var(--framer-link-current-text-color, var(--framer-link-text-color, var(--framer-text-color, #000)));
            font-size: var(--framer-link-current-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px)));
            text-transform: var(--framer-link-current-text-transform, var(--framer-link-text-transform, var(--framer-text-transform, none)));
            text-decoration: var(--framer-link-current-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration, none)))
        }

        a[data-framer-page-link-current]:hover,
        a[data-framer-page-link-current]:hover span:not([data-text-fill]) {
            font-family: var(--framer-link-hover-font-family, var(--framer-link-current-font-family, var(--framer-link-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif))));
            font-style: var(--framer-link-hover-font-style, var(--framer-link-current-font-style, var(--framer-link-font-style, var(--framer-font-style, normal))));
            font-weight: var(--framer-link-hover-font-weight, var(--framer-link-current-font-weight, var(--framer-link-font-weight, var(--framer-font-weight, 400))));
            color: var(--framer-link-hover-text-color, var(--framer-link-current-text-color, var(--framer-link-text-color, var(--framer-text-color, #000))));
            font-size: var(--framer-link-hover-font-size, var(--framer-link-current-font-size, var(--framer-link-font-size, var(--framer-font-size, 16px))));
            text-transform: var(--framer-link-hover-text-transform, var(--framer-link-current-text-transform, var(--framer-link-text-transform, var(--framer-text-transform, none))));
            text-decoration: var(--framer-link-hover-text-decoration, var(--framer-link-current-text-decoration, var(--framer-link-text-decoration, var(--framer-text-decoration, none))))
        }

        [data-framer-component-type=DeprecatedRichText] strong {
            font-weight: bolder
        }

        [data-framer-component-type=DeprecatedRichText] em {
            font-style: italic
        }

        [data-framer-component-type=DeprecatedRichText] .framer-image {
            display: block;
            max-width: 100%;
            height: auto
        }

        [data-framer-component-type=DeprecatedRichText] ul,
        [data-framer-component-type=DeprecatedRichText] ol {
            display: table;
            width: 100%;
            padding-left: 0;
            margin: 0
        }

        [data-framer-component-type=DeprecatedRichText] li {
            display: table-row;
            counter-increment: list-item;
            list-style: none
        }

        [data-framer-component-type=DeprecatedRichText] ol>li:before {
            display: table-cell;
            width: 2.25ch;
            box-sizing: border-box;
            padding-right: .75ch;
            content: counter(list-item) ".";
            white-space: nowrap
        }

        [data-framer-component-type=DeprecatedRichText] ul>li:before {
            display: table-cell;
            width: 2.25ch;
            box-sizing: border-box;
            padding-right: .75ch;
            content: "\2022"
        }

        :not([data-framer-generated])>[data-framer-stack-content-wrapper]>*,
        :not([data-framer-generated])>[data-framer-stack-content-wrapper]>[data-framer-component-type],
        :not([data-framer-generated])>[data-framer-stack-content-wrapper]>[data-framer-legacy-stack-gap-enabled]>*,
        :not([data-framer-generated])>[data-framer-stack-content-wrapper]>[data-framer-legacy-stack-gap-enabled]>[data-framer-component-type] {
            position: relative
        }

        .flexbox-gap-not-supported [data-framer-legacy-stack-gap-enabled=true]>*,
        [data-framer-legacy-stack-gap-enabled=true][data-framer-stack-flexbox-gap=false] {
            margin-top: calc(var(--stack-gap-y) / 2);
            margin-bottom: calc(var(--stack-gap-y) / 2);
            margin-right: calc(var(--stack-gap-x) / 2);
            margin-left: calc(var(--stack-gap-x) / 2)
        }

        [data-framer-stack-content-wrapper][data-framer-stack-gap-enabled=true] {
            row-gap: var(--stack-native-row-gap);
            column-gap: var(--stack-native-column-gap)
        }

        .flexbox-gap-not-supported [data-framer-stack-content-wrapper][data-framer-stack-gap-enabled=true] {
            row-gap: unset;
            column-gap: unset
        }

        .flexbox-gap-not-supported [data-framer-stack-direction-reverse=false] [data-framer-legacy-stack-gap-enabled=true]>*:first-child,
        [data-framer-stack-direction-reverse=false] [data-framer-legacy-stack-gap-enabled=true][data-framer-stack-flexbox-gap=false]>*:first-child,
        .flexbox-gap-not-supported [data-framer-stack-direction-reverse=true] [data-framer-legacy-stack-gap-enabled=true]>*:last-child,
        [data-framer-stack-direction-reverse=true] [data-framer-legacy-stack-gap-enabled=true][data-framer-stack-flexbox-gap=false]>*:last-child {
            margin-top: 0;
            margin-left: 0
        }

        .flexbox-gap-not-supported [data-framer-stack-direction-reverse=false] [data-framer-legacy-stack-gap-enabled=true]>*:last-child,
        [data-framer-stack-direction-reverse=false] [data-framer-legacy-stack-gap-enabled=true][data-framer-stack-flexbox-gap=false]>*:last-child,
        .flexbox-gap-not-supported [data-framer-stack-direction-reverse=true] [data-framer-legacy-stack-gap-enabled=true]>*:first-child,
        [data-framer-stack-direction-reverse=true] [data-framer-legacy-stack-gap-enabled=true][data-framer-stack-flexbox-gap=false]>*:first-child {
            margin-right: 0;
            margin-bottom: 0
        }

        NavigationContainer [data-framer-component-type=NavigationContainer]>*,
        [data-framer-component-type=NavigationContainer]>[data-framer-component-type] {
            position: relative
        }

        [data-framer-component-type=Scroll]::-webkit-scrollbar {
            display: none
        }

        [data-framer-component-type=ScrollContentWrapper]>* {
            position: relative
        }

        [data-framer-component-type=NativeScroll] {
            -webkit-overflow-scrolling: touch
        }

        [data-framer-component-type=NativeScroll]>* {
            position: relative
        }

        [data-framer-component-type=NativeScroll].direction-both {
            overflow-x: scroll;
            overflow-y: scroll
        }

        [data-framer-component-type=NativeScroll].direction-vertical {
            overflow-x: hidden;
            overflow-y: scroll
        }

        [data-framer-component-type=NativeScroll].direction-horizontal {
            overflow-x: scroll;
            overflow-y: hidden
        }

        [data-framer-component-type=NativeScroll].direction-vertical>* {
            width: 100% !important
        }

        [data-framer-component-type=NativeScroll].direction-horizontal>* {
            height: 100% !important
        }

        [data-framer-component-type=NativeScroll].scrollbar-hidden::-webkit-scrollbar {
            display: none
        }

        [data-framer-component-type=PageContentWrapper]>*,
        [data-framer-component-type=PageContentWrapper]>[data-framer-component-type] {
            position: relative
        }

        [data-framer-component-type=DeviceComponent].no-device>* {
            width: 100% !important;
            height: 100% !important
        }

        [data-is-present=false],
        [data-is-present=false] * {
            pointer-events: none !important
        }

        [data-framer-cursor=pointer] {
            cursor: pointer
        }

        [data-framer-cursor=grab] {
            cursor: grab
        }

        [data-framer-cursor=grab]:active {
            cursor: grabbing
        }

        [data-framer-component-type=Frame] *,
        [data-framer-component-type=Stack] * {
            pointer-events: auto
        }

        [data-framer-generated] * {
            pointer-events: unset
        }

        .svgContainer svg {
            display: block
        }

        [data-reset=button] {
            border-width: 0;
            padding: 0;
            background: none
        }

        [data-hide-scrollbars=true]::-webkit-scrollbar {
            width: 0px;
            height: 0px
        }

        [data-hide-scrollbars=true]::-webkit-scrollbar-thumb {
            background: transparent
        }

        .framer-XyIhp [data-border=true]:after {
            content: "";
            border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
            border-color: var(--border-color, none);
            border-style: var(--border-style, none);
            width: 100%;
            height: 100%;
            position: absolute;
            box-sizing: border-box;
            left: 0;
            top: 0;
            border-radius: inherit;
            pointer-events: none
        }

        .framer-body {
            background: var(--token-f9b89b17-0991-4c0e-b401-ca53a64a9c7b, rgb(2, 4, 29))
        }

        .framer-XyIhp .framer-1iwt1hz {
            display: block
        }

        .framer-XyIhp .framer-1fdb8e9 {
            align-content: center;
            align-items: center;
            background-color: var(--token-f9b89b17-0991-4c0e-b401-ca53a64a9c7b, #02041d);
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            overflow: hidden;
            padding: 0;
            position: relative;
            width: 1440px
        }

        .framer-XyIhp .navbar {
            --border-bottom-width: 1px;
            --border-color: rgba(255, 255, 255, .11);
            --border-left-width: 1px;
            --border-right-width: 1px;
            --border-style: solid;
            --border-top-width: 1px;
            -webkit-backdrop-filter: blur(45px);
            align-content: center;
            align-items: center;
            backdrop-filter: blur(45px);
            border-radius: 20px;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: center;
            left: 50%;
            overflow: hidden;
            padding: 20px;
            position: fixed;
            top: 20px;
            transform: translate(-50%);
            width: min-content;
            will-change: transform;
            z-index: 10
        }

        .framer-XyIhp .framer-3afuy5-container,
        .framer-XyIhp .framer-o6rf7a-container,
        .framer-XyIhp .framer-81d4qq-container,
        .framer-XyIhp .framer-fzbx7m-container,
        .framer-XyIhp .framer-1f950g6-container,
        .framer-XyIhp .framer-9e8a7h-container,
        .framer-XyIhp .framer-1j9thc6-container,
        .framer-XyIhp .framer-1j4251w-container {
            flex: none;
            height: auto;
            position: relative;
            width: auto
        }

        .framer-XyIhp .framer-1pxana1,
        .framer-XyIhp .framer-1rxdd4g,
        .framer-XyIhp .framer-1r8s7jc {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-XyIhp .framer-oq9jai {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: 1528px;
            justify-content: center;
            left: -316px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            right: -316px;
            top: -190px;
            transform: perspective(1200px);
            z-index: 0
        }

        .framer-XyIhp .framer-ry9op0-container {
            flex: none;
            height: 660px;
            left: 220px;
            position: absolute;
            top: 203px;
            width: 100%;
            z-index: -1
        }

        .framer-XyIhp .framer-w5yuep {
            aspect-ratio: 1.418848167539267 / 1;
            flex: none;
            height: var(--framer-aspect-ratio-supported, 1528px);
            position: relative;
            width: 2168px
        }

        .framer-XyIhp .framer-a7brsr {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            max-width: 1350px;
            overflow: hidden;
            padding: 240px 24px 0;
            position: relative;
            width: 100%;
            z-index: 0
        }

        .framer-XyIhp .framer-1wlua9y {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 80px;
            height: 1050px;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-XyIhp .framer-dhmoms {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 25px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            transform: perspective(1200px);
            width: min-content
        }

        .framer-XyIhp .framer-knvts4 {
            --border-bottom-width: 1px;
            --border-color: rgba(255, 255, 255, .08);
            --border-left-width: 1px;
            --border-right-width: 1px;
            --border-style: solid;
            --border-top-width: 1px;
            align-content: center;
            align-items: center;
            background-color: #ffffff08;
            border-radius: 12px;
            box-shadow: inset 0 0 11px #5c80fa66;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 5px 20px;
            position: relative;
            transform: perspective(1200px);
            width: min-content
        }

        .framer-XyIhp .framer-zje5jj {
            flex: none;
            height: 24px;
            position: relative;
            width: 25px
        }

        .framer-XyIhp .framer-yylcq,
        .framer-XyIhp .framer-1q1ljq3,
        .framer-XyIhp .framer-j4pyps,
        .framer-XyIhp .framer-is2odu,
        .framer-XyIhp .framer-jexi0o,
        .framer-XyIhp .framer-18ew2cl,
        .framer-XyIhp .framer-1gzvda5,
        .framer-XyIhp .framer-1u3lfz2,
        .framer-XyIhp .framer-144i9i1,
        .framer-XyIhp .framer-24a6rx,
        .framer-XyIhp .framer-1hpkppv,
        .framer-XyIhp .framer-3fp08x,
        .framer-XyIhp .framer-jfusm0,
        .framer-XyIhp .framer-851z3w,
        .framer-XyIhp .framer-1ntinmm,
        .framer-XyIhp .framer-kn9idq,
        .framer-XyIhp .framer-4kcmtl,
        .framer-XyIhp .framer-jeyvr2,
        .framer-XyIhp .framer-jv8ywr,
        .framer-XyIhp .framer-12kfj7m,
        .framer-XyIhp .framer-15okq9t,
        .framer-XyIhp .framer-1n5ke9g,
        .framer-XyIhp .framer-7i6xc8,
        .framer-XyIhp .framer-1q8qm9t,
        .framer-XyIhp .framer-x8vk0c,
        .framer-XyIhp .framer-1bo80ir,
        .framer-XyIhp .framer-161qdwk,
        .framer-XyIhp .framer-1q2copr,
        .framer-XyIhp .framer-15x1yip,
        .framer-XyIhp .framer-ouqkqx,
        .framer-XyIhp .framer-1yq0igo,
        .framer-XyIhp .framer-1udeick,
        .framer-XyIhp .framer-1amohsv,
        .framer-XyIhp .framer-27pmin,
        .framer-XyIhp .framer-7ktxx7,
        .framer-XyIhp .framer-1kizmbs,
        .framer-XyIhp .framer-jvkc4r,
        .framer-XyIhp .framer-1omsv8q,
        .framer-XyIhp .framer-6u4qz,
        .framer-XyIhp .framer-1y05pr2,
        .framer-XyIhp .framer-2qxiae {
            --framer-paragraph-spacing: 0px;
            flex: none;
            height: auto;
            position: relative;
            white-space: pre;
            width: auto
        }

        .framer-XyIhp .framer-1tuo9lc {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            transform: perspective(1200px);
            width: min-content
        }

        .framer-XyIhp .framer-mkl5is {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0 0 2px;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-vxbjup {
            --framer-paragraph-spacing: 0px;
            flex: none;
            height: auto;
            position: relative;
            transform: perspective(1200px);
            white-space: pre-wrap;
            width: 607px;
            word-break: break-word;
            word-wrap: break-word
        }

        .framer-XyIhp .framer-mhrp0o {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 8px;
            height: 35px;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 228px
        }

        .framer-XyIhp .framer-t14cig {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 2px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-cgj6u1 {
            flex: none;
            height: 12px;
            position: relative;
            width: 59px
        }

        .framer-XyIhp .framer-cakrzc {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: center;
            overflow: hidden;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-1bxm4u7 {
            align-content: center;
            align-items: center;
            bottom: -160px;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: center;
            left: 50%;
            overflow: hidden;
            padding: 0;
            position: absolute;
            transform: perspective(1200px) translate(-50%);
            width: min-content;
            z-index: 1
        }

        .framer-XyIhp .framer-znopii {
            aspect-ratio: .7866507747318237 / 1;
            flex: none;
            height: var(--framer-aspect-ratio-supported, 839px);
            position: relative;
            width: 660px
        }

        .framer-XyIhp .framer-19bu65k {
            align-content: center;
            align-items: center;
            background: linear-gradient(180deg, rgba(217, 217, 217, .1) 0%, rgba(217, 217, 217, 0) 100%);
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 165px;
            height: 301px;
            justify-content: center;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-XyIhp .framer-1dwfbcd {
            background-color: #fff;
            flex: none;
            inset: 0 832px 300px 105px;
            overflow: hidden;
            position: absolute;
            z-index: 1
        }

        .framer-XyIhp .framer-jduge5 {
            background-color: #fff;
            flex: none;
            inset: 0 112px 300px 825px;
            overflow: hidden;
            position: absolute;
            z-index: 1
        }

        .framer-XyIhp .framer-1i5chw,
        .framer-XyIhp .framer-l6tnfh {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-1ewqsxg,
        .framer-XyIhp .framer-1p0sh1i {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 25px;
            height: min-content;
            justify-content: center;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-1q1lw1r,
        .framer-XyIhp .framer-rbiuz4 {
            --framer-paragraph-spacing: 0px;
            flex: none;
            height: auto;
            position: relative;
            white-space: pre-wrap;
            width: 395px;
            word-break: break-word;
            word-wrap: break-word
        }

        .framer-XyIhp .framer-4qqe42 {
            flex: none;
            height: 201px;
            position: relative;
            width: 3px
        }

        .framer-XyIhp .framer-1uepckd {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            justify-content: center;
            inset: -50px 692px 267px 691px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            z-index: 1
        }

        .framer-XyIhp .framer-1m9zwry {
            flex: none;
            height: 84px;
            position: relative;
            width: 57px
        }

        .framer-XyIhp .framer-13rx7v7 {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            max-width: 1350px;
            overflow: visible;
            padding: 160px 24px 80px;
            position: relative;
            width: 100%
        }

        .framer-XyIhp .framer-pf7ty7,
        .framer-XyIhp .framer-dasrki {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 130px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-XyIhp .framer-1wd6miv,
        .framer-XyIhp .framer-1d7q4in {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 16px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            transform: perspective(1200px);
            width: min-content
        }

        .framer-XyIhp .framer-1fkqlne,
        .framer-XyIhp .framer-cwpdvo,
        .framer-XyIhp .framer-17o45yf,
        .framer-XyIhp .framer-10ecp3a,
        .framer-XyIhp .framer-zd2tfq {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 20px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-18va552,
        .framer-XyIhp .framer-d5wszl,
        .framer-XyIhp .framer-1xwyue6,
        .framer-XyIhp .framer-gzvsc5,
        .framer-XyIhp .framer-sg55ij,
        .framer-XyIhp .framer-1bd4imv,
        .framer-XyIhp .framer-15kjguo {
            flex: none;
            height: 5px;
            position: relative;
            width: 451px
        }

        .framer-XyIhp .framer-b3qr2b,
        .framer-XyIhp .framer-w6947w,
        .framer-XyIhp .framer-sc0hwa {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: center;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-tqod9x,
        .framer-XyIhp .framer-i88od4,
        .framer-XyIhp .framer-1tjf7iv {
            --framer-paragraph-spacing: 0px;
            flex: none;
            height: auto;
            position: relative;
            white-space: pre-wrap;
            width: 607px;
            word-break: break-word;
            word-wrap: break-word
        }

        .framer-XyIhp .framer-ypdnqf {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 90px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            transform: perspective(1200px);
            width: min-content
        }

        .framer-XyIhp .framer-9s1iyu {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 50px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-innttb,
        .framer-XyIhp .framer-dju9og,
        .framer-XyIhp .framer-5jto6g {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 27px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-1d01eib,
        .framer-XyIhp .framer-1awvzgu,
        .framer-XyIhp .framer-1pk2klm {
            aspect-ratio: 1 / 1;
            flex: none;
            height: var(--framer-aspect-ratio-supported, 76px);
            position: relative;
            width: 76px
        }

        .framer-XyIhp .framer-18hzsxf,
        .framer-XyIhp .framer-klceqd,
        .framer-XyIhp .framer-3n8fwm {
            --framer-paragraph-spacing: 0px;
            flex: none;
            height: auto;
            position: relative;
            white-space: pre-wrap;
            width: 344px;
            word-break: break-word;
            word-wrap: break-word
        }

        .framer-XyIhp .framer-8vd0n0,
        .framer-XyIhp .framer-e7q26m {
            flex: none;
            height: 196px;
            position: relative;
            width: 4px
        }

        .framer-XyIhp .framer-61j3w9,
        .framer-XyIhp .framer-1wdcgtp {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-XyIhp .framer-1q9737y,
        .framer-XyIhp .framer-1und9d4 {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            max-width: 1350px;
            overflow: visible;
            padding: 80px 24px;
            position: relative;
            width: 100%
        }

        .framer-XyIhp .framer-1kaoxzn {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-end;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-XyIhp .framer-p7g58f {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: center;
            left: -430px;
            overflow: visible;
            padding: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: min-content;
            z-index: 1
        }

        .framer-XyIhp .framer-1vnopp6-container {
            aspect-ratio: 1 / 1;
            flex: none;
            height: var(--framer-aspect-ratio-supported, 1200px);
            position: relative;
            width: 1200px
        }

        .framer-XyIhp .framer-nro0f5 {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 50px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-s2ewvg {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 20px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-11n9tyi {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-12uktrn {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-1wqvwy {
            --framer-paragraph-spacing: 0px;
            flex: none;
            height: auto;
            position: relative;
            white-space: pre-wrap;
            width: 507px;
            word-break: break-word;
            word-wrap: break-word
        }

        .framer-XyIhp .framer-1rsw90i,
        .framer-XyIhp .framer-1cvknzf {
            flex: none;
            height: 5px;
            position: relative;
            width: 300px
        }

        .framer-XyIhp .framer-171rso8 {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 40px;
            height: 307px;
            justify-content: center;
            overflow: hidden;
            padding: 0;
            position: relative;
            width: 586px
        }

        .framer-XyIhp .framer-1l2tehj,
        .framer-XyIhp .framer-oarzfo {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 40px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-1s6thx0,
        .framer-XyIhp .framer-1y7uznq,
        .framer-XyIhp .framer-1ac0iy6 {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-5o6sw6,
        .framer-XyIhp .framer-yfrdu1,
        .framer-XyIhp .framer-1vsu43a {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 19px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-fgxpt6,
        .framer-XyIhp .framer-bx7k81,
        .framer-XyIhp .framer-g24dxc,
        .framer-XyIhp .framer-cxzg9j,
        .framer-XyIhp .framer-5in8s8,
        .framer-XyIhp .framer-11x7boo {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-16vopup,
        .framer-XyIhp .framer-o6mvud,
        .framer-XyIhp .framer-ppismu,
        .framer-XyIhp .framer-1n5slct,
        .framer-XyIhp .framer-1o4dgbd,
        .framer-XyIhp .framer-6kh8ho,
        .framer-XyIhp .framer-xkuahq,
        .framer-XyIhp .framer-16phgcw,
        .framer-XyIhp .framer-11hf3be,
        .framer-XyIhp .framer-1wxj3xv,
        .framer-XyIhp .framer-141yalg,
        .framer-XyIhp .framer-e1pstx {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 12px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-9hhfv2,
        .framer-XyIhp .framer-11r78z8,
        .framer-XyIhp .framer-863ell,
        .framer-XyIhp .framer-1c4lsc1,
        .framer-XyIhp .framer-1do99ys,
        .framer-XyIhp .framer-15lyoiy,
        .framer-XyIhp .framer-o8ykqo,
        .framer-XyIhp .framer-jsdva6,
        .framer-XyIhp .framer-oyixu6,
        .framer-XyIhp .framer-mi8nsc,
        .framer-XyIhp .framer-2hee0i,
        .framer-XyIhp .framer-11a4px1 {
            flex: none;
            height: 24px;
            overflow: visible;
            position: relative;
            width: 24px
        }

        .framer-XyIhp .framer-fzw30y,
        .framer-XyIhp .framer-ibjzxr {
            aspect-ratio: .96 / 1;
            flex: none;
            height: var(--framer-aspect-ratio-supported, 27px);
            left: 0;
            position: absolute;
            top: 0;
            width: 26px
        }

        .framer-XyIhp .framer-6g7k00,
        .framer-XyIhp .framer-10qkvrl {
            aspect-ratio: .96 / 1;
            flex: none;
            height: var(--framer-aspect-ratio-supported, 26px);
            left: 0;
            position: absolute;
            top: 0;
            width: 25px
        }

        .framer-XyIhp .framer-1d5gj3c,
        .framer-XyIhp .framer-1azn9x,
        .framer-XyIhp .framer-u2xvrq,
        .framer-XyIhp .framer-cgpehi,
        .framer-XyIhp .framer-jfectt,
        .framer-XyIhp .framer-yrts8x,
        .framer-XyIhp .framer-1v55kv2,
        .framer-XyIhp .framer-1uya3g7 {
            flex: none;
            height: 25px;
            left: 0;
            position: absolute;
            top: 0;
            width: 24px
        }

        .framer-XyIhp .framer-8lwzvj,
        .framer-XyIhp .framer-1bz98ul {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 13px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-r1gfd9 {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            height: min-content;
            justify-content: space-evenly;
            overflow: visible;
            padding: 0;
            position: relative;
            transform: perspective(1200px);
            width: 100%
        }

        .framer-XyIhp .framer-1dweb51 {
            aspect-ratio: .9978354978354979 / 1;
            flex: none;
            height: var(--framer-aspect-ratio-supported, 391px);
            overflow: visible;
            position: relative;
            width: 390px
        }

        .framer-XyIhp .framer-1dw3xah,
        .framer-XyIhp .framer-bidwiq,
        .framer-XyIhp .framer-sjs455 {
            align-content: center;
            align-items: center;
            align-self: stretch;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 19px;
            height: min-content;
            justify-content: center;
            overflow: visible;
            padding: 0;
            position: relative;
            width: auto
        }

        .framer-XyIhp .framer-1au4ihw,
        .framer-XyIhp .framer-1yir2yf,
        .framer-XyIhp .framer-oryefi {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: center;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-ban390,
        .framer-XyIhp .framer-sbluc2 {
            aspect-ratio: 1 / 1;
            flex: none;
            height: var(--framer-aspect-ratio-supported, 390px);
            overflow: visible;
            position: relative;
            width: 390px
        }

        .framer-XyIhp .framer-dvvghe {
            -webkit-filter: blur(100px);
            background-color: var(--token-0bc9d5e2-cabe-4eb2-b691-d577f23a764a, #0052cc);
            filter: blur(100px);
            flex: none;
            height: 433px;
            left: 1120px;
            opacity: .33;
            overflow: hidden;
            position: absolute;
            top: 14px;
            transform: rotate(35deg);
            width: 157px;
            z-index: 1
        }

        .framer-XyIhp .framer-121wmus {
            -webkit-filter: blur(100px);
            background-color: var(--token-0bc9d5e2-cabe-4eb2-b691-d577f23a764a, #0052cc);
            bottom: 35px;
            filter: blur(100px);
            flex: none;
            height: 433px;
            left: 339px;
            opacity: .33;
            overflow: hidden;
            position: absolute;
            transform: rotate(35deg);
            width: 125px;
            z-index: 1
        }

        .framer-XyIhp .framer-1gpnxl2 {
            -webkit-filter: blur(100px);
            background-color: var(--token-0bc9d5e2-cabe-4eb2-b691-d577f23a764a, #0052cc);
            bottom: -3px;
            filter: blur(100px);
            flex: none;
            height: 433px;
            opacity: .33;
            overflow: hidden;
            position: absolute;
            right: -49px;
            transform: rotate(35deg);
            width: 125px;
            z-index: 1
        }

        .framer-XyIhp .framer-sx4hj {
            -webkit-filter: blur(100px);
            background-color: var(--token-0bc9d5e2-cabe-4eb2-b691-d577f23a764a, #0052cc);
            filter: blur(100px);
            flex: none;
            height: 433px;
            left: -18px;
            opacity: .33;
            overflow: hidden;
            position: absolute;
            top: -88px;
            transform: rotate(35deg);
            width: 125px;
            z-index: 1
        }

        .framer-XyIhp .framer-wfydv7 {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: 1321px;
            justify-content: center;
            left: -785px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            right: 716px;
            top: -229px;
            transform: perspective(1200px);
            z-index: 1
        }

        .framer-XyIhp .framer-1bvrrp6,
        .framer-XyIhp .framer-1eqhihn {
            aspect-ratio: 1.1431767337807606 / 1;
            flex: none;
            height: var(--framer-aspect-ratio-supported, 1067px);
            overflow: visible;
            position: relative;
            width: 1220px
        }

        .framer-XyIhp .framer-1t02k4l {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: 1321px;
            justify-content: center;
            left: 685px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            right: -744px;
            top: -260px;
            transform: perspective(1200px);
            z-index: 1
        }

        .framer-XyIhp .framer-8dwf5n {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            max-width: 1350px;
            overflow: visible;
            padding: 200px 24px;
            position: relative;
            width: 100%
        }

        .framer-XyIhp .framer-101sprs {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 120px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-XyIhp .framer-16dkls3 {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 44px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            transform: perspective(1200px);
            width: min-content;
            z-index: 9
        }

        .framer-XyIhp .framer-1bgv53g {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 20px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-yv0vdr {
            flex: none;
            height: 70px;
            position: relative;
            width: 64px
        }

        .framer-XyIhp .framer-1vrh72g {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 15px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-1xvgcze {
            --border-bottom-width: 1px;
            --border-color: rgba(255, 255, 255, .18);
            --border-left-width: 1px;
            --border-right-width: 1px;
            --border-style: solid;
            --border-top-width: 1px;
            align-content: center;
            align-items: center;
            background: linear-gradient(180deg, rgba(255, 255, 255, .19) 0%, rgba(255, 255, 255, .04) 100%);
            border-radius: 10px;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 8px 16px;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-1vx5c0d {
            flex: none;
            height: 25px;
            position: relative;
            width: 25px
        }

        .framer-XyIhp .framer-1gunb97 {
            --border-bottom-width: 1px;
            --border-color: rgba(255, 255, 255, .18);
            --border-left-width: 1px;
            --border-right-width: 1px;
            --border-style: solid;
            --border-top-width: 1px;
            align-content: center;
            align-items: center;
            background: linear-gradient(180deg, rgba(255, 255, 255, .19) 0%, rgba(255, 255, 255, .04) 100%);
            border-radius: 10px;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 8px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 8px 16px;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-bkc8bw {
            flex: none;
            height: 31px;
            position: relative;
            width: 31px
        }

        .framer-XyIhp .framer-o78sl0 {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 12px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-XyIhp .framer-7vz1aj {
            --framer-paragraph-spacing: 0px;
            flex: none;
            height: auto;
            opacity: .6;
            position: relative;
            white-space: pre-wrap;
            width: 607px;
            word-break: break-word;
            word-wrap: break-word
        }

        .framer-XyIhp .framer-4krusi-container {
            flex: none;
            height: auto;
            position: relative;
            width: 100%
        }

        .framer-XyIhp .footer {
            flex: none;
            height: auto;
            position: relative;
            width: 1440px
        }

        @supports (background: -webkit-named-image(i)) and (not (scale:1)) {

            .framer-XyIhp .framer-1fdb8e9,
            .framer-XyIhp .navbar,
            .framer-XyIhp .framer-1pxana1,
            .framer-XyIhp .framer-oq9jai,
            .framer-XyIhp .framer-a7brsr,
            .framer-XyIhp .framer-1wlua9y,
            .framer-XyIhp .framer-dhmoms,
            .framer-XyIhp .framer-knvts4,
            .framer-XyIhp .framer-1tuo9lc,
            .framer-XyIhp .framer-mkl5is,
            .framer-XyIhp .framer-mhrp0o,
            .framer-XyIhp .framer-t14cig,
            .framer-XyIhp .framer-cakrzc,
            .framer-XyIhp .framer-1bxm4u7,
            .framer-XyIhp .framer-19bu65k,
            .framer-XyIhp .framer-1i5chw,
            .framer-XyIhp .framer-1ewqsxg,
            .framer-XyIhp .framer-l6tnfh,
            .framer-XyIhp .framer-1p0sh1i,
            .framer-XyIhp .framer-1uepckd,
            .framer-XyIhp .framer-1rxdd4g,
            .framer-XyIhp .framer-13rx7v7,
            .framer-XyIhp .framer-pf7ty7,
            .framer-XyIhp .framer-1wd6miv,
            .framer-XyIhp .framer-1fkqlne,
            .framer-XyIhp .framer-b3qr2b,
            .framer-XyIhp .framer-ypdnqf,
            .framer-XyIhp .framer-9s1iyu,
            .framer-XyIhp .framer-innttb,
            .framer-XyIhp .framer-dju9og,
            .framer-XyIhp .framer-5jto6g,
            .framer-XyIhp .framer-61j3w9,
            .framer-XyIhp .framer-1q9737y,
            .framer-XyIhp .framer-1kaoxzn,
            .framer-XyIhp .framer-p7g58f,
            .framer-XyIhp .framer-nro0f5,
            .framer-XyIhp .framer-s2ewvg,
            .framer-XyIhp .framer-cwpdvo,
            .framer-XyIhp .framer-11n9tyi,
            .framer-XyIhp .framer-12uktrn,
            .framer-XyIhp .framer-17o45yf,
            .framer-XyIhp .framer-171rso8,
            .framer-XyIhp .framer-1l2tehj,
            .framer-XyIhp .framer-1s6thx0,
            .framer-XyIhp .framer-5o6sw6,
            .framer-XyIhp .framer-fgxpt6,
            .framer-XyIhp .framer-16vopup,
            .framer-XyIhp .framer-o6mvud,
            .framer-XyIhp .framer-1y7uznq,
            .framer-XyIhp .framer-yfrdu1,
            .framer-XyIhp .framer-bx7k81,
            .framer-XyIhp .framer-ppismu,
            .framer-XyIhp .framer-1n5slct,
            .framer-XyIhp .framer-oarzfo,
            .framer-XyIhp .framer-1ac0iy6,
            .framer-XyIhp .framer-1vsu43a,
            .framer-XyIhp .framer-g24dxc,
            .framer-XyIhp .framer-1o4dgbd,
            .framer-XyIhp .framer-6kh8ho,
            .framer-XyIhp .framer-8lwzvj,
            .framer-XyIhp .framer-1r8s7jc,
            .framer-XyIhp .framer-1und9d4,
            .framer-XyIhp .framer-dasrki,
            .framer-XyIhp .framer-1d7q4in,
            .framer-XyIhp .framer-10ecp3a,
            .framer-XyIhp .framer-w6947w,
            .framer-XyIhp .framer-cxzg9j,
            .framer-XyIhp .framer-1dw3xah,
            .framer-XyIhp .framer-1au4ihw,
            .framer-XyIhp .framer-xkuahq,
            .framer-XyIhp .framer-16phgcw,
            .framer-XyIhp .framer-5in8s8,
            .framer-XyIhp .framer-bidwiq,
            .framer-XyIhp .framer-1yir2yf,
            .framer-XyIhp .framer-11hf3be,
            .framer-XyIhp .framer-1wxj3xv,
            .framer-XyIhp .framer-11x7boo,
            .framer-XyIhp .framer-sjs455,
            .framer-XyIhp .framer-oryefi,
            .framer-XyIhp .framer-141yalg,
            .framer-XyIhp .framer-e1pstx,
            .framer-XyIhp .framer-1wdcgtp,
            .framer-XyIhp .framer-wfydv7,
            .framer-XyIhp .framer-1t02k4l,
            .framer-XyIhp .framer-8dwf5n,
            .framer-XyIhp .framer-101sprs,
            .framer-XyIhp .framer-16dkls3,
            .framer-XyIhp .framer-1bgv53g,
            .framer-XyIhp .framer-zd2tfq,
            .framer-XyIhp .framer-sc0hwa,
            .framer-XyIhp .framer-1vrh72g,
            .framer-XyIhp .framer-1xvgcze,
            .framer-XyIhp .framer-1gunb97,
            .framer-XyIhp .framer-o78sl0,
            .framer-XyIhp .framer-1bz98ul {
                gap: 0px
            }

            .framer-XyIhp .framer-1fdb8e9>*,
            .framer-XyIhp .framer-1pxana1>*,
            .framer-XyIhp .framer-a7brsr>*,
            .framer-XyIhp .framer-1tuo9lc>*,
            .framer-XyIhp .framer-1i5chw>*,
            .framer-XyIhp .framer-l6tnfh>*,
            .framer-XyIhp .framer-1rxdd4g>*,
            .framer-XyIhp .framer-13rx7v7>*,
            .framer-XyIhp .framer-1q9737y>*,
            .framer-XyIhp .framer-11n9tyi>*,
            .framer-XyIhp .framer-1r8s7jc>*,
            .framer-XyIhp .framer-1und9d4>*,
            .framer-XyIhp .framer-8dwf5n>* {
                margin: 0
            }

            .framer-XyIhp .framer-1fdb8e9>:first-child,
            .framer-XyIhp .framer-1pxana1>:first-child,
            .framer-XyIhp .framer-a7brsr>:first-child,
            .framer-XyIhp .framer-1wlua9y>:first-child,
            .framer-XyIhp .framer-dhmoms>:first-child,
            .framer-XyIhp .framer-1tuo9lc>:first-child,
            .framer-XyIhp .framer-t14cig>:first-child,
            .framer-XyIhp .framer-1i5chw>:first-child,
            .framer-XyIhp .framer-1ewqsxg>:first-child,
            .framer-XyIhp .framer-l6tnfh>:first-child,
            .framer-XyIhp .framer-1p0sh1i>:first-child,
            .framer-XyIhp .framer-1rxdd4g>:first-child,
            .framer-XyIhp .framer-13rx7v7>:first-child,
            .framer-XyIhp .framer-pf7ty7>:first-child,
            .framer-XyIhp .framer-1wd6miv>:first-child,
            .framer-XyIhp .framer-ypdnqf>:first-child,
            .framer-XyIhp .framer-innttb>:first-child,
            .framer-XyIhp .framer-dju9og>:first-child,
            .framer-XyIhp .framer-5jto6g>:first-child,
            .framer-XyIhp .framer-61j3w9>:first-child,
            .framer-XyIhp .framer-1q9737y>:first-child,
            .framer-XyIhp .framer-nro0f5>:first-child,
            .framer-XyIhp .framer-s2ewvg>:first-child,
            .framer-XyIhp .framer-11n9tyi>:first-child,
            .framer-XyIhp .framer-171rso8>:first-child,
            .framer-XyIhp .framer-5o6sw6>:first-child,
            .framer-XyIhp .framer-fgxpt6>:first-child,
            .framer-XyIhp .framer-yfrdu1>:first-child,
            .framer-XyIhp .framer-bx7k81>:first-child,
            .framer-XyIhp .framer-1vsu43a>:first-child,
            .framer-XyIhp .framer-g24dxc>:first-child,
            .framer-XyIhp .framer-1r8s7jc>:first-child,
            .framer-XyIhp .framer-1und9d4>:first-child,
            .framer-XyIhp .framer-dasrki>:first-child,
            .framer-XyIhp .framer-1d7q4in>:first-child,
            .framer-XyIhp .framer-cxzg9j>:first-child,
            .framer-XyIhp .framer-1dw3xah>:first-child,
            .framer-XyIhp .framer-1au4ihw>:first-child,
            .framer-XyIhp .framer-5in8s8>:first-child,
            .framer-XyIhp .framer-bidwiq>:first-child,
            .framer-XyIhp .framer-1yir2yf>:first-child,
            .framer-XyIhp .framer-11x7boo>:first-child,
            .framer-XyIhp .framer-sjs455>:first-child,
            .framer-XyIhp .framer-oryefi>:first-child,
            .framer-XyIhp .framer-1wdcgtp>:first-child,
            .framer-XyIhp .framer-8dwf5n>:first-child,
            .framer-XyIhp .framer-101sprs>:first-child,
            .framer-XyIhp .framer-16dkls3>:first-child,
            .framer-XyIhp .framer-1bgv53g>:first-child,
            .framer-XyIhp .framer-o78sl0>:first-child {
                margin-top: 0
            }

            .framer-XyIhp .framer-1fdb8e9>:last-child,
            .framer-XyIhp .framer-1pxana1>:last-child,
            .framer-XyIhp .framer-a7brsr>:last-child,
            .framer-XyIhp .framer-1wlua9y>:last-child,
            .framer-XyIhp .framer-dhmoms>:last-child,
            .framer-XyIhp .framer-1tuo9lc>:last-child,
            .framer-XyIhp .framer-t14cig>:last-child,
            .framer-XyIhp .framer-1i5chw>:last-child,
            .framer-XyIhp .framer-1ewqsxg>:last-child,
            .framer-XyIhp .framer-l6tnfh>:last-child,
            .framer-XyIhp .framer-1p0sh1i>:last-child,
            .framer-XyIhp .framer-1rxdd4g>:last-child,
            .framer-XyIhp .framer-13rx7v7>:last-child,
            .framer-XyIhp .framer-pf7ty7>:last-child,
            .framer-XyIhp .framer-1wd6miv>:last-child,
            .framer-XyIhp .framer-ypdnqf>:last-child,
            .framer-XyIhp .framer-innttb>:last-child,
            .framer-XyIhp .framer-dju9og>:last-child,
            .framer-XyIhp .framer-5jto6g>:last-child,
            .framer-XyIhp .framer-61j3w9>:last-child,
            .framer-XyIhp .framer-1q9737y>:last-child,
            .framer-XyIhp .framer-nro0f5>:last-child,
            .framer-XyIhp .framer-s2ewvg>:last-child,
            .framer-XyIhp .framer-11n9tyi>:last-child,
            .framer-XyIhp .framer-171rso8>:last-child,
            .framer-XyIhp .framer-5o6sw6>:last-child,
            .framer-XyIhp .framer-fgxpt6>:last-child,
            .framer-XyIhp .framer-yfrdu1>:last-child,
            .framer-XyIhp .framer-bx7k81>:last-child,
            .framer-XyIhp .framer-1vsu43a>:last-child,
            .framer-XyIhp .framer-g24dxc>:last-child,
            .framer-XyIhp .framer-1r8s7jc>:last-child,
            .framer-XyIhp .framer-1und9d4>:last-child,
            .framer-XyIhp .framer-dasrki>:last-child,
            .framer-XyIhp .framer-1d7q4in>:last-child,
            .framer-XyIhp .framer-cxzg9j>:last-child,
            .framer-XyIhp .framer-1dw3xah>:last-child,
            .framer-XyIhp .framer-1au4ihw>:last-child,
            .framer-XyIhp .framer-5in8s8>:last-child,
            .framer-XyIhp .framer-bidwiq>:last-child,
            .framer-XyIhp .framer-1yir2yf>:last-child,
            .framer-XyIhp .framer-11x7boo>:last-child,
            .framer-XyIhp .framer-sjs455>:last-child,
            .framer-XyIhp .framer-oryefi>:last-child,
            .framer-XyIhp .framer-1wdcgtp>:last-child,
            .framer-XyIhp .framer-8dwf5n>:last-child,
            .framer-XyIhp .framer-101sprs>:last-child,
            .framer-XyIhp .framer-16dkls3>:last-child,
            .framer-XyIhp .framer-1bgv53g>:last-child,
            .framer-XyIhp .framer-o78sl0>:last-child {
                margin-bottom: 0
            }

            .framer-XyIhp .navbar>*,
            .framer-XyIhp .framer-oq9jai>*,
            .framer-XyIhp .framer-knvts4>*,
            .framer-XyIhp .framer-mkl5is>*,
            .framer-XyIhp .framer-1bxm4u7>*,
            .framer-XyIhp .framer-1uepckd>*,
            .framer-XyIhp .framer-b3qr2b>*,
            .framer-XyIhp .framer-p7g58f>*,
            .framer-XyIhp .framer-12uktrn>*,
            .framer-XyIhp .framer-w6947w>*,
            .framer-XyIhp .framer-wfydv7>*,
            .framer-XyIhp .framer-1t02k4l>*,
            .framer-XyIhp .framer-sc0hwa>*,
            .framer-XyIhp .framer-1xvgcze>* {
                margin: 0 5px
            }

            .framer-XyIhp .navbar>:first-child,
            .framer-XyIhp .framer-oq9jai>:first-child,
            .framer-XyIhp .framer-knvts4>:first-child,
            .framer-XyIhp .framer-mkl5is>:first-child,
            .framer-XyIhp .framer-mhrp0o>:first-child,
            .framer-XyIhp .framer-cakrzc>:first-child,
            .framer-XyIhp .framer-1bxm4u7>:first-child,
            .framer-XyIhp .framer-19bu65k>:first-child,
            .framer-XyIhp .framer-1uepckd>:first-child,
            .framer-XyIhp .framer-1fkqlne>:first-child,
            .framer-XyIhp .framer-b3qr2b>:first-child,
            .framer-XyIhp .framer-9s1iyu>:first-child,
            .framer-XyIhp .framer-1kaoxzn>:first-child,
            .framer-XyIhp .framer-p7g58f>:first-child,
            .framer-XyIhp .framer-cwpdvo>:first-child,
            .framer-XyIhp .framer-12uktrn>:first-child,
            .framer-XyIhp .framer-17o45yf>:first-child,
            .framer-XyIhp .framer-1l2tehj>:first-child,
            .framer-XyIhp .framer-1s6thx0>:first-child,
            .framer-XyIhp .framer-16vopup>:first-child,
            .framer-XyIhp .framer-o6mvud>:first-child,
            .framer-XyIhp .framer-1y7uznq>:first-child,
            .framer-XyIhp .framer-ppismu>:first-child,
            .framer-XyIhp .framer-1n5slct>:first-child,
            .framer-XyIhp .framer-oarzfo>:first-child,
            .framer-XyIhp .framer-1ac0iy6>:first-child,
            .framer-XyIhp .framer-1o4dgbd>:first-child,
            .framer-XyIhp .framer-6kh8ho>:first-child,
            .framer-XyIhp .framer-8lwzvj>:first-child,
            .framer-XyIhp .framer-10ecp3a>:first-child,
            .framer-XyIhp .framer-w6947w>:first-child,
            .framer-XyIhp .framer-xkuahq>:first-child,
            .framer-XyIhp .framer-16phgcw>:first-child,
            .framer-XyIhp .framer-11hf3be>:first-child,
            .framer-XyIhp .framer-1wxj3xv>:first-child,
            .framer-XyIhp .framer-141yalg>:first-child,
            .framer-XyIhp .framer-e1pstx>:first-child,
            .framer-XyIhp .framer-wfydv7>:first-child,
            .framer-XyIhp .framer-1t02k4l>:first-child,
            .framer-XyIhp .framer-zd2tfq>:first-child,
            .framer-XyIhp .framer-sc0hwa>:first-child,
            .framer-XyIhp .framer-1vrh72g>:first-child,
            .framer-XyIhp .framer-1xvgcze>:first-child,
            .framer-XyIhp .framer-1gunb97>:first-child,
            .framer-XyIhp .framer-1bz98ul>:first-child {
                margin-left: 0
            }

            .framer-XyIhp .navbar>:last-child,
            .framer-XyIhp .framer-oq9jai>:last-child,
            .framer-XyIhp .framer-knvts4>:last-child,
            .framer-XyIhp .framer-mkl5is>:last-child,
            .framer-XyIhp .framer-mhrp0o>:last-child,
            .framer-XyIhp .framer-cakrzc>:last-child,
            .framer-XyIhp .framer-1bxm4u7>:last-child,
            .framer-XyIhp .framer-19bu65k>:last-child,
            .framer-XyIhp .framer-1uepckd>:last-child,
            .framer-XyIhp .framer-1fkqlne>:last-child,
            .framer-XyIhp .framer-b3qr2b>:last-child,
            .framer-XyIhp .framer-9s1iyu>:last-child,
            .framer-XyIhp .framer-1kaoxzn>:last-child,
            .framer-XyIhp .framer-p7g58f>:last-child,
            .framer-XyIhp .framer-cwpdvo>:last-child,
            .framer-XyIhp .framer-12uktrn>:last-child,
            .framer-XyIhp .framer-17o45yf>:last-child,
            .framer-XyIhp .framer-1l2tehj>:last-child,
            .framer-XyIhp .framer-1s6thx0>:last-child,
            .framer-XyIhp .framer-16vopup>:last-child,
            .framer-XyIhp .framer-o6mvud>:last-child,
            .framer-XyIhp .framer-1y7uznq>:last-child,
            .framer-XyIhp .framer-ppismu>:last-child,
            .framer-XyIhp .framer-1n5slct>:last-child,
            .framer-XyIhp .framer-oarzfo>:last-child,
            .framer-XyIhp .framer-1ac0iy6>:last-child,
            .framer-XyIhp .framer-1o4dgbd>:last-child,
            .framer-XyIhp .framer-6kh8ho>:last-child,
            .framer-XyIhp .framer-8lwzvj>:last-child,
            .framer-XyIhp .framer-10ecp3a>:last-child,
            .framer-XyIhp .framer-w6947w>:last-child,
            .framer-XyIhp .framer-xkuahq>:last-child,
            .framer-XyIhp .framer-16phgcw>:last-child,
            .framer-XyIhp .framer-11hf3be>:last-child,
            .framer-XyIhp .framer-1wxj3xv>:last-child,
            .framer-XyIhp .framer-141yalg>:last-child,
            .framer-XyIhp .framer-e1pstx>:last-child,
            .framer-XyIhp .framer-wfydv7>:last-child,
            .framer-XyIhp .framer-1t02k4l>:last-child,
            .framer-XyIhp .framer-zd2tfq>:last-child,
            .framer-XyIhp .framer-sc0hwa>:last-child,
            .framer-XyIhp .framer-1vrh72g>:last-child,
            .framer-XyIhp .framer-1xvgcze>:last-child,
            .framer-XyIhp .framer-1gunb97>:last-child,
            .framer-XyIhp .framer-1bz98ul>:last-child {
                margin-right: 0
            }

            .framer-XyIhp .framer-1wlua9y>* {
                margin: 40px 0
            }

            .framer-XyIhp .framer-dhmoms>*,
            .framer-XyIhp .framer-1ewqsxg>*,
            .framer-XyIhp .framer-1p0sh1i>* {
                margin: 12.5px 0
            }

            .framer-XyIhp .framer-mhrp0o>*,
            .framer-XyIhp .framer-1gunb97>* {
                margin: 0 4px
            }

            .framer-XyIhp .framer-t14cig>* {
                margin: 1px 0
            }

            .framer-XyIhp .framer-cakrzc>*,
            .framer-XyIhp .framer-1kaoxzn>*,
            .framer-XyIhp .framer-1s6thx0>*,
            .framer-XyIhp .framer-1y7uznq>*,
            .framer-XyIhp .framer-1ac0iy6>* {
                margin: 0
            }

            .framer-XyIhp .framer-19bu65k>* {
                margin: 0 82.5px
            }

            .framer-XyIhp .framer-pf7ty7>*,
            .framer-XyIhp .framer-dasrki>* {
                margin: 65px 0
            }

            .framer-XyIhp .framer-1wd6miv>*,
            .framer-XyIhp .framer-1d7q4in>* {
                margin: 8px 0
            }

            .framer-XyIhp .framer-1fkqlne>*,
            .framer-XyIhp .framer-cwpdvo>*,
            .framer-XyIhp .framer-17o45yf>*,
            .framer-XyIhp .framer-10ecp3a>*,
            .framer-XyIhp .framer-zd2tfq>* {
                margin: 0 10px
            }

            .framer-XyIhp .framer-ypdnqf>* {
                margin: 45px 0
            }

            .framer-XyIhp .framer-9s1iyu>* {
                margin: 0 25px
            }

            .framer-XyIhp .framer-innttb>*,
            .framer-XyIhp .framer-dju9og>*,
            .framer-XyIhp .framer-5jto6g>* {
                margin: 13.5px 0
            }

            .framer-XyIhp .framer-61j3w9>*,
            .framer-XyIhp .framer-fgxpt6>*,
            .framer-XyIhp .framer-bx7k81>*,
            .framer-XyIhp .framer-g24dxc>*,
            .framer-XyIhp .framer-cxzg9j>*,
            .framer-XyIhp .framer-1au4ihw>*,
            .framer-XyIhp .framer-5in8s8>*,
            .framer-XyIhp .framer-1yir2yf>*,
            .framer-XyIhp .framer-11x7boo>*,
            .framer-XyIhp .framer-oryefi>*,
            .framer-XyIhp .framer-1wdcgtp>* {
                margin: 5px 0
            }

            .framer-XyIhp .framer-nro0f5>* {
                margin: 25px 0
            }

            .framer-XyIhp .framer-s2ewvg>*,
            .framer-XyIhp .framer-1bgv53g>* {
                margin: 10px 0
            }

            .framer-XyIhp .framer-171rso8>* {
                margin: 20px 0
            }

            .framer-XyIhp .framer-1l2tehj>*,
            .framer-XyIhp .framer-oarzfo>* {
                margin: 0 20px
            }

            .framer-XyIhp .framer-5o6sw6>*,
            .framer-XyIhp .framer-yfrdu1>*,
            .framer-XyIhp .framer-1vsu43a>*,
            .framer-XyIhp .framer-1dw3xah>*,
            .framer-XyIhp .framer-bidwiq>*,
            .framer-XyIhp .framer-sjs455>* {
                margin: 9.5px 0
            }

            .framer-XyIhp .framer-16vopup>*,
            .framer-XyIhp .framer-o6mvud>*,
            .framer-XyIhp .framer-ppismu>*,
            .framer-XyIhp .framer-1n5slct>*,
            .framer-XyIhp .framer-1o4dgbd>*,
            .framer-XyIhp .framer-6kh8ho>*,
            .framer-XyIhp .framer-xkuahq>*,
            .framer-XyIhp .framer-16phgcw>*,
            .framer-XyIhp .framer-11hf3be>*,
            .framer-XyIhp .framer-1wxj3xv>*,
            .framer-XyIhp .framer-141yalg>*,
            .framer-XyIhp .framer-e1pstx>* {
                margin: 0 6px
            }

            .framer-XyIhp .framer-8lwzvj>*,
            .framer-XyIhp .framer-1bz98ul>* {
                margin: 0;
                margin-left: calc(12.64598560333252px / 2);
                margin-right: calc(12.64598560333252px / 2)
            }

            .framer-XyIhp .framer-101sprs>* {
                margin: 60px 0
            }

            .framer-XyIhp .framer-16dkls3>* {
                margin: 22px 0
            }

            .framer-XyIhp .framer-1vrh72g>* {
                margin: 0 7.5px
            }

            .framer-XyIhp .framer-o78sl0>* {
                margin: 6px 0
            }
        }

        @media (min-width: 1440px) {
            .framer-XyIhp .hidden-1fdb8e9 {
                display: none !important
            }
        }

        @media (min-width: 810px) and (max-width: 1439px) {
            .framer-XyIhp .hidden-4fkeid {
                display: none !important
            }

            .framer-body {
                background: var(--token-f9b89b17-0991-4c0e-b401-ca53a64a9c7b, rgb(2, 4, 29))
            }

            .framer-XyIhp .framer-1fdb8e9 {
                width: 810px
            }

            .framer-XyIhp .navbar {
                width: 90%
            }

            .framer-XyIhp .framer-3afuy5-container,
            .framer-XyIhp .framer-18va552,
            .framer-XyIhp .framer-d5wszl,
            .framer-XyIhp .framer-gzvsc5,
            .framer-XyIhp .framer-sg55ij,
            .framer-XyIhp .framer-1bd4imv,
            .framer-XyIhp .framer-15kjguo {
                flex: 1 0 0px;
                width: 1px
            }

            .framer-XyIhp .framer-oq9jai {
                height: 97%;
                left: 0;
                right: 0;
                top: -250px
            }

            .framer-XyIhp .framer-ry9op0-container {
                height: 279px;
                left: -246px;
                top: 331px;
                width: 770px
            }

            .framer-XyIhp .framer-a7brsr {
                padding: 200px 40px 0
            }

            .framer-XyIhp .framer-dhmoms,
            .framer-XyIhp .framer-1wd6miv,
            .framer-XyIhp .framer-1fkqlne,
            .framer-XyIhp .framer-1d7q4in,
            .framer-XyIhp .framer-10ecp3a,
            .framer-XyIhp .footer {
                width: 100%
            }

            .framer-XyIhp .framer-mkl5is {
                justify-content: center
            }

            .framer-XyIhp .framer-vxbjup {
                width: 600px
            }

            .framer-XyIhp .framer-1bxm4u7 {
                aspect-ratio: 1.1716171617161717 / 1;
                height: var(--framer-aspect-ratio-supported, 790px);
                width: 926px
            }

            .framer-XyIhp .framer-19bu65k {
                flex-direction: column;
                gap: 0px;
                height: min-content;
                padding: 80px 0
            }

            .framer-XyIhp .framer-4qqe42 {
                height: 101px;
                transform: rotate(90deg)
            }

            .framer-XyIhp .framer-1uepckd {
                bottom: unset;
                height: 84px;
                left: 0;
                right: 0;
                top: -48px
            }

            .framer-XyIhp .framer-13rx7v7 {
                padding: 160px 30px 80px
            }

            .framer-XyIhp .framer-ypdnqf {
                flex-wrap: wrap;
                width: 100%
            }

            .framer-XyIhp .framer-9s1iyu {
                flex-wrap: wrap;
                justify-content: center;
                width: 100%
            }

            .framer-XyIhp .framer-1q9737y {
                padding: 50px 40px 80px 24px
            }

            .framer-XyIhp .framer-1kaoxzn {
                flex-direction: column;
                gap: 80px
            }

            .framer-XyIhp .framer-p7g58f {
                left: unset;
                position: relative;
                top: unset;
                transform: unset;
                width: 100%
            }

            .framer-XyIhp .framer-1vnopp6-container {
                height: var(--framer-aspect-ratio-supported, 500px);
                width: 500px
            }

            .framer-XyIhp .framer-nro0f5 {
                align-content: center;
                align-items: center;
                justify-content: center
            }

            .framer-XyIhp .framer-s2ewvg,
            .framer-XyIhp .framer-171rso8,
            .framer-XyIhp .framer-1l2tehj,
            .framer-XyIhp .framer-5o6sw6,
            .framer-XyIhp .framer-fgxpt6,
            .framer-XyIhp .framer-yfrdu1,
            .framer-XyIhp .framer-bx7k81,
            .framer-XyIhp .framer-oarzfo,
            .framer-XyIhp .framer-1vsu43a,
            .framer-XyIhp .framer-g24dxc {
                align-content: center;
                align-items: center
            }

            .framer-XyIhp .framer-11n9tyi {
                align-content: center;
                align-items: center;
                order: 2
            }

            .framer-XyIhp .framer-1wqvwy {
                order: 3
            }

            .framer-XyIhp .framer-17o45yf {
                order: 0
            }

            .framer-XyIhp .framer-1und9d4 {
                padding: 80px 40px
            }

            .framer-XyIhp .framer-r1gfd9 {
                flex-direction: column;
                gap: 40px;
                justify-content: flex-start
            }

            .framer-XyIhp .framer-wfydv7 {
                height: min-content;
                left: -285px;
                right: unset;
                top: 351px;
                width: min-content
            }

            .framer-XyIhp .framer-1bvrrp6,
            .framer-XyIhp .framer-1eqhihn {
                height: var(--framer-aspect-ratio-supported, 568px);
                width: 650px
            }

            .framer-XyIhp .framer-1t02k4l {
                height: min-content;
                left: 98%;
                right: unset;
                top: 25%;
                transform: perspective(1200px) translate(-50%, -50%);
                width: 340px
            }

            .framer-XyIhp .framer-8dwf5n {
                padding: 180px 24px 200px
            }

            .framer-XyIhp .framer-zd2tfq {
                align-self: stretch;
                width: auto
            }

            @supports (background: -webkit-named-image(i)) and (not (scale:1)) {

                .framer-XyIhp .framer-19bu65k,
                .framer-XyIhp .framer-1kaoxzn,
                .framer-XyIhp .framer-r1gfd9 {
                    gap: 0px
                }

                .framer-XyIhp .framer-19bu65k>* {
                    margin: 0
                }

                .framer-XyIhp .framer-19bu65k>:first-child,
                .framer-XyIhp .framer-1kaoxzn>:first-child,
                .framer-XyIhp .framer-r1gfd9>:first-child {
                    margin-top: 0
                }

                .framer-XyIhp .framer-19bu65k>:last-child,
                .framer-XyIhp .framer-1kaoxzn>:last-child,
                .framer-XyIhp .framer-r1gfd9>:last-child {
                    margin-bottom: 0
                }

                .framer-XyIhp .framer-1kaoxzn>* {
                    margin: 40px 0
                }

                .framer-XyIhp .framer-r1gfd9>* {
                    margin: 20px 0
                }
            }
        }

        @media (max-width: 809px) {
            .framer-XyIhp .hidden-1t38e76 {
                display: none !important
            }

            .framer-body {
                background: var(--token-f9b89b17-0991-4c0e-b401-ca53a64a9c7b, rgb(2, 4, 29))
            }

            .framer-XyIhp .framer-1fdb8e9 {
                width: 390px
            }

            .framer-XyIhp .navbar {
                --border-left-width: 0px;
                --border-right-width: 0px;
                --border-top-width: 0px;
                border-bottom-left-radius: unset;
                border-bottom-right-radius: unset;
                border-top-left-radius: unset;
                border-top-right-radius: unset;
                padding: 13px 20px;
                top: 0;
                width: 100%
            }

            .framer-XyIhp .framer-3afuy5-container {
                flex: 1 0 0px;
                width: 1px
            }

            .framer-XyIhp .framer-oq9jai {
                height: 97%;
                left: 0;
                right: 0;
                top: -160px
            }

            .framer-XyIhp .framer-ry9op0-container {
                bottom: 327px;
                height: unset;
                left: -13px;
                top: 105px;
                transform: rotate(-40deg);
                width: 250px
            }

            .framer-XyIhp .framer-w5yuep {
                height: var(--framer-aspect-ratio-supported, 600px);
                width: 218%
            }

            .framer-XyIhp .framer-a7brsr {
                max-width: 450px;
                padding: 140px 20px 60px
            }

            .framer-XyIhp .framer-1wlua9y {
                gap: 10px;
                height: 900px
            }

            .framer-XyIhp .framer-dhmoms {
                gap: 30px;
                max-width: 500px;
                width: 100%
            }

            .framer-XyIhp .framer-knvts4 {
                padding: 3px 17px
            }

            .framer-XyIhp .framer-zje5jj {
                aspect-ratio: 1.0416666666666667 / 1;
                height: var(--framer-aspect-ratio-supported, 21px);
                width: 21px
            }

            .framer-XyIhp .framer-1tuo9lc,
            .framer-XyIhp .framer-vxbjup,
            .framer-XyIhp .framer-1q1lw1r,
            .framer-XyIhp .framer-rbiuz4,
            .framer-XyIhp .framer-81d4qq-container,
            .framer-XyIhp .framer-tqod9x,
            .framer-XyIhp .framer-innttb,
            .framer-XyIhp .framer-18hzsxf,
            .framer-XyIhp .framer-dju9og,
            .framer-XyIhp .framer-klceqd,
            .framer-XyIhp .framer-5jto6g,
            .framer-XyIhp .framer-3n8fwm,
            .framer-XyIhp .framer-9e8a7h-container,
            .framer-XyIhp .framer-i88od4,
            .framer-XyIhp .framer-cxzg9j,
            .framer-XyIhp .framer-5in8s8,
            .framer-XyIhp .framer-11x7boo,
            .framer-XyIhp .framer-16dkls3,
            .framer-XyIhp .framer-1bgv53g,
            .framer-XyIhp .framer-1tjf7iv,
            .framer-XyIhp .footer {
                width: 100%
            }

            .framer-XyIhp .framer-mkl5is,
            .framer-XyIhp .framer-b3qr2b,
            .framer-XyIhp .framer-w6947w {
                flex-direction: column;
                gap: 0px;
                width: 100%
            }

            .framer-XyIhp .framer-j4pyps,
            .framer-XyIhp .framer-24a6rx,
            .framer-XyIhp .framer-1q2copr {
                white-space: pre-wrap;
                width: 100%;
                word-break: break-word;
                word-wrap: break-word
            }

            .framer-XyIhp .framer-1bxm4u7 {
                aspect-ratio: .8838383838383839 / 1;
                bottom: -180px;
                height: var(--framer-aspect-ratio-supported, 676px);
                left: 0;
                overflow: visible;
                right: 0;
                transform: perspective(1200px);
                width: unset
            }

            .framer-XyIhp .framer-znopii {
                height: var(--framer-aspect-ratio-supported, 676px);
                width: 532px
            }

            .framer-XyIhp .framer-19bu65k {
                flex-direction: column;
                gap: 80px;
                height: min-content;
                padding: 70px 20px
            }

            .framer-XyIhp .framer-1i5chw,
            .framer-XyIhp .framer-l6tnfh {
                max-width: 350px;
                width: 100%
            }

            .framer-XyIhp .framer-1ewqsxg,
            .framer-XyIhp .framer-1p0sh1i {
                padding: 0 20px;
                width: 100%
            }

            .framer-XyIhp .framer-1uepckd {
                bottom: unset;
                height: 514px;
                left: 0;
                right: 0;
                top: -260px
            }

            .framer-XyIhp .framer-13rx7v7,
            .framer-XyIhp .framer-1und9d4 {
                justify-content: center;
                max-width: 450px;
                padding: 60px 20px
            }

            .framer-XyIhp .framer-pf7ty7,
            .framer-XyIhp .framer-zd2tfq {
                justify-content: center
            }

            .framer-XyIhp .framer-1wd6miv,
            .framer-XyIhp .framer-1fkqlne,
            .framer-XyIhp .framer-oarzfo,
            .framer-XyIhp .framer-1d7q4in,
            .framer-XyIhp .framer-10ecp3a {
                justify-content: center;
                width: 100%
            }

            .framer-XyIhp .framer-18va552,
            .framer-XyIhp .framer-d5wszl,
            .framer-XyIhp .framer-1rsw90i,
            .framer-XyIhp .framer-1cvknzf,
            .framer-XyIhp .framer-gzvsc5,
            .framer-XyIhp .framer-sg55ij,
            .framer-XyIhp .framer-1bd4imv,
            .framer-XyIhp .framer-15kjguo {
                width: 100px
            }

            .framer-XyIhp .framer-ypdnqf {
                gap: 70px;
                width: 100%
            }

            .framer-XyIhp .framer-9s1iyu {
                align-content: center;
                align-items: center;
                flex-direction: column;
                gap: 70px;
                padding: 0 20px;
                width: 100%
            }

            .framer-XyIhp .framer-1q9737y {
                max-width: 450px;
                padding: 30px 20px 60px
            }

            .framer-XyIhp .framer-1kaoxzn {
                flex-direction: column;
                gap: 60px
            }

            .framer-XyIhp .framer-p7g58f {
                left: unset;
                position: relative;
                top: unset;
                transform: unset;
                width: 100%
            }

            .framer-XyIhp .framer-1vnopp6-container {
                flex: 1 0 0px;
                height: var(--framer-aspect-ratio-supported, 350px);
                width: 1px
            }

            .framer-XyIhp .framer-nro0f5 {
                align-content: center;
                align-items: center;
                width: 100%
            }

            .framer-XyIhp .framer-s2ewvg {
                align-content: center;
                align-items: center;
                justify-content: center;
                width: 100%
            }

            .framer-XyIhp .framer-11n9tyi {
                align-content: center;
                align-items: center;
                order: 2
            }

            .framer-XyIhp .framer-1wqvwy {
                order: 3;
                width: 100%
            }

            .framer-XyIhp .framer-17o45yf {
                justify-content: center;
                order: 0;
                width: 100%
            }

            .framer-XyIhp .framer-171rso8 {
                height: min-content;
                width: 100%
            }

            .framer-XyIhp .framer-1l2tehj {
                align-content: center;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                width: 100%
            }

            .framer-XyIhp .framer-5o6sw6,
            .framer-XyIhp .framer-fgxpt6,
            .framer-XyIhp .framer-yfrdu1,
            .framer-XyIhp .framer-bx7k81,
            .framer-XyIhp .framer-g24dxc {
                align-content: center;
                align-items: center
            }

            .framer-XyIhp .framer-1ac0iy6 {
                width: 273px
            }

            .framer-XyIhp .framer-1vsu43a {
                align-content: center;
                align-items: center;
                flex: 1 0 0px;
                width: 1px
            }

            .framer-XyIhp .framer-dasrki {
                gap: 80px;
                justify-content: center
            }

            .framer-XyIhp .framer-r1gfd9 {
                flex-direction: column;
                gap: 30px;
                justify-content: flex-start
            }

            .framer-XyIhp .framer-1dweb51 {
                height: var(--framer-aspect-ratio-supported, 351px);
                width: 100%
            }

            .framer-XyIhp .framer-1dw3xah,
            .framer-XyIhp .framer-bidwiq,
            .framer-XyIhp .framer-sjs455 {
                align-self: unset;
                width: 100%
            }

            .framer-XyIhp .framer-ban390,
            .framer-XyIhp .framer-sbluc2 {
                height: var(--framer-aspect-ratio-supported, 350px);
                width: 100%
            }

            .framer-XyIhp .framer-1wdcgtp {
                gap: 0px
            }

            .framer-XyIhp .framer-wfydv7 {
                height: min-content;
                left: -230px;
                right: unset;
                top: -150px;
                width: min-content
            }

            .framer-XyIhp .framer-1bvrrp6,
            .framer-XyIhp .framer-1eqhihn {
                height: var(--framer-aspect-ratio-supported, 411px);
                width: 470px
            }

            .framer-XyIhp .framer-1t02k4l {
                height: min-content;
                left: 94%;
                right: unset;
                top: 90%;
                transform: perspective(1200px) translate(-50%, -50%);
                width: min-content
            }

            .framer-XyIhp .framer-8dwf5n {
                max-width: 450px;
                padding: 220px 20px 310px
            }

            .framer-XyIhp .framer-sc0hwa {
                align-content: center;
                align-items: center;
                flex-direction: column;
                gap: 0px;
                width: 100%
            }

            .framer-XyIhp .framer-1vrh72g {
                align-content: center;
                align-items: center;
                flex-direction: column;
                gap: 20px;
                justify-content: center
            }

            @supports (background: -webkit-named-image(i)) and (not (scale:1)) {

                .framer-XyIhp .framer-1wlua9y,
                .framer-XyIhp .framer-dhmoms,
                .framer-XyIhp .framer-mkl5is,
                .framer-XyIhp .framer-19bu65k,
                .framer-XyIhp .framer-b3qr2b,
                .framer-XyIhp .framer-ypdnqf,
                .framer-XyIhp .framer-9s1iyu,
                .framer-XyIhp .framer-1kaoxzn,
                .framer-XyIhp .framer-1l2tehj,
                .framer-XyIhp .framer-dasrki,
                .framer-XyIhp .framer-w6947w,
                .framer-XyIhp .framer-r1gfd9,
                .framer-XyIhp .framer-1wdcgtp,
                .framer-XyIhp .framer-sc0hwa,
                .framer-XyIhp .framer-1vrh72g {
                    gap: 0px
                }

                .framer-XyIhp .framer-1wlua9y>* {
                    margin: 5px 0
                }

                .framer-XyIhp .framer-1wlua9y>:first-child,
                .framer-XyIhp .framer-dhmoms>:first-child,
                .framer-XyIhp .framer-mkl5is>:first-child,
                .framer-XyIhp .framer-19bu65k>:first-child,
                .framer-XyIhp .framer-b3qr2b>:first-child,
                .framer-XyIhp .framer-ypdnqf>:first-child,
                .framer-XyIhp .framer-9s1iyu>:first-child,
                .framer-XyIhp .framer-1kaoxzn>:first-child,
                .framer-XyIhp .framer-1l2tehj>:first-child,
                .framer-XyIhp .framer-dasrki>:first-child,
                .framer-XyIhp .framer-w6947w>:first-child,
                .framer-XyIhp .framer-r1gfd9>:first-child,
                .framer-XyIhp .framer-1wdcgtp>:first-child,
                .framer-XyIhp .framer-sc0hwa>:first-child,
                .framer-XyIhp .framer-1vrh72g>:first-child {
                    margin-top: 0
                }

                .framer-XyIhp .framer-1wlua9y>:last-child,
                .framer-XyIhp .framer-dhmoms>:last-child,
                .framer-XyIhp .framer-mkl5is>:last-child,
                .framer-XyIhp .framer-19bu65k>:last-child,
                .framer-XyIhp .framer-b3qr2b>:last-child,
                .framer-XyIhp .framer-ypdnqf>:last-child,
                .framer-XyIhp .framer-9s1iyu>:last-child,
                .framer-XyIhp .framer-1kaoxzn>:last-child,
                .framer-XyIhp .framer-1l2tehj>:last-child,
                .framer-XyIhp .framer-dasrki>:last-child,
                .framer-XyIhp .framer-w6947w>:last-child,
                .framer-XyIhp .framer-r1gfd9>:last-child,
                .framer-XyIhp .framer-1wdcgtp>:last-child,
                .framer-XyIhp .framer-sc0hwa>:last-child,
                .framer-XyIhp .framer-1vrh72g>:last-child {
                    margin-bottom: 0
                }

                .framer-XyIhp .framer-dhmoms>*,
                .framer-XyIhp .framer-r1gfd9>* {
                    margin: 15px 0
                }

                .framer-XyIhp .framer-mkl5is>*,
                .framer-XyIhp .framer-b3qr2b>*,
                .framer-XyIhp .framer-w6947w>*,
                .framer-XyIhp .framer-1wdcgtp>*,
                .framer-XyIhp .framer-sc0hwa>* {
                    margin: 0
                }

                .framer-XyIhp .framer-19bu65k>*,
                .framer-XyIhp .framer-dasrki>* {
                    margin: 40px 0
                }

                .framer-XyIhp .framer-ypdnqf>*,
                .framer-XyIhp .framer-9s1iyu>* {
                    margin: 35px 0
                }

                .framer-XyIhp .framer-1kaoxzn>* {
                    margin: 30px 0
                }

                .framer-XyIhp .framer-1l2tehj>* {
                    margin: 20px 0
                }

                .framer-XyIhp .framer-1vrh72g>* {
                    margin: 10px 0
                }
            }
        }

        .framer-Pyo6Q .framer-styles-preset-13kmdo8:not(.rich-text-wrapper),
        .framer-Pyo6Q .framer-styles-preset-13kmdo8.rich-text-wrapper h1 {
            --framer-font-family: "Inter-SemiBold", "Inter", sans-serif;
            --framer-font-size: 80px;
            --framer-font-style: normal;
            --framer-font-weight: 600;
            --framer-letter-spacing: -4px;
            --framer-line-height: 90px;
            --framer-paragraph-spacing: 40px;
            --framer-text-alignment: center;
            --framer-text-color: var(--token-fed421e9-3f6f-4d58-bb1b-a95deadf172a, #ffffff);
            --framer-text-decoration: none;
            --framer-text-transform: none
        }

        @media (max-width: 1439px) and (min-width: 810px) {

            .framer-Pyo6Q .framer-styles-preset-13kmdo8:not(.rich-text-wrapper),
            .framer-Pyo6Q .framer-styles-preset-13kmdo8.rich-text-wrapper h1 {
                --framer-font-family: "Inter-SemiBold", "Inter", sans-serif;
                --framer-font-size: 64px;
                --framer-font-style: normal;
                --framer-font-weight: 600;
                --framer-letter-spacing: -4px;
                --framer-line-height: 90px;
                --framer-paragraph-spacing: 40px;
                --framer-text-alignment: center;
                --framer-text-color: var(--token-fed421e9-3f6f-4d58-bb1b-a95deadf172a, #ffffff);
                --framer-text-decoration: none;
                --framer-text-transform: none
            }
        }

        @media (max-width: 809px) and (min-width: 0px) {

            .framer-Pyo6Q .framer-styles-preset-13kmdo8:not(.rich-text-wrapper),
            .framer-Pyo6Q .framer-styles-preset-13kmdo8.rich-text-wrapper h1 {
                --framer-font-family: "Inter-SemiBold", "Inter", sans-serif;
                --framer-font-size: 42px;
                --framer-font-style: normal;
                --framer-font-weight: 600;
                --framer-letter-spacing: -2px;
                --framer-line-height: 50px;
                --framer-paragraph-spacing: 40px;
                --framer-text-alignment: center;
                --framer-text-color: var(--token-fed421e9-3f6f-4d58-bb1b-a95deadf172a, #ffffff);
                --framer-text-decoration: none;
                --framer-text-transform: none
            }
        }

        .framer-8dioY .framer-styles-preset-12s9psp:not(.rich-text-wrapper),
        .framer-8dioY .framer-styles-preset-12s9psp.rich-text-wrapper h3 {
            --framer-font-family: "Inter-Medium", "Inter", sans-serif;
            --framer-font-size: 33px;
            --framer-font-style: normal;
            --framer-font-weight: 500;
            --framer-letter-spacing: -1.65px;
            --framer-line-height: 1.2em;
            --framer-paragraph-spacing: 40px;
            --framer-text-alignment: center;
            --framer-text-color: #000000;
            --framer-text-decoration: none;
            --framer-text-transform: none
        }

        @media (max-width: 1439px) and (min-width: 810px) {

            .framer-8dioY .framer-styles-preset-12s9psp:not(.rich-text-wrapper),
            .framer-8dioY .framer-styles-preset-12s9psp.rich-text-wrapper h3 {
                --framer-font-family: "Inter-Medium", "Inter", sans-serif;
                --framer-font-size: 33px;
                --framer-font-style: normal;
                --framer-font-weight: 500;
                --framer-letter-spacing: -1.65px;
                --framer-line-height: 1.2em;
                --framer-paragraph-spacing: 40px;
                --framer-text-alignment: center;
                --framer-text-color: #000000;
                --framer-text-decoration: none;
                --framer-text-transform: none
            }
        }

        @media (max-width: 809px) and (min-width: 0px) {

            .framer-8dioY .framer-styles-preset-12s9psp:not(.rich-text-wrapper),
            .framer-8dioY .framer-styles-preset-12s9psp.rich-text-wrapper h3 {
                --framer-font-family: "Inter-Medium", "Inter", sans-serif;
                --framer-font-size: 28px;
                --framer-font-style: normal;
                --framer-font-weight: 500;
                --framer-letter-spacing: -1.65px;
                --framer-line-height: 1.2em;
                --framer-paragraph-spacing: 40px;
                --framer-text-alignment: center;
                --framer-text-color: #000000;
                --framer-text-decoration: none;
                --framer-text-transform: none
            }
        }

        .framer-AxzDx [data-border=true]:after {
            content: "";
            border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
            border-color: var(--border-color, none);
            border-style: var(--border-style, none);
            width: 100%;
            height: 100%;
            position: absolute;
            box-sizing: border-box;
            left: 0;
            top: 0;
            border-radius: inherit;
            pointer-events: none
        }

        .framer-AxzDx .framer-9wlam3 {
            display: block
        }

        .framer-AxzDx .framer-lo4vtz {
            align-content: center;
            align-items: center;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-AxzDx .framer-87ldv4 {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: 51px;
            justify-content: center;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 1197px
        }

        .framer-AxzDx .framer-28q7tn {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 63px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 994px
        }

        .framer-AxzDx .framer-1sxzzqr {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            text-decoration: none;
            width: min-content
        }

        .framer-AxzDx .framer-18eia0k {
            flex: none;
            height: 36px;
            position: relative;
            width: 33px
        }

        .framer-AxzDx .framer-1sj1zk,
        .framer-AxzDx .framer-byc63r,
        .framer-AxzDx .framer-d5cdbu,
        .framer-AxzDx .framer-1d6g3ku,
        .framer-AxzDx .framer-zazjwk,
        .framer-AxzDx .framer-x06fog,
        .framer-AxzDx .framer-1bqhzgq,
        .framer-AxzDx .framer-g8kqwl,
        .framer-AxzDx .framer-1ai7jy2,
        .framer-AxzDx .framer-859j1z,
        .framer-AxzDx .framer-1ui2da5 {
            flex: none;
            height: auto;
            position: relative;
            white-space: pre;
            width: auto
        }

        .framer-AxzDx .framer-1bpgfuw {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 19px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-AxzDx .framer-ls9ti8 {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 13px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-AxzDx .framer-fpsl16-container {
            flex: none;
            height: auto;
            position: relative;
            width: auto
        }

        .framer-AxzDx .framer-1xjaemu {
            align-content: center;
            align-items: center;
            display: flex;
            flex: 1 0 0px;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: flex-start;
            overflow: hidden;
            padding: 35px 20px;
            position: relative;
            width: 1px
        }

        .framer-AxzDx .framer-fbp3j2 {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 15px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        @supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

            .framer-AxzDx .framer-lo4vtz,
            .framer-AxzDx .framer-87ldv4,
            .framer-AxzDx .framer-28q7tn,
            .framer-AxzDx .framer-1sxzzqr,
            .framer-AxzDx .framer-1bpgfuw,
            .framer-AxzDx .framer-ls9ti8,
            .framer-AxzDx .framer-1xjaemu,
            .framer-AxzDx .framer-fbp3j2 {
                gap: 0px
            }

            .framer-AxzDx .framer-lo4vtz>* {
                margin: 0
            }

            .framer-AxzDx .framer-lo4vtz>:first-child,
            .framer-AxzDx .framer-87ldv4>:first-child,
            .framer-AxzDx .framer-28q7tn>:first-child,
            .framer-AxzDx .framer-1sxzzqr>:first-child,
            .framer-AxzDx .framer-1bpgfuw>:first-child,
            .framer-AxzDx .framer-ls9ti8>:first-child,
            .framer-AxzDx .framer-1xjaemu>:first-child {
                margin-left: 0
            }

            .framer-AxzDx .framer-lo4vtz>:last-child,
            .framer-AxzDx .framer-87ldv4>:last-child,
            .framer-AxzDx .framer-28q7tn>:last-child,
            .framer-AxzDx .framer-1sxzzqr>:last-child,
            .framer-AxzDx .framer-1bpgfuw>:last-child,
            .framer-AxzDx .framer-ls9ti8>:last-child,
            .framer-AxzDx .framer-1xjaemu>:last-child {
                margin-right: 0
            }

            .framer-AxzDx .framer-87ldv4>*,
            .framer-AxzDx .framer-1sxzzqr>*,
            .framer-AxzDx .framer-1xjaemu>* {
                margin: 0 5px
            }

            .framer-AxzDx .framer-28q7tn>* {
                margin: 0 31.5px
            }

            .framer-AxzDx .framer-1bpgfuw>* {
                margin: 0 9.5px
            }

            .framer-AxzDx .framer-ls9ti8>* {
                margin: 0;
                margin-left: calc(12.64598560333252px / 2);
                margin-right: calc(12.64598560333252px / 2)
            }

            .framer-AxzDx .framer-fbp3j2>* {
                margin: 7.5px 0
            }

            .framer-AxzDx .framer-fbp3j2>:first-child {
                margin-top: 0
            }

            .framer-AxzDx .framer-fbp3j2>:last-child {
                margin-bottom: 0
            }
        }

        .framer-AxzDx.framer-v-11x0qzs .framer-lo4vtz,
        .framer-AxzDx.framer-v-s1w00j .framer-lo4vtz {
            align-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            width: 700px
        }

        .framer-AxzDx.framer-v-11x0qzs .framer-87ldv4,
        .framer-AxzDx.framer-v-s1w00j .framer-87ldv4 {
            height: min-content;
            width: 100%
        }

        .framer-AxzDx.framer-v-11x0qzs .framer-28q7tn,
        .framer-AxzDx.framer-v-s1w00j .framer-28q7tn {
            flex: 1 0 0px;
            width: 1px
        }

        @supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {
            .framer-AxzDx.framer-v-11x0qzs .framer-lo4vtz {
                gap: 0px
            }

            .framer-AxzDx.framer-v-11x0qzs .framer-lo4vtz>* {
                margin: 0
            }

            .framer-AxzDx.framer-v-11x0qzs .framer-lo4vtz>:first-child {
                margin-top: 0
            }

            .framer-AxzDx.framer-v-11x0qzs .framer-lo4vtz>:last-child {
                margin-bottom: 0
            }
        }

        .framer-AxzDx.framer-v-s1w00j .framer-1xjaemu {
            flex: none;
            width: 100%
        }

        @supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {
            .framer-AxzDx.framer-v-s1w00j .framer-lo4vtz {
                gap: 0px
            }

            .framer-AxzDx.framer-v-s1w00j .framer-lo4vtz>* {
                margin: 0
            }

            .framer-AxzDx.framer-v-s1w00j .framer-lo4vtz>:first-child {
                margin-top: 0
            }

            .framer-AxzDx.framer-v-s1w00j .framer-lo4vtz>:last-child {
                margin-bottom: 0
            }
        }

        .framer-LJUxW [data-border=true]:after {
            content: "";
            border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
            border-color: var(--border-color, none);
            border-style: var(--border-style, none);
            width: 100%;
            height: 100%;
            position: absolute;
            box-sizing: border-box;
            left: 0;
            top: 0;
            border-radius: inherit;
            pointer-events: none
        }

        .framer-LJUxW .framer-12g3yo2 {
            display: block
        }

        .framer-LJUxW .framer-1aayn3s {
            align-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 1440px
        }

        .framer-LJUxW .framer-2am1f1 {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            max-width: 1350px;
            overflow: visible;
            padding: 80px 24px;
            position: relative;
            width: 100%
        }

        .framer-LJUxW .framer-fbtuha {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 110px;
            height: min-content;
            justify-content: center;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-LJUxW .framer-sbtnlc {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 220px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-LJUxW .framer-izixkc {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 20px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-LJUxW .framer-r3i0ys {
            align-content: center;
            align-items: center;
            align-self: stretch;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 20px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: auto
        }

        .framer-LJUxW .framer-p0n910,
        .framer-LJUxW .framer-171v13l {
            flex: 1 0 0px;
            height: 5px;
            position: relative;
            width: 1px
        }

        .framer-LJUxW .framer-11faj47,
        .framer-LJUxW .framer-u5fogz,
        .framer-LJUxW .framer-axfwds,
        .framer-LJUxW .framer-rni9ns,
        .framer-LJUxW .framer-1m021lb {
            flex: none;
            height: auto;
            position: relative;
            white-space: pre;
            width: auto
        }

        .framer-LJUxW .framer-19waysq {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 20px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-LJUxW .framer-1db3of8 {
            flex: none;
            height: 5px;
            position: relative;
            width: 451px
        }

        .framer-LJUxW .framer-1rkmp1x {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-LJUxW .framer-2jsrsv {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-LJUxW .framer-1umr50u {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 35px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-LJUxW .framer-1wsiscg {
            flex: none;
            height: auto;
            position: relative;
            white-space: pre-wrap;
            width: 507px;
            word-break: break-word;
            word-wrap: break-word
        }

        .framer-LJUxW .framer-8yx99d-container,
        .framer-LJUxW .framer-1tve9vk-container,
        .framer-LJUxW .framer-hyafd1-container,
        .framer-LJUxW .framer-kfh7up-container,
        .framer-LJUxW .framer-pyx49v-container,
        .framer-LJUxW .framer-1q1zv07-container {
            flex: none;
            height: auto;
            position: relative;
            width: auto
        }

        .framer-LJUxW .framer-9nz7c6 {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 35px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 10px;
            position: relative;
            width: min-content
        }

        .framer-LJUxW .framer-bu5nxc,
        .framer-LJUxW .framer-1q6w3m2,
        .framer-LJUxW .framer-1rxczvh,
        .framer-LJUxW .framer-19a87go {
            align-self: stretch;
            flex: none;
            height: 2px;
            overflow: hidden;
            position: relative;
            width: auto
        }

        @supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

            .framer-LJUxW .framer-1aayn3s,
            .framer-LJUxW .framer-2am1f1,
            .framer-LJUxW .framer-fbtuha,
            .framer-LJUxW .framer-sbtnlc,
            .framer-LJUxW .framer-izixkc,
            .framer-LJUxW .framer-r3i0ys,
            .framer-LJUxW .framer-19waysq,
            .framer-LJUxW .framer-1rkmp1x,
            .framer-LJUxW .framer-2jsrsv,
            .framer-LJUxW .framer-1umr50u,
            .framer-LJUxW .framer-9nz7c6 {
                gap: 0px
            }

            .framer-LJUxW .framer-1aayn3s>*,
            .framer-LJUxW .framer-2am1f1>*,
            .framer-LJUxW .framer-1rkmp1x>* {
                margin: 0
            }

            .framer-LJUxW .framer-1aayn3s>:first-child,
            .framer-LJUxW .framer-2am1f1>:first-child,
            .framer-LJUxW .framer-sbtnlc>:first-child,
            .framer-LJUxW .framer-izixkc>:first-child,
            .framer-LJUxW .framer-1rkmp1x>:first-child,
            .framer-LJUxW .framer-1umr50u>:first-child,
            .framer-LJUxW .framer-9nz7c6>:first-child {
                margin-top: 0
            }

            .framer-LJUxW .framer-1aayn3s>:last-child,
            .framer-LJUxW .framer-2am1f1>:last-child,
            .framer-LJUxW .framer-sbtnlc>:last-child,
            .framer-LJUxW .framer-izixkc>:last-child,
            .framer-LJUxW .framer-1rkmp1x>:last-child,
            .framer-LJUxW .framer-1umr50u>:last-child,
            .framer-LJUxW .framer-9nz7c6>:last-child {
                margin-bottom: 0
            }

            .framer-LJUxW .framer-fbtuha>* {
                margin: 0 55px
            }

            .framer-LJUxW .framer-fbtuha>:first-child,
            .framer-LJUxW .framer-r3i0ys>:first-child,
            .framer-LJUxW .framer-19waysq>:first-child,
            .framer-LJUxW .framer-2jsrsv>:first-child {
                margin-left: 0
            }

            .framer-LJUxW .framer-fbtuha>:last-child,
            .framer-LJUxW .framer-r3i0ys>:last-child,
            .framer-LJUxW .framer-19waysq>:last-child,
            .framer-LJUxW .framer-2jsrsv>:last-child {
                margin-right: 0
            }

            .framer-LJUxW .framer-sbtnlc>* {
                margin: 110px 0
            }

            .framer-LJUxW .framer-izixkc>* {
                margin: 10px 0
            }

            .framer-LJUxW .framer-r3i0ys>*,
            .framer-LJUxW .framer-19waysq>* {
                margin: 0 10px
            }

            .framer-LJUxW .framer-2jsrsv>* {
                margin: 0 5px
            }

            .framer-LJUxW .framer-1umr50u>*,
            .framer-LJUxW .framer-9nz7c6>* {
                margin: 17.5px 0
            }
        }

        .framer-LJUxW.framer-v-gww2m3 .framer-fbtuha,
        .framer-LJUxW.framer-v-1swwsya .framer-fbtuha {
            flex-direction: column
        }

        .framer-LJUxW.framer-v-gww2m3 .framer-sbtnlc {
            align-content: center;
            align-items: center;
            gap: 70px;
            justify-content: center
        }

        .framer-LJUxW.framer-v-gww2m3 .framer-izixkc,
        .framer-LJUxW.framer-v-1swwsya .framer-izixkc {
            align-content: center;
            align-items: center;
            justify-content: center
        }

        .framer-LJUxW.framer-v-gww2m3 .framer-r3i0ys,
        .framer-LJUxW.framer-v-1swwsya .framer-r3i0ys {
            order: 0
        }

        .framer-LJUxW.framer-v-gww2m3 .framer-1rkmp1x {
            align-content: center;
            align-items: center;
            align-self: stretch;
            order: 2;
            width: auto
        }

        .framer-LJUxW.framer-v-gww2m3 .framer-1umr50u,
        .framer-LJUxW.framer-v-1swwsya .framer-2jsrsv {
            align-content: center;
            align-items: center
        }

        @supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

            .framer-LJUxW.framer-v-gww2m3 .framer-fbtuha,
            .framer-LJUxW.framer-v-gww2m3 .framer-sbtnlc {
                gap: 0px
            }

            .framer-LJUxW.framer-v-gww2m3 .framer-fbtuha>* {
                margin: 55px 0
            }

            .framer-LJUxW.framer-v-gww2m3 .framer-fbtuha>:first-child,
            .framer-LJUxW.framer-v-gww2m3 .framer-sbtnlc>:first-child {
                margin-top: 0
            }

            .framer-LJUxW.framer-v-gww2m3 .framer-fbtuha>:last-child,
            .framer-LJUxW.framer-v-gww2m3 .framer-sbtnlc>:last-child {
                margin-bottom: 0
            }

            .framer-LJUxW.framer-v-gww2m3 .framer-sbtnlc>* {
                margin: 35px 0
            }
        }

        .framer-LJUxW.framer-v-1swwsya .framer-1aayn3s {
            width: 390px
        }

        .framer-LJUxW.framer-v-1swwsya .framer-sbtnlc {
            align-content: center;
            align-items: center;
            gap: 70px;
            justify-content: center;
            width: 100%
        }

        .framer-LJUxW.framer-v-1swwsya .framer-1rkmp1x {
            align-content: center;
            align-items: center;
            order: 2
        }

        .framer-LJUxW.framer-v-1swwsya .framer-1umr50u {
            align-content: center;
            align-items: center;
            width: 100%
        }

        .framer-LJUxW.framer-v-1swwsya .framer-1wsiscg,
        .framer-LJUxW.framer-v-1swwsya .framer-9nz7c6,
        .framer-LJUxW.framer-v-1swwsya .framer-1tve9vk-container,
        .framer-LJUxW.framer-v-1swwsya .framer-hyafd1-container,
        .framer-LJUxW.framer-v-1swwsya .framer-kfh7up-container,
        .framer-LJUxW.framer-v-1swwsya .framer-pyx49v-container,
        .framer-LJUxW.framer-v-1swwsya .framer-1q1zv07-container {
            width: 100%
        }

        .framer-LJUxW.framer-v-1swwsya .framer-bu5nxc,
        .framer-LJUxW.framer-v-1swwsya .framer-1q6w3m2,
        .framer-LJUxW.framer-v-1swwsya .framer-1rxczvh,
        .framer-LJUxW.framer-v-1swwsya .framer-19a87go {
            align-self: unset;
            width: 100%
        }

        @supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

            .framer-LJUxW.framer-v-1swwsya .framer-fbtuha,
            .framer-LJUxW.framer-v-1swwsya .framer-sbtnlc {
                gap: 0px
            }

            .framer-LJUxW.framer-v-1swwsya .framer-fbtuha>* {
                margin: 55px 0
            }

            .framer-LJUxW.framer-v-1swwsya .framer-fbtuha>:first-child,
            .framer-LJUxW.framer-v-1swwsya .framer-sbtnlc>:first-child {
                margin-top: 0
            }

            .framer-LJUxW.framer-v-1swwsya .framer-fbtuha>:last-child,
            .framer-LJUxW.framer-v-1swwsya .framer-sbtnlc>:last-child {
                margin-bottom: 0
            }

            .framer-LJUxW.framer-v-1swwsya .framer-sbtnlc>* {
                margin: 35px 0
            }
        }

        .framer-jUJDS .framer-styles-preset-186siln:not(.rich-text-wrapper),
        .framer-jUJDS .framer-styles-preset-186siln.rich-text-wrapper h2 {
            --framer-font-family: "Inter-SemiBold", "Inter", sans-serif;
            --framer-font-size: 62px;
            --framer-font-style: normal;
            --framer-font-weight: 600;
            --framer-letter-spacing: -3.1px;
            --framer-line-height: 1.2em;
            --framer-paragraph-spacing: 40px;
            --framer-text-alignment: center;
            --framer-text-color: #000000;
            --framer-text-decoration: none;
            --framer-text-transform: none
        }

        @media (max-width: 1439px) and (min-width: 810px) {

            .framer-jUJDS .framer-styles-preset-186siln:not(.rich-text-wrapper),
            .framer-jUJDS .framer-styles-preset-186siln.rich-text-wrapper h2 {
                --framer-font-family: "Inter-SemiBold", "Inter", sans-serif;
                --framer-font-size: 55px;
                --framer-font-style: normal;
                --framer-font-weight: 600;
                --framer-letter-spacing: -3.1px;
                --framer-line-height: 1.2em;
                --framer-paragraph-spacing: 40px;
                --framer-text-alignment: center;
                --framer-text-color: #000000;
                --framer-text-decoration: none;
                --framer-text-transform: none
            }
        }

        @media (max-width: 809px) and (min-width: 0px) {

            .framer-jUJDS .framer-styles-preset-186siln:not(.rich-text-wrapper),
            .framer-jUJDS .framer-styles-preset-186siln.rich-text-wrapper h2 {
                --framer-font-family: "Inter-SemiBold", "Inter", sans-serif;
                --framer-font-size: 38px;
                --framer-font-style: normal;
                --framer-font-weight: 600;
                --framer-letter-spacing: -1.8px;
                --framer-line-height: 45px;
                --framer-paragraph-spacing: 40px;
                --framer-text-alignment: center;
                --framer-text-color: #000000;
                --framer-text-decoration: none;
                --framer-text-transform: none
            }
        }

        .framer-oyI4k [data-border=true]:after {
            content: "";
            border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
            border-color: var(--border-color, none);
            border-style: var(--border-style, none);
            width: 100%;
            height: 100%;
            position: absolute;
            box-sizing: border-box;
            left: 0;
            top: 0;
            border-radius: inherit;
            pointer-events: none
        }

        .framer-oyI4k .framer-mesh6j {
            display: block
        }

        .framer-oyI4k .framer-693ydj {
            align-content: flex-start;
            align-items: flex-start;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 8px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 17px 25px;
            position: relative;
            text-decoration: none;
            width: min-content
        }

        .framer-oyI4k .framer-crfnzq {
            flex: none;
            height: auto;
            position: relative;
            white-space: pre;
            width: auto
        }

        .framer-oyI4k .framer-v-693ydj .framer-693ydj,
        .framer-oyI4k .framer-v-1qf1y5n .framer-693ydj {
            cursor: pointer
        }

        @supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {
            .framer-oyI4k .framer-693ydj {
                gap: 0px
            }

            .framer-oyI4k .framer-693ydj>* {
                margin: 0;
                margin-left: calc(8.430656433105469px / 2);
                margin-right: calc(8.430656433105469px / 2)
            }

            .framer-oyI4k .framer-693ydj>:first-child {
                margin-left: 0
            }

            .framer-oyI4k .framer-693ydj>:last-child {
                margin-right: 0
            }
        }

        .framer-KrDQC [data-border=true]:after {
            content: "";
            border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
            border-color: var(--border-color, none);
            border-style: var(--border-style, none);
            width: 100%;
            height: 100%;
            position: absolute;
            box-sizing: border-box;
            left: 0;
            top: 0;
            border-radius: inherit;
            pointer-events: none
        }

        .framer-KrDQC .framer-1mzrcov {
            display: block
        }

        .framer-KrDQC .framer-1vbgyyi {
            align-content: center;
            align-items: center;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: center;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 500px
        }

        .framer-KrDQC .framer-1t196q0,
        .framer-KrDQC .framer-18qc69a {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 20px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-KrDQC .framer-97smsc {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 8px;
            position: relative;
            width: min-content
        }

        .framer-KrDQC .framer-o5tq9a {
            aspect-ratio: 1 / 1;
            flex: none;
            height: var(--framer-aspect-ratio-supported, 13px);
            position: relative;
            width: 13px
        }

        .framer-KrDQC .framer-1it09ja,
        .framer-KrDQC .framer-slca9b {
            align-content: center;
            align-items: center;
            display: flex;
            flex: 1 0 0px;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: flex-end;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 1px
        }

        .framer-KrDQC .framer-f2wsgf,
        .framer-KrDQC .framer-18f3nw9 {
            flex: 1 0 0px;
            height: auto;
            position: relative;
            white-space: pre-wrap;
            width: 1px;
            word-break: break-word;
            word-wrap: break-word
        }

        @supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

            .framer-KrDQC .framer-1vbgyyi,
            .framer-KrDQC .framer-1t196q0,
            .framer-KrDQC .framer-97smsc,
            .framer-KrDQC .framer-1it09ja,
            .framer-KrDQC .framer-18qc69a,
            .framer-KrDQC .framer-slca9b {
                gap: 0px
            }

            .framer-KrDQC .framer-1vbgyyi>*,
            .framer-KrDQC .framer-97smsc>* {
                margin: 5px 0
            }

            .framer-KrDQC .framer-1vbgyyi>:first-child,
            .framer-KrDQC .framer-97smsc>:first-child {
                margin-top: 0
            }

            .framer-KrDQC .framer-1vbgyyi>:last-child,
            .framer-KrDQC .framer-97smsc>:last-child {
                margin-bottom: 0
            }

            .framer-KrDQC .framer-1t196q0>*,
            .framer-KrDQC .framer-18qc69a>* {
                margin: 0 10px
            }

            .framer-KrDQC .framer-1t196q0>:first-child,
            .framer-KrDQC .framer-1it09ja>:first-child,
            .framer-KrDQC .framer-18qc69a>:first-child,
            .framer-KrDQC .framer-slca9b>:first-child {
                margin-left: 0
            }

            .framer-KrDQC .framer-1t196q0>:last-child,
            .framer-KrDQC .framer-1it09ja>:last-child,
            .framer-KrDQC .framer-18qc69a>:last-child,
            .framer-KrDQC .framer-slca9b>:last-child {
                margin-right: 0
            }

            .framer-KrDQC .framer-1it09ja>*,
            .framer-KrDQC .framer-slca9b>* {
                margin: 0 5px
            }
        }

        .ssr-variant {
            display: contents
        }

        .framer-QisTT [data-border=true]:after {
            content: "";
            border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
            border-color: var(--border-color, none);
            border-style: var(--border-style, none);
            width: 100%;
            height: 100%;
            position: absolute;
            box-sizing: border-box;
            left: 0;
            top: 0;
            border-radius: inherit;
            pointer-events: none
        }

        @supports (aspect-ratio: 1) {
            body {
                --framer-aspect-ratio-supported: auto
            }
        }

        .framer-QisTT .framer-q9lbgg {
            display: block
        }

        .framer-QisTT .framer-18ei3y1 {
            align-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 1440px
        }

        .framer-QisTT .framer-99vg7j {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            max-width: 1350px;
            overflow: visible;
            padding: 50px 40px;
            position: relative;
            width: 100%
        }

        .framer-QisTT .framer-1dc1oib {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 80px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-QisTT .framer-gj4g34 {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 78px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-QisTT .framer-1clxsno {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-QisTT .framer-1jg9z9u {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: 1 0 0px;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 12px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 1px
        }

        .framer-QisTT .framer-1fbiftf,
        .framer-QisTT .framer-2996yt,
        .framer-QisTT .framer-w5bnc6 {
            flex: none;
            height: auto;
            position: relative;
            white-space: pre;
            width: auto
        }

        .framer-QisTT .framer-1kpmir6 {
            flex: none;
            height: auto;
            position: relative;
            white-space: pre-wrap;
            width: 406px;
            word-break: break-word;
            word-wrap: break-word
        }

        .framer-QisTT .framer-tqrv5f {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 22px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-QisTT .framer-1d5s6c9 {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 30px;
            height: min-content;
            justify-content: center;
            overflow: hidden;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-QisTT .framer-18kl1nc {
            aspect-ratio: 3.4285714285714284 / 1;
            flex: none;
            height: var(--framer-aspect-ratio-supported, 77px);
            position: relative;
            text-decoration: none;
            width: 264px
        }

        .framer-QisTT .framer-1bh845a {
            aspect-ratio: 3.4155844155844157 / 1;
            flex: none;
            height: var(--framer-aspect-ratio-supported, 77px);
            position: relative;
            text-decoration: none;
            width: 263px
        }

        .framer-QisTT .framer-17sxb2k,
        .framer-QisTT .framer-1q5qog7 {
            flex: none;
            height: 4px;
            position: relative;
            width: 100%
        }

        .framer-QisTT .framer-ys4oie {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-QisTT .framer-1l3t5an {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: 1 0 0px;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 20px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 1px
        }

        .framer-QisTT .framer-17uvgnt {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
            height: min-content;
            justify-content: center;
            overflow: visible;
            padding: 0;
            position: relative;
            text-decoration: none;
            width: min-content
        }

        .framer-QisTT .framer-11ew9a1 {
            flex: none;
            height: 36px;
            position: relative;
            width: 33px
        }

        .framer-QisTT .framer-icvppe {
            flex: none;
            height: auto;
            position: relative;
            white-space: pre-wrap;
            width: 322px;
            word-break: break-word;
            word-wrap: break-word
        }

        .framer-QisTT .framer-je2t0f {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 9px;
            height: min-content;
            justify-content: center;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-QisTT .framer-6lu5oz,
        .framer-QisTT .framer-qmmn06 {
            aspect-ratio: 1.0810810810810811 / 1;
            flex: none;
            height: var(--framer-aspect-ratio-supported, 37px);
            position: relative;
            text-decoration: none;
            width: 40px
        }

        .framer-QisTT .framer-2ub89w,
        .framer-QisTT .framer-1q9apjh {
            aspect-ratio: .975609756097561 / 1;
            flex: none;
            height: var(--framer-aspect-ratio-supported, 41px);
            position: relative;
            text-decoration: none;
            width: 40px
        }

        .framer-QisTT .framer-14vrh6b,
        .framer-QisTT .framer-wjg7bn {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 46px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-QisTT .framer-cqqoa6,
        .framer-QisTT .framer-kmkk5l {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 32px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 180px
        }

        .framer-QisTT .framer-5v7vgb,
        .framer-QisTT .framer-d2d03o,
        .framer-QisTT .framer-p4ok57,
        .framer-QisTT .framer-1qoelkn,
        .framer-QisTT .framer-mor0ai,
        .framer-QisTT .framer-71n7r1,
        .framer-QisTT .framer-ggz6qz,
        .framer-QisTT .framer-1bcffoz,
        .framer-QisTT .framer-17x09fs {
            flex: none;
            height: auto;
            position: relative;
            white-space: pre-wrap;
            width: 180px;
            word-break: break-word;
            word-wrap: break-word
        }

        .framer-QisTT .framer-12qnpf5,
        .framer-QisTT .framer-hvhcsq {
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 16px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-QisTT .framer-yd4uzl {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 16px;
            height: min-content;
            justify-content: flex-start;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-QisTT .framer-1pza12w {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            height: min-content;
            justify-content: space-between;
            overflow: visible;
            padding: 0;
            position: relative;
            width: 100%
        }

        .framer-QisTT .framer-pponoo {
            flex: none;
            height: auto;
            position: relative;
            white-space: pre-wrap;
            width: 282px;
            word-break: break-word;
            word-wrap: break-word
        }

        .framer-QisTT .framer-1c0b7qi {
            align-content: center;
            align-items: center;
            display: flex;
            flex: none;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 0px;
            height: min-content;
            justify-content: center;
            overflow: visible;
            padding: 0;
            position: relative;
            width: min-content
        }

        .framer-QisTT .framer-1ob507i,
        .framer-QisTT .framer-186zccb {
            flex: none;
            height: auto;
            position: relative;
            white-space: pre-wrap;
            width: 150px;
            word-break: break-word;
            word-wrap: break-word
        }

        @supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

            .framer-QisTT .framer-18ei3y1,
            .framer-QisTT .framer-99vg7j,
            .framer-QisTT .framer-1dc1oib,
            .framer-QisTT .framer-gj4g34,
            .framer-QisTT .framer-1clxsno,
            .framer-QisTT .framer-1jg9z9u,
            .framer-QisTT .framer-tqrv5f,
            .framer-QisTT .framer-1d5s6c9,
            .framer-QisTT .framer-ys4oie,
            .framer-QisTT .framer-1l3t5an,
            .framer-QisTT .framer-17uvgnt,
            .framer-QisTT .framer-je2t0f,
            .framer-QisTT .framer-14vrh6b,
            .framer-QisTT .framer-cqqoa6,
            .framer-QisTT .framer-12qnpf5,
            .framer-QisTT .framer-wjg7bn,
            .framer-QisTT .framer-kmkk5l,
            .framer-QisTT .framer-hvhcsq,
            .framer-QisTT .framer-yd4uzl,
            .framer-QisTT .framer-1c0b7qi {
                gap: 0px
            }

            .framer-QisTT .framer-18ei3y1>* {
                margin: 5px 0
            }

            .framer-QisTT .framer-18ei3y1>:first-child,
            .framer-QisTT .framer-99vg7j>:first-child,
            .framer-QisTT .framer-1dc1oib>:first-child,
            .framer-QisTT .framer-gj4g34>:first-child,
            .framer-QisTT .framer-1jg9z9u>:first-child,
            .framer-QisTT .framer-tqrv5f>:first-child,
            .framer-QisTT .framer-1l3t5an>:first-child,
            .framer-QisTT .framer-cqqoa6>:first-child,
            .framer-QisTT .framer-12qnpf5>:first-child,
            .framer-QisTT .framer-kmkk5l>:first-child,
            .framer-QisTT .framer-hvhcsq>:first-child,
            .framer-QisTT .framer-yd4uzl>:first-child {
                margin-top: 0
            }

            .framer-QisTT .framer-18ei3y1>:last-child,
            .framer-QisTT .framer-99vg7j>:last-child,
            .framer-QisTT .framer-1dc1oib>:last-child,
            .framer-QisTT .framer-gj4g34>:last-child,
            .framer-QisTT .framer-1jg9z9u>:last-child,
            .framer-QisTT .framer-tqrv5f>:last-child,
            .framer-QisTT .framer-1l3t5an>:last-child,
            .framer-QisTT .framer-cqqoa6>:last-child,
            .framer-QisTT .framer-12qnpf5>:last-child,
            .framer-QisTT .framer-kmkk5l>:last-child,
            .framer-QisTT .framer-hvhcsq>:last-child,
            .framer-QisTT .framer-yd4uzl>:last-child {
                margin-bottom: 0
            }

            .framer-QisTT .framer-99vg7j>* {
                margin: 0
            }

            .framer-QisTT .framer-1dc1oib>* {
                margin: 40px 0
            }

            .framer-QisTT .framer-gj4g34>* {
                margin: 39px 0
            }

            .framer-QisTT .framer-1clxsno>*,
            .framer-QisTT .framer-ys4oie>*,
            .framer-QisTT .framer-1c0b7qi>* {
                margin: 0
            }

            .framer-QisTT .framer-1clxsno>:first-child,
            .framer-QisTT .framer-1d5s6c9>:first-child,
            .framer-QisTT .framer-ys4oie>:first-child,
            .framer-QisTT .framer-17uvgnt>:first-child,
            .framer-QisTT .framer-je2t0f>:first-child,
            .framer-QisTT .framer-14vrh6b>:first-child,
            .framer-QisTT .framer-wjg7bn>:first-child,
            .framer-QisTT .framer-1c0b7qi>:first-child {
                margin-left: 0
            }

            .framer-QisTT .framer-1clxsno>:last-child,
            .framer-QisTT .framer-1d5s6c9>:last-child,
            .framer-QisTT .framer-ys4oie>:last-child,
            .framer-QisTT .framer-17uvgnt>:last-child,
            .framer-QisTT .framer-je2t0f>:last-child,
            .framer-QisTT .framer-14vrh6b>:last-child,
            .framer-QisTT .framer-wjg7bn>:last-child,
            .framer-QisTT .framer-1c0b7qi>:last-child {
                margin-right: 0
            }

            .framer-QisTT .framer-1jg9z9u>* {
                margin: 6px 0
            }

            .framer-QisTT .framer-tqrv5f>* {
                margin: 11px 0
            }

            .framer-QisTT .framer-1d5s6c9>* {
                margin: 0 15px
            }

            .framer-QisTT .framer-1l3t5an>* {
                margin: 10px 0
            }

            .framer-QisTT .framer-17uvgnt>* {
                margin: 0 5px
            }

            .framer-QisTT .framer-je2t0f>* {
                margin: 0 4.5px
            }

            .framer-QisTT .framer-14vrh6b>*,
            .framer-QisTT .framer-wjg7bn>* {
                margin: 0 23px
            }

            .framer-QisTT .framer-cqqoa6>*,
            .framer-QisTT .framer-kmkk5l>* {
                margin: 16px 0
            }

            .framer-QisTT .framer-12qnpf5>*,
            .framer-QisTT .framer-hvhcsq>*,
            .framer-QisTT .framer-yd4uzl>* {
                margin: 8px 0
            }
        }

        .framer-QisTT.framer-v-100waq7 .framer-18ei3y1 {
            width: 780px
        }

        .framer-QisTT.framer-v-100waq7 .framer-1dc1oib,
        .framer-QisTT.framer-v-15ysd0k .framer-1dc1oib {
            gap: 50px
        }

        .framer-QisTT.framer-v-100waq7 .framer-1clxsno,
        .framer-QisTT.framer-v-15ysd0k .framer-1clxsno {
            flex-direction: column;
            gap: 50px;
            justify-content: center
        }

        .framer-QisTT.framer-v-100waq7 .framer-1jg9z9u,
        .framer-QisTT.framer-v-100waq7 .framer-1l3t5an,
        .framer-QisTT.framer-v-15ysd0k .framer-1jg9z9u,
        .framer-QisTT.framer-v-15ysd0k .framer-1l3t5an {
            align-content: center;
            align-items: center;
            flex: none;
            justify-content: center;
            width: 100%
        }

        .framer-QisTT.framer-v-100waq7 .framer-1kpmir6 {
            width: 426px
        }

        .framer-QisTT.framer-v-100waq7 .framer-tqrv5f,
        .framer-QisTT.framer-v-100waq7 .framer-12qnpf5,
        .framer-QisTT.framer-v-15ysd0k .framer-tqrv5f {
            align-content: center;
            align-items: center
        }

        .framer-QisTT.framer-v-100waq7 .framer-ys4oie,
        .framer-QisTT.framer-v-15ysd0k .framer-ys4oie {
            align-content: center;
            align-items: center;
            flex-direction: column;
            gap: 50px;
            justify-content: center
        }

        .framer-QisTT.framer-v-100waq7 .framer-14vrh6b,
        .framer-QisTT.framer-v-15ysd0k .framer-14vrh6b {
            justify-content: center
        }

        .framer-QisTT.framer-v-100waq7 .framer-cqqoa6,
        .framer-QisTT.framer-v-100waq7 .framer-kmkk5l,
        .framer-QisTT.framer-v-100waq7 .framer-hvhcsq {
            align-content: center;
            align-items: center;
            justify-content: center
        }

        .framer-QisTT.framer-v-100waq7 .framer-5v7vgb,
        .framer-QisTT.framer-v-100waq7 .framer-d2d03o,
        .framer-QisTT.framer-v-100waq7 .framer-p4ok57,
        .framer-QisTT.framer-v-100waq7 .framer-1qoelkn,
        .framer-QisTT.framer-v-100waq7 .framer-mor0ai,
        .framer-QisTT.framer-v-100waq7 .framer-71n7r1,
        .framer-QisTT.framer-v-100waq7 .framer-ggz6qz,
        .framer-QisTT.framer-v-100waq7 .framer-1bcffoz,
        .framer-QisTT.framer-v-100waq7 .framer-17x09fs,
        .framer-QisTT.framer-v-15ysd0k .framer-5v7vgb,
        .framer-QisTT.framer-v-15ysd0k .framer-d2d03o,
        .framer-QisTT.framer-v-15ysd0k .framer-p4ok57,
        .framer-QisTT.framer-v-15ysd0k .framer-1qoelkn,
        .framer-QisTT.framer-v-15ysd0k .framer-mor0ai,
        .framer-QisTT.framer-v-15ysd0k .framer-71n7r1,
        .framer-QisTT.framer-v-15ysd0k .framer-ggz6qz,
        .framer-QisTT.framer-v-15ysd0k .framer-1bcffoz,
        .framer-QisTT.framer-v-15ysd0k .framer-17x09fs,
        .framer-QisTT.framer-v-15ysd0k .framer-1ob507i,
        .framer-QisTT.framer-v-15ysd0k .framer-186zccb {
            white-space: pre;
            width: auto
        }

        @supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

            .framer-QisTT.framer-v-100waq7 .framer-1dc1oib,
            .framer-QisTT.framer-v-100waq7 .framer-1clxsno,
            .framer-QisTT.framer-v-100waq7 .framer-ys4oie {
                gap: 0px
            }

            .framer-QisTT.framer-v-100waq7 .framer-1dc1oib>*,
            .framer-QisTT.framer-v-100waq7 .framer-1clxsno>*,
            .framer-QisTT.framer-v-100waq7 .framer-ys4oie>* {
                margin: 25px 0
            }

            .framer-QisTT.framer-v-100waq7 .framer-1dc1oib>:first-child,
            .framer-QisTT.framer-v-100waq7 .framer-1clxsno>:first-child,
            .framer-QisTT.framer-v-100waq7 .framer-ys4oie>:first-child {
                margin-top: 0
            }

            .framer-QisTT.framer-v-100waq7 .framer-1dc1oib>:last-child,
            .framer-QisTT.framer-v-100waq7 .framer-1clxsno>:last-child,
            .framer-QisTT.framer-v-100waq7 .framer-ys4oie>:last-child {
                margin-bottom: 0
            }
        }

        .framer-QisTT.framer-v-15ysd0k .framer-18ei3y1 {
            width: 390px
        }

        .framer-QisTT.framer-v-15ysd0k .framer-99vg7j {
            max-width: 450px;
            padding: 50px 20px
        }

        .framer-QisTT.framer-v-15ysd0k .framer-1fbiftf {
            white-space: pre-wrap;
            width: 100%;
            word-break: break-word;
            word-wrap: break-word
        }

        .framer-QisTT.framer-v-15ysd0k .framer-1kpmir6,
        .framer-QisTT.framer-v-15ysd0k .framer-icvppe {
            width: 100%
        }

        .framer-QisTT.framer-v-15ysd0k .framer-1d5s6c9 {
            flex-direction: column;
            gap: 20px
        }

        .framer-QisTT.framer-v-15ysd0k .framer-cqqoa6,
        .framer-QisTT.framer-v-15ysd0k .framer-kmkk5l,
        .framer-QisTT.framer-v-15ysd0k .framer-hvhcsq {
            align-content: center;
            align-items: center;
            justify-content: center;
            width: min-content
        }

        .framer-QisTT.framer-v-15ysd0k .framer-12qnpf5 {
            align-content: center;
            align-items: center;
            width: 84px
        }

        .framer-QisTT.framer-v-15ysd0k .framer-1pza12w {
            flex-direction: column;
            gap: 20px;
            justify-content: flex-start;
            padding: 10px
        }

        .framer-QisTT.framer-v-15ysd0k .framer-1c0b7qi {
            gap: 20px;
            width: 100%
        }

        @supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) {

            .framer-QisTT.framer-v-15ysd0k .framer-1dc1oib,
            .framer-QisTT.framer-v-15ysd0k .framer-1clxsno,
            .framer-QisTT.framer-v-15ysd0k .framer-1d5s6c9,
            .framer-QisTT.framer-v-15ysd0k .framer-ys4oie,
            .framer-QisTT.framer-v-15ysd0k .framer-1pza12w,
            .framer-QisTT.framer-v-15ysd0k .framer-1c0b7qi {
                gap: 0px
            }

            .framer-QisTT.framer-v-15ysd0k .framer-1dc1oib>*,
            .framer-QisTT.framer-v-15ysd0k .framer-1clxsno>*,
            .framer-QisTT.framer-v-15ysd0k .framer-ys4oie>* {
                margin: 25px 0
            }

            .framer-QisTT.framer-v-15ysd0k .framer-1dc1oib>:first-child,
            .framer-QisTT.framer-v-15ysd0k .framer-1clxsno>:first-child,
            .framer-QisTT.framer-v-15ysd0k .framer-1d5s6c9>:first-child,
            .framer-QisTT.framer-v-15ysd0k .framer-ys4oie>:first-child,
            .framer-QisTT.framer-v-15ysd0k .framer-1pza12w>:first-child {
                margin-top: 0
            }

            .framer-QisTT.framer-v-15ysd0k .framer-1dc1oib>:last-child,
            .framer-QisTT.framer-v-15ysd0k .framer-1clxsno>:last-child,
            .framer-QisTT.framer-v-15ysd0k .framer-1d5s6c9>:last-child,
            .framer-QisTT.framer-v-15ysd0k .framer-ys4oie>:last-child,
            .framer-QisTT.framer-v-15ysd0k .framer-1pza12w>:last-child {
                margin-bottom: 0
            }

            .framer-QisTT.framer-v-15ysd0k .framer-1d5s6c9>*,
            .framer-QisTT.framer-v-15ysd0k .framer-1pza12w>* {
                margin: 10px 0
            }

            .framer-QisTT.framer-v-15ysd0k .framer-1c0b7qi>* {
                margin: 0 10px
            }

            .framer-QisTT.framer-v-15ysd0k .framer-1c0b7qi>:first-child {
                margin-left: 0
            }

            .framer-QisTT.framer-v-15ysd0k .framer-1c0b7qi>:last-child {
                margin-right: 0
            }
        }

        .framer-EjWs1 .framer-styles-preset-1wzfti9:not(.rich-text-wrapper),
        .framer-EjWs1 .framer-styles-preset-1wzfti9.rich-text-wrapper p {
            --framer-font-family: "Inter-Light", "Inter", sans-serif;
            --framer-font-size: 20px;
            --framer-font-style: normal;
            --framer-font-weight: 300;
            --framer-letter-spacing: -.4px;
            --framer-line-height: 35px;
            --framer-paragraph-spacing: 20px;
            --framer-text-alignment: center;
            --framer-text-color: #f1f5f9;
            --framer-text-decoration: none;
            --framer-text-transform: none
        }

        .framer-Adeeo .framer-styles-preset-138ee5y:not(.rich-text-wrapper),
        .framer-Adeeo .framer-styles-preset-138ee5y.rich-text-wrapper p {
            --framer-font-family: "Inter-Light", "Inter", sans-serif;
            --framer-font-size: 19px;
            --framer-font-style: normal;
            --framer-font-weight: 300;
            --framer-letter-spacing: -.38px;
            --framer-line-height: 30px;
            --framer-paragraph-spacing: 20px;
            --framer-text-alignment: center;
            --framer-text-color: #f1f5f9;
            --framer-text-decoration: none;
            --framer-text-transform: none
        }

        .framer-Agnx9 .framer-styles-preset-1j1173s:not(.rich-text-wrapper),
        .framer-Agnx9 .framer-styles-preset-1j1173s.rich-text-wrapper a {
            --framer-link-current-text-color: #ffffff;
            --framer-link-current-text-decoration: none;
            --framer-link-hover-text-color: var(--token-fed421e9-3f6f-4d58-bb1b-a95deadf172a, #ffffff);
            --framer-link-hover-text-decoration: none;
            --framer-link-text-color: rgba(255, 255, 255, .5);
            --framer-link-text-decoration: none
        }

        .framer-yRxrJ .framer-styles-preset-137xg8f:not(.rich-text-wrapper),
        .framer-yRxrJ .framer-styles-preset-137xg8f.rich-text-wrapper a {
            --framer-link-current-text-decoration: none;
            --framer-link-hover-text-decoration: none;
            --framer-link-text-color: var(--token-fed421e9-3f6f-4d58-bb1b-a95deadf172a, #ffffff);
            --framer-link-text-decoration: none
        }

        .framer-body {

            .framer-XyIhp,
            .framer-Pyo6Q,
            .framer-EjWs1,
            .framer-jUJDS,
            .framer-8dioY,
            .framer-Adeeo {
                display: contents;

                .framer-1fdb8e9 {
                    min-height: 100%;
                    width: auto
                }
            }
        }