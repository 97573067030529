.framer-kzpjwj {
    .framer-11udwvy {
        .framer-1a2dpgh {
            .framer-1xwcvgu {
                .framer-1wfkc1z {

                    .framer-1pkz81l,
                    .hidden-72rtr7 {
                        .framer-19w5gd7 {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1);

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;


                                svg {
                                    width: 100%;
                                    height: 100%
                                }
                            }
                        }

                        .framer-1vhcsox {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text {
                                --font-selector: SW50ZXItTGlnaHQ=;
                                font-family: "Inter-Bold", "Inter", "Inter Placeholder", sans-serif;
                                --framer-font-size: 15px;
                                --framer-font-weight: 300;
                                --framer-letter-spacing: 0.75px;
                                --framer-line-height: 35px;
                                --framer-text-alignment: center;
                                --framer-text-color: rgb(241, 245, 249);
                                --framer-text-transform: uppercase
                            }
                        }

                        .framer-13rh0fy {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1);

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%
                                }
                            }
                        }
                    }

                    .framer-7t6h8s,
                    .hidden-18ccrvo,
                    .hidden-p5w47l {
                        .framer-8ag0cn {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text {
                                --font-selector: SW50ZXItTGlnaHQ=;
                                font-family: "Inter-Bold", "Inter", "Inter Placeholder", sans-serif;
                                --framer-font-size: 15px;
                                --framer-font-weight: 300;
                                --framer-letter-spacing: 0.75px;
                                --framer-line-height: 35px;
                                --framer-text-alignment: center;
                                --framer-text-color: rgb(241, 245, 249);
                                --framer-text-transform: uppercase
                            }
                        }

                        .framer-1syp31b {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1);

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%
                                }
                            }
                        }
                    }

                    .framer-1n6ov80 {
                        .framer-fy4ath {
                            .framer-v39bd3-container {

                                .ssr-variant,
                                .hidden-p5w47l,
                                .hidden-18ccrvo {
                                    h1 {
                                        background-image: url(https://framerusercontent.com/images/DN7ut8BMdxCiadtYJWSG1Vt7zA.svg);
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        background-position: 0% 0%;
                                        background-clip: text;
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                        text-fill-color: transparent;
                                        color: rgb(136, 136, 136);
                                        font-size: 63px;
                                        font-family: "Inter-Bold", "Inter", "Inter Placeholder", sans-serif;
                                        font-style: normal;
                                        font-weight: 600;
                                        text-align: center;
                                        letter-spacing: -3.1px;
                                        white-space: normal;
                                        line-height: 1.1;
                                        margin: 0;
                                        padding: 3px
                                    }
                                }

                                .ssr-variant,
                                .hidden-18ccrvo,
                                .hidden-72rtr7 {
                                    h1 {
                                        background-image: url(https://framerusercontent.com/images/DN7ut8BMdxCiadtYJWSG1Vt7zA.svg);
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        background-position: 0% 0%;
                                        background-clip: text;
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                        text-fill-color: transparent;
                                        color: rgb(136, 136, 136);
                                        font-size: 38px;
                                        font-family: "Inter-Bold", "Inter", "Inter Placeholder", sans-serif;
                                        font-style: normal;
                                        font-weight: 600;
                                        text-align: center;
                                        letter-spacing: -1.8px;
                                        white-space: normal;
                                        line-height: 1;
                                        margin: 0;
                                        padding: 3px
                                    }
                                }

                                .ssr-variant,
                                .hidden-p5w47l,
                                .hidden-72rtr7 {
                                    h1 {
                                        background-image: url(https://framerusercontent.com/images/DN7ut8BMdxCiadtYJWSG1Vt7zA.svg);
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        background-position: 0% 0%;
                                        background-clip: text;
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                        text-fill-color: transparent;
                                        color: rgb(136, 136, 136);

                                        @media only screen and (max-width: 1439px) {

                                            font-size: 55px;

                                        }

                                        @media only screen and (max-width: 809px) {

                                            font-size: 38px;

                                        }

                                        font-size: 63px;
                                        font-family: "Inter-Bold",
                                        "Inter",
                                        "Inter Placeholder",
                                        sans-serif;
                                        font-style: normal;
                                        font-weight: 600;
                                        text-align: center;
                                        letter-spacing: -3.1px;
                                        white-space: normal;
                                        line-height: 1.1;
                                        margin: 0;
                                        padding: 3px
                                    }
                                }
                            }

                            .framer-cn6gtm {
                                outline: none;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                flex-shrink: 0;
                                transform: none;

                                .framer-text,
                                .framer-styles-preset-186siln {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                                    background-clip: text;
                                }
                            }
                        }

                        .framer-1o31f8i {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-186siln {
                                background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);
                                background-clip: text;
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-p5w47l,
                    .hidden-18ccrvo {
                        .framer-o8tt5c {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-1wzfti9 {
                                --framer-text-alignment: left
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-18ccrvo,
                    .hidden-72rtr7 {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        .framer-text,
                        .framer-styles-preset-1wzfti9 {
                            --framer-text-alignment: center
                        }
                    }

                    .ssr-variant,
                    .hidden-p5w47l,
                    .hidden-72rtr7 {
                        .framer-o8tt5c {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text,
                            .framer-styles-preset-1wzfti9 {
                                --framer-text-alignment: center
                            }
                        }
                    }
                }

                .framer-1rp823r {


                    .framer-19ah3bk {
                        .framer-u1xhss {
                            .framer-zoyn32 {
                                image-rendering: pixelated;
                                flex-shrink: 0;
                                fill: rgba(0, 0, 0, 1);
                                color: rgba(0, 0, 0, 1);

                                .svgContainer {
                                    width: 100%;
                                    height: 100%;
                                    aspect-ratio: inherit;

                                    .svg {
                                        width: 100%;
                                        height: 100%
                                    }
                                }
                            }

                            .ssr-variant,
                            .hidden-p5w47l {
                                .framer-qnjpuc {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text,
                                    .framer-styles-preset-1wzfti9 {
                                        --framer-text-alignment: left
                                    }

                                    @media only screen and (max-width: 809px) {

                                        .framer-text,
                                        .framer-styles-preset-1wzfti9 {
                                            --framer-text-alignment: center
                                        }

                                    }
                                }
                            }

                            .ssr-variant,
                            .hidden-18ccrvo,
                            .hidden-72rtr7 {
                                .framer-qnjpu {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text,
                                    .framer-styles-preset-1wzfti9 {
                                        --framer-text-alignment: center
                                    }
                                }
                            }
                        }

                        .framer-1tsrlxx,
                        .hidden-p5w47l {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1);

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%
                                }
                            }
                        }
                    }

                    .framer-1peizua {

                        .framer-2hs7n1 {

                            .framer-c5bs8e {
                                image-rendering: pixelated;
                                flex-shrink: 0;
                                fill: rgba(0, 0, 0, 1);
                                color: rgba(0, 0, 0, 1);

                                .svgContainer {
                                    width: 100%;
                                    height: 100%;
                                    aspect-ratio: inherit;

                                    svg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }



                            .ssr-variant,
                            .hidden-p5w47l {

                                .framer-zri7uj {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text,
                                    .framer-styles-preset-1wzfti9 {
                                        --framer-text-alignment: left
                                    }

                                    @media only screen and (max-width: 809px) {

                                        .framer-text,
                                        .framer-styles-preset-1wzfti9 {
                                            --framer-text-alignment: center
                                        }

                                    }

                                }
                            }

                            .ssr-variant,
                            .hidden-18ccrvo,
                            .hidden-72rtr7 {
                                border: 2px solid red;

                                .ramer-zri7uj {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;
                                    border: 2px solid;

                                    @media only screen and (max-width: 809px) {

                                        .framer-text,
                                        .framer-styles-preset-1wzfti9 {
                                            --framer-text-alignment: center
                                        }

                                    }
                                }
                            }
                        }

                        .framer-r7pqdu,
                        .hidden-p5w47l {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1);

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%
                                }
                            }

                        }
                    }

                    .framer-juqyll {
                        .framer-1sfr8we {
                            .framer-1int50y {
                                image-rendering: pixelated;
                                flex-shrink: 0;
                                fill: rgba(0, 0, 0, 1);
                                color: rgba(0, 0, 0, 1);

                                .svgContainer {
                                    width: 100%;
                                    height: 100%;
                                    aspect-ratio: inherit;

                                    svg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }

                            .ssr-variant,
                            .hidden-p5w47l {
                                .framer-fqwwjg {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    .framer-text,
                                    .framer-styles-preset-1wzfti9 {
                                        --framer-text-alignment: left
                                    }
                                }
                            }

                            .ssr-variant,
                            .hidden-18ccrvo,
                            .hidden-72rtr7 {
                                .framer-fqwwjg {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    transform: none;

                                    @media only screen and (max-width: 809px) {

                                        .framer-text,
                                        .framer-styles-preset-1wzfti9 {
                                            --framer-text-alignment: center
                                        }
                                    }
                                }
                            }
                        }

                        .framer-1cqgb3d,
                        .hidden-p5w47l {
                            image-rendering: pixelated;
                            flex-shrink: 0;
                            fill: rgba(0, 0, 0, 1);
                            color: rgba(0, 0, 0, 1);

                            .svgContainer {
                                width: 100%;
                                height: 100%;
                                aspect-ratio: inherit;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }

                .framer-zf6ut8 {
                    .framer-1ki0x6a-container {

                        .framer-oyI4k,
                        .framer-v-693ydj {
                            display: contents;

                            .framer-693ydj,
                            .framer-mesh6j {
                                --border-bottom-width: 1.6861314px;
                                --border-color: rgba(255, 255, 255, 0.3);
                                --border-left-width: 1.6861314px;
                                --border-right-width: 1.6861314px;
                                --border-style: solid;
                                --border-top-width: 1.6861314px;
                                background: linear-gradient(180deg, rgba(117, 172, 255, 0) 0%, rgba(255, 255, 255, 0.12) 100%);
                                background-color: rgba(0, 0, 0, 0);
                                border-bottom-left-radius: 10px;
                                border-bottom-right-radius: 10px;
                                border-top-left-radius: 10px;
                                border-top-right-radius: 10px;

                                .framer-crfnzq {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    --extracted-r6o4lv: rgb(255, 255, 255);
                                    --framer-paragraph-spacing: 0px;
                                    transform: none;

                                    .framer-text {
                                        --font-selector: SW50ZXItU2VtaUJvbGQ=;
                                        font-family: "Inter-Bold", "Inter", "Inter Placeholder", sans-serif;
                                        --framer-font-size: 16.86px;
                                        --framer-font-weight: 600;
                                        --framer-letter-spacing: -0.42px;
                                        --framer-line-height: 100%;
                                        --framer-text-alignment: center;
                                        --framer-text-color: var(--extracted-r6o4lv)
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .framer-rgy95z {

                .ssr-variant,
                .hidden-18ccrvo,
                .hidden-72rtr7 {
                    transform: translateX(-50%)
                }

                .ssr-variant,
                .hidden-p5w47l,
                .hidden-72rtr7 {
                    transform: translateX(-50%)
                }

                .framer-elb6p6 {
                    div {
                        position: absolute;
                        border-radius: inherit;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-position: center;
                            object-fit: cover;
                            image-rendering: auto
                        }
                    }
                }
            }
        }
    }
}