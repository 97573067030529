.framer-nprimq-container {
    display: contents;
    z-index: 1;

    .hidden-p5w47l,
    .hidden-18ccrvo {
        #svg434534884_140 {
            width: 13px !important;
            height: 13px !important;
        }

        .svgContainer {
            width: 100%;
            height: 100%;
            aspect-ratio: inherit;



            .svg {
                width: 100%;
                height: 100%;


            }
        }

        .framer-LJUxW,
        .framer-jUJDS,
        .framer-EjWs1,
        .framer-v-1aayn3s {
            .framer-1aayn3s {
                width: 100%;



                .framer-2am1f1 {
                    .framer-fbtuha {
                        z-index: 1;

                        .framer-sbtnlc {
                            gap: 100px;

                            @media only screen and (max-width: 809px) {
                                // width: 80%;
                                gap: 50px;
                            }

                            @media only screen and (max-width: 1439px) {
                                // width: 80%;
                                gap: 50px;
                            }

                            .framer-izixkc {
                                .framer-19waysq {


                                    .framer-u5fogz {
                                        outline: none;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: flex-start;
                                        flex-shrink: 0;
                                        --extracted-r6o4lv: rgb(241, 245, 249);
                                        --framer-paragraph-spacing: 0px;
                                        transform: none;

                                        .framer-text {
                                            --font-selector: SW50ZXItTGlnaHQ=;
                                            --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                            --framer-font-size: 15px;
                                            --framer-font-weight: 300;
                                            --framer-letter-spacing: 0.75px;
                                            --framer-line-height: 35px;
                                            --framer-text-alignment: center;
                                            --framer-text-color: var(--extracted-r6o4lv);
                                            --framer-text-transform: uppercase;
                                        }
                                    }

                                    .framer-1db3of8 {
                                        image-rendering: pixelated;
                                        flex-shrink: 0;
                                        fill: rgba(0, 0, 0, 1);
                                        color: rgba(0, 0, 0, 1);

                                        .svgContainer {
                                            width: 100%;
                                            height: 100%;
                                            aspect-ratio: inherit;

                                            .svg {
                                                width: 100%;
                                                height: 100%;
                                            }
                                        }
                                    }
                                }

                                .framer-1rkmp1x {
                                    .framer-2jsrsv {
                                        .framer-axfwds {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            --framer-paragraph-spacing: 0px;
                                            transform: none;

                                            .framer-text {
                                                --framer-font-size: 62px;

                                                .framer-text {
                                                    background-image: linear-gradient(93.45701822025558deg, rgba(90, 194, 255, 1) 0%, rgba(186, 254, 109, 1) 100%);
                                                    background-clip: text;

                                                    .framer-text {
                                                        --font-selector: SW50ZXItU2VtaUJvbGQ=;
                                                        --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                        --framer-font-size: 62px;
                                                        --framer-font-weight: 600;
                                                        --framer-letter-spacing: -3.1px
                                                    }
                                                }
                                            }
                                        }

                                        .framer-rni9ns {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            --framer-paragraph-spacing: 0px;
                                            transform: none;

                                            .framer-text,
                                            .framer-styles-preset-186siln {
                                                .framer-text {
                                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%);

                                                }
                                            }
                                        }
                                    }

                                    .framer-1m021lb {
                                        outline: none;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: flex-start;
                                        flex-shrink: 0;
                                        --framer-paragraph-spacing: 0px;
                                        transform: none;

                                        .framer-text,
                                        .framer-styles-preset-186siln {
                                            .framer-text {
                                                background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                            }
                                        }
                                    }
                                }
                            }

                            .framer-1umr50u {
                                gap: 100px;
                                width: 100%;

                                @media only screen and (max-width: 809px) {
                                    gap: 50px;

                                    .framer-1wsiscg {

                                        .framer-text,
                                        .framer-styles-preset-1wzfti9 {
                                            --framer-text-alignment: center !important;
                                        }
                                    }
                                }


                                @media only screen and (max-width: 1439px) {
                                    // width: 80%;
                                    gap: 50px;

                                    .framer-1wsiscg {

                                        .framer-text,
                                        .framer-styles-preset-1wzfti9 {
                                            --framer-text-alignment: center !important;
                                        }
                                    }
                                }

                                .framer-1wsiscg {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    --framer-paragraph-spacing: 0px;
                                    transform: none;

                                    .framer-text,
                                    .framer-styles-preset-1wzfti9 {
                                        --framer-text-alignment: left;
                                    }
                                }

                                .framer-8yx99d-container {

                                    .framer-oyI4k,
                                    .framer-v-693ydj {
                                        display: flex;
                                        flex-wrap: wrap;
                                        gap: 15px;

                                        .framer-693ydj,
                                        .framer-mesh6j {
                                            --border-bottom-width: 1.6861314px;
                                            --border-color: rgba(255, 255, 255, 0.3);
                                            --border-left-width: 1.6861314px;
                                            --border-right-width: 1.6861314px;
                                            --border-style: solid;
                                            --border-top-width: 1.6861314px;
                                            background: linear-gradient(180deg, rgba(117, 172, 255, 0) 0%, rgba(255, 255, 255, 0.12) 100%);
                                            background-color: rgba(0, 0, 0, 0);
                                            border-bottom-left-radius: 10px;
                                            border-bottom-right-radius: 10px;
                                            border-top-left-radius: 10px;
                                            border-top-right-radius: 10px;
                                            $borderWidth: 1.6px;
                                            transition-duration: .5s;
                                            box-shadow: inset 0 0 0 $borderWidth --border-color;

                                            &:hover {
                                                box-shadow:
                                                    inset 0 0 7px $borderWidth rgba(255, 255, 255, 0.6),
                                                    0 0 1px rgba(0, 0, 0, 0);

                                            }

                                            .framer-crfnzq {
                                                outline: none;
                                                display: flex;
                                                flex-direction: column;
                                                justify-content: flex-start;
                                                flex-shrink: 0;
                                                --extracted-r6o4lv: rgb(255, 255, 255);
                                                --framer-paragraph-spacing: 0px;
                                                transform: none;

                                                .framer-text {
                                                    --font-selector: SW50ZXItU2VtaUJvbGQ=;
                                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                    --framer-font-size: 16.86px;
                                                    --framer-font-weight: 400;
                                                    --framer-letter-spacing: -0.42px;
                                                    --framer-line-height: 100%;
                                                    --framer-text-alignment: center;
                                                    --framer-text-color: var(--extracted-r6o4lv);
                                                }
                                            }

                                        }

                                        .active {
                                            box-shadow:
                                                inset 0 0 7px 1.6px rgba(255, 255, 255, 0.6),
                                                0 0 1px rgba(0, 0, 0, 0);
                                        }
                                    }
                                }
                            }
                        }

                        .framer-9nz7c6 {
                            .framer-1tve9vk-container {
                                transform: none;
                                transform-origin: 50% 50% 0px;

                                .framer-KrDQC,
                                .framer-v-43wrbu {}
                            }


                            .framer-bu5nxc {
                                background-color: rgb(255, 255, 255);
                                opacity: 1.2;
                            }

                            .framer-1q6w3m2 {
                                background-color: rgb(255, 255, 255);
                                opacity: 1.2
                            }

                            .framer-1rxczvh {
                                background-color: rgb(255, 255, 255);
                                opacity: 1.2
                            }

                            .framer-19a87go {
                                background-color: rgb(255, 255, 255);
                                opacity: 1.2
                            }





                            .framer-1vbgyyi {
                                transform: none;
                                transform-origin: 50% 50% 0px;

                                .framer-1t196q0 {
                                    transform: none;
                                    transform-origin: 50% 50% 0px;

                                    .open {
                                        background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
                                        transform: rotate(45deg) !important;
                                        transition: .3s !important;
                                    }

                                    .framer-97smsc {
                                        --border-bottom-width: 1px;
                                        --border-color: rgb(255, 255, 255);
                                        --border-left-width: 1px;
                                        --border-right-width: 1px;
                                        --border-style: solid;
                                        --border-top-width: 1px;
                                        background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
                                        transform: rotate(0deg);
                                        transform-origin: 50% 50% 0px;
                                        border-radius: 207.455%;
                                        transition: .3s !important;

                                        .framer-o5tq9a {
                                            image-rendering: pixelated;
                                            flex-shrink: 0;
                                            fill: black;
                                            color: black;
                                            transform: none;
                                            transform-origin: 50% 50% 0px;

                                        }
                                    }

                                    .framer-1it09ja {
                                        transform: none;
                                        transform-origin: 50% 50% 0px;

                                        .framer-f2wsgf {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            --extracted-r6o4lv: rgb(241, 245, 249);
                                            --framer-paragraph-spacing: 0px;
                                            transform: none;
                                            transform-origin: 50% 50% 0px;

                                            .framer-text {
                                                --font-selector: SW50ZXItTGlnaHQ=;
                                                --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                --framer-font-size: 20px;
                                                --framer-font-weight: 300;
                                                --framer-letter-spacing: -0.4px;
                                                --framer-line-height: 130%;
                                                --framer-text-alignment: left;
                                                --framer-text-color: var(--extracted-r6o4lv)
                                            }
                                        }
                                    }
                                }



                                .framer-18qc69a {
                                    transform: none;
                                    transform-origin: 50% 50% 0px;
                                    max-height: 0vh !important;
                                    overflow: hidden;
                                    transition: .5s !important;

                                    .framer-slca9b {
                                        transform: none;
                                        transform-origin: 50% 50% 0px;

                                        .framer-18f3nw9 {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            --extracted-r6o4lv: rgba(241, 245, 249, 0.5);
                                            --framer-paragraph-spacing: 0px;
                                            transform: none;
                                            transform-origin: 50% 50% 0px;

                                            .framer-text {
                                                --font-selector: SW50ZXItTGlnaHQ=;
                                                --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                --framer-font-size: 20px;
                                                --framer-font-weight: 300;
                                                --framer-letter-spacing: -0.4px;
                                                --framer-line-height: 130%;
                                                --framer-text-alignment: left;
                                                --framer-text-color: var(--extracted-r6o4lv);
                                            }
                                        }
                                    }
                                }

                                .close {
                                    transition: 2s !important;
                                    max-height: 100vh !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    @media only screen and (max-width: 809px) {


        .hidden-18ccrvo,
        .hidden-72rtr7 {

            .framer-LJUxW,
            .framer-jUJDS,
            .framer-EjWs1,
            .framer-v-1swwsya {
                .framer-1aayn3s {
                    width: 100%;



                    .framer-2am1f1 {
                        .framer-fbtuha {
                            z-index: 1;

                            .framer-sbtnlc {
                                .framer-izixkc {

                                    .framer-r3i0ys {

                                        .framer-p0n910 {
                                            image-rendering: pixelated;
                                            flex-shrink: 0;
                                            fill: rgba(0, 0, 0, 1);
                                            color: rgba(0, 0, 0, 1);

                                            #container_svg562808578_442 {
                                                svg {
                                                    width: 100% !important;

                                                }
                                            }
                                        }

                                        .framer-11faj47 {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            --extracted-r6o4lv: rgb(241, 245, 249);
                                            --framer-paragraph-spacing: 0px;
                                            transform: none;

                                            .framer-text,
                                            p {
                                                --font-selector: SW50ZXItTGlnaHQ=;
                                                --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                --framer-font-size: 15px;
                                                --framer-font-weight: 300;
                                                --framer-letter-spacing: 0.75px;
                                                --framer-line-height: 35px;
                                                --framer-text-alignment: center;
                                                --framer-text-color: var(--extracted-r6o4lv);
                                                --framer-text-transform: uppercase
                                            }
                                        }

                                        .framer-171v13l {
                                            image-rendering: pixelated;
                                            flex-shrink: 0;
                                            fill: rgba(0, 0, 0, 1);
                                            color: rgba(0, 0, 0, 1);

                                            #container_svg1921603383_437 {
                                                svg {
                                                    width: 100% !important;
                                                }
                                            }

                                        }
                                    }

                                    .framer-1rkmp1x {
                                        .framer-2jsrsv {
                                            .framer-axfwds {
                                                outline: none;
                                                display: flex;
                                                flex-direction: column;
                                                justify-content: flex-start;
                                                flex-shrink: 0;
                                                --framer-paragraph-spacing: 0px;
                                                transform: none;

                                                .framer-text {
                                                    --font-selector: SW50ZXItU2VtaUJvbGQ=;
                                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                    --framer-font-size: 38px;
                                                    --framer-font-weight: 600;
                                                    --framer-letter-spacing: -3.1px;

                                                    .framer-text {
                                                        background-image: linear-gradient(93.457deg, rgb(90, 194, 255) 0%, rgb(186, 254, 109) 100%)
                                                    }
                                                }
                                            }

                                            .framer-rni9ns {
                                                outline: none;
                                                display: flex;
                                                flex-direction: column;
                                                justify-content: flex-start;
                                                flex-shrink: 0;
                                                --framer-paragraph-spacing: 0px;
                                                transform: none;

                                                .ramer-text,
                                                .framer-styles-preset-186siln {
                                                    .ramer-text {
                                                        background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                                    }
                                                }
                                            }
                                        }

                                        .framer-1m021lb {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            --framer-paragraph-spacing: 0px;
                                            transform: none;

                                            .framer-text,
                                            .framer-styles-preset-186siln {
                                                .framer-text {
                                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                                }
                                            }
                                        }
                                    }

                                }

                                .framer-1umr50u {
                                    .framer-1wsiscg {
                                        outline: none;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: flex-start;
                                        flex-shrink: 0;
                                        --framer-paragraph-spacing: 0px;
                                        transform: none;

                                        .framer-text,
                                        .framer-styles-preset-1wzfti9 {
                                            --framer-text-alignment: center
                                        }
                                    }

                                    .framer-8yx99d-container {

                                        .framer-oyI4k,
                                        .framer-v-693ydj {
                                            display: flex;
                                            flex-wrap: wrap;
                                            gap: 10px;

                                            .framer-693ydj,
                                            .framer-mesh6j {
                                                --border-bottom-width: 1.6861314px;
                                                --border-color: rgba(255, 255, 255, 0.3);
                                                --border-left-width: 1.6861314px;
                                                --border-right-width: 1.6861314px;
                                                --border-style: solid;
                                                --border-top-width: 1.6861314px;
                                                background: linear-gradient(180deg, rgba(117, 172, 255, 0) 0%, rgba(255, 255, 255, 0.12) 100%);
                                                background-color: rgba(0, 0, 0, 0);
                                                border-bottom-left-radius: 10px;
                                                border-bottom-right-radius: 10px;
                                                border-top-left-radius: 10px;
                                                border-top-right-radius: 10px;

                                                $borderWidth: 1.6px;
                                                transition-duration: .5s;
                                                box-shadow: inset 0 0 0 $borderWidth --border-color;

                                                &:hover {
                                                    box-shadow:
                                                        inset 0 0 7px $borderWidth rgba(255, 255, 255, 0.6),
                                                        0 0 1px rgba(0, 0, 0, 0);

                                                }

                                                .framer-crfnzq {
                                                    outline: none;
                                                    display: flex;
                                                    flex-direction: column;
                                                    justify-content: flex-start;
                                                    flex-shrink: 0;
                                                    --extracted-r6o4lv: rgb(255, 255, 255);
                                                    --framer-paragraph-spacing: 0px;
                                                    transform: none;

                                                    .framer-text {
                                                        --font-selector: SW50ZXItU2VtaUJvbGQ=;
                                                        --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                        --framer-font-size: 16.86px;
                                                        --framer-font-weight: 600;
                                                        --framer-letter-spacing: -0.42px;
                                                        --framer-line-height: 100%;
                                                        --framer-text-alignment: center;
                                                        --framer-text-color: var(--extracted-r6o4lv)
                                                    }
                                                }
                                            }

                                            .active {
                                                box-shadow:
                                                    inset 0 0 7px 1.6px rgba(255, 255, 255, 0.6),
                                                    0 0 1px rgba(0, 0, 0, 0);
                                            }
                                        }

                                    }

                                }
                            }

                            .framer-9nz7c6 {
                                .framer-1tve9vk-container {
                                    transform: none;
                                    transform-origin: 50% 50% 0px;

                                    .framer-KrDQC,
                                    .framer-v-43wrbu {}
                                }


                                .framer-bu5nxc {
                                    background-color: rgb(255, 255, 255);
                                    opacity: 1.2;
                                }

                                .framer-1q6w3m2 {
                                    background-color: rgb(255, 255, 255);
                                    opacity: 1.2
                                }

                                .framer-1rxczvh {
                                    background-color: rgb(255, 255, 255);
                                    opacity: 1.2
                                }

                                .framer-19a87go {
                                    background-color: rgb(255, 255, 255);
                                    opacity: 1.2
                                }





                                .framer-1vbgyyi {
                                    transform: none;
                                    transform-origin: 50% 50% 0px;
                                    width: 100%;

                                    .framer-1t196q0 {
                                        transform: none;
                                        transform-origin: 50% 50% 0px;

                                        .open {
                                            background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
                                            transform: rotate(45deg) !important;
                                            transition: .3s !important;
                                        }

                                        .framer-97smsc {
                                            --border-bottom-width: 1px;
                                            --border-color: rgb(255, 255, 255);
                                            --border-left-width: 1px;
                                            --border-right-width: 1px;
                                            --border-style: solid;
                                            --border-top-width: 1px;
                                            background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
                                            transform: rotate(0deg);
                                            transform-origin: 50% 50% 0px;
                                            border-radius: 206.957%;
                                            transition: .3s !important;

                                            .framer-o5tq9a {
                                                image-rendering: pixelated;
                                                flex-shrink: 0;
                                                fill: black;
                                                color: black;
                                                transform: none;
                                                transform-origin: 50% 50% 0px;
                                            }
                                        }

                                        .framer-1it09ja {
                                            transform: none;
                                            transform-origin: 50% 50% 0px;

                                            .framer-f2wsgf {
                                                outline: none;
                                                display: flex;
                                                flex-direction: column;
                                                justify-content: flex-start;
                                                flex-shrink: 0;
                                                --extracted-r6o4lv: rgb(241, 245, 249);
                                                --framer-paragraph-spacing: 0px;
                                                transform: none;
                                                transform-origin: 50% 50% 0px;

                                                .framer-text {
                                                    --font-selector: SW50ZXItTGlnaHQ=;
                                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                    --framer-font-size: 20px;
                                                    --framer-font-weight: 300;
                                                    --framer-letter-spacing: -0.4px;
                                                    --framer-line-height: 130%;
                                                    --framer-text-alignment: left;
                                                    --framer-text-color: var(--extracted-r6o4lv)
                                                }
                                            }
                                        }
                                    }



                                    .framer-18qc69a {
                                        transform: none;
                                        transform-origin: 50% 50% 0px;
                                        max-height: 0vh !important;
                                        overflow: hidden;
                                        transition: .5s !important;

                                        .framer-slca9b {
                                            transform: none;
                                            transform-origin: 50% 50% 0px;

                                            .framer-18f3nw9 {
                                                outline: none;
                                                display: flex;
                                                flex-direction: column;
                                                justify-content: flex-start;
                                                flex-shrink: 0;
                                                --extracted-r6o4lv: rgba(241, 245, 249, 0.5);
                                                --framer-paragraph-spacing: 0px;
                                                transform: none;
                                                transform-origin: 50% 50% 0px;

                                                .framer-text {
                                                    --font-selector: SW50ZXItTGlnaHQ=;
                                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                    --framer-font-size: 20px;
                                                    --framer-font-weight: 300;
                                                    --framer-letter-spacing: -0.4px;
                                                    --framer-line-height: 130%;
                                                    --framer-text-alignment: left;
                                                    --framer-text-color: var(--extracted-r6o4lv);
                                                }
                                            }
                                        }
                                    }

                                    .close {
                                        transition: 2s !important;
                                        max-height: 100vh !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1439px) {

        .hidden-p5w47l,
        .hidden-72rtr7 {

            .ramer-LJUxW,
            .framer-jUJDS,
            .framer-EjWs1,
            .framer-v-gww2m3 {
                .framer-2am1f1 {
                    .framer-fbtuha {
                        z-index: 1;

                        .framer-sbtnlc {
                            .framer-izixkc {

                                .framer-r3i0ys {

                                    .framer-p0n910 {
                                        image-rendering: pixelated;
                                        flex-shrink: 0;
                                        fill: rgba(0, 0, 0, 1);
                                        color: rgba(0, 0, 0, 1);
                                    }

                                    .framer-11faj47 {
                                        outline: none;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: flex-start;
                                        flex-shrink: 0;
                                        --extracted-r6o4lv: rgb(241, 245, 249);
                                        --framer-paragraph-spacing: 0px;
                                        transform: none;

                                        .framer-text,
                                        p {
                                            --font-selector: SW50ZXItTGlnaHQ=;
                                            --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                            --framer-font-size: 15px;
                                            --framer-font-weight: 300;
                                            --framer-letter-spacing: 0.75px;
                                            --framer-line-height: 35px;
                                            --framer-text-alignment: center;
                                            --framer-text-color: var(--extracted-r6o4lv);
                                            --framer-text-transform: uppercase
                                        }
                                    }

                                    .framer-171v13l {
                                        image-rendering: pixelated;
                                        flex-shrink: 0;
                                        fill: rgba(0, 0, 0, 1);
                                        color: rgba(0, 0, 0, 1);

                                    }
                                }

                                .framer-1rkmp1x {
                                    .framer-2jsrsv {
                                        .framer-axfwds {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            --framer-paragraph-spacing: 0px;
                                            transform: none;

                                            .framer-text {
                                                --framer-font-size: 62px;

                                                .framer-text {
                                                    background-image: linear-gradient(93.45701822025558deg, rgba(90, 194, 255, 1) 0%, rgba(186, 254, 109, 1) 100%);

                                                    .framer-text {
                                                        --font-selector: SW50ZXItU2VtaUJvbGQ=;
                                                        --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                        --framer-font-size: 62px;
                                                        --framer-font-weight: 600;
                                                        --framer-letter-spacing: -3.1px;
                                                    }
                                                }
                                            }
                                        }

                                        .framer-rni9ns {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            --framer-paragraph-spacing: 0px;
                                            transform: none;

                                            .ramer-text,
                                            .framer-styles-preset-186siln {
                                                .ramer-text {
                                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                                }
                                            }
                                        }
                                    }

                                    .framer-1m021lb {
                                        outline: none;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: flex-start;
                                        flex-shrink: 0;
                                        --framer-paragraph-spacing: 0px;
                                        transform: none;

                                        .framer-text,
                                        .framer-styles-preset-186siln {
                                            .framer-text {
                                                background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.698) 100%)
                                            }
                                        }
                                    }
                                }

                            }

                            .framer-1umr50u {
                                .framer-1wsiscg {
                                    outline: none;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    flex-shrink: 0;
                                    --framer-paragraph-spacing: 0px;
                                    transform: none;

                                    .framer-text,
                                    .framer-styles-preset-1wzfti9 {
                                        --framer-text-alignment: center
                                    }
                                }

                                .framer-8yx99d-container {


                                    .framer-oyI4k,
                                    .framer-v-693ydj {
                                        display: flex;
                                        justify-content: center;
                                        flex-wrap: wrap;
                                        gap: 10px;

                                        .framer-693ydj,
                                        .framer-mesh6j {
                                            --border-bottom-width: 1.6861314px;
                                            --border-color: rgba(255, 255, 255, 0.3);
                                            --border-left-width: 1.6861314px;
                                            --border-right-width: 1.6861314px;
                                            --border-style: solid;
                                            --border-top-width: 1.6861314px;
                                            background: linear-gradient(180deg, rgba(117, 172, 255, 0) 0%, rgba(255, 255, 255, 0.12) 100%);
                                            background-color: rgba(0, 0, 0, 0);
                                            border-bottom-left-radius: 10px;
                                            border-bottom-right-radius: 10px;
                                            border-top-left-radius: 10px;
                                            border-top-right-radius: 10px;

                                            $borderWidth: 1.6px;
                                            transition-duration: .5s;
                                            box-shadow: inset 0 0 0 $borderWidth --border-color;

                                            &:hover {
                                                box-shadow:
                                                    inset 0 0 7px $borderWidth rgba(255, 255, 255, 0.6),
                                                    0 0 1px rgba(0, 0, 0, 0);

                                            }

                                            .framer-crfnzq {
                                                outline: none;
                                                display: flex;
                                                flex-direction: column;
                                                justify-content: flex-start;
                                                flex-shrink: 0;
                                                --extracted-r6o4lv: rgb(255, 255, 255);
                                                --framer-paragraph-spacing: 0px;
                                                transform: none;

                                                .framer-text {
                                                    --font-selector: SW50ZXItU2VtaUJvbGQ=;
                                                    --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                    --framer-font-size: 16.86px;
                                                    --framer-font-weight: 600;
                                                    --framer-letter-spacing: -0.42px;
                                                    --framer-line-height: 100%;
                                                    --framer-text-alignment: center;
                                                    --framer-text-color: var(--extracted-r6o4lv)
                                                }
                                            }
                                        }
                                    }
                                }

                                .active {
                                    box-shadow:
                                        inset 0 0 7px 1.6px rgba(255, 255, 255, 0.6),
                                        0 0 1px rgba(0, 0, 0, 0);
                                }
                            }
                        }

                        .framer-9nz7c6 {
                            .framer-1tve9vk-container {
                                transform: none;
                                transform-origin: 50% 50% 0px;

                                .framer-KrDQC,
                                .framer-v-43wrbu {}
                            }


                            .framer-bu5nxc {
                                background-color: rgb(255, 255, 255);
                                opacity: 1.2;
                            }

                            .framer-1q6w3m2 {
                                background-color: rgb(255, 255, 255);
                                opacity: 1.2
                            }

                            .framer-1rxczvh {
                                background-color: rgb(255, 255, 255);
                                opacity: 1.2
                            }

                            .framer-19a87go {
                                background-color: rgb(255, 255, 255);
                                opacity: 1.2
                            }





                            .framer-1vbgyyi {
                                transform: none;
                                transform-origin: 50% 50% 0px;
                                opacity: 1;

                                .framer-1t196q0 {
                                    transform: none;
                                    transform-origin: 50% 50% 0px;
                                    opacity: 1;

                                    .open {
                                        background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
                                        transform: rotate(0deg) !important;
                                        transition: .3s !important;
                                    }

                                    .framer-97smsc {
                                        --border-bottom-width: 1px;
                                        --border-color: rgb(255, 255, 255);
                                        --border-left-width: 1px;
                                        --border-right-width: 1px;
                                        --border-style: solid;
                                        --border-top-width: 1px;
                                        background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
                                        transform: rotate(50deg) translateZ(0px);
                                        transform-origin: 50% 50% 0px;
                                        border-radius: 60px;
                                        opacity: 1;
                                        transition: .3s !important;

                                        .framer-o5tq9a {
                                            image-rendering: pixelated;
                                            flex-shrink: 0;
                                            fill: black;
                                            color: black;
                                            transform: none;
                                            transform-origin: 50% 50% 0px;
                                        }
                                    }

                                    .framer-1it09ja {
                                        transform: none;
                                        transform-origin: 50% 50% 0px;
                                        opacity: 1;

                                        .framer-f2wsgf {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            --extracted-r6o4lv: rgb(241, 245, 249);
                                            --framer-paragraph-spacing: 0px;
                                            transform: none;
                                            transform-origin: 50% 50% 0px;
                                            opacity: 1;

                                            .framer-text {
                                                --font-selector: SW50ZXItTGlnaHQ=;
                                                --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                --framer-font-size: 20px;
                                                --framer-font-weight: 300;
                                                --framer-letter-spacing: -0.4px;
                                                --framer-line-height: 130%;
                                                --framer-text-alignment: left;
                                                --framer-text-color: var(--extracted-r6o4lv)
                                            }
                                        }
                                    }
                                }

                                .close {
                                    transition: 2s !important;
                                    max-height: 100vh !important;
                                }

                                .framer-18qc69a {
                                    transform: none;
                                    transform-origin: 50% 50% 0px;
                                    max-height: 0vh !important;
                                    overflow: hidden;
                                    transition: .5s !important;

                                    .framer-slca9b {
                                        transform: none;
                                        transform-origin: 50% 50% 0px;
                                        opacity: 1;


                                        .framer-18f3nw9 {
                                            outline: none;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: flex-start;
                                            flex-shrink: 0;
                                            --extracted-r6o4lv: rgba(241, 245, 249, 0.5);
                                            --framer-paragraph-spacing: 0px;
                                            transform: none;
                                            transform-origin: 50% 50% 0px;
                                            opacity: 1;

                                            .framer-text {
                                                --font-selector: SW50ZXItTGlnaHQ=;
                                                --framer-font-family: "Inter-Light", "Inter", sans-serif;
                                                --framer-font-size: 20px;
                                                --framer-font-weight: 300;
                                                --framer-letter-spacing: -0.4px;
                                                --framer-line-height: 130%;
                                                --framer-text-alignment: left;
                                                --framer-text-color: var(--extracted-r6o4lv);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}