.framer-1l9mm7q {

    .framer-1pvz644 {
        opacity: 1;
        transform: perspective(1200px);

        .ssr-variant,
        .hidden-p5w47l {
            .framer-y6bzdz-container {
                transform: rotate(390deg);

                div {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    background-color: rgba(255, 255, 255, 0);
                    transform: translateZ(0);
                    border-radius: 700px;
                    position: relative;

                    #One {
                        canvas {
                            width: 100%;
                            height: 100%;
                            position: absolute
                        }
                    }
                }
            }
        }

        .ssr-variant,
        .hidden-18ccrvo,
        .hidden-72rtr7 {
            .framer-y6bzdz-container {
                transform: rotate(-40deg);

                div {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    background-color: rgba(255, 255, 255, 0);
                    transform: translateZ(0);
                    border-radius: 700px;
                    position: relative;

                    #One {
                        canvas {
                            width: 100%;
                            height: 100%;
                            position: absolute
                        }
                    }
                }
            }
        }

        .framer-5j3a53 {
            image-rendering: pixelated;
            flex-shrink: 0;
            fill: rgba(0, 0, 0, 1);
            color: rgba(0, 0, 0, 1);

            .svgContainer {
                width: 100%;
                height: 100%;
                aspect-ratio: inherit;

                svg {
                    width: 100%;
                    height: 100%;

                    .bg_svg {
                        border: 2px solid yellow;
                        width: 100% !important;

                    }
                }
            }
        }
    }

    .framer-1wrrtkp {
        .framer-ofssek {
            .framer-e61qcl {
                opacity: 1;
                transform: perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0);

                .framer-hgg6ej {
                    opacity: 1;
                    transform: perspective(1200px);

                    .framer-1ps7qll {
                        image-rendering: pixelated;
                        flex-shrink: 0;
                        fill: rgba(0, 0, 0, 1);
                        color: rgba(0, 0, 0, 1);

                        .svgContainer {
                            width: 100%;
                            height: 100%;
                            aspect-ratio: inherit;

                            svg {
                                width: 100%;
                                height: 100%
                            }
                        }
                    }

                    .ssr-variant,
                    .hidden-p5w47l {
                        .framer-wla99d {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text {
                                --font-selector: SW50ZXItU2VtaUJvbGQ=;
                                font-family: "Inter-Bold", "Inter", "Inter Placeholder", sans-serif;
                                --framer-font-size: 17px;
                                --framer-font-weight: 600;
                                --framer-letter-spacing: -0.34px;
                                --framer-line-height: 35px;
                                --framer-text-alignment: center;
                                --framer-text-color: rgb(241, 245, 249);

                                .framer-text {
                                    --framer-font-weight: 400
                                }
                            }
                        }
                    }



                    .ssr-variant,
                    .hidden-18ccrvo,
                    .hidden-72rtr7 {
                        .framer-wla99d {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            .framer-text {
                                --font-selector: SW50ZXItU2VtaUJvbGQ=;
                                font-family: "Inter-Bold", "Inter", "Inter Placeholder", sans-serif;
                                --framer-font-size: 14px;
                                --framer-font-weight: 600;
                                --framer-letter-spacing: -0.34px;
                                --framer-line-height: 35px;
                                --framer-text-alignment: center;
                                --framer-text-color: rgb(241, 245, 249);

                                .framer-text {
                                    --font-selector: SW50ZXI=;
                                    --framer-font-weight: 400
                                }
                            }
                        }
                    }

                }

                .framer-1chwo98 {
                    opacity: 1;
                    transform: perspective(1200px);

                    .framer-gj8r3p {
                        .framer-1ca8cvs-container {

                            .ssr-variant,
                            .hidden-p5w47l,
                            .hidden-18ccrvo {
                                h1 {
                                    background-image: url(https://framerusercontent.com/images/bTF3xblyn2uyR2rZCo89tUf9mVE.svg);
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    background-position: 0% 0%;
                                    background-clip: text;
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    text-fill-color: transparent;
                                    color: rgb(136, 136, 136);
                                    font-size: 80px;
                                    font-family: "Inter-Bold", "Inter", "Inter Placeholder", sans-serif;
                                    font-style: normal;
                                    font-weight: 600;
                                    text-align: center;
                                    letter-spacing: -4px;
                                    white-space: normal;
                                    line-height: 1;
                                    margin: 0;
                                    padding: 3px
                                }
                            }

                            .ssr-variant,
                            .hidden-18ccrvo,
                            .hidden-72rtr7 {
                                h1 {
                                    background-image: url(https://framerusercontent.com/images/bTF3xblyn2uyR2rZCo89tUf9mVE.svg);
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    background-position: 0% 0%;
                                    background-clip: text;
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    text-fill-color: transparent;

                                    @media only screen and (max-width: 809px) {
                                        font-size: 42px;
                                    }

                                    color: rgb(136, 136, 136);

                                    font-family: "Inter-Bold",
                                    "Inter",
                                    "Inter Placeholder",
                                    sans-serif;
                                    font-style: normal;
                                    font-weight: 600;
                                    text-align: center;
                                    letter-spacing: -2px;
                                    white-space: normal;
                                    line-height: 1;
                                    margin: 0;
                                    padding: 3px
                                }
                            }

                            .ssr-variant,
                            .hidden-p5w47l,
                            .hidden-72rtr7 {
                                background-image: url(https://framerusercontent.com/images/bTF3xblyn2uyR2rZCo89tUf9mVE.svg);
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: 0% 0%;
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                text-fill-color: transparent;
                                color: rgb(136, 136, 136);

                                @media only screen and (max-width: 1439px) {
                                    font-size: 64px;
                                }

                                font-family: "Inter-Bold",
                                "Inter",
                                "Inter Placeholder",
                                sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                text-align: center;
                                letter-spacing: -4px;
                                white-space: normal;
                                line-height: 1;
                                margin: 0;
                                padding: 3px
                            }
                        }

                        .framer-1f73tpq {
                            outline: none;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            flex-shrink: 0;
                            transform: none;

                            h1 {
                                span {
                                    background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.4) 100%)
                                }
                            }
                        }
                    }

                    .framer-1jnfu0l {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        transform: none;

                        h1 {
                            span {
                                background-image: linear-gradient(179deg, rgb(248, 250, 252) 0%, rgba(248, 250, 252, 0.4) 100%)
                            }
                        }
                    }
                }

                .ssr-variant,
                .hidden-p5w47l {
                    .framer-1gjs8ze {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        opacity: 1;
                        transform: perspective(1200px)
                    }
                }

                .ssr-variant,
                .hidden-18ccrvo,
                .hidden-72rtr7 {
                    .framer-1gjs8ze {
                        outline: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        flex-shrink: 0;
                        opacity: 1;
                        transform: perspective(1200px)
                    }

                }

            }

            .framer-wpl817 {

                .ssr-variant,
                .hidden-p5w47l,
                .hidden-18ccrvo {
                    opacity: 1;
                    transform: perspective(1200px) translateX(0px) translateY(8px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) translateZ(0);

                    .ssr-variant,
                    .hidden-p5w47l,
                    .hidden-18ccrvo {
                        .framer-1yfo5ku-container {
                            transform: translateX(-50%);

                            .framer-M5MRE,
                            .framer-v-86m2yc {
                                display: contents;

                                .framer-86m2yc {
                                    border-bottom-left-radius: 54px;
                                    border-bottom-right-radius: 54px;
                                    border-top-left-radius: 54px;
                                    border-top-right-radius: 54px;

                                    .framer-dbgpm1 {
                                        backdrop-filter: blur(60px);
                                        -webkit-backdrop-filter: blur(60px);
                                        image-rendering: pixelated;
                                        flex-shrink: 0;
                                        fill: rgba(0, 0, 0, 1);
                                        color: rgba(0, 0, 0, 1);

                                        .svgContainer {
                                            width: 100%;
                                            height: 100%;
                                            aspect-ratio: inherit;

                                            svg {
                                                width: 100%;
                                                height: 100%;


                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    ssr-variant hidden-18ccrvo hidden-72rtr7 {}

                    ssr-variant hidden-p5w47l hidden-72rtr7 {}

                    framer-14k2fe4 {}
                }

                .ssr-variant,
                .hidden-18ccrvo,
                .hidden-72rtr7 {
                    .framer-1yfo5ku-container {
                        transform: translate(-50%, -50%);

                        .framer-M5MRE,
                        .framer-v-86m2yc {
                            display: contents;

                            .framer-86m2yc {
                                border-bottom-left-radius: 54px;
                                border-bottom-right-radius: 54px;
                                border-top-left-radius: 54px;
                                border-top-right-radius: 54px;
                                height: 100%;
                                width: 100%;

                                .framer-dbgpm1 {
                                    backdrop-filter: blur(60px);
                                    -webkit-backdrop-filter: blur(60px);
                                    image-rendering: pixelated;
                                    flex-shrink: 0;
                                    fill: rgba(0, 0, 0, 1);
                                    color: rgba(0, 0, 0, 1);

                                    .svgContainer {
                                        width: 100%;
                                        height: 100%;
                                        aspect-ratio: inherit;

                                        svg {
                                            width: 100%;
                                            height: 100%
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .ssr-variant,
                .hidden-p5w47l,
                .hidden-72rtr7 {
                    .framer-1yfo5ku-container {
                        transform: translate(-50%, -50%);

                        .framer-M5MRE,
                        .framer-v-86m2yc {
                            display: contents;

                            .framer-86m2yc {
                                border-bottom-left-radius: 54px;
                                border-bottom-right-radius: 54px;
                                border-top-left-radius: 54px;
                                border-top-right-radius: 54px;

                                .framer-dbgpm1 {
                                    backdrop-filter: blur(60px);
                                    -webkit-backdrop-filter: blur(60px);
                                    image-rendering: pixelated;
                                    flex-shrink: 0;
                                    fill: rgba(0, 0, 0, 1);
                                    color: rgba(0, 0, 0, 1);

                                    .svgContainer {}
                                }
                            }
                        }
                    }
                }

                .framer-14k2fe4 {
                    div {
                        position: absolute;
                        border-radius: inherit;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            border-radius: inherit;
                            object-position: center;
                            object-fit: cover;
                            image-rendering: auto;
                        }
                    }
                }
            }

            .framer-1nwyw2w-container {
                width: 100%;
                height: 100%;
                aspect-ratio: inherit;
                height: 61px;

                section {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                    max-height: 100%;
                    place-items: center;
                    margin: 0px;
                    padding: 10px;
                    list-style-type: none;
                    opacity: 1;
                    -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%);
                    overflow: hidden;

                    ul {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        max-width: 100%;
                        max-height: 100%;
                        place-items: center;
                        margin: 0;
                        padding: 0;
                        list-style-type: none;
                        text-indent: none;
                        gap: 60px;
                        position: relative;
                        flex-direction: row;
                        will-change: transform;
                        transform: translateX(-0px);

                        li {
                            .framer-1b5foaf {
                                flex-shrink: 0;

                                .framer-15rf8eu {
                                    .framer-h6hqo4 {
                                        image-rendering: pixelated;
                                        flex-shrink: 0;
                                        fill: black;
                                        color: black;

                                        .svgContainer {
                                            width: 100%;
                                            height: 100%;
                                            aspect-ratio: inherit;

                                            svg {
                                                width: 100%;
                                                height: 100%;
                                            }
                                        }
                                    }
                                }

                                .framer-1yspppi {
                                    .framer-x9fjzq {
                                        image-rendering: pixelated;
                                        flex-shrink: 0;
                                        fill: black;
                                        color: black;

                                        .svgContainer {
                                            width: 100%;
                                            height: 100%;
                                            aspect-ratio: inherit;

                                            svg {
                                                width: 100%;
                                                height: 100%
                                            }
                                        }
                                    }
                                }

                                .framer-euc7iw {
                                    .framer-bgc34j {
                                        image-rendering: pixelated;
                                        flex-shrink: 0;
                                        fill: black;
                                        color: black;

                                        .svgContainer {
                                            width: 100%;
                                            height: 100%;
                                            aspect-ratio: inherit;

                                            svg {
                                                width: 100%;
                                                height: 100%;
                                            }
                                        }
                                    }
                                }

                                .framer-1wcn9io {
                                    .framer-1qxxihe {

                                        image-rendering: pixelated;
                                        flex-shrink: 0;
                                        fill: black;
                                        color: black;

                                        .svgContainer {
                                            width: 100%;
                                            height: 100%;
                                            aspect-ratio: inherit;

                                            svg {
                                                width: 100%;
                                                height: 100%
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}